import React, { MouseEventHandler} from 'react'
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import {proyecciones} from '../proyecciones'
import * as proyeccionCtrl from '../ProyeccionesService'

interface Props {
    show: boolean,
    proyeccion: proyecciones,
    onHide: MouseEventHandler<HTMLElement>|any,
    afterHideModalDeleteProy: ()=> void
}
function ModalDeleteProy(props: Props) {
    
    const handleDeleteProyeccion = async(idProyeccion: string)=>{
        await proyeccionCtrl.deleteOneProyeccion(idProyeccion)
        props.afterHideModalDeleteProy();
        toast.warning("Eliminado")
    }
    return (
        <>
           <Modal
                show={props.show}
                onHide={props.onHide}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>  
                    <Modal.Title id="contained-modal-title-vcenter" className="text-center">
                        Eliminar Proyeccion <br/> # {props.proyeccion.numeroProyeccion} ?
                    </Modal.Title>                  
                </Modal.Header>

                <div className="p-2">
                    <button className=" mt-3 btn btn-lg btn-danger btn-block"
                        onClick={()=> handleDeleteProyeccion(props.proyeccion._id)}
                    >
                        Eliminar
                    </button>
                    <button className="mt-4 btn btn-lg btn-outline-dark btn-block">
                        Cancelar
                    </button>
                </div>

              
            </Modal> 
        </>
    )
}

export default ModalDeleteProy
