import React, { MouseEventHandler, FormEvent, useEffect, useState, ChangeEvent } from 'react'
import { Modal } from 'react-bootstrap';
import { rangoFechasPrint, listObjetivos, regObjetivo } from '../typesCalidadProduccion'

import * as calidadProdServices from '../CalidadProduccionServices'
import { toast } from 'react-toastify';
import moment from 'moment';
import gifLoad from '../../../Media/gifs/load.gif'

type InputChange = ChangeEvent<HTMLInputElement | HTMLSelectElement>;
interface Props {
    show: boolean,
    onHide: MouseEventHandler<HTMLElement>,
    objetivos: any,
    afterHidenModal: () => void,
    setLoadDiv: (state:boolean)=> void
}
function ModalRegObjetivos(props: Props|any) {
    const [listObjetivos, setListObjetivos] = useState<listObjetivos[]>([])
    const [fechas, setFechas] = useState({ fechaPrincipal: '', fechaCopia: '' })
    const [categoriaObjetivos, setCategoriaObjetivos] = useState()
    const [loadDiv, setLoadDiv] = useState(false)
    const changeFechas = (e: InputChange) => {
        setFechas({ ...fechas, [e.target.name]: e.target.value })
    }

    const getRegistroByFecha = async () => {
        const resRegObjetivos = await calidadProdServices.getRegistrosByOneFecha(fechas.fechaPrincipal)
        const newListObjetivos = [...listObjetivos]
        console.log(resRegObjetivos)
        if (resRegObjetivos.data.length! <= 0) {
            for (let i = 0; i < listObjetivos.length; i++) {
                const oneObjetivo = listObjetivos[i];
                newListObjetivos[i].valueInput = 0;
            }
            setListObjetivos(newListObjetivos)
            return toast.warning("Fecha sin Registros")
        } else {
            for (let i = 0; i < listObjetivos.length; i++) {
                const oneObjetivo = listObjetivos[i];
                const findValueObjetivo = resRegObjetivos.data.find((regObjetivo: regObjetivo) => regObjetivo.idObjetivo === oneObjetivo._id)                
                if (findValueObjetivo) {
                    newListObjetivos[i].valueInput = findValueObjetivo.cantidad
                }
            }
            setListObjetivos(newListObjetivos)
        }
    }
    const newListObjetivos = [...listObjetivos]
    const changeInputValue = (e: InputChange) => {        
        const findIndexListObj = listObjetivos.findIndex((objetivo) => objetivo._id === e.target.id)
        // setListObjetivos({...listObjetivos, [e.target.id]: e.target.value})
        newListObjetivos[findIndexListObj].valueInput = parseFloat(e.target.value);
        setListObjetivos(newListObjetivos)        
    }
    const getRegObjToDuplicate = async() =>{
        if(!fechas.fechaCopia) return toast.info("Seleccione fecha de copiado")
        const resRegObjToDuplicate = await calidadProdServices.getRegistrosByOneFecha(fechas.fechaCopia)
        
        console.log(resRegObjToDuplicate.data)
        if (resRegObjToDuplicate.data.length !<= 0) {
            for (let i = 0; i < listObjetivos.length; i++) {
                const oneObjetivo = listObjetivos[i];
                newListObjetivos[i].valueInput = 0;
            }
            setListObjetivos(newListObjetivos)
            return toast.warning("Fecha sin Registros")
        } else {
            for (let i = 0; i < listObjetivos.length; i++) {
                const oneObjetivo = listObjetivos[i];
                const findValueObjetivo = resRegObjToDuplicate.data.find((regObjetivo: regObjetivo) => regObjetivo.idObjetivo === oneObjetivo._id)                
                if (findValueObjetivo) {
                    newListObjetivos[i].valueInput = findValueObjetivo.cantidad
                }
            }
            setListObjetivos(newListObjetivos)
        }
    }
    const submitListRegObjetivos = async(e: FormEvent<HTMLFormElement>)=>{
        e.preventDefault();
       try {
            props.setLoadDiv(true)
            if (!fechas.fechaPrincipal) {
                props.setLoadDiv(false);
                return toast.warning("Fecha no seleccionada")
            }
            const dataFechaAndObj = {
                RegObjetivos: listObjetivos,
                fechaPrincipal: fechas.fechaPrincipal
            }
            const resSaveRegObj = await calidadProdServices.crearRegistroObjetivosPorFecha(dataFechaAndObj)
            props.setLoadDiv(false);
            props.afterHidenModal();
            toast.success("Objetivos Guardados");
       } catch (error:any) {
        props.setLoadDiv(false);  
        toast.error(error.message)
       }
    }
    useEffect(() => {
        if (props.show) {
            setListObjetivos(props.objetivos)
        }else{
            setListObjetivos([]);
            setFechas({ fechaPrincipal: '', fechaCopia: '' })
        }
    }, [props.show])
    return (
        <div className={`pt-5`}>
           
            <Modal
                show={props.show}
                onHide={props.onHide}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                
            >
                <Modal.Header style={{background: '#cfd1d0'}}  closeButton>
                    <h3 className="text-dark">Configuracion de Objetivos</h3>
                </Modal.Header>
                <Modal.Body style={{background: '#cfd1d0'}} >
                    
                    <div className="d-flex flex-wrap justify-content-center ">
                        <div className='p-3'>
                            <input type="date" className="form-control form-control-lg "
                                name="fechaCopia"
                                value={fechas.fechaCopia}
                                onChange={changeFechas}
                            />
                        </div>
                        <div className="p-3 text-center text-dark" style={{ cursor: "pointer" }}
                            onClick={getRegObjToDuplicate}
                        >
                            <i className="far fa-copy" style={{ fontSize: '40px' }}></i>
                        </div>

                    </div>
                    <div className="d-flex flex-wrap justify-content-center ">
                        <div className='p-3'>
                            <input type="date" className="form-control form-control-lg "
                                name="fechaPrincipal"
                                onChange={changeFechas}
                                value={fechas.fechaPrincipal}
                            />
                        </div>
                        <div className="p-3 text-center text-dark" style={{ cursor: "pointer" }}
                            onClick={getRegistroByFecha}
                        >
                            <i className="fas fa-search" style={{ fontSize: '40px' }}></i>
                        </div>
                    </div>

                    <div className="col-12 border-bottom  bg-primary" style={{ height: '2px' }}>
                    </div>
                    <form
                    onSubmit={submitListRegObjetivos}
                    >
                        <div className="d-flex flex-wrap justify-content-center">
                            {listObjetivos?.map((objetivo: listObjetivos) => {
                                return (
                                    <div className="p-2 mx-auto col-12 col-sm-12 col-md-6">
                                        <label htmlFor="" className="text-dark "> <strong >{objetivo.nameObjetivo} - {objetivo.nameCategorio} </strong></label>
                                        <input className="form-control form-control-lg"
                                            style={{ fontSize: '16px', margin: "0px", padding: "2px" }}
                                            name="fechaRegistro"
                                            type="number"
                                            step="any"
                                            id={objetivo._id}
                                            onChange={changeInputValue}
                                            value={objetivo?.valueInput}
                                            required />
                                    </div>
                                )
                            })}

                        </div>
                        <div className="m-3 p-3 col-12 col-sm-8 mx-auto ">
                            <button className='btn btn-outline-light btn-block btn-lg'><h1> GUARDAR CAMBIOS</h1></button>
                        </div>  
                    </form>
                </Modal.Body>

            </Modal>
        </div>
    )
}

export default ModalRegObjetivos
