import React, { useState, useContext, useEffect, ChangeEvent} from 'react'
import { useNavigate } from 'react-router-dom'
import authContext from '../../../context/AuthContext'
import { categoria, subCateogria, input, listRegInputs } from '../typesCalidadProduccion'
import * as CalidadProduccionServices from '../CalidadProduccionServices'
import ModalFormInput from './ModalFormInput'
import { toast } from 'react-toastify';
import moment from 'moment'
import momentz from 'moment-timezone';
type InputChange = ChangeEvent<HTMLInputElement | HTMLSelectElement>;
function Inputs() {
    const { loggedIn } = useContext(authContext);
    const history = useNavigate();

    const initialRegInput ={_id: '', fechaRegistro: '', cantidad: 0, input:{_id: '',nameInput: ''},usuario:{_id: '',nombre: ''}}
    const [listCategorias, setListCategorias] = useState<categoria[]>([])
    const [listSubCategorias, setListSubCategorias] = useState<subCateogria[]>([])
    const [listInputs, setListInputs] = useState<input[]>([])
    const [Input, setInput] = useState({_id: '', nameInput: '', uMedida:{nombre: ''}})
    const [stateModalForm, setStateModalForm] = useState(false)
    const [listRegistrosInput, setListRegistrosInput] = useState<listRegInputs[]>([])
    const [registroInput, setRegistroInput] = useState<listRegInputs>(initialRegInput)

    const getCategorias = async () => {
        const resCategorias = await CalidadProduccionServices.getCatagoriaInputs(loggedIn._id)
        console.log(resCategorias)
        if (resCategorias.data.length > 0) {
            setListCategorias(resCategorias.data);
        } else {
            toast.error("Usuario sin Categorias Asignadas")
        }
    }
    const changeCategoria = async (e: any) => {
        const valueCat = e.target.value;
        setListSubCategorias([])
        if (!valueCat) return false;
        const idSubCategoria = await CalidadProduccionServices.getSubCategoriaInputs('' + loggedIn._id + ',' + valueCat + '')
        if (idSubCategoria.data.length > 0) {
            setListSubCategorias(idSubCategoria.data);
        } else {
            toast.error("SubCategorias sin Asignar")
        }
    }
    const changeSubCategoria = async (e: any) => {
        setListInputs([])
        
        const resInputs = await CalidadProduccionServices.getInputsBySubCat(e.target.value)
        setListInputs(resInputs.data);
    }
    const getAllRegInputs = async(id : string) =>{
        const resAllRegInputs = await CalidadProduccionServices.getAllRegistrosInput(id)
        if(!resAllRegInputs.data[0]){
            setListRegistrosInput([])
            return toast.error("Sin registros")
        }
        setListRegistrosInput(resAllRegInputs.data)
    }
    const changeInput = async(e:InputChange) =>{
        const idInput = e.target.value;        
            const input: input|undefined = listInputs.find((input)=> input._id === idInput);
            if (input) {
                getAllRegInputs(input._id)
                setInput(input);          
            }            
    }
    const crearRegistro = async()=>{        
        if (!Input.nameInput || !Input._id) return toast.error("Seleccione Input")        
        setStateModalForm(true);
    }
    const editarRegistro = async(idReg:string)=>{
       
        if (!Input.nameInput || !Input._id) return toast.error("Seleccione Input")  
        const resRegInput = listRegistrosInput.find((regInputs)=>regInputs._id === idReg)      
        const today = new Date();
        if (momentz.tz(today, "America/Lima").format('DD-MM-YYYY') !== momentz.tz(resRegInput?.fechaRegistro, "America/Lima").format('DD-MM-YYYY')) {
            return toast.error("Registro Vencido")
        }
        if (resRegInput) {
            setRegistroInput(resRegInput)
        }else{
            return toast.error("Registro no encontrado")
        }
        setStateModalForm(true);
    }
    const hideModalForm = async()=>{
        getAllRegInputs(Input._id)
        setStateModalForm(false);
        setRegistroInput(initialRegInput);        
    }

    const afterHidenModalForm = () =>{
        hideModalForm();
    }
    useEffect(() => {
        getCategorias();
    }, [])
    return (
        <div>
            <div className="text-center col-12 col-sm-4 mx-auto p-3">
                <h3><strong>Registro de Inputs</strong></h3>
            </div>
            <div className="d-flex flex-wrap justify-content-center">
                <div className="p-2">

                    <select className="form-control form-control-lg border-dark" name="categoria"
                        onChange={changeCategoria} >
                        <option value="">Seleccione Categoria</option>
                        {listCategorias.map((categoria) => {
                            return (
                                <option value={categoria.categoria._id}>{categoria.categoria.nombre}</option>
                            )
                        })}
                    </select>
                </div>
                <div className="p-2">
                    <select className="form-control form-control-lg border-dark" name="subCategoria"
                        onChange={changeSubCategoria}
                    >
                        <option value="">Seleccione SubCategoria</option>
                        {listSubCategorias.map((subCategoria) => {
                            return (
                                <option value={subCategoria.subCategoria._id}>{subCategoria.subCategoria.nombre}</option>
                            )
                        })}
                    </select>
                </div>
                <div className="p-2">
                    <select className="form-control form-control-lg border-dark" name="input"   
                        onChange={changeInput}                     
                    >
                        <option value="">Seleccione INPUT</option>
                        {listInputs.map((input) => {
                            return (
                                <option value={input._id}>{input.nameInput}</option>
                            )
                        })}
                    </select>
                </div>
                
            </div>
            <div className="text-center pt-4 pb-2">
                <button className="btn btn-info btn-lg"
                    onClick={crearRegistro}
                    >
                    Crear Registro
                </button>
            </div>
            <div className="table-responsive mx-auto col-12 col-sm-8 col-md-6">
                <table className="table   table-hover table-info border border-info  tableFixHead">
                    <thead>
                        <tr>
                            <th scope="col" style={{ minWidth: '120px' }}className="">INPUT</th>
                            <th scope="col"  style={{ minWidth: '100px' }} className="">FECHA</th>
                            <th scope="col" style={{ minWidth: '60px' }}className="">{Input.uMedida.nombre ? (Input.uMedida.nombre): ('Unidad Medida')}</th>
                            <th scope="col"   className=""></th>
                        </tr>
                    </thead>
                    <tbody>
                        {listRegistrosInput.map((regInput)=>{
                            return <tr  id={regInput._id} key={regInput._id} className="align-items-center" onClick={()=>editarRegistro(regInput._id)}>
                            <td>{regInput.input.nameInput}</td>
                            <td>{moment(regInput.fechaRegistro).format('DD-MM-YYYY')}</td>
                            <td>{regInput.cantidad}</td>
                            <td><i style={{fontSize:"35px" , cursor:'pointer'}} className="far fa-edit" ></i> 
                                {/* <i style={{fontSize:"35px", cursor:'pointer'}} className="fas fa-times pl-4"></i> */}
                                </td>                            
                        </tr>
                        })}
                    </tbody>
                </table>
            </div>
            <ModalFormInput show={stateModalForm} onHide={hideModalForm} input={Input} idUsuario={loggedIn._id} afterHidenModal={afterHidenModalForm} registroInput={registroInput}/>
        </div>
        
    )
}

export default Inputs
