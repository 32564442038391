import React, { useState, useEffect, ChangeEvent, FormEvent, FormEventHandler } from 'react'
import {busqueda, resTransportista, showModalFlete} from './pagoTransportista'
import * as transportistaCtrl from '../Transportistas/TrasportistaService'
import ModalConfirm from '../Modals/ModalConfirm'
import { toast } from 'react-toastify';
import { transportes ,transportesResumen, totalesTransporte } from '../Transportistas/transportista';
import moment from 'moment'
import {saveAs} from 'file-saver'
const noTruncarDecimales = {maximumFractionDigits: 20};
type InputChange = ChangeEvent<HTMLInputElement | HTMLSelectElement>;
function PagoTransporteList() {
    
    const [busqueda, setbusqueda] = useState<busqueda>({
        textBusqueda:'',
        transportista:'',
        fechaInicio:'',
        fechaFin:'',
        guiaRemision:''
    });

    const [resTransportista, setresTransportista] = useState<resTransportista[]>([]);///estado para almacenar transportistas en el select
    const [transportes, setTransportes] = useState<transportes[]>([]); ////listar transportes en tabla
    const [fleteo, setFleteo] = useState<totalesTransporte>({ totalJabas: 0, totalPeso: 0, montoPorJaba: 0, flete: 0, igv: 0, fleteSubTotal: 0, detraccion: 0, fleteaPagar: 0, factura: '', resumenApagar:0});//Configuramos Fleteo para asignar montos  y tambien facctura
    const [showFleteModal, setShowFleteModal] = useState<showModalFlete>({ estado: false, idTransportes: [], solJaba: 0, factura:'' });
    const [PushModal, setPushModal] = useState('');
    const [checkBoxs, setcheckBoxs] = useState(false)//Controlamos la apricion de checkboxs en el listado
    const [loadDiv, setLoadDiv] = useState(false)
    

    const [styleModal, setStyleModal] = useState({
        title:'',
        color:''
    })  
 
    const handleFlete = (e:InputChange)=>{
        setFleteo({...fleteo, factura:e.target.value})
    }
    const getDataBusqueda = async() => {          
          try {  
            setLoadDiv(true) 
              setcheckBoxs(false)         
            setShowFleteModal({...showFleteModal, idTransportes:[]})        
            const resTransporteVsCosecha = await transportistaCtrl.getTransporteVsFechaServer(busqueda);
            const allTransportes = resTransporteVsCosecha.data.resBusqueda;  
            const arrayIds = []          
            if(allTransportes.length>0){                
                setTransportes(resTransporteVsCosecha.data.resBusqueda);
                const totalJabas = resTransporteVsCosecha.data.data2.totalJabas;
                const totalPesos = resTransporteVsCosecha.data.data2.totalPeso;
                const totalApagar = resTransporteVsCosecha.data.data2.totalApagar;
                
                for (let index = 0; index < allTransportes.length; index++) {
                    const oneTransporte = allTransportes[index];                    
                    arrayIds.push(oneTransporte._id+'_,'+oneTransporte.jabas+'_,'+oneTransporte.peso)                
                }          
                setFleteo({...fleteo, totalJabas:totalJabas , totalPeso: totalPesos, resumenApagar: totalApagar , montoPorJaba: 0, flete: 0, igv: 0, fleteSubTotal: 0, detraccion: 0, fleteaPagar: 0, factura: ''})                
            }else{
                setTransportes([]);
                setFleteo({...fleteo, totalJabas:0, totalPeso:0})
                toast.info("No hay registros")
            }  
            setShowFleteModal({...showFleteModal, idTransportes:arrayIds, estado:false})  
            toast.success("Todo OK")  
            setLoadDiv(false)      
        } catch (error:any) {
            toast.error(error.message)
            setLoadDiv(false)
        }
    }
    const setAllPesoJabas = async()=>{   
        try {
            setLoadDiv(true)
            setcheckBoxs(true)
            setShowFleteModal({...showFleteModal, idTransportes:[], estado: false})            
            const resTransporteVsCosecha = await transportistaCtrl.getTransporteVsFechaServer(busqueda);            
            const allTransportes = resTransporteVsCosecha.data.resBusqueda;
            if(allTransportes.length>0){                
                setTransportes(resTransporteVsCosecha.data.resBusqueda);                                               
                setFleteo({...fleteo, totalJabas: 0, totalPeso: 0, resumenApagar: 0 , montoPorJaba: 0, flete: 0, igv: 0, fleteSubTotal: 0, detraccion: 0, fleteaPagar: 0, factura: ''})                
            }else{
                setTransportes([]);
                setFleteo({...fleteo, totalJabas:0, totalPeso:0})
                toast.info("No hay registros")
            }   
            setLoadDiv(false)         
        } catch (error:any) {
            toast.error(error.message)
            setLoadDiv(false)
        }
    }
    const handleSubmitBusqueda = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        getDataBusqueda();        
    }
    const busquedaTransportista = async(e:any) => {
        e.preventDefault();
        try {
            setLoadDiv(true)
                const resTransportistas = await transportistaCtrl.textBusquedaTransportistaServer(busqueda.textBusqueda)
                if(resTransportistas.data.length>0){
                    setresTransportista(resTransportistas.data)
                }else{
                    setresTransportista([]);
                    return toast.error("No hay registros =(")
                }   
            
                setLoadDiv(false)    
        } catch (error:any) {
            setresTransportista([]);
            toast.error("Error de proceso")
            setLoadDiv(false)
        }
    }
    const handleChange= (e:any )=>{
        setbusqueda({...busqueda, [e.target.name]: e.target.value})
        
    }
    const setFleteJaba = (e:InputChange)=>{        
        setFleteo({...fleteo, [e.target.name]: e.target.value})
    }
    const calculoFlete= ()=>{
        const flete =parseFloat(parseFloat((fleteo.montoPorJaba*fleteo.totalJabas).toString()).toFixed(2)); ;
        const igvFlete = parseFloat(parseFloat((flete*0.18).toString()).toFixed(2));
        const fleteSubTotal = parseFloat(parseFloat((flete*0.82).toString()).toFixed(2));
        const detraccion = parseFloat(parseFloat((flete*0.04).toString()).toFixed(2)); 
        const fleteaPagar = parseFloat(parseFloat((fleteSubTotal-detraccion).toString()).toFixed(2));
        setFleteo({...fleteo, igv: igvFlete, fleteSubTotal: fleteSubTotal, flete:flete, detraccion: detraccion, fleteaPagar: fleteaPagar})
    }
    const handleCloseModal = () => setShowFleteModal({ ...showFleteModal, estado: false, solJaba: 0, factura:'' });
    const vaciarIDsToModal = () =>{setShowFleteModal({...showFleteModal, idTransportes:[]})}
    const handleShowModal = (e: any) => {
               
        if(showFleteModal.idTransportes.length===0) return toast.error("No hay Transportes Seleccionados");
        if (e.target.name==='btnSetFactura') {
            console.log(fleteo)
            if(fleteo.factura==='' ) return toast.error("Factura no configurada") 
            setStyleModal({title: 'ASIGNACION DE FACTURAS', color: 'warning'})
        } if(e.target.name==='btnSetFlete'){
            if(fleteo.montoPorJaba<=0 ) return toast.error("El monto debe ser mayor a 0") 
            setStyleModal({title: 'ASIGNACION DE FLETE', color: 'info'})
        }
        setPushModal(e.target.name);
        setShowFleteModal({ ...showFleteModal, estado: true, solJaba: fleteo.montoPorJaba, factura: fleteo.factura });
    }
   
    const setIdCosecha = (e:any) => { 
        const estadoCheckBox = e.target.checked;  
        const arrayIdsJabaPeso = e.target.name;
        const idFila = e.target.name.split('_,');
        var totalJabas= fleteo.totalJabas;
        var totalPeso= fleteo.totalPeso;
        var resumenApagar = fleteo.resumenApagar;
        const existeID = showFleteModal.idTransportes.indexOf(arrayIdsJabaPeso); //En esta parte verificarmos si existe el id         
        if (existeID>=0 && !estadoCheckBox) { // Si existeID es  Mayor o igual a 0 indica que el id si Existe y el Check Box esta en false por lo que debe entrar para eliminarse          
            showFleteModal.idTransportes.splice(existeID, 1); //Eliminamos el indice encontrado en existeID  
            var valorTotalJabas = fleteo.totalJabas - parseFloat(idFila[1]);
            var valorTotalPeso =  fleteo.totalPeso - parseFloat(idFila[2])
            if(valorTotalJabas<0){
                setFleteo({...fleteo, totalJabas:0 , totalPeso: 0})
            }else{
                setFleteo({...fleteo, totalJabas: valorTotalJabas , totalPeso:valorTotalPeso })
            }         
        }else if(estadoCheckBox){
            showFleteModal.idTransportes.push(idFila[0]+'_,'+idFila[1]+'_,'+idFila[2]+'_,'+idFila[3]); // Adicionamos el ID en el array para el MODAL
            setFleteo({...fleteo, totalJabas: totalJabas+parseFloat(idFila[1]) , totalPeso: totalPeso+parseFloat(idFila[2]), resumenApagar: resumenApagar+parseFloat(idFila[3])})    
        }                   
    }    
    const pdfBlob = async(res:any)=>{
        const pdfBlob= await new Blob([res.data], {type: 'appication/pdf'})
        saveAs(pdfBlob, 'PdfPagoTransportes.pdf')
       }
    const getPdfReporte = async() =>{
        
        try {
            setLoadDiv(true)
            const resPdfTransportes = await transportistaCtrl.pdfListTransportesServer(transportes);
            const getPdfTransportes = await transportistaCtrl.SendPdfTransportesServer();
            pdfBlob(getPdfTransportes);
            setLoadDiv(false)
        } catch (error:any) {
            toast.error(error.message)
            setLoadDiv(false)
        }
        
    }
    useEffect(() => {
       calculoFlete();
    }, [fleteo?.montoPorJaba])
    return (
        <div className={`${loadDiv?'loadDiv':''}`} >
            <div className="text-center ">
                <h3>PAGO DE TRASPORTISTAS                    
                </h3>
            </div>
                       
                <form onSubmit={handleSubmitBusqueda}>
                <div className="row mt-5 ">    
                        <div className="form-group row col-12 col-md-1 align-self-end">                    
                            <input type="text" className="form-control" name="textBusqueda" value={busqueda.textBusqueda} 
                            // onKeyDown={busquedaTransportista}  
                            onChange={handleChange} placeholder="Transportista"/>                                               
                        </div>
                        <div className="form-group col-12 col-md-1 align-self-end ">
                            <button className="btn btn-outline-dark"  onClickCapture={busquedaTransportista} ><i className="fas fa-search schFormulario"></i></button>
                        </div>
                        <div className="form-group col-12 col-md-3 align-self-end">                                                                            
                            <select name="transportista"  onChange={handleChange} className={`form-control form-select form-select-sm shadow`} aria-label=".form-select-sm example" required>
                                
                                {resTransportista[0]?(<option value="">Seleccione</option>):('')}
                                {resTransportista.map((transportista) => {
                                            return <option key={transportista._id} value={transportista._id}>{transportista.rasonSocial} - {transportista.ruc}</option>
                                        })}
                            </select>
                        </div>
                        <div className="form-group row col-12 col-md-2 align-self-end">                    
                            <input type="text" className="form-control" name="guiaRemision" value={busqueda.guiaRemision}  onChange={handleChange} placeholder="Guia Rem"/>                                               
                        </div>
                        <div className="form-group col-12 col-md-2 align-self-end">
                            <label htmlFor="fechaInicio">Fecha Inicio</label>
                            <input 
                            required
                            type="date" 
                            id="fechaInicio" 
                            name="fechaInicio" 
                            className="form-control"
                            max={busqueda.fechaFin}
                            value={busqueda.fechaInicio}
                            onChange={handleChange}
                            />
                        </div>
                        <div className="form-group col-12 col-md-2 align-self-end">
                            <label htmlFor="fechaFin">Fecha Fin</label>
                            <input 
                            required
                            type="date" 
                            id="fechaFin" 
                            name="fechaFin" 
                            className="form-control"
                            min={busqueda.fechaInicio}
                            value={busqueda.fechaFin}
                            onChange={handleChange}
                            />
                        </div>
                        <div className="row form-group col-12 col-md-1 align-self-end text-center">
                            <button className="btn btn-outline-success " ><i className="fas fa-search schFormulario"></i></button>
                            
                        </div> 
                        </div>   
                </form>
            
            <div className="row col-12 form-group mx-auto text-center justify-content-between">
                <div className=" form-group row col-12 col-md-6 fleteo">
                    <div className="col-12 m-1 p-0 border-bottom">
                        <label >Fleteo</label>
                    </div>
                    <div className="p-1">
                    <h5>Jabas: <strong className="montosFlete">{fleteo.totalJabas}</strong>&nbsp;&nbsp; </h5>
                    </div>                    
                    <div className="p-1">
                    <h5>Peso: <strong className="montosFlete">{fleteo.totalPeso.toLocaleString(undefined, noTruncarDecimales)} Kg</strong> </h5> 
                    </div>
                    <div className="  p-1">                            
                            <input 
                            required
                            placeholder="Soles Jaba"
                            type="number" 
                            step="any"
                            id="montoPorJaba" 
                            name="montoPorJaba" 
                            className=""
                            min='0'
                            value={fleteo.montoPorJaba}
                            onChange={setFleteJaba}
                            />
                        </div>
                        <div className="  text-center">
                            <button className="btn btn-outline-info btnsmall2" name="btnSetFlete" onClick={handleShowModal}>Asignar Flete</button>
                        </div>
                       <div className="row col-12">
                       <div className="p-1">
                        <h6>Flete:  <strong className="montosFlete">S/. {fleteo.flete}</strong> </h6> 
                        </div>
                        <div className="p-1">
                        <h6>IGV: <strong className="montosFlete">S/. {fleteo.igv}</strong></h6> 
                        </div>
                        <div className="p-1">
                        <h6>Flete Subtotal: <strong className="montosFlete">S/. {fleteo.fleteSubTotal}</strong></h6> 
                        </div>
                        <div className="p-1">
                        <h6>Detraccion: <strong className="montosFlete">S/. {fleteo.detraccion}</strong></h6> 
                        </div>
                        <div className="p-1">
                        <h6>A Pagar: <strong className="montosFlete">S/. {fleteo.fleteaPagar}</strong></h6> 
                        </div>
                       </div>
                </div>
                <div className="form-group col-12 col-md-3 row  afactura">
                <div className="col-12 m-1 p-0 border-bottom">
                        <label >Asignar Factura</label>
                    </div>
                    
                    <div className="  p-1">                            
                            <input 
                            required
                            placeholder="Factura"
                            type="text" 
                            id="asignarFactura" 
                            name="asignarFactura" 
                            className=""
                            
                            value={fleteo.factura}
                            onChange={handleFlete}
                            />
                        </div>
                        <div className="  text-center">
                            <button className="btn btn-outline-warning btnsmall2" name="btnSetFactura" onClick={handleShowModal}>Asignar Factura</button>
                        </div>
                        <div className="p-1">
                        <h6>Resumen A Pagar: <strong className="montosFlete">S/. {fleteo.resumenApagar.toFixed(2)}</strong></h6> 
                        </div>
                      
                </div>
            </div>
            <div className="row justify-content-end">
                <div>
                <i className="far fa-file-pdf p-3" onClick={getPdfReporte}></i>
                </div>
            </div>
            <div className="table-responsive table-striped col-12 mx-auto shadow mb-5 bg-body rounded trheight tableFixHead">

                <table className="table">
                    <thead>
                        <tr>
                            {checkBoxs?(<th scope="col" >Select</th>):('')}
                            <th scope="col">Fecha</th>
                            <th scope="col">Transportista</th>
                            <th scope="col">Guia Remision</th>
                            <th scope="col">Placa</th>
                            <th scope="col">Proveedor</th>
                            <th className="push" scope="col " onDoubleClick={setAllPesoJabas}>Jabas</th>
                            <th scope="col">Kg MP</th>
                            <th scope="col">S/ Jaba</th>
                            <th scope="col">S/ Flete</th>
                            <th scope="col">IGV 18%</th>
                            <th scope="col">S/ Flete SubTotal</th>
                            <th scope="col">4% Detrac</th>
                            <th scope="col">A Pagar</th>
                            <th scope="col">Factura</th>
                          
                        </tr>
                    </thead>
                    <tbody>
                        {transportes.map((transporte, index)=>{
                            return <tr key={transporte._id} >
                                {checkBoxs?(<td><input type="checkbox" 
                                className="checkTable"                                 
                                onClick={setIdCosecha} 
                                name={`${transporte._id}_,${transporte.jabas}_,${transporte.peso}_,${transporte.aPagar}`} 
                                id={transporte._id} /></td>):('')}
                                <td>{moment(transporte.fecha).format('DD-MM-YYYY')}</td>
                                <td>{transporte.transportista.rasonSocial}</td>
                                <td>{transporte.guiaRemision}</td>
                                <td>{transporte.placa}</td>
                                <td>{transporte.lote.proveedor.nombre}</td>
                                <td>{transporte.jabas}</td>
                                <td>{transporte.peso}</td>
                                <td>{transporte.solesJaba}</td>
                                <td>{transporte.solesFlete}</td>
                                <td>{transporte.igvFlete}</td>
                                <td>{transporte.solesFleteSubtotal}</td>
                                
                                <td>{transporte.detFlete}</td>
                                <td>{transporte.aPagar}</td>
                                <td>{transporte.factura}</td>
                            </tr>
                        })}
                        
                    </tbody>
                </table>

            </div>
            <ModalConfirm show={showFleteModal} btnModalType={PushModal} styleModal={styleModal}  onHide={handleCloseModal} dataUpdated={getDataBusqueda}/>
            {/* <ModalConfirm show={showFleteModal}  onHide={handleCloseModal} dataUpdated={setFacturacion}/> */}
        </div>
    )
}

export default PagoTransporteList
