import React, { useEffect, useState } from 'react'
import { UsuarioInterface } from './usuario'
import * as usuarioService from './UsuarioService'
import { toast } from 'react-toastify'
// import useStyles from "./styles";
import { useNavigate } from 'react-router-dom'

const UsuarioList = () => {
  const history = useNavigate();

  const [usuarios, setUsuarios] = useState<UsuarioInterface[]>([]);

  const loadUsuarios = async () => {
    const res = await usuarioService.getUsuarios()
    setUsuarios(res.data);
  }
  useEffect(() => {
    loadUsuarios();
  }, [])
  const handleDelete = async (id: string) => {
    try {
      await usuarioService.deleteUsuario(id);
      loadUsuarios();
      toast.warning('Usuario Eliminado');
    } catch (error:any) {
      if (error.message === 'Request failed with status code 303') {
        toast.error('No se puede eliminar, registros pendientes');
      }
    }
  }
  // const classes = useStyles();
  return (
    <>
      <div className="col-8 mx-auto">
        <div className="text-center ">
          <h3>USUARIOS  <svg onClick={() => history('/new-usuario')} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
          </svg></h3>
        </div>
      </div>
      <div className="table-responsive table-striped col-12 mx-auto shadow mb-5 bg-body rounded trheight tableFixHead">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">NOMBRE</th>
              <th scope="col">USUARIO</th>
              <th scope="col">TIPO DE USUARIO</th>
              <th scope="col">OPCIONES</th>
            </tr>
          </thead>
          <tbody>
            {usuarios.map((usuario) => {
              return <tr key={usuario._id}>
                <td>{usuario.nombre}</td>
                <td>{usuario.usuario}</td>
                <td>{usuario.tipo}</td>
                <td><svg onClick={() => history(`/update-usuario/${usuario._id}`)} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-pencil-fill" style={{ cursor: "pointer" }} viewBox="0 0 16 16">
                  <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                </svg> &nbsp;&nbsp;&nbsp;
                <svg onClick={() => usuario._id && handleDelete(usuario._id)} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-trash" style={{ cursor: "pointer" }} viewBox="0 0 16 16">
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                    <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                  </svg>
                </td>
              </tr>
            })}
          </tbody>
        </table>
      </div>

    </>
  )
}

export default UsuarioList
