import React, {useEffect, useState, MouseEventHandler } from 'react'
import { Button, Modal } from 'react-bootstrap';
import * as cogidasService from '../../Cogidas/CogidaService'
import * as loteService from '../../Lotes/LoteService'
import { cogida } from '../../Cogidas/cogida'
import momentz from 'moment-timezone'
import { toast } from 'react-toastify';
interface Props {
    show: {
        estado: boolean
        idLote: string
    };
    onHide: MouseEventHandler<HTMLElement>
}
interface datahead{
    area: string,
    cosecha: string
}

function ModalDash({ show, onHide }: Props| any ) {
    const idLote = show.idLote;
    
    const [cogidas, setCogidas] = useState<cogida[]>([]);
    const [dataHead, setDataHead] = useState<datahead>({
        area:'0',
        cosecha:'0'
    })
    // const [mainstate, setMainstate] = useState(false);

    // const setPesoTotal = ()=>{
    //     var totalPeso = 0;
    //     // setDataHead({...dataHead, cosecha: totalPeso }) ;
    //     if(cogidas.length>0){
    //         for (let index = 0; index < cogidas.length; index++) {
    //             const onePeso = cogidas[index].peso;
    //             totalPeso = onePeso + totalPeso;
    //         }
    //     // console.log(totalPeso, "TOTAL PESO");
        
    //     }
    //     // setDataHead({...dataHead, cosecha: Math.round(totalPeso) }) ;
    // }   
    // const setAreaLote = async ()=>{
    //     // setDataHead({...dataHead, area: '0', cosecha:0}) ;
    //     const resLote = await loteService.getLote(idLote);        
    //     setDataHead({...dataHead, area: resLote.data.areaLote}) ;
    // }
    const getCogidas = async () => {
        // console.log(idLote,"PINTANDO MI ID LOTE")
        const resCogidas = await cogidasService.getDetCogidaLocalidadDep(idLote);
        const allCosechas = resCogidas.data;
        const resLote = await loteService.getLote(idLote);        
         
        var totalPeso = 0;
        for (let index = 0; index < allCosechas.length; index++) {
            const oneCosecha = allCosechas[index];
            totalPeso = totalPeso+ oneCosecha.peso;
            // console.log("sumando", totalPeso);
        }   
        // console.log(totalPeso); 
        const roundPeso = parseFloat(totalPeso.toString()).toFixed(2)
        setCogidas(resCogidas.data);   
        setDataHead({...dataHead,area: resLote.data.areaLote, cosecha: roundPeso }) ;
    }
    const pdfBlob = async(res:any)=>{
        const pdfBlob= await new Blob([res.data], {type: 'appication/pdf'})
        // saveAs(pdfBlob, 'newPdf.pdf')
       }
    const PdfCosechasVsLote = async() =>{
        try {
            await cogidasService.PdfCosechasVsLote(idLote);
            const getPdfLote = await cogidasService.SendPdfCosechasVsLote();
            pdfBlob(getPdfLote);
        } catch (error) {
            toast.error('Verifique conexion caso contrario Consulte con su administrador')
        }
    }

    useEffect(() => { 
        console.log(idLote,"se supone")
        // setAreaLote();  
        setDataHead({area: '0', cosecha: '0' }) ;      
        // getCogidas();   
                 
    //    console.log("se ejecuta modal")
    },[idLote])
    // useEffect(() => {
    //     // setPesoTotal();
    //     console.log("hola")
    // }, [dataHead.area])
    return (
        <div>
            <Modal show={show.estado}
             onHide={onHide} 
             size="lg"
                aria-labelledby="contained-modal-title-vcenter-lg"
                centered>
                <Modal.Header closeButton>
                    <div className="row" >
                    <Modal.Title className="col-12 col-md-8">Detalle de Cosechas<i className="far fa-file-pdf p-3" onClick={PdfCosechasVsLote}></i></Modal.Title>
                    
                        <div className="row col-12 col-md-4">
                            <div className="col-12">
                                Area: <strong className="text-danger">{dataHead.area}</strong> Hec
                            </div>
                            <div className="col-12">
                                Cosecha: <strong className="text-danger">{dataHead.cosecha}</strong> Kg
                            </div>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="table-responsive-lg  col-12 mx-auto  mb-5 bg-body rounded trheight tableFixHead">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">NUM COSECHA</th>
                                    <th scope="col">PESO</th>
                                    <th scope="col">JABAS</th>
                                    {/* <th scope="col">CALIBRE</th> */}
                                    <th scope="col">GR</th>
                                    {/* <th scope="col">RAZON SOCIAL</th> */}
                                    <th scope="col">PLACA</th>
                                    <th scope="col">FECHA_______</th>
                                </tr>
                            </thead>
                            <tbody>                                
                                {cogidas.length>0 ?(
                                  cogidas.map((cogida) =>{
                                    return <tr key={cogida._id}>
                                    <td>{cogida.numCosecha}</td>
                                    <td>{cogida.peso}</td>
                                    <td>{cogida.jabas}</td>
                                    {/* <td>{cogida.calibre}</td> */}
                                    <td>{cogida.guiaRemision}</td>
                                    {/* <td>{cogida.proveedor.nombre}</td> */}
                                    <td>{cogida.placa}</td>
                                    <td>{ momentz.tz(cogida.fecha, "America/Lima").format('YYYY-MM-DD')}</td>
                                </tr>
                                })   
                                ):( <tr >
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td><h3>SIN REGISTROS</h3></td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td></td>
                                </tr> )

                                }
                                    
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onHide}>
                        Close
                </Button>
                    {/* <Button variant="primary" onClick={onHide}>
                        Save Changes
                </Button> */}
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ModalDash
