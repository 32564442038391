import React, { useState, useEffect, MouseEventHandler } from 'react'
import {Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import * as almacenServices from '../../AlmacenMP/AlmacenMPServices'
import { detallePallet,Pallets } from '../boxModal'
import moment from 'moment';
interface Props {
    show: boolean,
    onHide: MouseEventHandler<HTMLElement> | any,
    idPallet: string,
    codPallet: string,
    detPallets: detallePallet[],
    // pallet: any,
    afterModalConfirm: () => void
}

function ModalDivir(props: Props) {
    const [listDetPallet, setListDetPallet] = useState<detallePallet[]>([])
    const [listDetPallet2, setListDetPallet2] = useState<detallePallet[]>([])
    const [prodPalletOriginal, setProdToDivide] = useState({_id:'', produccion:0})
    const [prodPalletDivide, setProdPalletDivide] = useState(0)
    // const [headPallet, setHeadPallet] = useState<Pallets[]>()
    const [tipoSalida, setTipoSalida] = useState('SA')
    
    const changeTipoSalida = (e:any) =>{
        setTipoSalida(e.target.value)
    }
    
    const reiniciarEstados = () =>{
        setProdToDivide({_id:'', produccion:0})
        setProdPalletDivide(0)
    }
    const actualizarProdAcumulada = ()=>{
        console.log("ENTRO AL ACTUALIZAR ACUMULADO")
        var prodAcumulada1 ;
        for (let i = 0; i < listDetPallet.length; i++) {
            if (i>0) {
                var oneDetPallet = listDetPallet[i-1];
                console.log(oneDetPallet, " ONEDETPALLET")
                
                const actualDetPallet = listDetPallet[i]
                prodAcumulada1 = 1*oneDetPallet.prodAcumulada + 1*actualDetPallet.prodDia; 
                listDetPallet[i].prodAcumulada = prodAcumulada1;
            }else{
                const oneDetPallet = listDetPallet[i];
                prodAcumulada1 = oneDetPallet.prodDia; 
                listDetPallet[i].prodAcumulada = prodAcumulada1;
            }
            setListDetPallet([...listDetPallet])
        }
        var prodAcumulada ;
        for (let i = 0; i < listDetPallet2.length; i++) {
            if (i>0) {
                const oneDetPallet2 = listDetPallet2[i-1];
                const actualDetPallet2 = listDetPallet2[i]
                prodAcumulada = 1*oneDetPallet2.prodAcumulada + 1*actualDetPallet2.prodDia; 
                listDetPallet2[i].prodAcumulada = prodAcumulada;
            }else{
                const oneDetPallet2 = listDetPallet2[i];
                prodAcumulada = oneDetPallet2.prodDia; 
                listDetPallet2[i].prodAcumulada = prodAcumulada;
            }
            setListDetPallet2([...listDetPallet2])
        }
    }

    const dividirCantidad = () =>{
        if(prodPalletOriginal.produccion <= 0 || prodPalletDivide <=0)return toast.error("Seleccione una cantidad valida para dividir")
        var indexDet = listDetPallet.findIndex((detPallet)=> detPallet._id === prodPalletOriginal._id)
        if (prodPalletDivide > listDetPallet[indexDet].prodDia )  return toast.error("Verificar cantidad")
        if (listDetPallet[indexDet] && prodPalletDivide>0 && prodPalletDivide <= prodPalletOriginal.produccion) {
            listDetPallet[indexDet].prodDia = listDetPallet[indexDet].prodDia -  prodPalletDivide
            // console.log(listDetPallet[indexDet].prodDia)
            if (listDetPallet[indexDet].prodDia===0) { //Proceso en caso la division del pallet sea completa
                
                let newDetPallet2 = listDetPallet[indexDet];
                    newDetPallet2.prodDia = prodPalletDivide;
                let actualDetPallet2 = listDetPallet2;
                actualDetPallet2.push(newDetPallet2) 
                setListDetPallet2(actualDetPallet2)
                var resDetPallet = listDetPallet;
                resDetPallet.splice(indexDet, 1)
                setListDetPallet(resDetPallet)
                setListDetPallet([...listDetPallet])
            }else if (listDetPallet[indexDet].prodDia > 0) {
                // var newProdAcumulada=prodPalletDivide;
                // if (listDetPallet2.length>0) {
                //     for (let i = 0; i < listDetPallet2.length; i++) {
                //         const oneDetList2 = listDetPallet2[i];
                //         newProdAcumulada= 1* newProdAcumulada + 1* oneDetList2.prodDia;
                //     }
                // }
                const dataDetPallet1 = listDetPallet[indexDet];
                const newJsonDet2 = {
                    _id: dataDetPallet1._id,
                    batch: dataDetPallet1.batch,
                    codigoJuliano: dataDetPallet1.codigoJuliano,
                    fechaProduccion: dataDetPallet1.fechaProduccion,
                    niveles: dataDetPallet1.niveles,
                    pallet: dataDetPallet1.pallet,
                    prodAcumulada: prodPalletDivide,
                    prodDia: prodPalletDivide,
                    saldo: dataDetPallet1.saldo ,
                    subproceso: dataDetPallet1.subproceso
                }
                
                //Validar si el det ya existe para que solo sume la produccion
                const indexValidaExisDetalle = listDetPallet2.findIndex(detalle2 => detalle2._id === newJsonDet2._id);
                // console.log(indexValidaExisDetalle,"VALOR DE INDEX ")
                if(indexValidaExisDetalle !== -1){ // Si el detalle existe se procede a solo cambiar la produccion
                    listDetPallet2[indexValidaExisDetalle].prodDia = 1*listDetPallet2[indexValidaExisDetalle].prodDia + 1*prodPalletDivide                    
                    setListDetPallet2(listDetPallet2)
                    setListDetPallet2([...listDetPallet2])
                }else{ // Si el detalle no existe lo anadimos al Array
                    listDetPallet2.push(newJsonDet2)
                    setListDetPallet2(listDetPallet2)
                    setListDetPallet2([...listDetPallet2])
                }
                
                
                          
            }
            // setListDetPallet([...listDetPallet])
            // console.log(listDetPallet, listDetPallet2)
        }else{
            toast.error('Error en cantidad o detalle')
        }
        actualizarProdAcumulada();
        reiniciarEstados();
    }
    const changeProdToDivide = (e:any) =>{
        setProdPalletDivide(e.target.value)
    }
    const selectDetPalletToDivide = (id: string) =>{
        const findDetPallet = listDetPallet.find((detallet)=> detallet._id === id);
        console.log(findDetPallet)
        if (findDetPallet) {
            setProdToDivide({_id:findDetPallet?._id, produccion: findDetPallet?.prodDia})    
        }else{
            return toast.error("Error en Detalle!")
        }        
    }
    const guardarDivision = async() =>{
       try {
        if(listDetPallet.length===0 || listDetPallet2.length===0) return toast.error("No puede haber detalle sin registros")   
        console.log(props.codPallet,"CODIGO PALLET", listDetPallet,"LISTA1", listDetPallet2,"LISTA2", tipoSalida,"TIPO SALIDA") 
        const dataDivision = {
            idPallet: props.idPallet,
            numPallet: props.codPallet,
            tipoSalida: tipoSalida,
            detalle1: listDetPallet,
            detalle2: listDetPallet2
        }
        await almacenServices.dividirPallet(dataDivision);
        toast.success("Pallet Dividido")
        props.afterModalConfirm();
       } catch (error:any) {
           toast.error(error.message)
       }
    }
    useEffect(() => {
        if (props.show) {
            setListDetPallet(props.detPallets);
        } else {
            setListDetPallet([]);
            setListDetPallet2([])
        }
    }, [props.show])
    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.onHide}
                size='lg'
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Dividir Pallet {props.codPallet}
                        {/* {props.pallet.numPallet}  */}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div id="detalle" >
                        <div className="table-responsive  col-12 mx-auto  mb-5 bg-body rounded trheight tableFixHeadPallet">
                            <table className="table shadow">
                                <thead>
                                    <tr>
                                        <th scope="col">Fecha Produccion</th>
                                        <th scope="col">Codigo Juliano</th>
                                        <th scope="col">Batch</th>
                                        <th scope="col">Niveles</th>
                                        <th scope="col">Saldo</th>
                                        <th scope="col">Produccion Del Dia</th>
                                        <th scope="col">Produccion Acumulada</th>
                                        <th></th>


                                    </tr>
                                </thead>
                                <tbody>
                                    {listDetPallet.map((det) => {
                                        return <tr>
                                            <td>{moment(det.fechaProduccion).format('DD-MM-YYYY')}</td>
                                            <td>{det.codigoJuliano}</td>
                                            <td>{det.batch}</td>
                                            <td>{det.niveles}</td>
                                            <td>{det.saldo}</td>
                                            <td>{det.prodDia}</td>
                                            <td>{det.prodAcumulada}</td>
                                            <td>
                                                <span className="btn btn-outline-dark m-2" onClick={()=>selectDetPalletToDivide(det._id)}>Seleccionar</span>
                                                {/* <span className="btn btn-outline-dark m-2" onClick={()=>{editarDetalle(det._id)}}>Editar</span>
                                            <span className="btn btn-outline-danger m-2" onClick={()=>{eliminarDetalle(det._id)}}>Eliminar</span> */}
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="row justify-content-center align-items-center">
                        <div className="p-1 col-10 col-sm-10 col-md-3">
                                    <input className="form-control-lg text-center col-12" type="text" disabled 
                                    value={prodPalletOriginal.produccion}
                                    />
                        </div>
                        <div className="p-1 col-10 col-sm-10 col-md-3">
                            <input className="form-control-lg text-center col-12" type="text" 
                            value={prodPalletDivide} 
                            onChange={changeProdToDivide}
                            />
                        </div>
                        <div className="p-1 col-10 col-sm-10 col-md-3">
                            <span className="btn btn-lg btn-dark col-12"
                                onClick={dividirCantidad}
                            >Dividir</span>
                        </div>
                    </div>
                    <div className="row justify-content-center align-items-center pt-3">
                        <div className="p-1 col-10 col-sm-10 col-md-3">
                            <select className="form-control-lg text-center col-12"
                                onChange={changeTipoSalida}>                                
                                <option value="SA">SALDO</option>
                                <option value="ME">MERMA</option>
                            </select>
                        </div>
                        <div className="p-2 col-10 col-sm-10 col-md-3 text-center">
                            <h4>{tipoSalida}-{props.codPallet}</h4>
                        </div>
                    </div>
                    <div className="table-responsive  col-12 mx-auto  mb-5 bg-body rounded trheight tableFixHeadPallet">
                        <table className="table shadow">
                            <thead>
                                <tr>
                                    <th scope="col">Fecha Produccion</th>
                                    <th scope="col">Codigo Juliano</th>
                                    <th scope="col">Batch</th>
                                    <th scope="col">Niveles</th>
                                    <th scope="col">Saldo</th>
                                    <th scope="col">Produccion Del Dia</th>
                                    <th scope="col">Produccion Acumulada</th>


                                </tr>
                            </thead>
                            <tbody>
                            {listDetPallet2.map((det) => {
                                        return <tr>
                                            <td>{moment(det.fechaProduccion).format('DD-MM-YYYY')}</td>
                                            <td>{det.codigoJuliano}</td>
                                            <td>{det.batch}</td>
                                            <td>{det.niveles}</td>
                                            <td>{det.saldo}</td>
                                            <td>{det.prodDia}</td>
                                            <td>{det.prodAcumulada}</td>
                                            <td>
                                                {/* <span className="btn btn-outline-dark m-2" onClick={()=>selectDetPalletToDivide(det._id)}>Seleccionar</span> */}
                                                {/* <span className="btn btn-outline-dark m-2" onClick={()=>{editarDetalle(det._id)}}>Editar</span>
                                            <span className="btn btn-outline-danger m-2" onClick={()=>{eliminarDetalle(det._id)}}>Eliminar</span> */}
                                            </td>
                                        </tr>
                                    })}
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row col-12 text-center ">
                        <button className="btn-lg btn-block btn-dark" onClick={guardarDivision} >GUARDAR DIVISION</button>
                        
                    {/* <Button variant="outline-info" className="col-12 col-md-5 m-1" onClick={props.onHide} >Cancelar</Button> */}
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ModalDivir
