import React, { MouseEventHandler, useState, useEffect } from 'react'
import finCosecha from '../../../Media/png/finCosecha.png'
// import cosechadoImg from '../../../Media/png/cosechado.png'
import { Modal } from 'react-bootstrap';
import { loteToModal, usuariosByTipo , proyecciones} from '../proyecciones'
import {ContainerAdmLote, ContainerCerrarCampo} from '../ProyeccionesElements'
import moment from 'moment';
import * as ProyeccionCtrl from '../ProyeccionesService'
import { toast } from 'react-toastify';

interface Props {
    show: boolean,
    lote: loteToModal,
    proyectistas: usuariosByTipo[];
    onHide: MouseEventHandler<HTMLElement>|any,
    // onHideFun: () => void
}

function ModalAdmLote(props: Props) {
    // getProyeccionesList
    const initialNewProyeccion = {
        numProyeccion: 0,
        fechaProyeccion:'',
        jabasProyeccion: '',
        lote: ''
    }
    const [proyecciones, setProyecciones] = useState<proyecciones[]>([])
    const [confirmAddProyeccion, setConfirmAddProyeccion] = useState(false)
    const [confirmDeleteProyeccion, setConfirmDeleteProyeccion] = useState(false)
    const [newProyeccion, setNewProyeccion] = useState(initialNewProyeccion)
    const [idProyectistaToLote, setIdProyectistaToLote] = useState('')
    const getProyecciones = async(idLote: string)=>{
        try {
            const resProyecciones = await ProyeccionCtrl.getProyeccionesList(idLote);
            if(resProyecciones.status !== 200) return toast.error("Vuelva Iniciar Sesion")
            
            setProyecciones(resProyecciones.data)
        } catch (error:any) {
            toast.error(error)
        }
    }
    const changeNewProyeccion = (e:any)=>{
        setNewProyeccion({...newProyeccion, lote: props.lote.idLote, numProyeccion: proyecciones.length+1, [e.target.name]:e.target.value})
    }
    const submitCreateProyeccion = async()=>{
        if(!confirmAddProyeccion){
            setConfirmAddProyeccion(true)
        }else{
            
            if(!newProyeccion.numProyeccion || !newProyeccion.fechaProyeccion || !newProyeccion.jabasProyeccion) {
                setConfirmAddProyeccion(false)
                return toast.error("Datos incompletos")
            }
            const validaFecha =  proyecciones.find((proyeccion) => proyeccion.numeroProyeccion === newProyeccion.numProyeccion-1)
            if(validaFecha && validaFecha?.fechaProyeccionSistema >= newProyeccion.fechaProyeccion) {
                setConfirmAddProyeccion(false)  
                return toast.error("Seleccione fecha mayor a las anteriores");
            }
            const resCreateLote = await ProyeccionCtrl.createNewProyeccion(newProyeccion)
            if(resCreateLote.status!==200) return toast.error("Error");
            getProyecciones(props.lote.idLote)
            
            toast.success("Proyeccion Anadida")
            setConfirmAddProyeccion(false)
            setNewProyeccion(initialNewProyeccion);
        }
    }
    const deleteProyeccion = async(idProyeccion: string)=>{
        if(!confirmDeleteProyeccion){
            setConfirmDeleteProyeccion(true)
        }else{
            const resDeleteProyeccion = await ProyeccionCtrl.deleteOneProyeccion(idProyeccion);
            if(resDeleteProyeccion.data.ErrorMessage) {

                return toast.error(resDeleteProyeccion.data.ErrorMessage)
            }
            getProyecciones(props.lote.idLote)
            toast.info("Proyeccion eliminada")
            setConfirmDeleteProyeccion(false)
        }
    }
    const changeProyectista = (e:any) =>{
        
        setIdProyectistaToLote(e.target.value);
    }
    const submitProyectistaToLote = async()=>{
        if (!idProyectistaToLote) return toast.error("Seleccione Nuevo Proyectista")
        const data = {
            idProyectista: idProyectistaToLote,
            idLote: props.lote.idLote
        }
        const resSetProyectista = await ProyeccionCtrl.setNewProyectistaToLote(data)
        toast.success("Actualizado")
    }
    const submitCerrarLote = async()=>{
        
    }
    useEffect(() => {
        if(props.show){
            getProyecciones(props.lote.idLote)
        }else{
            setNewProyeccion(initialNewProyeccion);
            setConfirmAddProyeccion(false);
            setConfirmDeleteProyeccion(false)
            setProyecciones([])
        }
    }, [props.show])
    return (
        <>
            <Modal
                show={props.show}
                onHide={props.onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <ContainerAdmLote>
                        <div id="title">
                            <h3>{props.lote.numeroContrato} - {props.lote.nombreLote}</h3>
                            <div id="proyectista">
                                <div>
                                    <label htmlFor="idProyectista">Proyectista</label>
                                    <select name="idProyectista" id="idProyectista" onChange={changeProyectista}>
                                        <option value="">{props.lote.proyectista}</option>
                                        {props.proyectistas.map((proyectista)=>{
                                            return(
                                                <option value={proyectista._id}>{proyectista.nombre}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <button onClick={submitProyectistaToLote}>
                                <i className="fas fa-check"></i>
                                </button>
                            </div>
                        </div>
                        <div id="proyecciones">
                            {proyecciones.map((proyeccion, index)=>{
                                return <div>
                                    <h3>{proyeccion.numeroProyeccion}</h3>
                                    <div className="proyeccionSistema" >{moment(proyeccion.fechaProyeccionSistema).format('DD-MM-YYYY')}&nbsp; <h5>{proyeccion.jabasProyeccionSistema}</h5></div>   
                                    <div className="proyeccionCampo" >{proyeccion.fechaProyeccionCampo?moment(proyeccion.fechaProyeccionCampo).format('DD-MM-YYYY'):'dd-MM-YYYY'}&nbsp; <h5>{proyeccion.jabasProyeccionCampo?proyeccion.jabasProyeccionCampo:'0'}</h5></div>   
                                    <div className="cosechado" >{proyeccion.FechaCosechadoCampo?moment(proyeccion.FechaCosechadoCampo).format('DD-MM-YYYY'):'dd-MM-YYYY'}&nbsp; <h5>{proyeccion.jabasCosechadoCampo?proyeccion.jabasCosechadoCampo:'0'}</h5></div>   
                                    {proyecciones.length === index + 1
                                        ? (<div className="delete"  onClick={() => { deleteProyeccion(proyeccion._id) }}>
                                           {confirmDeleteProyeccion?
                                           'Seguro?':
                                           <i className="fas fa-times"></i>                                               
                                           } 
                                        </div>)
                                        : ''
                                    }
                                </div>
                            })}
                            {proyecciones.length > 0 ? (
                                <div className="addProyeccion"
                                    // onClick={handleShowModalCreateProy}
                                >
                                    <h3>{proyecciones.length+1}</h3>
                                    <input type="date" 
                                        name="fechaProyeccion"
                                        value={newProyeccion.fechaProyeccion}  
                                        onChange={changeNewProyeccion}
                                    />
                                    <input type="text"
                                        name="jabasProyeccion"
                                        value={newProyeccion.jabasProyeccion}  
                                        onChange={changeNewProyeccion}
                                    />
                                    <button onClick={submitCreateProyeccion}>                                        
                                        {confirmAddProyeccion?
                                        'Seguro?':
                                        <i className="fas fa-plus"></i>}
                                        
                                    </button>
                                </div>
                            ) : ''}

                            
                        </div>
                        <ContainerCerrarCampo onClick={submitCerrarLote}>
                                    <img src={finCosecha} alt=""/>
                                    <p>Cerrar Lote</p>
                            </ContainerCerrarCampo>
                    </ContainerAdmLote>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ModalAdmLote
