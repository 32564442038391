import React, { useState, useEffect } from 'react'
import { areas, temas, capacitadores, repGenCapacitador } from '../registroPersonal'
import {
  MainContainerSet,
  ContainerInputs,
  SelectGeneral,
  InputGeneral,
  ButtonGeneral,
  H3Title,
  DivDivider
} from '../../../stylesGeneral/mainElements'
import momentz from 'moment-timezone';
import moment from 'moment'
import Swal from 'sweetalert2'
import saveAs from 'file-saver'
import * as RPservices from '../RegistroPersonalServices'
function ReporteGeneral() {
  const iDataBusqueda= {
    fechaInicio:'',
    fechaFin:'',
    dni:'',
    idCapacitador:'',
    idArea:'',
    idTema:''
  }
  const [registros, setRegistros] = useState<repGenCapacitador[]>([])
  const [capacitador, setCapacitador] = useState<capacitadores[]>([])
  const [areas, setAreas] = useState<areas[]>([])
  const [temas, setTemas] = useState<temas[]>([])
  const [dataBusqueda, setDataBusqueda] = useState(iDataBusqueda)

  const changeDataBusqueda = (e:any)=>{
    setDataBusqueda({...dataBusqueda, [e.target.name]: e.target.value});
  }

  const getDataInicial = async() =>{
    const resDataInicial =  await RPservices.getInitialDataBusqueda();  
    setAreas(resDataInicial.data.resAreas); 
    setCapacitador(resDataInicial.data.resCapacitadores)
  }

  const changeArea = (e:any)=>{
    const resTemas = areas.find((area)=> area._id === e.target.value)
    if(resTemas && resTemas.temas) setTemas(resTemas.temas);
    setDataBusqueda({...dataBusqueda, [e.target.name]: e.target.value});
  }

  const changeTema = (e:any) => {
    setDataBusqueda({...dataBusqueda, [e.target.name]: e.target.value});
  }

  const submitBusqueda = async() =>{
    const fechaInicioFormated =  momentz.tz(dataBusqueda.fechaInicio, "America/Lima")
    const fechaFinFormated =  momentz.tz(dataBusqueda.fechaFin, "America/Lima")
    const diffDias = fechaFinFormated.diff(fechaInicioFormated, "days")
    if(diffDias !<0 || diffDias !>31) {      
      return(
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: "Busqueda permitida rango de 31 dias",
          showConfirmButton: true,
          // timer: 2000
        })
      )
    }
    if(!dataBusqueda.fechaInicio|| !dataBusqueda.fechaFin ) {      
      return(
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: "Asigne rango de fechas",
          showConfirmButton: true,
          // timer: 2000
        })
      )
    }   
    const resBusqueda = await RPservices.reporteGeneralCap(dataBusqueda)
    setRegistros(resBusqueda.data)
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: " ",
      showConfirmButton: false,
      timer: 1000
    })
  }
  const sincPendientes = async() =>{
    if(registros.length === 0 ) return  Swal.fire({
      position: 'center',
      icon: 'error',
      title: "Reporte sin datos",
      showConfirmButton: true,
      // timer: 2000
    })
    const resSincPendientes = await RPservices.sincPendientesReporte(registros)
    setRegistros(resSincPendientes.data.registros)    
    if (resSincPendientes.data.jsonLogs.length>0) {
      var textConcatDniNoSinc='';
      for (let i = 0; i < resSincPendientes.data.jsonLogs.length; i++) {
        const oneDniNoSinc = resSincPendientes.data.jsonLogs[i];
        textConcatDniNoSinc =  textConcatDniNoSinc + ' ' + oneDniNoSinc
      }
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: "Actualizacion Completada",
        showConfirmButton: false,
        timer: 1000
      }).then(()=>{
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: "DNIs no encontrados",
          showConfirmButton: true,
          text: textConcatDniNoSinc
          // timer: 1000
        })
      })      
    }else{
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: "Actualizacion Completada",
        showConfirmButton: false,
        timer: 1000
      })
    }
  }

  const pdfBlob = async(res:any, nameFile:string)=>{
    const pdfBlob= await new Blob([res.data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"})
    saveAs(pdfBlob, nameFile)
   }
  const downloadExcel = async()=>{
    
    try {
      if(registros.length === 0 ) return  Swal.fire({
        position: 'center',
        icon: 'error',
        title: "Reporte sin datos",
        showConfirmButton: true,
        // timer: 2000
      })
      await RPservices.generateReporteGenExcel(registros)
      const downloadReporte = await RPservices.sendReporteGenExcel('RepRegCaps.xlsx')
      pdfBlob(downloadReporte, 'RepRegCaps.xlsx')
    } catch (error:any) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: error,
        showConfirmButton: true,
        // timer: 2000
      })
    }
  }
  useEffect(() => {
    getDataInicial();
  }, [])
  
  return (
    <>
      <MainContainerSet display='flex' width='100%' alignItems='flex-end' flexWrap='wrap' justifyContent='start'>
        <H3Title>Reporte General - Registro de Capacitaciones</H3Title>
        <DivDivider></DivDivider>
      </MainContainerSet>
      <MainContainerSet display='flex' width='100%' alignItems='flex-end' flexWrap='wrap' justifyContent='start'>
        <ContainerInputs width='300px'>
          <label htmlFor="fechaInicio">Fecha Inicio</label>
          <InputGeneral
            type="date"
            height='35px'
            width='100%'
            name="fechaInicio"
            onChange={changeDataBusqueda}
          />
        </ContainerInputs>
        <ContainerInputs width='300px'>
          <label htmlFor="fechaFin">Fecha Fin</label>
          <InputGeneral
            type="date"
            height='35px'
            width='100%'
            name="fechaFin"
            onChange={changeDataBusqueda}
          />
        </ContainerInputs>
        <ContainerInputs width='300px'>
          <label htmlFor="dni">Dni</label>
          <InputGeneral
            type="text"
            height='35px'
            width='100%'
            name="dni"
            onChange={changeDataBusqueda}
          />
        </ContainerInputs>
        <ContainerInputs width='300px'>
          <label htmlFor="idCapacitador">Capacitador</label>
          <SelectGeneral 
            height='35px' 
            bg=''
            name="idCapacitador"
            onChange={changeDataBusqueda}
          >
            <option value="">Todos</option>
            {capacitador.map((cap)=>{
              return(
                <option value={cap._id}>{cap.nombres} {cap.apellidos}</option>
              )
            })}
          </SelectGeneral>
        </ContainerInputs>
        <ContainerInputs width='300px'>
          <label htmlFor="idArea">Area</label>
          <SelectGeneral 
            height='35px' 
            bg=''
            name='idArea'
            onChange={changeArea}
          >
            <option value="">Todos</option>
            {areas.map((area)=>{
              return(
                <option value={area._id}>{area.nombre}</option>
              )
            })}
          </SelectGeneral>
        </ContainerInputs>
        <ContainerInputs width='300px'>
          <label htmlFor="idTema">Tema</label>
          <SelectGeneral 
          height='35px' 
          bg='#212F3C'
          name='idTema'
          onChange={changeDataBusqueda}
          >
            <option value="">Todos</option>
            {temas.map((tema)=>{
              return(
                <option value={tema._id}>{tema.nombre}</option>
              )
            })}
          </SelectGeneral>
        </ContainerInputs>
        <ContainerInputs width='200px'>
          <ButtonGeneral width='100%' height='50px' color=''
            onClick={submitBusqueda}
          ><i className="fas fa-search"></i></ButtonGeneral>
        </ContainerInputs>
        <ContainerInputs width='100px'>
          <ButtonGeneral 
            width='100%' 
            height='50px' 
            color='#145A32'
            onClick={downloadExcel}
            >
            <i className="fas fa-file-csv"></i>
          </ButtonGeneral>
        </ContainerInputs>
        <ContainerInputs width='100px'>
          <ButtonGeneral 
            width='100%' 
            height='50px' 
            color='#145A32'
            onClick={sincPendientes}
            >
            <i className="fas fa-sync"></i>
          </ButtonGeneral>
        </ContainerInputs>
      </MainContainerSet>
      <div className="table-responsive table-striped col-12 col-md-12 mx-auto shadow mb-5 bg-body rounded trheight tableFixHead">
        <table className="table">
          <thead>
            <tr className=''>
              <th style={{background:'#145A32', color:'white'}}>#</th>
              <th style={{background:'#145A32', color:'white'}}>FECHA</th>
              <th style={{background:'#145A32', color:'white'}}>HORA INICIO</th>
              <th style={{background:'#145A32', color:'white'}}>HORA FIN</th>
              <th style={{background:'#145A32', color:'white'}}>DURACION(min)</th>
              <th style={{background:'#145A32', color:'white'}}>DNI</th>
              <th style={{background:'#145A32', color:'white'}}>TRABAJADOR</th>
              <th style={{background:'#145A32', color:'white'}}>Dur(min)</th>
              <th style={{background:'#145A32', color:'white'}}>TEMA</th>
              <th style={{background:'#145A32', color:'white'}}>AREA</th>
              <th style={{background:'#145A32', color:'white'}}>CAPACITADOR</th>                        
            </tr>
          </thead>
          <tbody>
            { registros.map((reg, index)=>{   
              return <tr key={index}>              
                <td>{registros.length-index}</td>
                <td>{momentz.tz(reg.fecha, "America/Lima").format("DD-MM-YYYY")}</td>
                <td>{reg.horaInicio}</td>
                <td>{reg.horaFin}</td>
                <td>{moment.duration(moment(reg.horaFin, 'HH:mm:ss').diff(moment(reg.horaInicio, 'HH:mm:ss'))).get('hours')*60+moment.duration(moment(reg.horaFin, 'HH:mm:ss').diff(moment(reg.horaInicio, 'HH:mm:ss'))).get('minutes')}</td>
                <td>{reg.dni}</td>
                <td>{reg.nombres} {reg.aPaterno} {reg.aMaterno}</td>
                <td>{reg.idTema.duracion}</td>
                <td>{reg.idTema.nombre}</td>
                <td>{reg.idArea.nombre}</td>
                <td>{reg.idCapacitador.nombres} {reg.idCapacitador.apellidos}</td>
              </tr>
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default ReporteGeneral