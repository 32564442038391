import React, { useState, useEffect, ChangeEvent, FormEvent, FormEventHandler } from 'react'
import * as usuarioService from '../Usuarios/UsuarioService'
import { UsuarioInterface } from '../Usuarios/usuario'
import * as localidadService from '../Localidades/LocalidadService'
import { localidad } from '../Localidades/localidad'
import {departamento} from '../Departamentos/departamento'
import { lote } from './lote'
import {proxCosecha} from '../Cogidas/cogida'
import {proxCosechaFromLote} from '../Proyecciones/proyecciones'
import * as loteService from './LoteService'
import * as departamentoService from '../Departamentos/departamentoService'
import * as proveedorService from '../Proveedores/ProveedorService'
import * as cogidaService from '../Cogidas/CogidaService'
import * as proyeccionesService from '../Proyecciones/ProyeccionesService'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment';
import momentz from 'moment-timezone';


type InputChange = ChangeEvent<HTMLInputElement | HTMLSelectElement>;

interface Params {
    id: string
}

function LoteForm() {
    const history = useNavigate();
    const params = useParams();

    const initialState = {
        nombreLote: '',
        numeroContrato: '',
        coordenadas: '',
        departamento: {
            _id: '',
            nombre: ''
        },
        localidad: {
            _id: '',
            nombre: ''
        },
        areaLote: '',
        estado: '',
        user: {
            _id: '',
            nombre: ''
        },
        evaluador: {
            _id: '',
            nombre: ''
        },
        proyectista: {
            _id: '',
            nombre: ''
        },
        proveedor: {
            _id: '',
            nombre: '',
            numeroDocumento:''
        },
        campaña: '',
        fechaSiembra: '',
        variedad: '',
        vivero: '',
        edadCultivo: '',
        diasAcosechar: '',
        siembraVivero: '',
        inicioCosechaTen: '',
        proximaCosecha:'',
        finCosechaTen: '',
        cantidadCosecha: 0,
        PrimPeq: 0,
        PrimGra: 0,
        Seg: 0,
        Dscte:0
    }

    const [usuarios, setUsuarios] = useState<UsuarioInterface[]>([]);
    const [departamentos, setDepartamentos] = useState<departamento[]>([]);
    const [localidades, setLocalidades] = useState<localidad[]>([]);
    const [lotes, setLotes] = useState<lote>(initialState);
    const [busquedaProveedor, setBusquedaProveedor] =useState({
        rucdni:'0'
    })
    const [loadDiv, setLoadDiv] = useState(false)
    //ESTADO PARA ENVIAR DATOS AL MODIFICAR PROXIMA COSECHA
    const [proximaCosecha, setProximaCosecha] = useState<proxCosechaFromLote>({
        idLote: '',
        proximaCosechaActual: '',
        proximaFecha: '' 
    });
    const handleInputChangeDepartamento = async (e: any) => {
        const id= e.target.value;
        setLotes({...lotes, departamento: id});
        const reslocalidades = await localidadService.getLocalidadesVSdep(id);
        setLocalidades(reslocalidades.data);

    }
    const handleInputChange = async (e: InputChange) => {
        const nameInput = e.target.name;
        const valueInput = e.target.value;
        if(nameInput==='areaLote'){
            const cosechaTotal = parseFloat(valueInput)*14000;
            
            setLotes({...lotes, cantidadCosecha:cosechaTotal, [e.target.name]: e.target.value})
            console.log(lotes);
        }else if (nameInput==='fechaSiembra') {
            // const fechaUpdated = moment(oneProRest.proFechaCosecha, "YYYY-MM-DD").add(diffDias, 'days');
            // const formarFechaSiembre = moment(e.target.value, "YYYY-MM-DD").subtract(0, 'days').toString();
            const siembraVivero= moment(valueInput, "YYYY-MM-DD").subtract(35, 'days').toString();
            const inicioCosecha= moment(valueInput, "YYYY-MM-DD").add(120, 'days').toString();
            const finCosecha= moment(valueInput, "YYYY-MM-DD").add(240, 'days').toString();
            if (!params.id) { 
                setLotes({...lotes, siembraVivero: siembraVivero, inicioCosechaTen: inicioCosecha, finCosechaTen: finCosecha, proximaCosecha: inicioCosecha, [e.target.name]: e.target.value})
            }else{
                setLotes({...lotes, siembraVivero: siembraVivero, inicioCosechaTen: inicioCosecha, finCosechaTen: finCosecha, [e.target.name]: e.target.value})
            }
            
        }else if(nameInput==='proximaCosecha'){
            if(params.id){
                const validaCosechas = await loteService.validaSiExistenCosechas(params.id);
                console.log(validaCosechas.data.state," RESPUES DESPUES DE CONSULTA SI EXISTEN COSECHAS DEL LOTE") 
                if (validaCosechas.data.state==='yes') {
                    toast.info('El lote ya cuenta con cosechas ingresadas');
                }else{
                   setLotes({...lotes, proximaCosecha: valueInput})
                   setProximaCosecha({...proximaCosecha, proximaFecha: valueInput})
                }       
            }            
        }
        else{
            setLotes({ ...lotes, [e.target.name]: e.target.value });
        }
        
        
    }

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoadDiv(true);
        try {
            if (!params.id) {                
                const newLote = await loteService.createLote(lotes);
                // await loteService.createProyeccion(newLote.data);
               
                toast.success('Nuevo Lote Anadido');
                setLotes(initialState);
            } else {
                await loteService.updateLotes(params.id, lotes);
                console.log(lotes)
                //  EDITAMOS LAS FECHAS DE LA PROYECCION DEL LOTE
                //  await proyeccionesService.editProxFechaFromLote(proximaCosecha)
                toast.success('Lote Editado');
            }
            history('/lotes');
            setLoadDiv(false);
        } catch (error:any) {
            console.log(error.message)
            setLoadDiv(false);
            if (error.message === 'Request failed with status code 301') {
                toast.error('Mumero de Contrato ya Registrado');
            }
        }
    }
    const handleCancel = (e: any) => {
        e.preventDefault();
        setLotes(initialState);
        history('/lotes');
    }
    const handleBusquedaProveedor= async(e: any) => {
        setBusquedaProveedor({ ...busquedaProveedor, [e.target.name]: e.target.value });
        
    }
    const submitBusquedaProveedor = async()=>{        
        const resProveedor = await proveedorService.findOneProveedorByRuc(busquedaProveedor.rucdni);
        const docProveedor: any = resProveedor.data
        if(!docProveedor){
            setLotes({...lotes, proveedor: {_id: '', nombre: '', numeroDocumento: ''} })
        }else{
            setLotes({...lotes, proveedor: {_id: docProveedor._id, nombre: docProveedor.nombre, numeroDocumento: docProveedor.numeroDocumento} })
        // console.log(cogidas);
        }
        
    }
    const loadData = async () => {
        const resDepartamentos = await departamentoService.getDepartamentos();
        const resUsuarios = await usuarioService.getUsuarios();
        // const resLocalidades = await localidadService.getLocalidades();
        setDepartamentos(resDepartamentos.data);
        setUsuarios(resUsuarios.data);
        // setLocalidades(resLocalidades.data);
    }
    const loadDataEdit = async (id: string) => {
        const resLote = await loteService.getLote(id);
        console.log(resLote.data, "data lote edit")
        // CONFIGURAMOS EL ID DEL LOTE EN EL ESTADO DE LA PROXIMA COSECHA
        setProximaCosecha({...proximaCosecha, idLote:id, proximaCosechaActual: resLote.data.proximaCosecha})
        //CONFIGURACOS ESTADO GENERAL DEL LOTE
        setLotes(resLote.data);
    }
    useEffect(() => {
        loadData();
        if (params.id) {
            loadDataEdit(params.id);
        }
    }, [])

    return (
        <div>
            <div className={`${loadDiv?'loadDiv':''}`}>
                <div className="col-12">
                    <div className="card">
                        <div className="card-body ">
                            {params.id?(<h3>Edicion de Lote</h3>):(<h3>Nuevo Lote</h3>)}
                            <form onSubmit={handleSubmit}>
                               <div className="row">
                               <div className="form-group col-12 col-md-2">
                                    <label htmlFor="">Departamento</label>
                                    <select onChange={handleInputChangeDepartamento} name="departamento" className="form-control form-select form-select-sm" aria-label=".form-select-sm example" required>
                                        {params.id ? (
                                            <option value={lotes.departamento._id} >{lotes.departamento.nombre}</option>
                                        ) : (
                                            <option value={''} > </option>
                                        )}

                                        {departamentos.map((departamento) => {
                                            return <option key={departamento._id} value={departamento._id}>{departamento.nombre}</option>
                                        })}
                                    </select>
                                </div>
                               <div className="form-group col-12 col-md-2">
                                    <label htmlFor="">Localidad</label>
                                    <select onChange={handleInputChange} name="localidad" className="form-control form-select form-select-sm" aria-label=".form-select-sm example" required>
                                        {params.id ? (
                                            <option value={lotes.localidad._id} >{lotes.localidad.nombre}</option>
                                        ) : (
                                            <option value={''} >Localidad</option>
                                        )}

                                        {localidades.map((localidad) => {
                                            return <option key={localidad._id} value={localidad._id}>{localidad.nombre}</option>
                                        })}
                                    </select>
                                </div>
                               <div className="form-group col-12 col-md-2">
                                    <label htmlFor="">Nombre de Lote</label>
                                    <input
                                        required
                                        type="text"
                                        name="nombreLote"
                                        placeholder="Nombre Lote"
                                        className="form-control"
                                        value={lotes.nombreLote}
                                        onChange={handleInputChange}
                                        autoFocus />
                                </div>
                                <div className="form-group col-12 col-md-2">
                                    <label htmlFor="">Numero de Contrato</label>
                                    <input
                                        required
                                        type="text"
                                        name="numeroContrato"
                                        placeholder="Numero de Contrato"
                                        className="form-control"
                                        value={lotes.numeroContrato}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="form-group col-6 col-md-2">
                                    <label htmlFor="">Pri Pequeña</label>
                                    <input
                                        required
                                        type="number"
                                        name="PrimPeq"
                                        min='0'
                                        step='any'
                                        className="form-control"
                                        value={lotes.PrimPeq}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="form-group col-6 col-md-2">
                                    <label htmlFor="">Prim Grande</label>
                                    <input
                                        required
                                        type="number"
                                        name="PrimGra"
                                        min='0'
                                        step='any'
                                        className="form-control"
                                        value={lotes.PrimGra}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="form-group col-6 col-md-2">
                                    <label htmlFor="">Segunda</label>
                                    <input
                                        required
                                        type="number"
                                        name="Seg"
                                        min='0'
                                        step='any'
                                        className="form-control"
                                        value={lotes.Seg}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="form-group col-6 col-md-2">
                                    <label htmlFor="">Descarte</label>
                                    <input
                                        required
                                        type="number"
                                        name="Dscte"
                                        min='0'
                                        step='any'
                                        className="form-control"
                                        value={lotes.Dscte}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="form-group col-12 col-md-3">
                                        <label htmlFor="">Proveedor-RUC/DNI</label>                                            
                                            <div className="row mx-auto">                                            
                                                <input
                                                    required
                                                    type="number"
                                                    name="rucdni"
                                                    placeholder="RUC/DNI"
                                                    className="form-control col-9"
                                                    value={busquedaProveedor.rucdni}
                                                    onChange={handleBusquedaProveedor}
                                                                                                      
                                                />
                                                <div className="form-control btn btn-outline-dark btnsmall col-3 " onClick={submitBusquedaProveedor}  >
                                                    <i className="fas fa-search"  ></i>
                                                </div>
                                            </div>   
                                    </div>
                                    <div className="form-group col-12 col-md-3">
                                        <label htmlFor="">Razon Social</label>
                                       
                                         <select name="razonSocial" className={`form-control form-select form-select-sm shadow`} aria-label=".form-select-sm example" required>
                                         {params.id ? (
                                                <option value={lotes.proveedor._id} >{lotes.proveedor.nombre}</option>
                                            ) : (
                                                <option value={lotes.proveedor._id} >{lotes.proveedor.nombre}</option>
                                            )}

                                        </select>
                                    </div>
                                <div className="form-group col-12 col-md-3">
                                    <label htmlFor="">Coordenadas</label>
                                    <input
                                        required
                                        type="text"
                                        name="coordenadas"
                                        placeholder="Coordenadas"
                                        className="form-control"
                                        value={lotes.coordenadas}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                
                                <div className="form-group col-12 col-md-3">
                                    <label htmlFor="">Area de Terreno</label>
                                    <input
                                        required
                                        type="number"
                                        name="areaLote"
                                        min='0'
                                        step='any'
                                        placeholder="Area de Terreno"
                                        className="form-control"
                                        value={lotes.areaLote}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="form-group col-12 col-md-3">
                                    <label htmlFor="cantidadCosecha">Proyeccion Cosecha Total</label>
                                    <input
                                        required
                                        type="number"
                                        name="cantidadCosecha"
                                        
                                        className="form-control"
                                        value={lotes.cantidadCosecha}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="form-group col-12 col-md-3">
                                    <label htmlFor="">Toneladas proyectadas</label>
                                    <input
                                        required
                                        type="number"
                                        name="cantidadCosecha"
                                        placeholder="Cantidad Cosecha / Tn"
                                        className="form-control"
                                        value={lotes.cantidadCosecha}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="form-group col-12 col-md-3">
                                    <label htmlFor="">Estado</label>
                                    <select onChange={handleInputChange} name="estado" className="form-control form-select form-select-sm" aria-label=".form-select-sm example" required>
                                        {params.id ? (
                                            <option value={lotes.estado} >{lotes.estado}</option>
                                        ) :
                                            (<option value={''} >Estado</option>)}

                                        <option value='HABILITADO'>HABILITADO</option>
                                        <option value='VEGETATIVO'>VEGETATIVO</option>
                                        <option value='COSECHA'>COSECHA</option>
                                        <option value='CERRADO'>CERRADO</option>
                                        <option value='ANULADO'>ANULADO</option>

                                    </select>
                                </div>
                                
                                <div className="form-group col-12 col-md-3">
                                    <label htmlFor="">Tecnico</label>
                                    <select onChange={handleInputChange} name="user" className="form-control form-select form-select-sm" aria-label=".form-select-sm example" required>
                                        {params.id ? (
                                            <option value={lotes.user?._id} >{lotes.user?.nombre}</option>
                                        ) :
                                            (<option value={''} ></option>)}
                                        {usuarios.map((usuario) => {
                                            if(usuario.tipo==='TECNICO'){
                                                return <option key={usuario._id} value={usuario._id}>{usuario.nombre}</option>
                                            }
                                            
                                        })}



                                    </select>
                                </div>
                                <div className="form-group col-12 col-md-3">
                                    <label htmlFor="">Evaluador</label>
                                    <select onChange={handleInputChange} name="evaluador" className="form-control form-select form-select-sm" aria-label=".form-select-sm example" required>
                                        {params.id ? (
                                            <option value={`${lotes.evaluador ? (lotes.evaluador._id):''}`}>{`${lotes.evaluador ? (lotes.evaluador.nombre):''}`}</option>
                                        ) :
                                            (<option value={''} ></option>)}
                                        {usuarios.map((usuario) => {
                                            if(usuario.tipo==='EVALUADOR'){
                                                return <option key={usuario._id} value={usuario._id}>{usuario.nombre}</option>
                                            }
                                            
                                        })}



                                    </select>
                                </div>
                                {/* <div className="form-group col-12 col-md-3">
                                    <label htmlFor="">Proyectista</label>
                                    <select onChange={handleInputChange} name="proyectista" className="form-control form-select form-select-sm" aria-label=".form-select-sm example" required>
                                        {params.id ? (
                                            <option value={`${lotes.proyectista ? (lotes.proyectista._id):''}`}>{`${lotes.proyectista ? (lotes.proyectista.nombre):''}`}</option>
                                        ) :
                                            (<option value={''} ></option>)}
                                        {usuarios.map((usuario) => {
                                            if(usuario.tipo==='PROYECTISTA'){
                                                return <option key={usuario._id} value={usuario._id}>{usuario.nombre}</option>
                                            }
                                            
                                        })}



                                    </select>
                                </div> */}
                                <div className="form-group col-12 col-md-3">
                                    {/* <label htmlFor="">Campaña</label>
                                    <input
                                        required
                                        type="text"
                                        name="campaña"
                                        placeholder="Año de Campaña"
                                        className="form-control"
                                        value={lotes.campaña}
                                        onChange={handleInputChange}
                                    /> */}
                                    <label htmlFor="campaña" >Campaña</label>
                                    <select onChange={handleInputChange} name="campaña" id="campaña" className="form-control form-select form-select-sm" aria-label=".form-select-sm example" required>
                                    {params.id ? (
                                            
                                            <option value={lotes.campaña}  >{lotes.campaña}</option>
                                        ) :
                                            (<option value={''} ></option>)}
                                        
                                        
                                        <option value="2020-2021"  >2020-2021</option>
                                        <option value="2021-2022"  >2021-2022</option>
                                        <option value="2022-2023"  >2022-2023</option>
                                        <option value="2023-2024"  >2023-2024</option>
                                        
                                    </select>
                                </div>
                                
                                <div className="form-group col-12 col-md-3">
                                    <label htmlFor="variedad" >Variedad de Cultivo</label>
                                    <select onChange={handleInputChange} name="variedad" id="variedad" className="form-control form-select form-select-sm" aria-label=".form-select-sm example" required>
                                        {params.id ? (
                                            <option value={lotes.variedad} >{lotes.variedad}</option>
                                        ) :
                                            (<option selected >Seleccione</option>)}

                                        <option value="LORCA" >LORCA</option>
                                        <option value="BLANCA TUDELA"  >BLANCA TUDELA</option>
                                        <option value="IMPERIAL"  >IMPERIAL</option>
                                        <option value="NOVA 1"  >NOVA 1</option>
                                        <option value="NOVA 2"  >NOVA 2</option>
                                        <option value="NOVA 3"  >NOVA 3</option>
                                    </select>
                                </div>
                                <div className="form-group col-12 col-md-3">
                                    <label htmlFor="vivero" >Vivero</label>
                                    <select onChange={handleInputChange} name="vivero" className="form-control form-select form-select-sm" aria-label=".form-select-sm example" required>
                                        {params.id ? (
                                            <option value={lotes.vivero} >{lotes.vivero}</option>
                                        ) :
                                            (<option selected >Seleccione</option>)}
                                        <option value="GENESIS" >GENESIS</option>
                                        <option value="VIVERO SUR"  >VIVERO SUR</option>
                                        <option value="VIVERO CUSCO"  >VIVERO CUSCO</option>
                                        <option value="VIVEROS Y SERVICIOS AGRICOLAS DEL SUR"  >VIVEROS Y SERVICIOS AGRICOLAS DEL SUR</option>
                                    </select>
                                </div>
                                <div className="form-group col-12 col-md-3">
                                    <label htmlFor="fechaSiembra" className="form-label">Fecha Siembra </label>
                                    <input
                                        required
                                        type="date"
                                        id="fechaSiembra"
                                        name="fechaSiembra"
                                        placeholder="Fecha de Siembra"
                                        className="form-control"
                                        value={moment(lotes.fechaSiembra).format('YYYY-MM-DD')}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="form-group col-12 col-md-3" >
                                    <label htmlFor="siembraVivero" className="form-label">Fecha de Siembra Vivero</label>
                                    <input
                                        required
                                        type="date"
                                        id="siembraVivero"
                                        name="siembraVivero"
                                        placeholder="Inicio de Cosecha Tentativo"
                                        className="form-control"
                                        value={moment(lotes.siembraVivero).format('YYYY-MM-DD')}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="form-group col-12 col-md-3">
                                    <label htmlFor="inicioCosechaTen" className="form-label">Inicio de Cosecha Tentativo</label>
                                    <input
                                        required
                                        type="date"
                                        id="inicioCosechaTen"
                                        name="inicioCosechaTen"
                                        placeholder="Inicio de Cosecha Tentativo"
                                        className="form-control"
                                        value={moment(lotes.inicioCosechaTen).format('YYYY-MM-DD')}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="form-group col-12 col-md-3">
                                    <label htmlFor="finCosechaTen" className="form-label">Fin de Cosecha Tentativo</label>
                                    <input
                                        required
                                        type="date"
                                        id="finCosechaTen"
                                        name="finCosechaTen"
                                        placeholder="Fin de Cosecha Tentativo"
                                        className="form-control"
                                        value={moment(lotes.finCosechaTen).format('YYYY-MM-DD')}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                
                                <div className="form-group col-12 col-md-3">
                                    <label htmlFor="proximaCosecha" className="form-label">Proxima Cosecha</label>
                                    <input
                                        required
                                        type="date"
                                        id="proximaCosecha"
                                        name="proximaCosecha"                                       
                                        className="form-control"
                                        
                                        value={moment(lotes.proximaCosecha).format('YYYY-MM-DD')}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                
                               </div>
                               <div className="form-group row col-12 offset-md-3 text-center ">
                                        <div className="col-md-3 p-2">
                                        {params.id?(<button className=" btn btn-dark col-12  ">Editar Lote</button>)
                                        :(<button className=" btn btn-dark col-12  ">Crear Lote</button>)}
                                        </div>
                                        <div className="col-md-3 p-2">
                                        <button onClick={handleCancel} className="btn btn-outline-secondary col-12">Cancelar</button>
                                        </div>                                                                                   
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default LoteForm
