import React from 'react'

function IndexReportes() {
    return (
        <div>
            Index Reportes   
        </div>
    )
}

export default IndexReportes
    