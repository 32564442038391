import React, { useState, useEffect } from 'react'
import { localidad } from './localidad'
import * as localidadService from './LocalidadService'
import {toast} from 'react-toastify'
import { useNavigate } from 'react-router-dom'

function LocalidadList() {
    const history = useNavigate();
    const [localidades, setLocalidades] = useState<localidad[]>([]);
    const loadLocalidades = async () => {
        const res = await localidadService.getLocalidades();
        setLocalidades(res.data);
    }
    useEffect(() => {
        loadLocalidades();
    }, []);
    const handleDelete = async (id: string) => {
        try {
            await localidadService.deleteLocalidad(id);
            loadLocalidades();
        } catch (error:any) {
            if (error.message === 'Request failed with status code 303') {
                toast.error('No se puede eliminar, registros pendientes');
            }
        }
    }
    return (
        <div className="col-10 offset-1">
            <div className="text-center">
                <h3>LOCALIDADES  <svg onClick={() => history('/new-localidad')} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                </svg></h3>
            </div>
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">NOMBRE</th>
                        <th scope="col">COORDENADAS</th>
                        <th scope="col">DEPARTAMENTO</th>
                        <th scope="col">OPCIONES</th>
                    </tr>
                </thead>
                <tbody>
                    {localidades.map((localidad) => {
                        return <tr key={localidad._id}>
                            <td>{localidad.nombre}</td>
                            <td>{localidad.coordenadas}</td>
                            <td>{localidad.departamento}</td>

                            <td><svg onClick={() => history(`/update-localidad/${localidad._id}`)} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-pencil-fill" style={{ cursor: "pointer" }} viewBox="0 0 16 16">
                                <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                            </svg> &nbsp;&nbsp;&nbsp;
                <svg onClick={() => localidad._id && handleDelete(localidad._id)} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-trash" style={{ cursor: "pointer" }} viewBox="0 0 16 16">
                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                    <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                </svg>
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>

        </div>
    )
}

export default LocalidadList
