import React from 'react'

function DepartamentoList() {
    return (
        <div>
            DEPARTAMENTO LIST
        </div>
    )
}

export default DepartamentoList
