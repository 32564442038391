import moment from 'moment';
import React, { useState, useEffect, ChangeEvent } from 'react'
import { toast } from 'react-toastify';

import {
    CardReporte, HeaderCard, BodyCard, RegistroObjetivo, RegistroOutput,
    WrapProgressbar, ValorProgressBar, DivContainerPB, ColorContainerPB, 
    TextPorcentPB, ContainerTitle, TextUMTitle, ContainerMixProd, CardMixProd,
    WrapTitleMixProd, WrapDataMixProd, RowDataMP, ContendNameMP, ContendDataMP,
    WrapPBMP, DivContainerPBMP, TextPorcentPBMP, ColorContainerPBMP, DivisorCat,
    CheckResumen, ContainerCheckBox, LabelCheckBox
} from './ResumenInputElements'
import Progressbar from './ProgressBar'
import { rangoFechas, rangoFechasPrint, resumenInputs, listInputs, listOutputs, listParametros, listOutputsV2, listRegistrosObj, filterProductos } from '../typesCalidadProduccion'
import * as CalidadProduccionServices from '../CalidadProduccionServices'
import gifLoad from '../../../Media/gifs/load.gif'

type InputChange = ChangeEvent<HTMLInputElement | HTMLSelectElement>;
function ResumenInputs() {
    //**INTERVAL */
    const [counter, setCounter] = useState(0)
    const [reloadInterval, setReloadInterval] = useState(false)
    // const [listResumenInputs, setListResumenInputs] = useState<listRegistrosObj[]>([])
    const [listReporteObjetivos, setListReporteObjetivos] = useState<listRegistrosObj[]>([])
    const [listROGeneral, setListROGeneral] = useState<listRegistrosObj[]>([]);
    const [listROMateriaPrima, setListROMateriaPrima] = useState<listRegistrosObj[]>([]);
    const [listROLineaCocido, setListROLineaCocido] = useState<listRegistrosObj[]>([]);
    const [listROLineaCrudo, setListROLineaCrudo] = useState<listRegistrosObj[]>([]);
    const [listROSublineaCongelado, setListROSublineaCongelado] = useState<listRegistrosObj[]>([]);
    const [listRODisEquipos, setListRODisEquipos] = useState<listRegistrosObj[]>([]);
    const [listROConsumibles, setListROConsumibles] = useState<listRegistrosObj[]>([]);

    const [rangoFechas, setRangoFechas] = useState({ fechaInicio: '', fechaFin: '' });
    const [diferenciaDias, setDiferenciaDias] = useState([])
    const [fechasTH, setFechasTH] = useState<rangoFechasPrint[]>([])
    const [listInputs, setListInputs] = useState<listInputs[]>([])
    const [listOutputs, setListOutputs] = useState<listOutputsV2[]>([])
    const [listParametros, setListParametros] = useState<listParametros[]>([])
    const [busquedaInputs, setBusquedaInputs] = useState<listInputs[]>([])
    const [filterProductos, setFilterProductos] = useState<filterProductos[]>([])
    const [filterCalibres, setFilterCalibres] = useState<filterProductos[]>([])

    const [listObjetivos, setListObjetivos] = useState()
    const [setValueLoad, setSetValueLoad] = useState(false)
    const [stateCheckResumen, setStateCheckResumen] = useState(false)
    const changeRangoFechas = async (e: InputChange) => {
        setRangoFechas({ ...rangoFechas, [e.target.name]: e.target.value })
    }
    const getResumenInputs = async () => {
        try {
            setLoadDiv(true)
            setFechasTH([])

            setCounter(0)
            setReloadInterval(true)
            if (!rangoFechas.fechaFin || !rangoFechas.fechaInicio) {
                return toast.error("Fechas no definidas")
            }
            const momentFecha1 = moment(rangoFechas.fechaInicio)
            const momentFecha2 = moment(rangoFechas.fechaFin)
            const difDias = momentFecha2.diff(momentFecha1, 'days')
            // console.log(difDias, "DIFERENCIA DE DIAS")
            let arrayFechas = []
            for (let i = 0; i <= difDias; i++) {
                const oneFecha = moment(rangoFechas.fechaInicio).add(i, 'days').format('DD-MM-YYYY')
                const fechaJson = {
                    id: i,
                    fecha: oneFecha
                }
                arrayFechas.push(fechaJson);
            }
            setFechasTH(arrayFechas)            
            const resResumenInputs = await CalidadProduccionServices.getResumenInputsByFecha(rangoFechas)            
            if(resResumenInputs.data.error) {
                setLoadDiv(false)
                return toast.error(resResumenInputs.data.message)
            }
            setFilterCalibres(resResumenInputs.data.filterCalibres)
            setFilterProductos(resResumenInputs.data.filterCortes)
            setListReporteObjetivos(resResumenInputs.data.resObjetivos)
            var tmpListObjetivos = resResumenInputs.data.resObjetivos;
            if (stateCheckResumen) {
                tmpListObjetivos = resResumenInputs.data.resObjetivos.filter((allObjetivos:listRegistrosObj) => allObjetivos.esResumen === 1)
            }
            const findROGeneral = tmpListObjetivos.filter((objetivo:listRegistrosObj)=> objetivo.nameCategorio ==='GENERAL'); // Filtramos Categoria General
            const findROMateriaPrima = tmpListObjetivos.filter((objetivo:listRegistrosObj)=> objetivo.nameCategorio ==='MATERIA PRIMA'); // Filtramos Categoria Materia Prima
            const findROLineaCocido = tmpListObjetivos.filter((objetivo:listRegistrosObj)=> objetivo.nameCategorio ==='LINEA DE COCIDO'); // Filtramos Categoria Materia Prima
            const findROLineaCrudo = tmpListObjetivos.filter((objetivo:listRegistrosObj)=> objetivo.nameCategorio ==='LINEA DE CRUDO'); // Filtramos Categoria Materia Prima
            const findROSublineaCongelado = tmpListObjetivos.filter((objetivo:listRegistrosObj)=> objetivo.nameCategorio ==='SUB-LINEA DE CONGELADO'); // Filtramos Categoria Materia Prima
            const findRODisEquipos = tmpListObjetivos.filter((objetivo:listRegistrosObj)=> objetivo.nameCategorio ==='DISPONIBILIDAD DE EQUIPOS'); // Filtramos Categoria Materia Prima
            const findROConsumibles = tmpListObjetivos.filter((objetivo:listRegistrosObj)=> objetivo.nameCategorio ==='CONSUMIBLES'); // Filtramos Categoria Materia Prima
            setListROGeneral(findROGeneral);
            setListROMateriaPrima(findROMateriaPrima);
            setListROLineaCocido(findROLineaCocido);
            setListROLineaCrudo(findROLineaCrudo);
            setListROSublineaCongelado(findROSublineaCongelado);
            setListRODisEquipos(findRODisEquipos);
            setListROConsumibles(findROConsumibles);
            
            
            setListInputs(resResumenInputs.data.resInputs)
            setBusquedaInputs(resResumenInputs.data.resInputs)
            setListOutputs(resResumenInputs.data.outputs)
            
            getAllParametros();
            setReloadInterval(false)
            setLoadDiv(false)
        } catch (error:any) {
            toast.error(error.message)
            setLoadDiv(false)
        }
    }
   const setResumenReportes = async() =>{
       const resumenReportes = listReporteObjetivos.filter((allObjetivos) => allObjetivos.esResumen === 1)
       const findROGeneral = resumenReportes.filter((objetivo:listRegistrosObj)=> objetivo.nameCategorio ==='GENERAL'); // Filtramos Categoria General
       const findROMateriaPrima = resumenReportes.filter((objetivo:listRegistrosObj)=> objetivo.nameCategorio ==='MATERIA PRIMA'); // Filtramos Categoria Materia Prima
       const findROLineaCocido = resumenReportes.filter((objetivo:listRegistrosObj)=> objetivo.nameCategorio ==='LINEA DE COCIDO'); // Filtramos Categoria Materia Prima
       const findROLineaCrudo = resumenReportes.filter((objetivo:listRegistrosObj)=> objetivo.nameCategorio ==='LINEA DE CRUDO'); // Filtramos Categoria Materia Prima
       const findROSublineaCongelado = resumenReportes.filter((objetivo:listRegistrosObj)=> objetivo.nameCategorio ==='SUB-LINEA DE CONGELADO'); // Filtramos Categoria Materia Prima
       const findRODisEquipos = resumenReportes.filter((objetivo:listRegistrosObj)=> objetivo.nameCategorio ==='DISPONIBILIDAD DE EQUIPOS'); // Filtramos Categoria Materia Prima
       const findROConsumibles = resumenReportes.filter((objetivo:listRegistrosObj)=> objetivo.nameCategorio ==='CONSUMIBLES'); // Filtramos Categoria Materia Prima
       setListROGeneral(findROGeneral);
       setListROMateriaPrima(findROMateriaPrima);
       setListROLineaCocido(findROLineaCocido);
       setListROLineaCrudo(findROLineaCrudo);
       setListROSublineaCongelado(findROSublineaCongelado);
       setListRODisEquipos(findRODisEquipos);
       setListROConsumibles(findROConsumibles);
   }
   const disableResumenReportes = async() =>{
    const findROGeneral = listReporteObjetivos.filter((objetivo:listRegistrosObj)=> objetivo.nameCategorio ==='GENERAL'); // Filtramos Categoria General
    const findROMateriaPrima = listReporteObjetivos.filter((objetivo:listRegistrosObj)=> objetivo.nameCategorio ==='MATERIA PRIMA'); // Filtramos Categoria Materia Prima
    const findROLineaCocido = listReporteObjetivos.filter((objetivo:listRegistrosObj)=> objetivo.nameCategorio ==='LINEA DE COCIDO'); // Filtramos Categoria Materia Prima
    const findROLineaCrudo = listReporteObjetivos.filter((objetivo:listRegistrosObj)=> objetivo.nameCategorio ==='LINEA DE CRUDO'); // Filtramos Categoria Materia Prima
    const findROSublineaCongelado = listReporteObjetivos.filter((objetivo:listRegistrosObj)=> objetivo.nameCategorio ==='SUB-LINEA DE CONGELADO'); // Filtramos Categoria Materia Prima
    const findRODisEquipos = listReporteObjetivos.filter((objetivo:listRegistrosObj)=> objetivo.nameCategorio ==='DISPONIBILIDAD DE EQUIPOS'); // Filtramos Categoria Materia Prima
    const findROConsumibles = listReporteObjetivos.filter((objetivo:listRegistrosObj)=> objetivo.nameCategorio ==='CONSUMIBLES'); // Filtramos Categoria Materia Prima
    setListROGeneral(findROGeneral);
    setListROMateriaPrima(findROMateriaPrima);
    setListROLineaCocido(findROLineaCocido);
    setListROLineaCrudo(findROLineaCrudo);
    setListROSublineaCongelado(findROSublineaCongelado);
    setListRODisEquipos(findRODisEquipos);
    setListROConsumibles(findROConsumibles);
   }

    const getAllParametros = async () => {
        const resParametros = await CalidadProduccionServices.listParametros();
        setListParametros(resParametros.data)
    }

    const changeBusquedaInput = (e: InputChange) => {
        console.log(e.target.value)
        filterInput(e.target.value)
    }
    const filterInput = (terminoBusqueda: string) => {
        var resultadoBusqueda = busquedaInputs.filter((input) => {
            if (input.nameInput.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())) {
                return input
            }

        })
        setListInputs(resultadoBusqueda)
    }
    const setLoadDiv =(state:boolean)=>{
        setSetValueLoad(state)
    }
    const handleCheckResumen = ()=>{
        setStateCheckResumen(!stateCheckResumen);        
    }

    useEffect(() => {
        if (stateCheckResumen) {            
            setResumenReportes();
        }else{
            disableResumenReportes();
        }

    }, [stateCheckResumen])
    useEffect(() => {
        console.log(reloadInterval)
        if (reloadInterval) {
            console.log("ENTRO")
            const interval = setInterval(() => {
                setCounter(oldValue => {
                    const newValue = oldValue + 1
                    if (newValue >= 50) {
                        console.log(newValue)
                        clearInterval(interval)
                    }
                    return newValue
                })

            }, 10)
        }
    }, [reloadInterval])
    return (
        <div className="pb-5">

            <div className={`${setValueLoad ? 'addOpacitiGif': ''} loadContainerGif`}>
                <img src={gifLoad} className={`${setValueLoad ? 'opacityOn': 'opacityOff'}`}  alt=""/>
            </div>
            <h2 className="text-center">Resumen Inputs</h2>
            <div className="d-flex flex-wrap align-items-end justify-content-center">
                <div className="p-2">
                    <label htmlFor="" className="">Fecha Inicial</label>
                    <input className="form-control form-control-lg  "
                        name="fechaInicio"
                        type="date"
                        max={rangoFechas.fechaFin}
                        onChange={changeRangoFechas}
                        value={rangoFechas.fechaInicio}
                        required />
                </div>
                <div className="p-2">
                    <label htmlFor="" className="">Fecha Final</label>
                    <input className="form-control form-control-lg "
                        name="fechaFin"
                        type="date"
                        min={rangoFechas.fechaInicio}
                        onChange={changeRangoFechas}
                        value={rangoFechas.fechaFin}
                        required />
                </div>
                <div className="p-2">
                    <button className='btn btn-dark btn-lg'
                        onClick={getResumenInputs}>Buscar</button>
                </div>
            </div>

            <div className="text-left p-4">
                <div className="row col-12">
                    <h3 style={{ color: 'gray' }}>REPORTE</h3>
                    <ContainerCheckBox>
                        <CheckResumen type='checkbox' 
                        onClick={handleCheckResumen}
                        checked={stateCheckResumen}
                        />
                        <LabelCheckBox>Resumen</LabelCheckBox>
                    </ContainerCheckBox>                    
                </div>
                <div style={{ height: '4px', background: 'gray' }}>
                </div>
            </div>
            <div className="d-flex flex-wrap align-items-end justify-content-center">
            <ContainerMixProd>
                <CardMixProd>
                    <WrapTitleMixProd>
                        Mix de Productos
                    </WrapTitleMixProd>
                    <WrapDataMixProd>
                    {filterProductos.map((producto) =>{
                            return(
                            <RowDataMP>
                                <ContendNameMP>
                                    {producto.nameOutput}  &nbsp;
                                </ContendNameMP>
                                <ContendDataMP>
                                    <WrapPBMP>
                                        <DivContainerPBMP>
                                        <ColorContainerPBMP width={`${producto.avgRegistros}%`}></ColorContainerPBMP>
                                            <TextPorcentPBMP> {producto.avgRegistros.toFixed(2)}% </TextPorcentPBMP>                                        
                                        </DivContainerPBMP>
                                    </WrapPBMP>
                                </ContendDataMP>
                            </RowDataMP> 
                            )
                        })}     
                    </WrapDataMixProd>
                </CardMixProd>
                <CardMixProd>
                    <WrapTitleMixProd>
                        Mix de Calibres
                    </WrapTitleMixProd>
                    <WrapDataMixProd>
                        {filterCalibres.map((calibre) =>{
                            return(
                            <RowDataMP>
                                <ContendNameMP>
                                    {calibre.nameOutput}  &nbsp;
                                </ContendNameMP>
                                <ContendDataMP>
                                    <WrapPBMP>
                                        <DivContainerPBMP>
                                        <ColorContainerPBMP width={`${calibre.avgRegistros}%`}></ColorContainerPBMP>
                                            <TextPorcentPBMP> {calibre.avgRegistros.toFixed(2)}% </TextPorcentPBMP>                                        
                                        </DivContainerPBMP>
                                    </WrapPBMP>
                                </ContendDataMP>
                            </RowDataMP> 
                            )
                        })}                    
                    </WrapDataMixProd>
                </CardMixProd>
            </ContainerMixProd>
            </div>

            <div className="d-flex flex-wrap align-items-end justify-content-center">
                <DivisorCat>
                    GENERAL
                </DivisorCat>
                {listROGeneral.map((ROGeneral) =>{
                    return (
                        <CardReporte>
                            <HeaderCard>                                
                                <ContainerTitle>
                                {ROGeneral.nameObjetivo}
                                <TextUMTitle>
                                    {ROGeneral.Output.uMedida}
                                </TextUMTitle>
                                </ContainerTitle>
                            </HeaderCard>
                            <BodyCard>
                                <RegistroOutput>
                                    Output <br />
                                    {ROGeneral.AvgRegOutput.toFixed(2)}
                                </RegistroOutput>
                                <WrapProgressbar>
                                    <DivContainerPB>
                                        <TextPorcentPB>{ROGeneral.PocentOutput.toFixed(2)} %</TextPorcentPB>
                                        <ColorContainerPB width={`${ROGeneral.PocentOutput}%`}>
                                        </ColorContainerPB>
                                    </DivContainerPB>                                  
                                </WrapProgressbar>
                                <RegistroObjetivo>
                                    Objetivo <br />
                                    {ROGeneral.AvgRegObjetivo.toFixed(2)}
                                </RegistroObjetivo>
                            </BodyCard>
                        </CardReporte>
                    )
                })}
                <DivisorCat>
                    MATERIA PRIMA
                </DivisorCat>   
                {listROMateriaPrima.map((ROMateriaPrima) =>{
                    return (
                        <CardReporte>
                            <HeaderCard>                                
                                <ContainerTitle>
                                {ROMateriaPrima.nameObjetivo}
                                <TextUMTitle>
                                    {ROMateriaPrima.Output.uMedida}
                                </TextUMTitle>
                                </ContainerTitle>
                            </HeaderCard>
                            <BodyCard>
                                <RegistroOutput>
                                    Output <br />
                                    {ROMateriaPrima.AvgRegOutput.toFixed(2)}
                                </RegistroOutput>
                                <WrapProgressbar>
                                    <DivContainerPB>
                                        <TextPorcentPB>{ROMateriaPrima.PocentOutput.toFixed(2)} %</TextPorcentPB>
                                        <ColorContainerPB width={`${ROMateriaPrima.PocentOutput}%`}>
                                        </ColorContainerPB>
                                    </DivContainerPB>                                  
                                </WrapProgressbar>
                                <RegistroObjetivo>
                                    Objetivo <br />
                                    {ROMateriaPrima.AvgRegObjetivo.toFixed(2)}
                                </RegistroObjetivo>
                            </BodyCard>
                        </CardReporte>
                    )
                })} 
                <DivisorCat>
                    LINEA DE COCIDO
                </DivisorCat> 
                {listROLineaCocido.map((ROLineaCocido) =>{
                    return (
                        <CardReporte>
                            <HeaderCard>                                
                                <ContainerTitle>
                                {ROLineaCocido.nameObjetivo}
                                <TextUMTitle>
                                    {ROLineaCocido.Output.uMedida}
                                </TextUMTitle>
                                </ContainerTitle>
                            </HeaderCard>
                            <BodyCard>
                                <RegistroOutput>
                                    Output <br />
                                    {ROLineaCocido.AvgRegOutput.toFixed(2)}
                                </RegistroOutput>
                                <WrapProgressbar>
                                    <DivContainerPB>
                                        <TextPorcentPB>{ROLineaCocido.PocentOutput.toFixed(2)} %</TextPorcentPB>
                                        <ColorContainerPB width={`${ROLineaCocido.PocentOutput}%`}>
                                        </ColorContainerPB>
                                    </DivContainerPB>                                  
                                </WrapProgressbar>
                                <RegistroObjetivo>
                                    Objetivo <br />
                                    {ROLineaCocido.AvgRegObjetivo.toFixed(2)}
                                </RegistroObjetivo>
                            </BodyCard>
                        </CardReporte>
                    )
                })} 
                <DivisorCat>
                    LINEA DE CRUDO
                </DivisorCat> 
                {listROLineaCrudo.map((ROLineaCrudo) =>{
                    return (
                        <CardReporte>
                            <HeaderCard>                                
                                <ContainerTitle>
                                {ROLineaCrudo.nameObjetivo}
                                <TextUMTitle>
                                    {ROLineaCrudo.Output.uMedida}
                                </TextUMTitle>
                                </ContainerTitle>
                            </HeaderCard>
                            <BodyCard>
                                <RegistroOutput>
                                    Output <br />
                                    {ROLineaCrudo.AvgRegOutput.toFixed(2)}
                                </RegistroOutput>
                                <WrapProgressbar>
                                    <DivContainerPB>
                                        <TextPorcentPB>{ROLineaCrudo.PocentOutput.toFixed(2)} %</TextPorcentPB>
                                        <ColorContainerPB width={`${ROLineaCrudo.PocentOutput}%`}>
                                        </ColorContainerPB>
                                    </DivContainerPB>                                  
                                </WrapProgressbar>
                                <RegistroObjetivo>
                                    Objetivo <br />
                                    {ROLineaCrudo.AvgRegObjetivo.toFixed(2)}
                                </RegistroObjetivo>
                            </BodyCard>
                        </CardReporte>
                    )
                })}
                <DivisorCat>
                    SUB-LINEA DE CONGELADO
                </DivisorCat> 
                {listROSublineaCongelado.map((ROSublineaCongelado) =>{
                    return (
                        <CardReporte>
                            <HeaderCard>                                
                                <ContainerTitle>
                                {ROSublineaCongelado.nameObjetivo}
                                <TextUMTitle>
                                    {ROSublineaCongelado.Output.uMedida}
                                </TextUMTitle>
                                </ContainerTitle>
                            </HeaderCard>
                            <BodyCard>
                                <RegistroOutput>
                                    Output <br />
                                    {ROSublineaCongelado.AvgRegOutput.toFixed(2)}
                                </RegistroOutput>
                                <WrapProgressbar>
                                    <DivContainerPB>
                                        <TextPorcentPB>{ROSublineaCongelado.PocentOutput.toFixed(2)} %</TextPorcentPB>
                                        <ColorContainerPB width={`${ROSublineaCongelado.PocentOutput}%`}>
                                        </ColorContainerPB>
                                    </DivContainerPB>                                  
                                </WrapProgressbar>
                                <RegistroObjetivo>
                                    Objetivo <br />
                                    {ROSublineaCongelado.AvgRegObjetivo.toFixed(2)}
                                </RegistroObjetivo>
                            </BodyCard>
                        </CardReporte>
                    )
                })}  
                <DivisorCat>
                    DISPONIBILIDAD DE EQUIPOS
                </DivisorCat> 
                {listRODisEquipos.map((RODisEquipos) =>{
                    return (
                        <CardReporte>
                            <HeaderCard>                                
                                <ContainerTitle>
                                {RODisEquipos.nameObjetivo}
                                <TextUMTitle>
                                    {RODisEquipos.Output.uMedida}
                                </TextUMTitle>
                                </ContainerTitle>
                            </HeaderCard>
                            <BodyCard>
                                <RegistroOutput>
                                    Output <br />
                                    {RODisEquipos.AvgRegOutput.toFixed(2)}
                                </RegistroOutput>
                                <WrapProgressbar>
                                    <DivContainerPB>
                                        <TextPorcentPB>{RODisEquipos.PocentOutput.toFixed(2)} %</TextPorcentPB>
                                        <ColorContainerPB width={`${RODisEquipos.PocentOutput}%`}>
                                        </ColorContainerPB>
                                    </DivContainerPB>                                  
                                </WrapProgressbar>
                                <RegistroObjetivo>
                                    Objetivo <br />
                                    {RODisEquipos.AvgRegObjetivo.toFixed(2)}
                                </RegistroObjetivo>
                            </BodyCard>
                        </CardReporte>
                    )
                })}  
                <DivisorCat>
                    CONSUMIBLES
                </DivisorCat> 
                {listROConsumibles.map((ROConsumibles) =>{
                    return (
                        <CardReporte>
                            <HeaderCard>                                
                                <ContainerTitle>
                                {ROConsumibles.nameObjetivo}
                                <TextUMTitle>
                                    {ROConsumibles.Output.uMedida}
                                </TextUMTitle>
                                </ContainerTitle>
                            </HeaderCard>
                            <BodyCard>
                                <RegistroOutput>
                                    Output <br />
                                    {ROConsumibles.AvgRegOutput.toFixed(2)}
                                </RegistroOutput>
                                <WrapProgressbar>
                                    <DivContainerPB>
                                        <TextPorcentPB>{ROConsumibles.PocentOutput.toFixed(2)} %</TextPorcentPB>
                                        <ColorContainerPB width={`${ROConsumibles.PocentOutput}%`}>
                                        </ColorContainerPB>
                                    </DivContainerPB>                                  
                                </WrapProgressbar>
                                <RegistroObjetivo>
                                    Objetivo <br />
                                    {ROConsumibles.AvgRegObjetivo.toFixed(2)}
                                </RegistroObjetivo>
                            </BodyCard>
                        </CardReporte>
                    )
                })}        
              

            </div>
            <div className="d-flex flex-wrap justify-content-start col-12 col-sm-10 mx-auto">
                <div className="p-2">
                    <input className="form-control form-control-lg border-info" placeholder="Busqueda Input" type="text" name="busquedaInput" id="busquedaInput"
                        onChange={changeBusquedaInput} />
                </div>
            </div>
            <div className="table-responsive col-12 col-sm-10 mx-auto">
                <table className="table-bordered tableFixHead" style={{ background: '#ffe9e9' }}>
                    <thead>
                        <tr>
                            <th scope="col" style={{ maxWidth: '50px' }} className="">CATEGORIA</th>
                            <th scope="col" style={{ maxWidth: '120px' }} className="">SUB CATEGORIA</th>
                            <th scope="col" style={{ minWidth: '220px' }} className="">INPUT</th>


                            {fechasTH.map((th) => {
                                return <th key={th.id} scope="col" style={{ minWidth: '100px' }} className="">{th.fecha}</th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {listInputs.map((inputs) => {
                            return (
                                <tr key={inputs._id}>
                                    <td>{inputs.categoria.nombre}</td>
                                    <td>{inputs.idSubCatInput.nombre}</td>
                                    <td>{inputs.nameInput} <br />
                                        <span className='text-info h6'>{inputs.uMedida.nombre}</span>
                                    </td>
                                    {inputs.registros.map((registro) => {
                                        if (registro > 0) {
                                            return <td className="text-info font-weight-bold h5">{registro}</td>
                                        } else {
                                            return <td >{registro}</td>
                                        }
                                    })}
                                </tr>)
                        })}
                    </tbody>
                </table>
            </div>
            <div className="text-left p-4">
                <h3 className="text-info">OUTPUTS</h3>
                <div className='bg-info' style={{ height: '4px' }}>
                </div>
            </div>
            <div className="table-responsive col-12 col-sm-10 mx-auto ">
                <table className="table  table-info border border-info  tableFixHead">
                    <thead>
                        <tr>
                            <th scope="col" style={{ maxWidth: '50px' }} className="">CATEGORIA</th>
                            <th scope="col" style={{ maxWidth: '120px' }} className="">SUB CATEGORIA</th>
                            <th scope="col" style={{ minWidth: '220px' }} className="">OUTPUTS</th>
                            {fechasTH.map((th) => {
                                return <th key={th.id} scope="col" style={{ minWidth: '100px' }} className="">{th.fecha}</th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {listOutputs.map((output) => {
                            return <tr key={output._id}>
                                <td>{output.categoria.nombre}</td>
                                <td>{output.subcategoria.nombre}</td>
                                <td>{output.nameOutput} <br /><span className='text-info h6'>{output.uMedida}</span></td>
                                {/* {fechasTH.map((fecha) => {
                                    const actualRegInput = listResumenInputs.find((resuInput) => (resuInput.idInput.nameInput === output.nameOutput && moment(resuInput.fechaRegistro).format('DD-MM-YYYY') === fecha.fecha))
                                    if (actualRegInput) {
                                        return c
                                    } else {
                                        return <td>0</td>
                                    }
                                })} */}
                                {output.registros.map((registro) => {
                                    if (registro == Infinity) {
                                        return <td>0</td>
                                    }
                                    else if (registro >= 0.5 || registro <= -0.5) {
                                        return <td className="text-info font-weight-bold h5">{registro}</td>
                                    }
                                    else {
                                        return <td>0</td>
                                    }
                                })}
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ResumenInputs
