import React, { MouseEventHandler, useState, useEffect, useContext } from 'react'
import { Button, Modal } from 'react-bootstrap';
// import ModalDetalle from './ModalDetalle';
import {ModalConfirm, ModalConfirmDeleteDet} from './ModalConfirm/ModalConfirm'
import ModalMover from './ModalMover/ModalMover'
import ModalDividir from './ModalDividir/ModalDivir'
import { Pallets, detallePallet, formatos, envases, tapas } from './boxModal'
import * as almacenMPService from '../AlmacenMP/AlmacenMPServices'
import { toast } from 'react-toastify';
import authContext from '../../../context/AuthContext';

import moment from 'moment';
import { ListFormat } from 'typescript';
import { Console } from 'console';
interface Props {
    show: boolean,
    onHide: MouseEventHandler<HTMLElement> | any,
    base: any,
    checkFlagState: ()=> void
}
function ModalBox(props: Props) {
    const { loggedIn } = useContext(authContext);
    

    const initialStatePalletArray = {
        accionesCorrectivas: '',
        base: props.base._id,
        codigo: '',
        planta: '', rx: '', userid : loggedIn._id,
        estadopallet: '',
        created: '',
        envase: '',
        formato:  {
            _id: '',
            codigo: '',
            descripcion: '',
            calidad: ''
          },
        nivel: 0,
        numPallet: '',
        observaciones: '',
        tapa:  {
            _id: '',
            codigo: '',
            descripcion: ''
          },
        contador: '',
        turno: '',
        _id: ''
    }
    const [pallets, setPallets] = useState<Pallets[]>([initialStatePalletArray])
    // const [Niveles, setNiveles] = useState({ id: '' })
    const initialStatePallet = {
        accionesCorrectivas: '',
        base: props.base._id,
        codigo: '',
        created: '',
        envase: '',
        planta: '', rx: '', userid : loggedIn._id,
        estadopallet: '',
        formato:  {
            _id: '',
            codigo: '',
            descripcion: '',
            calidad: ''
          },
        nivel: pallets.length + 1,
        numPallet: '',
        observaciones: '',
        tapa:  {
            _id: '',
            codigo: '',
            descripcion: ''
          },
        contador: '',
        turno: '',
        _id: ''
    }
    const [topPallet, setTopPallet] = useState<Pallets>(initialStatePallet)
    // const [modalDet, setModalDet] = useState(false)
    const [miQr, setMiQr] = useState("QR Inicial")
    const [miPallet, setMiPallet] = useState('')
    const [modalConfirm, setModalConfirm] = useState(false)
    const [modalConfirmDet, setModalConfirmDet] = useState(false)
    const [estadoModalMover, setEstadoModalMover] = useState(false)
    const [modalDividir, setModalDividir] = useState(false)
    const [stateBtnSaveTop, setStateBtnSaveTop] = useState({ top: true, detalle: true })
    const [detallePallet, setDetallePallet] = useState<detallePallet[]>([])
    const [idPalletToDelete, setidPalletToDelete] = useState('')
    const [formDetPallet, setFormDetPallet] = useState<detallePallet>({_id:'', fechaProduccion: '', codigoJuliano: 0, batch: '', niveles: 0, saldo: 0, prodDia: 0, prodAcumulada: 0, pallet: '',subproceso: ''})
    const [textBusFormato, setTextBusFormato] = useState('');
   
    //const [fecha, setFecha] = useState<string>('');
    const [filaDetalle, setFilaDetalle] = useState(-1);
    const [diaJuliano, setDiaJuliano] = useState('');
    const [tipoProceso, setTipoProceso] = useState('');
    const calcularDiaJuliano = (fechaA:string) => {

      let fecha =new Date(fechaA);
      // Obtener el día del año
        var comienzoDeAño = new Date(fecha.getFullYear(), 0, 1);
        var milisegundosEnUnDia = 24 * 60 * 60 * 1000;
        var diasTranscurridos = Math.floor((fecha.getTime() - comienzoDeAño.getTime()) / milisegundosEnUnDia) + 1;
        diasTranscurridos++;

      setDiaJuliano(diasTranscurridos.toString());
      
    };

    const asignarJuliano = () => {
        
        setFormDetPallet({...formDetPallet, codigoJuliano: parseInt(diaJuliano)})
        
    }

    const [listFormatos, setListFormatos] = useState<formatos[]>([])
    const [esSalida, setEsSalida] = useState(0)
    // const [flagEstPallet, setFlagEstPallet] = useState(false)///enviaremos un true cuando detectemos la creacion o la edicion de un pallet para actualizar el almacen

    let valorQr = ""
    const actualizarPallets= async()=>{
        props.checkFlagState();   
        const getPalletsByBase = await almacenMPService.getPalletsByBase(topPallet.base)
        setStateBtnSaveTop({ top: false, detalle: false });
        setPallets(getPalletsByBase.data)        
    }
    const reinicioFormDetalle= ()=>{
        setFormDetPallet({_id:'', fechaProduccion: '', codigoJuliano: 0, batch: '', niveles: 0, saldo: 0, prodDia: 0, prodAcumulada: 0, pallet: '', subproceso: ''})
    }
    const actualizarDetalles = async(id:string)=>{
        const resDetPallet= await almacenMPService.getDetPalletsById(id)      
        setDetallePallet(resDetPallet.data)
        
    }
    const actualizarMiPallet = async(valor:string)=>{
        setMiPallet(valor)
        setIsChecked(topPallet.rx=="Si"?true:false)
        
    }
    const actualizarQr = async(valor:string)=>{
        
        setMiQr(valor)
        
    }
    const handleCloseModalConfirm = () => setModalConfirm(false);
    const handleCloseModalConfirmDet = () => setModalConfirmDet(false);
    const handleCloseModalMover = () => setEstadoModalMover(false);
    const handleCloseModalDividir = () => setModalDividir(false);
    const handleShowModalDividir = (e: any) =>{
        e.preventDefault();
        if (detallePallet.length===0) return toast.error('Pallet sin Detalle!')
        setModalDividir(true)
    }
    const handleShowModalMover = (e:any) => {
        e.preventDefault();
        setEstadoModalMover(true)
    };
    const handleShowModalConfirm = (e:any) => {
        e.preventDefault();
        setEsSalida(0);
        setModalConfirm(true)
    };
    const afterModalConfirm = ()=>{
        setModalConfirm(false)
        actualizarPallets();
    }
    const afterOkModalMover = () =>{
        setEstadoModalMover(false);
        actualizarPallets();
        toast.success('Ok')
    }
    const afterModalConfirmDet = () =>{
        setModalConfirmDet(false);
        actualizarDetalles(topPallet._id);
    }
    const palletsSort = ()=>{
        props.base.pallets.sort((a:any,b:any)=>{
            return a.nivel - b.nivel;
        })
    }
    const putPallets = () => {
        if (props.base.pallets) {
            palletsSort();
            setPallets(props.base.pallets)
        } else {
            // setStateBtnSaveTop(true);
            setPallets([])
        }
    }

  
    const getMiPallet = () => {
        return miPallet
    }

    const btnAddPallet = () => {
        setStateBtnSaveTop({ top: true, detalle: false });
        setTopPallet(initialStatePallet);
        setListFormatos([])
        setTextBusFormato('')
    }

    const cargarPallet = async(id: string) => {
        
        
        setStateBtnSaveTop({ top: true, detalle: true });
        // const busquedaNivel:any  = pallets.find(pallet => pallet._id === id);              
        // setTopPallet(busquedaNivel);
        
        const resOnePallet = await almacenMPService.selectOnePallet(id);
        //valorQr = "probando"
        setTopPallet(resOnePallet.data)      
        let temporal = 'url:'+resOnePallet.data.numPallet + '|'+ resOnePallet.data.formato.codigo+ '|' //+ resOnePallet.data.formato.descripcion
        
        setIsChecked(resOnePallet.data?.rx=="Si"?true:false)
       // console.log(resOnePallet.data)
        await actualizarDetalles(id);        
        setFormDetPallet({_id:'', fechaProduccion: '', codigoJuliano: 0, batch: '', niveles: 0, saldo: 0, prodDia: 0, prodAcumulada: 0, pallet:id, subproceso:''})
        setListFormatos([])
        setTextBusFormato('')
        let total = 0
        let detalles = ''
        detallePallet.forEach((item, index) => {
             //console.log(item)
             total = total + item.prodDia
             detalles = detalles + '||' + moment(item.fechaProduccion).format('YYYY-MM-DD') + '|' + item.batch + '|' + item.prodDia
        });
        temporal = temporal + total + detalles
        actualizarQr(temporal)
         //console.log(temporal)
        //console.log("AQUI--->" + temporal)
        window.open('#/obtenerQr/' + temporal,'_blank');
    }

    const handleBtnNivel = async(id: string) => {
        
        
        setStateBtnSaveTop({ top: true, detalle: true });
        
        const resOnePallet = await almacenMPService.selectOnePallet(id);
        setTopPallet(resOnePallet.data)      
        let temporal = 'url:'+resOnePallet.data.numPallet + '|'+ resOnePallet.data.formato.codigo+ '|' //+ resOnePallet.data.formato.descripcion
        //console.log("Seleccionando Pallet****************************" + resOnePallet.data.formato.calidad)
        let calidad = resOnePallet.data.formato.calidad;
        setTipoProceso((calidad=="CONGELADO"?"CONGELADO":( (calidad === undefined)?"":(calidad==""?"":"CONSERVA"))));
       // console.log(resOnePallet.data)
        actualizarDetalles(id);        
        setFormDetPallet({_id:'', fechaProduccion: '', codigoJuliano: 0, batch: '', niveles: 0, saldo: 0, prodDia: 0, prodAcumulada: 0, pallet:id,subproceso:''})
        setListFormatos([])
        setTextBusFormato('')
        setDiaJuliano('0');
        let total = 0
        let detalles = ''
        detallePallet.forEach((item, index) => {
             total = total + item.prodDia
             detalles = detalles + '||' + moment(item.fechaProduccion).format('YYYY-MM-DD') + '|' + item.batch + '|' + item.prodDia
        });
        temporal = temporal + total + detalles
        actualizarQr(temporal)      
        
    }
    const submitTopPallet = async(e:any) => {
        e.preventDefault() 
        props.checkFlagState();       
        try {
            if (topPallet?._id) {
                //console.log("-------------------VA A MODIFICAR PALLET -----------------------------")   
                await almacenMPService.editOnePallet(topPallet);                
                actualizarPallets();
                setTopPallet(initialStatePallet);                
                toast.success("Pallet Editado")
            }else {   
                //console.log("-------------------VA A CREAR PALLET -----------------------------")   
                await almacenMPService.createOnePallet(topPallet)
                actualizarPallets();               
                toast.success("Pallet Creado")
            }      
        } catch (error:any) {
            toast.error(error.message)
        }  
    }

    const handleSubmitDetallePallet = async(e:any)=>{

        e.preventDefault();
        setFilaDetalle(-1);
        
                
        try {
          asignarJuliano()
          if(formDetPallet._id){              
              await almacenMPService.updateDetPallet(formDetPallet);
              setFormDetPallet({...formDetPallet, _id:'', fechaProduccion: '', codigoJuliano: 0, batch: '', niveles: 0, saldo: 0, prodDia: 0, prodAcumulada: 0, subproceso:''})
              actualizarDetalles(topPallet._id)
              
              toast.success('Detalle Editado') 

          }else{            
            await almacenMPService.createDetPallet(formDetPallet);
            setFormDetPallet({...formDetPallet, _id:'', fechaProduccion: '', codigoJuliano: 0, batch: '', niveles: 0, saldo: 0, prodDia: 0, prodAcumulada: 0, subproceso:''})
            actualizarDetalles(topPallet._id);
            toast.success('Detalle Creado') 
          }   
          setDiaJuliano('0')
          setFilaDetalle(-1)
        } catch (error:any) {
            toast.error(error.message)
        }     
    }
    const handleChangeDetPallet= (e:any)=>{
        setFormDetPallet({...formDetPallet, [e.target.name]: e.target.value})
        if(e.target.name=="fechaProduccion"){
              calcularDiaJuliano(e.target.value);
        }
        
    }

    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked); // Cambia el estado del checkbox cuando se hace clic
        let campo = 'rx';
        //console.log ("modificando el check" + (!isChecked ? "Si" : "No"));
        setTopPallet({...topPallet, [campo]: (!isChecked ? "Si" : "No")});
        
    }

    const handleChangeTop= (e:any)=>{
        
        if(e.target.name === 'bformato'){
            setTextBusFormato(e.target.value);
        }else if(e.target.name=="formato"){
            setTopPallet({...topPallet, [e.target.name]: e.target.value})
            let calidad = e.target.options[e.target.selectedIndex].getAttribute('data-calidad')
            setTipoProceso((calidad=="CONGELADO"?"CONGELADO":(calidad===null?"":(calidad==""?"":"CONSERVA"))))
        }else{
            setTopPallet({...topPallet, [e.target.name]: e.target.value})
        }
        
    }



    const eliminarDetalle = (id: string) =>{
       
        setidPalletToDelete(id);
        setModalConfirmDet(true)
    }
    const editarDetalle = (id: string, indice: number)=>{
        const busquedaDetalle:any  = detallePallet.find(detPallet => detPallet._id === id);
        //if(detallePallet[detallePallet.length-1]._id !== id) return toast.error('Solo se permite la edicion del ultimo detalle')
        setFormDetPallet(busquedaDetalle);
        setFilaDetalle(indice);
        setFormDetPallet({...busquedaDetalle, fechaProduccion: moment(busquedaDetalle.fechaProduccion).format('YYYY-MM-DD')})
        calcularDiaJuliano(moment(busquedaDetalle.fechaProduccion).format('YYYY-MM-DD'));
       
            
        //let campo = "codigoJuliano";
        
    }

    const buscarFormato = async()=>{
        const resBusquedaFormato = await almacenMPService.busquedaFormato(textBusFormato)
        setListFormatos(resBusquedaFormato.data);
    }


    
    const getTapasEnvases = async()=>{
        //const resTapas = await almacenMPService.getTapas();
        //const resEnvases = await almacenMPService.getEnvases();
        //setListTapas(resTapas.data);
        //setListEnvases(resEnvases.data);
    }
    const handleShowModalSalida = (e:any) =>{
        e.preventDefault();
        setEsSalida(1)
        setModalConfirm(true)
    }

    

    useEffect(() => {
       if (props.show) {
        //getTapasEnvases();
        setStateBtnSaveTop({ top: false, detalle: false })        
        reinicioFormDetalle();
        putPallets();
        //Seleccionar las caracteristicas del formato seleccionado, si es CONGELADO, CONSERVA O NINGUNO
        
       }
        
    }, [props.show])

    useEffect(() => {
      var sumaProduccion = 0;
      
      if(!formDetPallet._id){
        for (let i = 0; i < detallePallet.length; i++) {
            if(filaDetalle==i )
                break;
            const oneProdAcumulada = detallePallet[i].prodDia;
            sumaProduccion += oneProdAcumulada
        }
      }else{
        for (let i = 0; i < detallePallet.length-1; i++) {
            if(filaDetalle==i )
                break;
            const oneProdAcumulada = detallePallet[i].prodDia;
            sumaProduccion += oneProdAcumulada
        } 
      }
      const prodTotal = sumaProduccion + formDetPallet.prodDia*1;
      setFormDetPallet({...formDetPallet, prodAcumulada: prodTotal})
    }, [formDetPallet.prodDia])
    return (
        <>
        
            <Modal
                show={props.show}
                onHide={props.onHide}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Pallet {props.base.matris}
                 </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row justify-content-center">
                        {pallets.map((pallet) => {
                            return (<button className="btn btn-outline-success m-1" onClick={() => { actualizarMiPallet(pallet._id); handleBtnNivel(pallet._id) }}>Nv.{pallet.nivel} - {pallet.numPallet}</button>)
                        })
                        }

                        <button className="btn btn-success m-1" onClick={btnAddPallet}>Add Nivel</button>
                       
                    </div>
                    <div id="topPallet">
                        {stateBtnSaveTop.top ? (
                            <>
                                <form onSubmit={submitTopPallet}>
                                    <div className="row m-2">
                                        <div className="col-12 col-md-1">
                                            <label htmlFor="nivel">Nivel</label>
                                            <input type="text" className="form-control " name="nivel"
                                                    value={topPallet?.nivel} 
                                                    onChange={handleChangeTop}                                                    
                                                     />
                                        </div>
                                        
                                        <div className="col-12 col-md-2">
                                            <label htmlFor="numPallet">Codigo Pallet</label>
                                            <input type="text" className="form-control " name="numPallet" 
                                            onChange={handleChangeTop}
                                            value={topPallet?.numPallet}  readOnly/>
                                        </div>

                                        <div className="col-12 col-md-9">
                                            <div className="row align-items-end">
                                                <div className="col-8 col-md-3">
                                                    <label htmlFor="bformato">Filtrar Formato</label>
                                                    <input type="text" className="form-control " name="bformato" 
                                                    onChange={handleChangeTop}
                                                    value={textBusFormato} onKeyUp={buscarFormato}/>
                                                </div>
                                                {/* <span className="col-4 btn btn-block btn-dark "><i className="fas fa-search"></i></span> */}
                                                <div className="col-4 col-md-2">
                                                    <span className=" btn btn-block btn-primary" onClick={buscarFormato}><i className="fas fa-search"></i></span>
                                                </div>
                                                <div className="col-12 col-md-7">
                                                    <label htmlFor="formato">Codigo - Formato</label>
                                                    <select className="form-control " name="formato" onChange={handleChangeTop}  required >
                                                        {topPallet?._id ? (<>
                                                            <option value={topPallet?.formato._id} data-calidad = {topPallet?.formato.calidad} selected>{topPallet?.formato.codigo} - {topPallet?.formato.descripcion}</option>                                                    
                                                            </>
                                                        ) : (<>
                                                            <option value="" data-calidad = "" selected>Seleccione</option>
                                                            </>
                                                        )}
                                                        {listFormatos.map((formato)=>{
                                                            return(
                                                                <option value={formato._id}  data-calidad = {formato.calidad} >{formato.codigo} - {formato.descripcion}</option>
                                                            )
                                                        })}                                                                                                                        
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        

                                        <div className="col-12 col-md-4">
                                            <label htmlFor="contador">Contador</label>
                                            <input type="text" className="form-control " name="contador" 
                                            onChange={handleChangeTop}
                                            value={topPallet?.contador}  required/>
                                        </div>
                                        <div className="col-12 col-md-2">
                                            <label htmlFor="turno">Turno</label>
                                            <select className="form-control " name="turno" onChange={handleChangeTop}  required >
                                                {topPallet?._id ? (<>
                                                    <option value={topPallet?.turno} selected>{topPallet?.turno}</option>                                                    
                                                    </>
                                                ) : (<>
                                                    <option value="" selected>Seleccione</option>
                                                    <option value="DIA" >DIA</option>
                                                    <option value="TARDE">TARDE</option>
                                                    <option value="NOCHE">NOCHE</option>
                                                    </>
                                                )}
                                                    
                                            </select>
                                        </div>
                                        <div className="col-12 col-md-3">
                                        <label htmlFor="estadopallet">Estado del Pallet</label>
                                            <select className="form-control " name="estadopallet" onChange={handleChangeTop}  required >
                                                {topPallet?._id ? (<>
                                                    <option value={topPallet?.estadopallet} selected>{topPallet?.estadopallet}</option>                                                    
                                                    </>
                                                ) : (<>
                                                    <option value="" selected>Seleccione</option>
                                                    </>
                                                )}
                                                 <option value="OBSERVADO" >OBSERVADO</option>
                                                    <option value="REPROCESO">REPROCESO</option>
                                                    <option value="3RA CALIDAD">3RA CALIDAD</option>
                                                    <option value="EXPORTABLE">EXPORTABLE</option>   
                                                    <option value="INSUMOS">INSUMOS</option> 
                                            </select>
                                        </div>
                                        <div className="col-12 col-md-2">
                                            <label htmlFor="planta">Planta</label>
                                            <select className="form-control " name="planta" onChange={handleChangeTop}  required >
                                                {topPallet?._id ? (<>
                                                    <option value={topPallet?.planta  } selected>{topPallet?.planta}</option>                                                    
                                                    </>
                                                ) : (<>
                                                    <option value="" selected>Seleccione</option>
                                                    <option value="AREQUIPA" >AREQUIPA</option>
                                                    <option value="CUSCO" >CUSCO</option>
                                                    </>
                                                )}
                                                   
                                            </select>
                                        </div>
                                        <div className="col-12 col-md-1">
                                            <label htmlFor="rx">Rx</label>
                                            <input type='checkbox' name='rx' className="form-control"  checked={(topPallet?.rx==="Si"?true:false)} onChange={handleCheckboxChange} ></input>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <label htmlFor="observaciones">Observaciones</label>
                                            <textarea name="observaciones" className="form-control" cols={30} rows={2} 
                                            onChange={handleChangeTop}
                                            value={topPallet?.observaciones}></textarea>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <label htmlFor="accionesCorrectivas">Acciones Correctivas</label>
                                            <textarea name="accionesCorrectivas" className="form-control" cols={30} rows={2} 
                                            onChange={handleChangeTop}
                                            value={topPallet?.accionesCorrectivas}></textarea>
                                        </div>
                                    </div>
                                    <div className="row">
                                    <div className={`col-12 col-md-2 m-1 mx-auto`}>
                                        <button className="btn btn-block btn-dark btn-lg" ><i className="fa fa-save"></i> {stateBtnSaveTop.detalle ? ('Actualizar') : ('Crear Pallet')}</button>
                                    </div>
                                    {stateBtnSaveTop.detalle?(
                                        <>
                                            
                                            <div className={`col-12 col-md-2 m-1 mx-auto`}>
                                                <button className="btn btn-block btn-info btn-lg" onClick={handleShowModalMover}><i className="fas fa-people-carry"></i> Mover</button>
                                            </div>
                                            {/* <div className={`col-12 col-md-6 m-1 mx-auto`}>
                                                <button className="btn btn-block btn-warning btn-lg" onClick={handleShowModalSalida}>Salida</button>
                                            </div> */}
                                            <div className={`col-12 col-md-2 m-1 mx-auto`}>
                                                <button className="btn btn-block btn-warning btn-lg" onClick={handleShowModalDividir}><i className="fas fa-cut"></i> Dividir</button>
                                            </div>
                                            <div className={`col-12 col-md-2 m-1 mx-auto`}>
                                                <button className="btn btn-block btn-danger btn-lg" onClick={handleShowModalConfirm}><i className="fas fa-trash"></i> Eliminar</button>
                                            </div>
                                            <div className={`col-12 col-md-2 m-1 mx-auto`}> 
                                                <button className="btn btn-block btn-success btn-lg"  onClick={() => { cargarPallet(getMiPallet());  }}><i className="fas fa-qrcode"></i> QR</button>
                                            </div>
                                        </>
                                    ):('')}
                                    </div>
                                </form>
                            </>) : ('')}
                    </div>
                    <div id="detalle" >
                        {stateBtnSaveTop.detalle ? (<> <div className="table-responsive  col-12 mx-auto  mb-5 bg-body rounded trheight tableFixHeadPallet">
                            <table className="table shadow">
                                <thead>
                                    <tr>
                                        <th scope="col">Fecha Produccion</th>
                                        <th scope="col">Codigo Juliano</th>
                                        <th scope="col">Batch</th>
                                        <th scope="col">Niveles</th>
                                        <th scope="col">Saldo</th>
                                        <th scope="col">Prod. Del Dia</th>
                                        <th scope="col">Prod. Acumulada</th>
                                        <th scope="col">Opciones</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {detallePallet.map((det,indice)=>{
                                        return <tr>
                                        <td align="center">{moment(det.fechaProduccion).format('DD-MM-YYYY')}</td>
                                        <td align="center">{det.codigoJuliano}</td>
                                        <td align="center">{det.batch}</td>
                                        <td align="center">{det.niveles}</td>
                                        <td align="center">{det.saldo}</td>
                                        <td align="center">{det.prodDia}</td>
                                        <td align="center">{det.prodAcumulada}</td>
                                        <td>
                                            <span className="btn btn-outline-primary m-2" onClick={()=>{editarDetalle(det._id,indice)}}><i className="fas fa-pen"></i></span>
                                            <span className="btn btn-outline-danger m-2" onClick={()=>{eliminarDetalle(det._id)}}><i className="fas fa-trash"></i></span>
                                        </td>
                                    </tr>
                                    })}
                                    
                                </tbody>
                            </table>
                        </div>
                            <form onSubmit={handleSubmitDetallePallet}>
                                <div className="row m-2">
                                    <div className="col-12 col-md-3">
                                        <label htmlFor="fechaProduccion">Fecha</label>
                                        <input type="date" className="form-control " name="fechaProduccion" required
                                        value={formDetPallet?.fechaProduccion} onChange={handleChangeDetPallet} onBlur={asignarJuliano}/>
                                    </div>
                                    <div className="col-12 col-md-2">
                                        <label htmlFor="codigoJuliano">Juliano</label>
                                        <input type="text" className="form-control " readOnly name="codigoJuliano" value={diaJuliano} onChange={handleChangeDetPallet}/>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <label htmlFor="batch">SubProceso</label>
                                        <select className="form-control " name="subproceso"  onChange={handleChangeDetPallet} required >
                                                {formDetPallet?.subproceso ? (<>
                                                    <option value={formDetPallet?.subproceso  } selected>{formDetPallet?.subproceso}</option>                                                    
                                                        {tipoProceso=="CONSERVA" ? (<>    
                                                            <option value="" >Seleccione</option>
                                                            <option value="COCIDO">COCIDO</option>
                                                            <option value="CRUDO">CRUDO</option>
                                                            <option value="DESCONGELADO">DESCONGELADO</option>
                                                            <option value="REPROCESO">REPROCESO</option>
                                                            </>) : (<>
                                                            {tipoProceso=="-" ? (<>    
                                                                <option value="" >Seleccione</option>
                                                                <option value="-" >No aplica</option>
                                                            </>) : (<>
                                                                <option value="" >Seleccione</option>
                                                                <option value="CONGELADO">CONGELADO</option>
                                                            </>)}
                                                        </>)}
                                                    </>
                                                ) : (<>
                                                    {tipoProceso=="CONSERVA" ? (<>    
                                                        <option value="" >Seleccione</option>
                                                        <option value="COCIDO">COCIDO</option>
                                                        <option value="CRUDO">CRUDO</option>
                                                        <option value="DESCONGELADO">DESCONGELADO</option>
                                                        <option value="REPROCESO">REPROCESO</option>
                                                        </>) : (<>
                                                        {tipoProceso=="" ? (<>    
                                                            <option value="" >Seleccione</option>
                                                            <option value="-">No aplica</option>
                                                        </>) : (<>
                                                            <option value="" >Seleccione</option>
                                                            <option value="CONGELADO">CONGELADO</option>
                                                        </>)}
                                                    </>)}
                                                </>)}
                                                   
                                            </select>
                                    </div>
                                    <div className="col-12 col-md-2">
                                        <label htmlFor="batch">Batch</label>
                                        <input type="text" className="form-control " name="batch" required
                                        value={formDetPallet?.batch} onChange={handleChangeDetPallet}/>
                                    </div>
                                    <div className="col-12 col-md-2">
                                        <label htmlFor="niveles">Niveles</label>
                                        <input type="number" className="form-control " name="niveles" required
                                        value={formDetPallet?.niveles} onChange={handleChangeDetPallet}/>
                                    </div>
                                    <div className="col-12 col-md-2">
                                        <label htmlFor="saldo">Saldo</label>
                                        <input type="number" className="form-control " name="saldo" required
                                        value={formDetPallet?.saldo} onChange={handleChangeDetPallet}/>
                                    </div>
                                    <div className="col-12 col-md-2">
                                        <label htmlFor="prodDia">Prod. Dia</label>
                                        <input type="number" className="form-control " name="prodDia" required
                                        value={formDetPallet?.prodDia} onChange={handleChangeDetPallet}/>
                                    </div>
                                    <div className="col-12 col-md-2">
                                        <label htmlFor="prodAcumulada">Prod. Acumulada</label>
                                        <input type="number" className="form-control " name="prodAcumulada" readOnly required
                                        value={formDetPallet?.prodAcumulada} />
                                    </div>
                                    <div className="col-12 col-md-2">
                                        <label htmlFor="prodAcumulada">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                                        <button className="btn btn-info">{formDetPallet._id ? ('EDITAR') : ('AGREGAR')}</button>
                                    </div>
                                    
                                </div>
                                
                            </form>
                        </>) : ('')}
                    </div>
                        
                    

                </Modal.Body>
                <Modal.Footer>                    
                    <Button variant="success btn-block" onClick={props.onHide} >Salir</Button>

                </Modal.Footer>
            </Modal>
            <ModalConfirm show={modalConfirm} onHide={handleCloseModalConfirm} pallet={topPallet}  idUsuario={loggedIn._id}  afterModalConfirm={afterModalConfirm} esSalida={esSalida}/>
            <ModalConfirmDeleteDet show={modalConfirmDet} onHide={handleCloseModalConfirmDet} id={idPalletToDelete} afterModalConfirmDet={afterModalConfirmDet} />
            <ModalMover show={estadoModalMover} pallet={topPallet} onHide={handleCloseModalMover} afterModalConfirm={afterOkModalMover}/>
            <ModalDividir show={modalDividir} onHide={handleCloseModalDividir} afterModalConfirm={handleCloseModalDividir} detPallets={detallePallet} idPallet={topPallet._id} codPallet={topPallet.numPallet}/>
            
        </>
    )
}

export default ModalBox
