import React, { useState, ChangeEvent, useEffect } from 'react'

import {
    LotesToProyeccion, proyeccionesVsCosechas, proyecciones,
    tipoProyeccion, usuariosByTipo
} from './proyecciones'
import { proveedor } from '../Proveedores/proveedor'
import ModalProyeccion from './ModalProyeccion/ModalProyeccion'
import ModalCosechado from './ModalProyeccion/ModalCosechado'
import ModalDeleteProy from './ModalProyeccion/ModalDeleteProy'
import ModalCreateProy from './ModalProyeccion/ModalCreateProy'
import ModalConfirmTipoCampo from './ModalProyeccion/ModalConfirmTipoCampo'
import proySistema from '../../Media/png/proyeccion.png'
import proyCampo from '../../Media/png/proyCampo.png'
import cosechado from '../../Media/png/cosechado.png'
import finCosecha from '../../Media/png/finCosecha.png'
// import {lote} from '../Lotes/lote'
import * as ProveedoresCtrl from '../Proveedores/ProveedorService'
import * as LotesController from '../Lotes/LoteService'
import * as ProyeccionesController from '../Proyecciones/ProyeccionesService'
import { toast } from 'react-toastify'
import momentz from 'moment-timezone';
import {
    MainContainer, ContainerInputs, SelectGeneral, InputGeneral,
    ButtonGeneral
} from '../../stylesGeneral/mainElements'
import {
    ContainerProyecciones, CardProy,
    TitleContainer, ContDeleteProy,
    CardAddProy, SelectLote, DivTipoCampo, WrapProySistema,
    WrapProyCampo, WrapCosechado, WrapImgProy, DivFechaRealSiembra,
    DivAsignarProyectista, WrapProyDiaCampo
} from './ProyeccionesElements'
import moment from 'moment-timezone'


type InputChange = ChangeEvent<HTMLInputElement | HTMLSelectElement>;
const noTruncarDecimales = { maximumFractionDigits: 20 };
function ProyeccionesList() {

    const initialProyToModal = {
        _id: '',
        numeroProyeccion: 0,
        fechaProyeccionSistema: '',
        fechaProyeccionCampo: '',
        FechaCosechadoCampo: '',
        fechaProyeccionDiaCampo: '',
        jabasProyeccionSistema: 0,
        jabasProyeccionCampo: 0,
        jabasCosechadoCampo: 0,
        jabasProyeccionDiaCampo:0,
        lote: ''
    }
    const initialOneLote = {
        _id: '',
        fechaSiembra: '',
        fechaRealSiembra: '',
        nombreLote: '',
        numeroContrato: '',
        localidad: {
            _id: '',
            nombre: ''
        },
        areaLote: 0,
        proyectista: {
            _id: '',
            nombre: ''
        },
        proveedor:{
            _id:'',
            nombre:''
        }
    }
    const [tipoProyecciones, setTipoProyecciones] = useState<tipoProyeccion[]>([]);
    const [proyecciones, setProyecciones] = useState<proyecciones[]>([])
    const [proyToModal, setProyToModal] = useState<proyecciones>(initialProyToModal)
    const [setBusqueda, setSetBusqueda] = useState('');
    const [setEvaluador, setSetEvaluador] = useState('')
    const [Agricultores, setAgricultores] = useState<proveedor[]>([])
    const [Lotes, setLotes] = useState<LotesToProyeccion[]>([]);
    const [oneLoteToShow, setOneLoteToShow] = useState<LotesToProyeccion>(initialOneLote)
    const [modalProyeccicon, setModalProyeccicon] = useState({ estado: false, idProyeccion: '', numCosecha: 0, fechaProyeccionCampo: '', jabasProyeccionCampo: 0, })
    const [LoteSelected, setLoteSelected] = useState('')
    const [idProyeccionSelected, setIdProyeccionSelected] = useState('')
    const [evaluadores, setEvaluadores] = useState<usuariosByTipo[]>([])
    const [idProyectistaToUpdate, setIdProyectistaToUpdate] = useState('')
    const [proyectistaAsignado, setProyectistaAsignado] = useState({_id:'', nombre:''})
    const [confirmCerrarLote, setConfirmCerrarLote] = useState(false);
    //FechaRealSiembra
    const [fechaRealSiembra, setFechaRealSiembra] = useState('') //Cambiado a inicio cosecha tentativo

    //Modal COsechado
    const [showModalCosechado, setShowModalCosechado] = useState(false)
    //Modal COnfirm Delete Proyeccion
    const [stateModalDeleteProy, setStateModalDeleteProy] = useState(false);
    //Modal Create Proyeccion
    const [stateModalCreateProy, setStateModalCreateProy] = useState(false)
    //ModalConfirm Tipo Campo
    const [stateModalConfirmTipoCampo, setStateModalConfirmTipoCampo] = useState(false)

    const changeFechaRealSiembra = (e: any) => {
        setFechaRealSiembra(e.target.value)
    }

    const closeModalDeleteProy = () => {
        setStateModalDeleteProy(false)
    }
    const closeModalCosechado = () => {
        setShowModalCosechado(false);
    }
    const closeModalCreateProy = () => {
        setStateModalCreateProy(false)
    }
    const closeModalConfirmTipoCampo = () => {
        setStateModalConfirmTipoCampo(false)
    }
    const handleShowModalConfirmTipoCampo = () => {
        if (!idProyeccionSelected) return toast.error("Seleccione tipo de campo")
        if (!idProyectistaToUpdate) return toast.error("Asigne Proyectista");
        setStateModalConfirmTipoCampo(true)
    }
    const handleShowModalCreateProy = () => {
        setProyToModal(proyecciones[0])
        setStateModalCreateProy(true);
    }
    const handleShowModalDeleteProy = (idProyeccion: string) => {
        const findProyeccion = proyecciones.find((proyeccion) => proyeccion._id === idProyeccion);
        // if (findProyeccion?.jabasCosechadoCampo || findProyeccion?.FechaCosechadoCampo) return toast.error("la Proyeccion cuenta con Cosecha, comuniquese con Sistemas")
        if (findProyeccion) setProyToModal(findProyeccion)
        setStateModalDeleteProy(true)
    }
    const handleShowModalCosechado = (idProyeccion: string) => {
        const findIndexCosechado = proyecciones.findIndex((proyeccion) => proyeccion._id === idProyeccion)
        for (let i = 0; i < findIndexCosechado; i++) {
            if (!proyecciones[i].FechaCosechadoCampo) return toast.error("Complete COSECHAS Anteriores")
        }
        if (!proyecciones[findIndexCosechado].fechaProyeccionCampo) return toast.error("Registre antes PROYECCION para esta Cosecha");
        if (proyecciones[findIndexCosechado].FechaCosechadoCampo) return toast.info("Cosecha ya registrada, Comuniquese con Sistemas si desea editar")
        const findProyeccion = proyecciones.find((proyeccion) => proyeccion._id === idProyeccion);

        if (findProyeccion) setProyToModal(findProyeccion)
        setShowModalCosechado(true)

    }

    const hideFuntionModalCosechado = () => {
        setTipoProyecciones([])
        reloadProy();
    }

    const handleCloseModal = () => setModalProyeccicon({ numCosecha: 0, fechaProyeccionCampo: '', jabasProyeccionCampo: 0, idProyeccion: '', estado: false });

    const handleShowModalProyeccionCampo = (idProyeccion: string) => {

        const findProyeccion = proyecciones.find((proyeccion) => proyeccion._id === idProyeccion)
        const findIndexProyeccionCampo = proyecciones.findIndex((proyeccion) => proyeccion._id === idProyeccion)
        for (let i = 0; i < findIndexProyeccionCampo; i++) {
            if (!proyecciones[i].fechaProyeccionCampo) return toast.error("Complete PROYECCIONES Anteriores")
        }
        if (findProyeccion?.jabasCosechadoCampo) {
            toast.info('Proyeccion Cosechada')
        } else {
            if (findProyeccion) {
                setModalProyeccicon({ numCosecha: findProyeccion.numeroProyeccion, fechaProyeccionCampo: findProyeccion.fechaProyeccionCampo, jabasProyeccionCampo: findProyeccion.jabasProyeccionCampo, idProyeccion: findProyeccion._id, estado: true });
            }
        }
    }

    const handleChange = (e: InputChange) => {
        setSetBusqueda(e.target.value)
    }
    const handleChangeEvaluador = async (e: InputChange) => {
        setSetEvaluador(e.target.value)
        setLotes([])
        setProyecciones([]);
        setAgricultores([]);
        setOneLoteToShow(initialOneLote);
        setTipoProyecciones([])
        if (e.target.value) {
            const resLotes = await LotesController.getLotesVsProyectista(e.target.value);
            setLotes(resLotes.data)
            if (resLotes.data.length! <= 0) return toast.info("Evaluador sin lotes disponibles")
        } else {
            setLotes([])
        }
    }
    const submitBusqueda = async () => {
        setProyecciones([]);
        setLotes([]);
        setAgricultores([]);
        setOneLoteToShow(initialOneLote);
        setTipoProyecciones([])

        if (setBusqueda) {
            const resAgricultores = await ProveedoresCtrl.busquedaProveedor(setBusqueda);
            setAgricultores(resAgricultores.data)
        }
    }
    const selectAgricultor = async (e: InputChange) => {
        setProyecciones([])
        setOneLoteToShow(initialOneLote)
        setTipoProyecciones([])
        const idAgricultor = e.target.value;
        const resLotes = await LotesController.getLotesVsAgricultor(idAgricultor);
        
        // console.log(resLotes.data)
        setLotes(resLotes.data)
    }
    const selectLote = async (e: InputChange) => {
        setProyecciones([])
        setOneLoteToShow(initialOneLote)
        setTipoProyecciones([])
        setFechaRealSiembra('')
        setIdProyectistaToUpdate('');
        try {
            const idLote = e.target.value;
            if (!idLote) return 0;
            setLoteSelected(idLote);
            const resProyecciones = await ProyeccionesController.getProyeccionesList(idLote)
            const lote = Lotes.find((lote) => lote._id === idLote);
            if (lote) {
                setOneLoteToShow(lote);
                // console.log(lote)
                // console.log(proyectistaAsignado);
                if(lote.proyectista){
                    setProyectistaAsignado(lote.proyectista)
                }else{
                    setProyectistaAsignado({_id:'', nombre:''})
                }
                setFechaRealSiembra(momentz.tz(lote.fechaSiembra, "America/Lima").add(120, "days").format("YYYY-MM-DD"))
                if (lote.fechaRealSiembra) setFechaRealSiembra(moment.tz(lote.fechaRealSiembra, "America/Lima").format("YYYY-MM-DD"))
            }
            if (resProyecciones.data.length > 0) return setProyecciones(resProyecciones.data)
            if (!lote?.fechaRealSiembra) {
                return toast.error("Lote sin Fecha Real de Siembra")
            }
            else {
                const resTipoProyeccionesByFSiembra = await ProyeccionesController.getTipoProyeccionByFechaSiembra(lote.fechaRealSiembra);
                setTipoProyecciones(resTipoProyeccionesByFSiembra.data)
            }
        } catch (error:any) {
            if (error.message === "Request failed with status code 401") return toast.info("Vuelva a iniciar Sesion")
            toast.error(error.message)
        }
    }
    const changeAsignarProyectista = (e:any)=>{
        const proyectista = evaluadores.find((evaluador) => evaluador._id === e.target.value)
        // console.log(proyectista)
        if(proyectista) setProyectistaAsignado(proyectista)
    }
    const reloadProy = async () => {
        const resProyecciones = await ProyeccionesController.getProyeccionesList(LoteSelected)
        setProyecciones(resProyecciones.data)
        setProyToModal(initialProyToModal)
        handleCloseModal();
        setShowModalCosechado(false);
        setStateModalDeleteProy(false);
        setStateModalCreateProy(false);
        setStateModalConfirmTipoCampo(false)
    }
    const onChangeTipoCampo = (e: any) => {
        setIdProyeccionSelected(e.target.value)
    }
    const savInicioCosechaTentativo = async () => {
        if (!fechaRealSiembra) return toast.error("Fecha invalida")
        const dataToSend = {
            idLote: oneLoteToShow._id,
            fechaRealSiembra: momentz.tz(fechaRealSiembra, "America/Lima").add(-120, "days")
        }
        const resLoteUpdated = await ProyeccionesController.setFechaRealSiembra(dataToSend);

        setOneLoteToShow(resLoteUpdated.data)
        const resTipoProyeccionesByFSiembra = await ProyeccionesController.getTipoProyeccionByFechaSiembra(resLoteUpdated.data.fechaRealSiembra);
        setTipoProyecciones(resTipoProyeccionesByFSiembra.data)
        toast.success("Fecha guardada")
    }
    const getEvaluadores = async () => {
        const resEvaluadores = await ProyeccionesController.getUsuariosByTipo("PROYECTISTA")
        setEvaluadores(resEvaluadores.data);
    }
    const changeIdProyectista = (e: any) => {
        setIdProyectistaToUpdate(e.target.value)
    }
    const saveProyectista = async () => {
        if (!idProyectistaToUpdate || !oneLoteToShow._id) return toast.info("Proyectista y/o Lote no Seleccionado")
        const resSubmit = await ProyeccionesController.updateProyectistaToLote(idProyectistaToUpdate + '::' + oneLoteToShow._id)
        // console.log(resSubmit)
        if (resSubmit.status !== 200) return toast.error("Error al asignar Proyectista, intente volver a iniciar session");
        toast.success("proyectista Asignado")
    }
    const submitAsignarProyectista = async()=>{
        if (!proyectistaAsignado._id || !oneLoteToShow._id) return toast.info("Proyectista y/o Lote no Seleccionado")
        const resSubmit = await ProyeccionesController.updateProyectistaToLote(proyectistaAsignado._id + '::' + oneLoteToShow._id)
        // console.log(resSubmit)
        if (resSubmit.status !== 200) return toast.error("Error al asignar Proyectista, intente volver a iniciar session");
        toast.success("proyectista Asignado")
    }
    const cerrarLote = async() =>{
        const validaCosechas = proyecciones.find((proyeccion)=> !proyeccion.FechaCosechadoCampo)
        // console.log(validaCosechas)
        if(validaCosechas) return toast.error("Complete cosecha de proyeccion #" + validaCosechas.numeroProyeccion)
        if(!confirmCerrarLote){
            setConfirmCerrarLote(true)
        }else{
            if(!oneLoteToShow._id)return toast.error("Lote no Seleccionado")
            await LotesController.cerrarLote(oneLoteToShow._id)            
            setConfirmCerrarLote(false)
            toast.success("Lote Cerrado")
        }
    }
    useEffect(() => {
        getEvaluadores()
    }, [])
    return (
        <div>
            <div className="col-12 border-bottom">
                <span className="h4 ">Proyeccioness</span>
            </div>
            <MainContainer >
                <ContainerInputs width="350px"  >
                    <label htmlFor="">Proyectista</label>
                    <SelectGeneral  height="50px" bg="" name="" id="" onChange={handleChangeEvaluador}>
                        {evaluadores.length > 0 ? (<option value="">Seleccione</option>) : ('')}
                        {evaluadores?.map((evaluador) => {
                            return <option key={evaluador._id} value={evaluador._id}>{evaluador.nombre}</option>
                        })}
                    </SelectGeneral>
                </ContainerInputs>
                {setEvaluador ? ('') : (
                    <>
                        <ContainerInputs width="350px"  >
                            <label htmlFor="textAgricultor">Busqueda Agricultor</label>
                            <InputGeneral width="100%" height="50px"
                                type="text"
                                name="textAgricultor"
                                id="textAgricultor"
                                placeholder="Ruc o Rason Social"
                                onChange={handleChange}
                                value={setBusqueda}
                            />
                        </ContainerInputs>
                        <ContainerInputs width="200px">
                            <ButtonGeneral width="100%" height="50px" color="" onClick={submitBusqueda}>Buscar</ButtonGeneral>
                        </ContainerInputs>
                        <ContainerInputs width="500px" >
                            <label htmlFor="selectAgricultor">Agricultores</label>
                            <SelectGeneral  height="50px" bg="" name="selectAgricultor" id="selectAgricultor" onChange={selectAgricultor} >
                                {Agricultores.length > 0 ? (<option value="">Seleccione</option>) : ('')}
                                {Agricultores?.map((agricultor) => {
                                    return <option key={agricultor._id} value={agricultor._id}>{agricultor.nombre} - {agricultor.numeroDocumento}</option>
                                })}
                            </SelectGeneral>
                        </ContainerInputs>
                    </>
                )}
               
            </MainContainer>
            <MainContainer>
            <ContainerInputs width="500px"  >
                    <label htmlFor="selectLote">lote {oneLoteToShow._id ? oneLoteToShow.localidad.nombre + ':' + oneLoteToShow.nombreLote + ' ' + oneLoteToShow.areaLote + 'Hec' : ''}                    
                    </label>
                    <SelectGeneral  height="50px"  bg="" name="selectLote" id="selectLote" onChange={selectLote}>
                        {Lotes.length > 0 ? (<option value="">Seleccione</option>) : ('')}
                        {Lotes?.map((lote) => {
                            return <option key={lote._id} value={lote._id}>{lote.proveedor?.nombre} * {lote.nombreLote} * {lote.numeroContrato}</option>
                        })}
                    </SelectGeneral>
                </ContainerInputs>
            </MainContainer>
            {setEvaluador || proyecciones.length !<= 0  ? ''
            :<MainContainer>
            <ContainerInputs width="300px"  >
                <label htmlFor="selectLote">Asignar Proyectista</label>
                <SelectGeneral  height="50px" bg='#495563' name="proyectistaAsignado" id="proyectistaAsignado" onChange={changeAsignarProyectista}>
                    {proyectistaAsignado._id  ? (<option value={proyectistaAsignado._id}>{proyectistaAsignado.nombre}</option>) : (<option value=''>Seleccione</option>)}
                    {evaluadores?.map((evaluador) => {
                        return <option key={evaluador._id} value={evaluador._id}>{evaluador.nombre}</option>
                    })}
                </SelectGeneral>
            </ContainerInputs>
            <ContainerInputs width="100px"  >
                <ButtonGeneral width="100%" height="50px" color="#495563" onClick={submitAsignarProyectista} ><i className="fas fa-check"></i></ButtonGeneral>
            </ContainerInputs>                
        </MainContainer>}
            {oneLoteToShow._id && proyecciones.length === 0 ?
                (<div>
                    <DivAsignarProyectista className="">
                        <h3>Asignar Proyectista</h3>
                        <select name="idProyectista" id="idProyectista" onChange={changeIdProyectista}>
                            {evaluadores.length > 0 ? (<option value="">Seleccione</option>) : ('')}
                            {evaluadores?.map((evaluador) => {
                                return <option key={evaluador._id} value={evaluador._id}>{evaluador.nombre}</option>
                            })}
                        </select>
                        <button onClick={saveProyectista}>Guardar Proyectista</button>
                    </DivAsignarProyectista>
                </div>) :
                ''}
            {oneLoteToShow._id && proyecciones.length === 0 ?
                (<div>
                    <DivFechaRealSiembra className="">
                        <h3>Inicio cosecha tentativo</h3>
                        <input type="date" value={fechaRealSiembra} onChange={changeFechaRealSiembra} />
                        <button onClick={savInicioCosechaTentativo}>Guardar</button>
                    </DivFechaRealSiembra>
                </div>) :
                ''}

            {oneLoteToShow.fechaRealSiembra && proyecciones.length === 0 ?
                (
                    <DivTipoCampo className="">
                        <h3>Asigne el tipo de campo</h3>
                        <select name="tipoCampo" id="tipoCampo" onChange={onChangeTipoCampo}>
                            <option value="">Seleccione</option>
                            {tipoProyecciones.map((tipoProyeccion) => {
                                return (
                                    <option value={tipoProyeccion._id}>{tipoProyeccion.estadoCampo}</option>
                                )
                            })}
                        </select>
                        <button onClick={handleShowModalConfirmTipoCampo}>Guardar</button>
                    </DivTipoCampo>
                ) :
                ''
            }
            <div className="row mx-auto col-12 col-sm-12 col-md-6">
                {tipoProyecciones.length > 0 ?
                    (
                        tipoProyecciones.map((tipoProyeccion) => {
                            return (
                                <div className="mx-auto text-center pt-3" >
                                    <div>
                                        <h5>{tipoProyeccion.estadoCampo} <span>{tipoProyeccion.tipoEstacion}</span></h5>
                                        {tipoProyeccion.proyeccionJabas.map((jabas, index) => {
                                            return (<>
                                                <span>Proyeccion#{index + 1}:</span> <span className="font-weight-bold">{jabas} Jabas</span> <br />
                                            </>)
                                        })}
                                    </div>
                                </div>
                            )
                        })
                    )
                    : (
                        ''
                    )
                }
            </div>
            <ContainerProyecciones >
                {proyecciones.map((proyeccion, index) => {
                    return (
                        <CardProy key={proyeccion._id}
                            // className={` ${proyeccion.estado ? 'proyeccionCerrada' : 'proyeccionAbierta'} text-center col-5 col-md-2 m-2`}
                            cosechado={proyeccion.jabasCosechadoCampo ? true : false}
                        >
                            <TitleContainer>
                                <h3 >{proyeccion.numeroProyeccion}</h3>
                            </TitleContainer>
                            <div id='wrapMain'>
                                <WrapProySistema>
                                    <WrapImgProy>
                                        <img src={proySistema} alt="" />
                                    </WrapImgProy>
                                    PROYECCION SISTEMA
                                    <div>
                                        <div>
                                            <h5>{proyeccion.fechaProyeccionSistema ? moment(proyeccion.fechaProyeccionSistema).format('DD-MM-YYYY') : 'DD-MM-YYYY'}</h5>
                                        </div>
                                        <div>
                                            <h3>{proyeccion.jabasProyeccionSistema}</h3> <h6>Jabas</h6>
                                        </div>
                                    </div>
                                </WrapProySistema>
                                <WrapProyCampo
                                    onClick={() => { handleShowModalProyeccionCampo(proyeccion._id) }}>
                                    <WrapImgProy>
                                        <img src={proyCampo} alt="" />
                                    </WrapImgProy>
                                    PROYECCION SEMANAL
                                    <div>
                                        <div>
                                            <h5>{proyeccion.fechaProyeccionCampo ? moment(proyeccion.fechaProyeccionCampo).format('DD-MM-YYYY') : 'DD-MM-YYYY'}</h5>
                                        </div>
                                        <div>
                                            <h3>{proyeccion.jabasProyeccionCampo ? proyeccion.jabasProyeccionCampo : 0}</h3> <h6>Jabas</h6>
                                        </div>
                                    </div>
                                </WrapProyCampo>
                                <WrapProyDiaCampo
                                    onClick={() => { handleShowModalProyeccionCampo(proyeccion._id) }}>
                                    <WrapImgProy>
                                        <img src={proyCampo} alt="" />
                                    </WrapImgProy>
                                    PROYECCION DIARIA
                                    <div>
                                        <div>
                                            <h5>{proyeccion.fechaProyeccionDiaCampo ? moment(proyeccion.fechaProyeccionDiaCampo).format('DD-MM-YYYY') : 'DD-MM-YYYY'}</h5>
                                        </div>
                                        <div>
                                            <h3>{proyeccion.jabasProyeccionDiaCampo ? proyeccion.jabasProyeccionDiaCampo : 0}</h3> <h6>Jabas</h6>
                                        </div>
                                    </div>
                                </WrapProyDiaCampo>
                                <WrapCosechado onClick={() => { handleShowModalCosechado(proyeccion._id) }}>
                                    <WrapImgProy>
                                        <img src={cosechado} alt="" />
                                    </WrapImgProy>
                                     COSECHADO
                                    <div>
                                        <div>
                                            <h5>{proyeccion.FechaCosechadoCampo ? moment(proyeccion.FechaCosechadoCampo).format('DD-MM-YYYY') : 'DD-MM-YYYY'}</h5>                                            
                                        </div>
                                        <div>
                                            <h3>{proyeccion.jabasCosechadoCampo ? proyeccion.jabasCosechadoCampo : 0}</h3> <h6>Jabas</h6>
                                        </div>
                                    </div>
                                </WrapCosechado>

                            </div>
                            {proyecciones.length === index + 1
                                ? (<ContDeleteProy>
                                    <i className="fas fa-trash-alt"
                                        onClick={() => { handleShowModalDeleteProy(proyeccion._id) }}
                                    ></i>
                                </ContDeleteProy>)
                                : ''
                            }

                        </CardProy>)
                })}
                {proyecciones.length > 0 ? (
                    <CardAddProy
                        onClick={handleShowModalCreateProy}
                    >
                        <i className="fas fa-plus"></i>
                    </CardAddProy>
                ) : ''}
                 
            </ContainerProyecciones>
            {proyecciones.length > 0 
            ?<MainContainer>
                <ContainerInputs width="50%"  >                        
                    <ButtonGeneral  width="100%" height="100px" color="#480700" 
                        onClick={cerrarLote}>
                        <img src={finCosecha} alt=""/> 
                        {confirmCerrarLote ?  'Esta Seguro?': ('CerrarLote')}
                
                    </ButtonGeneral>
                    </ContainerInputs>
                </MainContainer>
            :''}
            <ModalProyeccion show={modalProyeccicon} onHide={handleCloseModal} onHideFun={reloadProy} />
            <ModalCosechado
                show={showModalCosechado}
                idProyeccion={proyToModal._id}
                numeroCosecha={proyToModal.numeroProyeccion}
                fechaCosechada={proyToModal.FechaCosechadoCampo ? proyToModal.FechaCosechadoCampo : ''}
                jabasCosechado={proyToModal.jabasCosechadoCampo ? proyToModal.jabasCosechadoCampo : 0}
                onHide={closeModalCosechado}
                onHideFunction={hideFuntionModalCosechado}
            />
            <ModalDeleteProy
                show={stateModalDeleteProy}
                proyeccion={proyToModal}
                afterHideModalDeleteProy={hideFuntionModalCosechado}
                onHide={closeModalDeleteProy}
            />
            <ModalCreateProy
                show={stateModalCreateProy}
                numProyeccion={proyecciones.length + 1}
                // nombreEstacion={proyToModal.}
                lote={proyToModal.lote}
                onHide={closeModalCreateProy}
                afterHideModalCreateProy={hideFuntionModalCosechado}
            />
            <ModalConfirmTipoCampo
                show={stateModalConfirmTipoCampo}
                idTipoProyeccion={idProyeccionSelected}
                dataOneLoteToShow={oneLoteToShow}
                onHide={closeModalConfirmTipoCampo}
                afterHideModal={hideFuntionModalCosechado}
            />
        </div>
    )
}

export default ProyeccionesList
