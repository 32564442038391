import styled,{keyframes} from 'styled-components'

interface Width {
    width: string
}

export const CardReporte = styled.div`
    width:80%;
    padding: 10px;
    /* min-height:150px; */
    margin: 10px;
    /* border: 1px solid gray; */
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items : center;
    justify-content: space-between;
    text-shadow: 0px 1px 2px #8d8d8d, 0 0 2px #969696;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    @media screen and (max-width: 990px){
        flex-direction: column;
        justify-content: center;
        width:100%;
    }
`
export const ContainerMixProd = styled.div`
    width:80%;
    padding: 10px;
    min-height:350px;
    margin: 10px;
    /* border: 1px solid gray; */
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items : flex-start;
    justify-content: space-between;
    text-shadow: 0px 1px 2px #8d8d8d, 0 0 2px #969696;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    @media screen and (max-width: 990px){
        flex-direction: column;
        justify-content: center;
        width:100%;
    }
`
export const CardMixProd = styled.div`
    min-width: 50%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    /* box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; */
    @media screen and (max-width: 990px){
       width: 100%;
    }
`
export const WrapTitleMixProd = styled.div`
    text-align: center;
    font-family: 'Rubik', sans-serif;
    font-weight: 600;
    font-size: 25px;
`
export const WrapDataMixProd = styled.div`
    display: flex;
    flex-direction: column;
`
export const RowDataMP = styled.div`
    display: flex;  
    flex-direction: row; 
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    padding: 10px;
`
export const ContendNameMP = styled.div`
    min-width: 30%;
    text-align: end;
    font-family: 'Rubik', sans-serif;
    font-weight: 600;
    font-size: 15px;
    /* text-shadow: 1px 2px 3px #64646458; */
` 
export const ContendDataMP = styled.div`
    min-width: 70%;
    

    
`
export const WrapPBMP = styled.div`
    grid-area: Progressbar;
    width:100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    
    /* border: 1px solid gray; */
`
export const DivContainerPBMP = styled.div`
    /* position: relative; */
    display: flex;
    flex-direction: row;
    align-items: center;
     height: 30px;
    width: 100%;
    
    
    border-radius: 15px;
    background-color:#fff;
    margin:0;
    /* text-align: center; */
`
export const TextPorcentPBMP = styled.h5`
    
    color: #8b8b8b;
    z-index: 500;
    /* text-align: end; */
    left: 0;
    right: 0;
    top:0;
    bottom: 0;
    padding-left: 5px;
    /* margin: auto; */
    text-shadow: 1px 2px 3px #64646458;
    font-family: 'Rubik', sans-serif;
    font-weight:800;
    letter-spacing: 1px;
    font-size: 15px;
    /* border: 1px solid gray; */
`
export const ColorContainerPBMP = styled.div<Width>`
    
    height: 30px;  
    border-radius: 5px;
    transition: ease-in;
    animation: ${Width => breatheAnimationMP(Width.width)} 5s normal ;
    width: ${({width})=> {
        if (parseFloat(width)>=100) {
            return '100%';
        }else{
            return width;
        }
    }};
    background: #8ce68c;
    box-shadow: rgba(37, 90, 26, 0.507) 0px 2px 4px 0px, rgba(40, 90, 33, 0.32) 0px 2px 16px 0px;
`
//***FIN DE  ESTILO DE MIX PRODUCTOS */


export const HeaderCard = styled.div`
    text-align: center;
    width: 60%;
    font-family: 'Rubik', sans-serif;
    font-weight: 600;
    font-size: 30px;    
    @media screen and (max-width: 990px){
        width: 100%;
    }
`
export const BodyCard = styled.div`
    width: 40%;
    display: grid;
    grid-gap: 5px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 0.5fr;
    grid-template-areas: 
    "Output Objetivo" 
    "Progressbar Progressbar"
    ;
    @media screen and (max-width: 990px){
        width: 100%;
        
    }
`
export const RegistroOutput = styled.div`
    grid-area: Output;    
    text-align: center;
    padding: 5px;
    font-family: 'Rubik', sans-serif;
    font-weight: 600;
    font-size: 20px;
`
export const RegistroObjetivo = styled.div`
    grid-area: Objetivo;    
    text-align: center;
    padding: 5px;
    /* border: 1px solid gray; */
    font-weight: 600;
    font-size: 20px;
`
export const WrapProgressbar = styled.div`
    grid-area: Progressbar;
    width:100%;
    display: flex;
    flex-direction: row;
`
export const Progress = styled.progress`
    
    width:100%;
    height:30px;
    transition: 0.3s ease-in;
    padding: 5px;
    /* color: red; */
    appearance: none;
    ::-webkit-progress-bar{
        height: 25px;
        border-radius: 20px;
        margin: 2px;
        background-color: #eee;
        border-color: red;
        border: 1px solid red;
    }
    ::-webkit-progress-value{
        height:24px;
        border-radius: 20px;
        background-color: #e46262;        
    }
`
export const ValorProgressBar = styled.h3`
    padding: 5px;
`
export const DivContainerPB = styled.div`
    position: relative;
     height: 40px;
    width: 100%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    
    border-radius: 15px;
    background-color:#fff;
    margin:0;
    text-align: center;
`
const breatheAnimation = (y:string) => keyframes`
  0%{
      width: 0%;
      background-color:"#8b373e";
    }    
    100%{
        width: ${y};
        background-color:'#228022';
    }
`
const breatheAnimationMP = (y:string) => keyframes`
  0%{
      width: 0%;      
    }    
    100%{
        width: ${y};        
    }
`
export const ColorContainerPB = styled.div<Width>`
    position: absolute;
    height: 40px;  
    border-radius: 12px;
    transition: ease-in;
    animation: ${Width => breatheAnimation(Width.width)} 5s normal ;
    width: ${({width})=> {
        if (parseFloat(width)>=100) {
            return '100%';
        }else{
            return width;
        }
    }};
    background: ${({width}) =>{
        if (parseFloat(width)<=50) {
            return ('#8b373ed3')
        }
        else if(parseFloat(width)>50 && parseFloat(width)<=90){
            return ('#c4733ddd')
        }
        else if(parseFloat(width)>90){
            return ('#307530bc')
        }
    }};
`
export const TextPorcentPB = styled.h3`
    position: absolute;
    color: #cacaca;
    z-index: 500;
    text-align: center;
    left: 0;
    right: 0;
    top:0;
    bottom: 0;
    margin: auto;
    text-shadow: 1px 2px 3px #000000, 0 0 5px #353535;
    font-family: 'Rubik', sans-serif;
    font-weight:800;
    letter-spacing: 3px;
`
export const ContainerTitle = styled.div`
    
    
`
export const TextUMTitle = styled.p`
    font-size: 14px;
    font-weight: 100;
    color: gray;
`
export const DivisorCat = styled.div`
    background: #464646;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: 80%;
    border-radius: 5px;
    margin: 10px;
    padding: 5px;
    color: white;
    font-family: 'Rubik', sans-serif;
    font-size: 25px;
    font-weight: 800;
    @media screen and (max-width: 990px){
        width:100%;
        margin: 8px;
    padding: 5px;        
    font-size: 20px;
    font-weight: 800;
    }
`
export const CheckResumen = styled.input`
     margin-left: 10px;
     margin-left: 25px;
    height: 25px;
    width: 25px;
    
`
export const ContainerCheckBox = styled.div`
    margin:0;
    padding:0;    
    display: flex;
`
export const LabelCheckBox = styled.label`
    font-size: 18px;
    padding-left: 1px;  
`