import React,{MouseEventHandler} from 'react'
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

import * as almacenService from '../AlmacenesService'
interface Props {
    show: boolean,
    onHide: MouseEventHandler<HTMLElement>,
    idDetalleToDelete: string,
    afterConfirm: ()=> void
}

function DetalleConfirmacion(props: Props | any) {

    const handleDeleteDetalle = async() =>{
       try {
        console.log("Elimiando", props.idDetalleToDelete)
        const resDeleteDet = await almacenService.deleteDetalleIngreso(props.idDetalleToDelete)
        toast.success("Eliminado")
        props.afterConfirm();
       } catch (error:any) {
           console.log(error)
           toast.error(error.message)
       }
    } 
    return (
        <>
           <Modal
                show={props.show}
                onHide={props.onHide}
                size='sm'
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Eliminar Detalle
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                 
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-danger" onClick={handleDeleteDetalle} >Eliminar</Button>
                    <Button variant="danger" onClick={props.onHide} >Cancelar</Button>
                </Modal.Footer>
            </Modal> 
        </>
    )
}

export default DetalleConfirmacion
