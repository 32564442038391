import React, { MouseEventHandler } from 'react'
import { Button, Modal } from 'react-bootstrap';
import alertLogo from '../../../images/alertLogo.png'
import './modalCogida.css'
interface Props {
    show: {
        estado: boolean
        idLote: string
    };
    onHide: MouseEventHandler<HTMLElement>
}
function ModalCogida({ show, onHide }: Props|any ) {
    return (
        <div>
            <Modal centered show={show.estado} onHide={onHide}>
                <div className="col-12 text-center m-2">
                <h3>Eliminar Cosecha</h3>
                </div>
                <div className="text-center">
                    <img src={alertLogo} className="alertLogo" alt=""/>
                </div>
                <div className="col-6 mx-auto d-flex p-4">
                    
                    <button className="p-2 btn btn-outline-ligth"><strong className="text-danger">Eliminar</strong></button>
                    <button className="ml-auto p-2 btn btn-outline-dark"><strong>Cancelar</strong></button>
                </div>
               
            </Modal>
        </div>
    )
}

export default ModalCogida
