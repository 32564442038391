import axios from 'axios'
import {tipoMovimiento, ingreso, detIngreso, detSalida, salida, detIngresoToDetSalida, dataToOS} from './TypesAlmacenes'
import {listIngreso} from './TypesAlmacenes'
import apiURL from '../../context/apiURL'
const API = apiURL.API;

///########### TIPO MOVIMIENTOS
export const getAllMovimientos = async () => {
    return await axios.get<tipoMovimiento[]>(`${API}/getAllMovimientos`)    
 }
 export const getTipoMovimientoByDocumento = async(id: string) =>{
    return await axios.get(`${API}/getTipoMovimientoByDocumento/${id}`)
}

 /// #### PRODUCTOS
 export const getProductoByText = async (id: string) => {
    return await axios.get(`${API}/getProductoByText/${id}`)    
 }

 /// ### INGRESOS
export const createIngreso = async(data:  ingreso) =>{
    return await axios.post(`${API}/createIngreso`, data);
}
export const getOneIngreso = async(id: string | undefined) =>{
    return await axios.get(`${API}/getOneIngreso/${id}`)
}
export const editIngreso = async(data: ingreso) =>{
    return await axios.post(`${API}/editIngreso`, data)
}
export const getIngresosToDetSalida = async(id:number) =>{
    return await axios.get(`${API}/getIngresosToDetSalida/${id}`)
}

// ### DETALLE INGRESOS
export const createDetIngreso = async(data: detIngreso) =>{
    return await axios.post(`${API}/createDetIngreso`, data)
}

export const getDetIngresoByIdIngreso = async(id: string | undefined) =>{
    return await axios.get<detIngreso[]>(`${API}/getDetIngresoByIdIngreso/${id}`)
}
export const getIngresosByAlmacen = async(id: string | undefined) =>{
    return await axios.get<ingreso[]>(`${API}/getIngresosByAlmacen/${id}`)
}

export const editDetIngreso = async(data: detIngreso)=>{
    return await axios.post(`${API}/editDetIngreso`, data)
}

export const deleteDetalleIngreso = async(id: string) =>{
    return await axios.get(`${API}/deleteDetalleIngreso/${id}`);
}

export const getDetSalidasDisponiblesToSalida = async(data: detIngresoToDetSalida) =>{
    return await axios.post(`${API}/getDetSalidasDisponiblesToSalida`, data);
}

/// ### SALIDAS

export const createSalida = async(data: salida) =>{
    return await axios.post(`${API}/createSalida`, data);
}
export const getSalidasByAlmacen = async(id: string) =>{
    return await axios.get(`${API}/getSalidasByAlmacen/${id}`);
}
export const getOrdenSiembrasToIngreso = async(data: dataToOS) =>{
    return await axios.post(`${API}/getOrdenSiembrasToIngreso`, data)
}
///#### DETALLE SALIDAS
export const createDetSalida = async(data: detSalida) => {
    return await axios.post(`${API}/createDetSalida`, data);
}
export const getDetSalidasByIdSalida = async(id: string ) => {
    return await axios.get(`${API}/getDetSalidasByIdSalida/${id}`);
}
/// ###U MEDIDA
export const getUMedidaByProducto = async(id: any) =>{
    return await axios.get(`${API}/getUMedidaByProducto/${id}`)
}