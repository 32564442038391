import axios from 'axios'
import {lote, Blote, proximaCosecha} from './lote'
import { busquedaLote, loteDep, pdfLote } from './loteDep';

import apiURL from '../../context/apiURL'
const API = apiURL.API;

export const importLotes = async (data:any) => {
   return await axios.post(`${API}/importLotes`, data)    
}
export const getLotes = async () => {
   return await axios.get<lote[]>(`${API}/lotes`)    
}

export const createLote = async (lote: lote) => {
   return await axios.post(`${API}/lotes`, lote)    
}

export const getLote = async (id: string) => {
   return await axios.get<lote>(`${API}/lote/${id}`)    
}

export const updateLotes = async (id: string, lote: lote) => {
   return await axios.put<lote>(`${API}/lotes/${id}`, lote)    
}

export const deleteLote = async (id: string) => {
   return await axios.delete<lote>(`${API}/lotes/${id}`)    
}
// FUNCIONES ADICIONALES
export const getLoteVSLocalidad = async (id: string) => {
   return await axios.get<lote[]>(`${API}/lotes/${id}`)    
}

export const getLotesVSDep = async (id: string, id2: string, id3: string, id4: string) => {

   return await axios.get<loteDep[]>(`${API}/lotesdep/${id}-${id2}-${id3}-${id4}`)    
}

export const pesoProyeccion = async (lotes: any, fechas:any, areas:any) => {
   return await axios.post(`${API}/pesoproyeccion`, {lotes, fechas, areas});
}

//CREACION DE PROYECCIONES AL MOMENTO DE CREAR UN LOTE
export const createProyeccion = async (lote: lote)=>{
   return await axios.post(`${API}/createProyeccion`, lote)
}
// BUSQUEDAS
export const BusquedaLotes = async (Blote: Blote) =>{
   return await axios.post(`${API}/busquedalote`, Blote)
}

// EDITAR PROXIMA COSECHA
export const editProximaCosecha = async (id: string, proximaCosecha: any, numeroCosecha: number)=>{
   return await axios.post(`${API}/editproximacosecha`, {id, proximaCosecha, numeroCosecha})
}
//PRINTPDF
export const CretePdfLote = async(id: string)=>{
   return await axios.get(`${API}/createpdflote/${id}`)
}

export const SendPdfLote = async()=>{
   return await axios.get(`${API}/sendpdflote`, {responseType: 'blob'})
}

///VALIDA SI LOTE TIENE COSECHAS
export const validaSiExistenCosechas = async(id: string) => {
   return await axios.get(`${API}/validaSiExistenCosechas/${id}`)
}
///GET LOTE POR CONTRATO
export const getLoteVsContrato = async(id: string) =>{
   return await axios.get(`${API}/getLoteVsContrato/${id}`)
}

export const getLotesVsAgricultor = async(id: string)=>{
   return await axios.get(`${API}/getLotesVsAgricultor/${id}`)
}
export const getLotesVsEvaluador = async(id: string)=>{
   return await axios.get(`${API}/getLotesVsEvaluador/${id}`)
}

export const getLotesVsProyectista = async(id: string)=>{
   return await axios.get(`${API}/getLotesVsProyectista/${id}`)
}

export const cerrarLote = async(id: string)=>{
   return await axios.get(`${API}/cerrarLote/${id}`)
}