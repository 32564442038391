import React, { useState, useRef,useEffect } from 'react'

//import jsQR from 'jsqr';



function Pruebas() {
    const videoRef = useRef<HTMLVideoElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [esCamaraDetenida, setCamaraDetenida] = useState(false)

    const iniciaCamara = () => {
        const video = videoRef.current;
        const canvas = canvasRef.current;
    
        // Verificar que tanto el video como el canvas existan antes de continuar
        if (!video || !canvas) {
            console.log('Elementos de referencia no inicializados.');
            return;
        }
        const context = canvas.getContext('2d');

        if (!context) {
            console.log('2D context not supported. Your browser may not support the canvas API.');
            return;
        }
        let isCameraStopped = false; 
        navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } })
        .then((stream) => {
                video.srcObject = stream;
                video.play();
        
                const tick = () => {
                        if (video.readyState === video.HAVE_ENOUGH_DATA) {
                            canvas.width = video.videoWidth;
                            canvas.height = video.videoHeight;
                            context.drawImage(video, 0, 0, canvas.width, canvas.height);
                
                            const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
                            //const code = jsQR(imageData.data, imageData.width, imageData.height);
                            /*    
                            if (code) {
                                
                                if(code.data.includes('|')){
                                    
                                    let tmp = code.data.split('|')
                                    console.log('QR:'+ tmp[0]);
                                }else{
                                    console.log("Código NO Valido")
                                }
                              
                            if (!isCameraStopped) {
                                stopCamera(stream);
                                isCameraStopped = true;
                                setCamaraDetenida(true);
                            }
                            }*/  
                        }
                            requestAnimationFrame(tick);
                };
                tick();
        })
        .catch((error) => {
                 console.log('No es posible acceder a la cámara. Verifique los permisos.');
        });

        const stopCamera = (stream: MediaStream) => {
                const tracks = stream.getTracks();
                tracks.forEach((track) => track.stop());
        };

    return () => {
      if (video.srcObject) {
        const tracks = (video.srcObject as MediaStream).getTracks(); // <-- Cast to MediaStream
        tracks.forEach((track) => track.stop());
      }
    };
    }
    useEffect(() => {
       
         

         /*
        |
        | Fin código para captura de QR
        |
         */

        //getTapasEnvases();
        //setStateBtnSaveTop({ top: false, detalle: false })        
        //reinicioFormDetalle();
        //putPallets();
        //Seleccionar las caracteristicas del formato seleccionado, si es CONGELADO, CONSERVA O NINGUNO
        
       }); 
    

    return (         
        <div>Hola Mundo, activar camara    <button onClick={iniciaCamara}>Iniciar Camara</button>          
            <div  className="row justify-content-center">
                <video ref={videoRef} style={{ width: '100%', maxWidth: '400px' }} />
                <canvas ref={canvasRef} style={{ display: 'none' }} />
            </div>   
        </div>  
            
    )
}

export default Pruebas
