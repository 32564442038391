import React, { useState, useEffect, useContext } from 'react'
import {
    MainContainerSet,
    ContainerInputs,
    SelectGeneral,
    InputGeneral,
    ButtonGeneral,
    H3Title,
    H5Title,
    DivDivider,
    DivBordered,
    FormMainContainerSet,
    BtnSpanGeneral,
    TextAreaSet
} from '../../../stylesGeneral/mainElements'
import { formatos, Pcliente, envases, tapas, miniPallets } from '../AlmacenMP/almacen'
import * as almacenMPServices from '../AlmacenMP/AlmacenMPServices'
import Swal from 'sweetalert2'
import authContext from '../../../context/AuthContext';

function Administrar() {
    const { loggedIn } = useContext(authContext);
    const initialFormato = {
        _id:'',
        codigo: '',
        descripcion: '',
        umedida:'',
        calidad:'',
        conteo:'',
        cultivo:'',
        formatop:'',
        tipop: '',
        proceso: ''
    }
    const initialCliente = {
        _id: '',
        nombre: '',
        estado: 1
    }
    const initialEnvase = {
        _id: '',
        codigo: '',
        descripcion: '',
        umedida:''
    }
    const initialTapa = {
        _id:'',
        codigo: '',
        descripcion: '',
        umedida: ''
    }


    const initialPallet = {
        _id:'',
        numPallet : '',
        estado: '',
        planta: '',
        clave:'',
        userid: loggedIn._id
    }

    const initialOptEstado = [{valor:0, nombre:"ANULADO"}, {valor:1, nombre:"ACTIVO"}]
    const [optionsEstadoCli, setOptionsEstadoCli] = useState<any[]>(initialOptEstado)
    const [textBusquedaFormato, setTextBusquedaFormato] = useState('');
    const [textBusquedaCliente, setTextBusquedaCliente] = useState('')
    const [textBusquedaEnvase, setTextBusquedaEnvase] = useState('')
    const [textBusquedaTapa, setTextBusquedaTapa] = useState('')
    const [textBusquedaPallet, setTextBusquedaPallet] = useState('')

    const [formatos, setFormatos] = useState<formatos[]>([])
    const [clientes, setClientes] = useState<Pcliente[]>([])
    const [envases, setEnvases] = useState<envases[]>([])
    const [tapas, setTapas] = useState<tapas[]>([])
    const [pallets, setPallets] = useState<miniPallets[]>([])
    
    const [formFormato, setformFormato] = useState<formatos>(initialFormato)
    const [formCliente, setFormCliente] = useState<Pcliente>(initialCliente)
    const [formEnvase, setFormEnvase] = useState<envases>(initialEnvase)
    const [formTapa, setFormTapa] = useState<tapas>(initialTapa)
    const [formPallet, setFormPallet] = useState<miniPallets>(initialPallet)

    // FORMATOS
    const handleTextBusquedaFormato = async (e:any) =>{
        setTextBusquedaFormato(e.target.value)
    }
    const buscarFormatos = async () =>{
        const resFormatos =  await almacenMPServices.busquedaFormato(textBusquedaFormato)
        setFormatos(resFormatos.data)        
    }
    const  handleEditFormato = async (id: string) =>{
        const formato = formatos.find((formato) => formato._id === id)
        if(formato) setformFormato(formato)
    }
    const handleChangeFormato = async(e:any) =>{
        setformFormato({...formFormato, [e.target.name]: e.target.value })
    }
    const handleCancelarformato = () =>{
        setformFormato(initialFormato);
    }
    const handleCrearFormato = async(e:any)=>{
        e.preventDefault();
        if(!formFormato.codigo || !formFormato.descripcion || !formFormato.umedida || !formFormato.conteo || !formFormato.calidad || !formFormato.proceso || !formFormato.tipop || !formFormato.formatop ) {
            return Swal.fire({
                position: 'center',
                icon: 'info',
                title: "Complete todos los valores para continuar.",
                showConfirmButton: true,
                // timer: 2000
              })
        }
        const resCrearFormato = await almacenMPServices.crearFormato(formFormato);
        if(resCrearFormato.data.ErrorMessage) {
            return Swal.fire({
                position: 'center',
                icon: 'error',
                title: resCrearFormato.data.ErrorMessage,
                showConfirmButton: true,
                // timer: 2000
              })
        }else{
             Swal.fire({
                position: 'center',
                icon: 'success',
                title: "Tarea Completada",
                // showConfirmButton: true,
                timer: 2000
              })
        }
        setformFormato(initialFormato);
    }
    // END FORMATOS
    // CLIENTES
    const handleTextBusquedaCliente = async (e:any) =>{
        setTextBusquedaCliente(e.target.value)
    }
    const buscarClientes = async () =>{
        const resClientes =  await almacenMPServices.busquedaPcliente(textBusquedaCliente)
        setClientes(resClientes.data)        
    }
    const  handleEditCliente = async (id: string) =>{
        setOptionsEstadoCli([])
        const cliente = clientes.find((cliente) => cliente._id === id)
        if(cliente) setFormCliente(cliente)
        setInterval(()=>{ setOptionsEstadoCli(initialOptEstado) }, 500)
    }
    const handleChangeCliente = async(e:any) =>{
        setOptionsEstadoCli([])
        setFormCliente({...formCliente, [e.target.name]: e.target.value })
        setInterval(()=>{ setOptionsEstadoCli(initialOptEstado) }, 500)
    }
    const handleCancelarCliente = () =>{
        setFormCliente(initialCliente);
    }
    const handleCrearCliente = async(e:any)=>{
        e.preventDefault();
        console.log(formCliente)
        if(!formCliente.nombre) {
            return Swal.fire({
                position: 'center',
                icon: 'error',
                title: "Complete campos",
                showConfirmButton: true,    
                // timer: 2000
              })
        }
        const resCrearFormato = await almacenMPServices.createPcliente(formCliente);
        if(resCrearFormato.data.ErrorMessage) {
            return Swal.fire({
                position: 'center',
                icon: 'error',
                title: resCrearFormato.data.ErrorMessage,
                showConfirmButton: true,
                // timer: 2000
              })
        }else{
             Swal.fire({
                position: 'center',
                icon: 'success',
                title: "Tarea Completada",
                // showConfirmButton: true,
                timer: 2000
              })
        }
        setFormCliente(initialCliente);
    }
    // END CLIENTES
    // TAPAS
    const handleTextBusquedaPallet = async (e:any) =>{
        setTextBusquedaPallet(e.target.value)
    }


    const handleTextBusquedaTapa = async (e:any) =>{
        setTextBusquedaTapa(e.target.value)
    }

    const buscarPallet = async () =>{
        const resPallets =  await almacenMPServices.busquedaPallets(textBusquedaPallet)
        setPallets(resPallets.data)        
    }
    

    const buscarTapas = async () =>{
        const resTapas =  await almacenMPServices.busquedaTapas(textBusquedaTapa)
        setTapas(resTapas.data)        
    }
    const  handleEditTapa = async (id: string) =>{
        setOptionsEstadoCli([])
        const findTapas = tapas.find((tapa) => tapa._id === id)
        if(findTapas) setFormTapa(findTapas)
        setInterval(()=>{ setOptionsEstadoCli(initialOptEstado) }, 500)
    }

    const  handleEditPallet = async (id: string) =>{
        setOptionsEstadoCli([])
        const findPallet = pallets.find((pallet) => pallet._id === id)
        if(findPallet) setFormPallet(findPallet)
        setInterval(()=>{ setOptionsEstadoCli(initialOptEstado) }, 500)
    }
    
    const handleChangeTapa = async(e:any) =>{
        setOptionsEstadoCli([])
        setFormTapa({...formTapa, [e.target.name]: e.target.value })
        setInterval(()=>{ setOptionsEstadoCli(initialOptEstado) }, 500)
    }

    const handleChangePallet = async(e:any) =>{
        setOptionsEstadoCli([])
        setFormPallet({...formPallet, [e.target.name]: e.target.value })
        setInterval(()=>{ setOptionsEstadoCli(initialOptEstado) }, 500)
    }

    const handleCancelarTapa = () =>{
        setFormTapa(initialTapa);
    }

    const handleCancelarPallet = () =>{
        setFormPallet(initialPallet);
    }

    const handleEditarPallet = async(e:any)=>{
        e.preventDefault();
        console.log(formPallet)
        if(!formPallet.numPallet || !formPallet.clave ) {
            return Swal.fire({
                position: 'center',
                icon: 'info',
                title: "Complete los campos",
                showConfirmButton: true,    
                // timer: 2000
            })
        }

        if(formPallet.clave !="123" ) {
            return Swal.fire({
                position: 'center',
                icon: 'error',
                title: "Clave Incorrecta",
                showConfirmButton: true,    
                // timer: 2000
            })
        }

        const resPallet = await almacenMPServices.editarNumPallet(formPallet);
        if(resPallet.data.ErrorMessage) {
            return Swal.fire({
                position: 'center',
                icon: 'error',
                title: resPallet.data.ErrorMessage,
                showConfirmButton: true,
                // timer: 2000
              })
        }else{
             Swal.fire({
                position: 'center',
                icon: 'success',
                title: "Tarea Completada",
                // showConfirmButton: true,
                timer: 2000
              })
        }
        setFormPallet(initialPallet);
    }

    const handleCrearTapa = async(e:any)=>{
        e.preventDefault();
        console.log(formTapa)
        if(!formTapa.codigo || !formTapa.descripcion ||!formTapa.umedida ) {
            return Swal.fire({
                position: 'center',
                icon: 'error',
                title: "Complete campos",
                showConfirmButton: true,    
                // timer: 2000
            })
        }
        const resCrearTapa = await almacenMPServices.createTapas(formTapa);
        if(resCrearTapa.data.ErrorMessage) {
            return Swal.fire({
                position: 'center',
                icon: 'error',
                title: resCrearTapa.data.ErrorMessage,
                showConfirmButton: true,
                // timer: 2000
              })
        }else{
             Swal.fire({
                position: 'center',
                icon: 'success',
                title: "Tarea Completada",
                // showConfirmButton: true,
                timer: 2000
              })
        }
        setFormTapa(initialTapa);
    }
    // END TAPAS
    // ENVASES
    const handleTextBusquedaEnvase = async (e:any) =>{
        setTextBusquedaEnvase(e.target.value)
    }
    const buscarEnvases = async () =>{
        const resEnvases =  await almacenMPServices.busquedaEnvase(textBusquedaEnvase)
        setEnvases(resEnvases.data)        
    }
    const  handleEditEnvase = async (id: string) =>{
        setOptionsEstadoCli([])
        const findEnvases = envases.find((envase) => envase._id === id)
        if(findEnvases) setFormEnvase(findEnvases)
        setInterval(()=>{ setOptionsEstadoCli(initialOptEstado) }, 500)
    }
    const handleChangeEnvase = async(e:any) =>{
        setOptionsEstadoCli([])
        setFormEnvase({...formEnvase, [e.target.name]: e.target.value })
        setInterval(()=>{ setOptionsEstadoCli(initialOptEstado) }, 500)
    }
    const handleCancelarEnvase = () =>{
        setFormEnvase(initialEnvase);
    }
    const handleCrearEnvase = async(e:any)=>{
        e.preventDefault();
        console.log(formEnvase)
        if(!formEnvase.codigo || !formEnvase.descripcion ||!formEnvase.umedida ) {
            return Swal.fire({
                position: 'center',
                icon: 'error',
                title: "Complete campos",
                showConfirmButton: true,    
                // timer: 2000
            })
        }
        const resCrearEnvase = await almacenMPServices.crearEnvase(formEnvase);
        if(resCrearEnvase.data.ErrorMessage) {
            return Swal.fire({
                position: 'center',
                icon: 'error',
                title: resCrearEnvase.data.ErrorMessage,
                showConfirmButton: true,
                // timer: 2000
              })
        }else{
             Swal.fire({
                position: 'center',
                icon: 'success',
                title: "Tarea Completada",
                // showConfirmButton: true,
                timer: 2000
              })
        }
        setFormEnvase(initialEnvase);
    }
    // END ENVASES
    return (
        <>
            <MainContainerSet display="" width="" alignItems="" flexWrap="" justifyContent="">
                <H3Title>
                    Configuracion APT
            </H3Title>
                <DivDivider></DivDivider>
            </MainContainerSet>
            <MainContainerSet display="" width="" alignItems="center" flexWrap="" justifyContent="center">
            <MainContainerSet display="" width="" alignItems="" flexWrap="" justifyContent="">
                    <H5Title color="" bg="" fontSize="" width="">
                        Clientes
                    </H5Title>
                </MainContainerSet>
                <DivBordered>
                    <MainContainerSet display="" width="100%" alignItems="flex-start" flexWrap="" justifyContent="center">

                        <FormMainContainerSet
                            // onSubmit={submitCapacitador} 
                            display="" width="40%" alignItems="start" flexWrap="" justifyContent="start">
                            <ContainerInputs width="100%">
                                <label htmlFor="nombres">Nombre</label>
                                <InputGeneral width="100%" height="50px" name="nombre"
                                    onChange={handleChangeCliente}  
                                    value={formCliente.nombre}                        
                                    required
                                />
                            </ContainerInputs>
                            <ContainerInputs width="100%">
                                <label htmlFor="apellidos">Estado</label>
                              
                                <SelectGeneral height="50px" bg="" name="estado" onChange={handleChangeCliente} required>
                                    {formCliente.estado
                                    ?<option value="1" selected>ACTIVO</option>  
                                    :<option value="0"selected>ANULADO</option>} 

                                    {optionsEstadoCli.map((optcap)=>{
                                        return <option value={optcap.valor}>{optcap.nombre}</option>
                                    })}
                                </SelectGeneral>
                            </ContainerInputs>
                           
                            <ContainerInputs width="50%">
                                <ButtonGeneral width="100%" height="50px" color=""
                                    onClick={handleCrearCliente}
                                >
                                    {formCliente._id ? 'Editar': 'Crear'}                                     
                                </ButtonGeneral>
                            </ContainerInputs>
                            <ContainerInputs width="50%">
                                <BtnSpanGeneral width="100%" height="50px" color="#7B7D7D"
                                onClick={handleCancelarCliente}
                                >
                                    Cancelar
                       </BtnSpanGeneral>
                            </ContainerInputs>
                        </FormMainContainerSet>

                        <MainContainerSet display="" width="60%" alignItems="center" flexWrap="" justifyContent="center">
                            
                            <ContainerInputs width="50%">
                                {/* <label htmlFor="nombres">Codigo</label> */}
                                <InputGeneral width="80%" height="50px" name="nombres" placeholder="Busqueda"
                                    onChange={handleTextBusquedaCliente}  
                                    value={textBusquedaCliente}                                                            
                                />
                            </ContainerInputs>
                            <ContainerInputs width="auto">
                                <ButtonGeneral width="auto" height="50px" color=""
                                    onClick={buscarClientes}
                                >                                    
                                    <i className="fa-solid fa-magnifying-glass"></i>
                                </ButtonGeneral>
                            </ContainerInputs>

                            <div className="table-responsive m-4 table-responsive-sm">
                                <table className="tableFixedNone mx-auto">
                                    <thead>
                                        <tr className=''>                                            
                                            <th >NOMBRE</th>
                                            <th >ESTADO</th>                                            
                                            <th></th>                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {clientes.map((cliente)=>{
                                            return <tr>
                                                
                                                <td>{cliente.nombre}</td>
                                                <td>{cliente.estado ? 'ACTIVO' : 'ANULADO'}</td>
                                              
                                                
                                                <td>
                                                <ContainerInputs width="80px" 
                                                onClick={()=> handleEditCliente(cliente._id)}
                                                >
                                                    <ButtonGeneral width="100%" height="50px" color="#424949"><i className="fas fa-pen"></i></ButtonGeneral>
                                                </ContainerInputs>                           
                                                </td>
                                            </tr>
                                        })} 
                                    </tbody>
                                </table>
                            </div>
                        </MainContainerSet>
                    </MainContainerSet>
                </DivBordered>
                <MainContainerSet display="" width="" alignItems="" flexWrap="" justifyContent="">
                    <H5Title color="" bg="" fontSize="" width="">
                        Formatos
                    </H5Title>
                </MainContainerSet>
                <DivBordered>
                    <MainContainerSet display="" width="100%" alignItems="flex-start" flexWrap="" justifyContent="center">

                        <FormMainContainerSet
                            // onSubmit={submitCapacitador} 
                            display="" width="40%" alignItems="start" flexWrap="" justifyContent="start">
                            <ContainerInputs width="100%">
                                <label htmlFor="nombres">Codigo</label>
                                <InputGeneral width="100%" height="50px" name="codigo"
                                    onChange={handleChangeFormato}  
                                    value={formFormato.codigo}                        
                                    required
                                />
                            </ContainerInputs>
                            <ContainerInputs width="100%">
                                <label htmlFor="apellidos">Descripcion</label>
                                <InputGeneral width="100%" height="50px" name="descripcion"
                                    onChange={handleChangeFormato}  
                                    value={formFormato.descripcion} 
                                    required />
                            </ContainerInputs>
                            <ContainerInputs width="100%">
                                <label htmlFor="descripcion">Unidad Medida</label>
                                <InputGeneral width="100%" height="50px" name="umedida"
                                onChange={handleChangeFormato}  
                                value={formFormato.umedida} 
                                required
                                />
                            </ContainerInputs>
                            <ContainerInputs width="100%">
                                <label htmlFor="calidad">Calidad</label>
                                <InputGeneral width="100%" height="50px" name="calidad"
                                onChange={handleChangeFormato}  
                                value={formFormato.calidad} 
                                required
                                />
                            </ContainerInputs>
                            <ContainerInputs width="100%">
                                <label htmlFor="conteo">Conteo</label>
                                <InputGeneral width="100%" height="50px" name="conteo"
                                onChange={handleChangeFormato}  
                                value={formFormato.conteo} 
                                required
                                />
                            </ContainerInputs>

                            <ContainerInputs width="100%">
                                <label htmlFor="cultivo">Cultivo</label>
                                <InputGeneral width="100%" height="50px" name="cultivo"
                                onChange={handleChangeFormato}  
                                value={formFormato.cultivo} 
                                required
                                />
                            </ContainerInputs>

                            <ContainerInputs width="100%">
                                <label htmlFor="formatop">Formato Producto</label>
                                <InputGeneral width="100%" height="50px" name="formatop"
                                onChange={handleChangeFormato}  
                                value={formFormato.formatop} 
                                required
                                />
                            </ContainerInputs>

                            <ContainerInputs width="100%">
                                <label htmlFor="tipop">Tipo</label>
                                <InputGeneral width="100%" height="50px" name="tipop"
                                onChange={handleChangeFormato}  
                                value={formFormato.tipop} 
                                required
                                />
                            </ContainerInputs>

                            <ContainerInputs width="100%">
                                <label htmlFor="proceso">Proceso</label>
                                <InputGeneral width="100%" height="50px" name="proceso"
                                onChange={handleChangeFormato}  
                                value={formFormato.proceso} 
                                required
                                />
                            </ContainerInputs>




                            <ContainerInputs width="50%">
                                <ButtonGeneral width="100%" height="50px" color=""
                                    onClick={handleCrearFormato}
                                >
                                    {formFormato._id ? 'Editar': 'Crear'}                                     
                                </ButtonGeneral>
                            </ContainerInputs>

                            <ContainerInputs width="50%">
                                <BtnSpanGeneral width="100%" height="50px" color="#7B7D7D"
                                onClick={handleCancelarformato}
                                >Cancelar
                                </BtnSpanGeneral>
                            </ContainerInputs>
                        </FormMainContainerSet>

                        <MainContainerSet display="" width="60%" alignItems="center" flexWrap="" justifyContent="center">
                            
                            <ContainerInputs width="50%">
                                {/* <label htmlFor="nombres">Codigo</label> */}
                                <InputGeneral width="80%" height="50px" name="nombres" placeholder="Busqueda"
                                    onChange={handleTextBusquedaFormato}  
                                    value={textBusquedaFormato}                                                            
                                />
                            </ContainerInputs>
                            <ContainerInputs width="auto">
                                <ButtonGeneral width="auto" height="50px" color=""
                                    onClick={buscarFormatos}
                                >                                    
                                    <i className="fa-solid fa-magnifying-glass"></i>
                                </ButtonGeneral>
                            </ContainerInputs>

                            <div className="table-responsive m-4 table-responsive-sm">
                                <table className="tableFixedNone mx-auto">
                                    <thead>
                                        <tr className=''>
                                            {/* <th >ID</th>              */}
                                            <th >CODIGO</th>
                                            <th >DESCRIPCION</th>
                                            <th>U.M.</th>
                                            <th></th>                                            
                                        </tr>
                                    </thead>
                                    <tbody >
                                        {formatos.map((formato)=>{
                                            return <tr>
                                                
                                                <td >{formato.codigo}</td>
                                                <td>{formato.descripcion}</td>
                                                <td>{formato.umedida}</td>
                                                
                                                <td>
                                                <ContainerInputs width="80px" 
                                                onClick={()=> handleEditFormato(formato._id)}
                                                >
                                                    <ButtonGeneral width="75%" height="40px" color="#297943"><i className="fas fa-pen"></i></ButtonGeneral>
                                                </ContainerInputs>                           
                                                </td>
                                            </tr>
                                        })} 
                                    </tbody>
                                </table>
                            </div>
                        </MainContainerSet>
                    </MainContainerSet>
                </DivBordered>

                <MainContainerSet display="" width="" alignItems="" flexWrap="" justifyContent="">
                    <H5Title color="" bg="" fontSize="" width="">
                        Pallets
                </H5Title>
                </MainContainerSet>
                <DivBordered>
                    <MainContainerSet
                        // onSubmit={submitTema} 
                        display="" width="100%" alignItems="flex-start" flexWrap="" justifyContent="center">
                        <FormMainContainerSet
                            // onSubmit={submitCapacitador} 
                            display="" width="40%" alignItems="start" flexWrap="" justifyContent="start">
                            <ContainerInputs width="100%">
                                <label htmlFor="numPallet">Clave</label>
                                <InputGeneral width="100%" height="50px" name="clave"
                                    onChange={handleChangePallet}  
                                    value={formPallet.clave}                        
                                    required type="password"
                                />
                            </ContainerInputs>
                            <ContainerInputs width="100%">
                                <label htmlFor="numPallet">Nro Pallet</label>
                                <InputGeneral width="100%" height="50px" name="numPallet"
                                    onChange={handleChangePallet}  
                                    value={formPallet.numPallet}                        
                                    required
                                />
                            </ContainerInputs>
                            <ContainerInputs width="100%">
                                <label htmlFor="planta">Planta</label>
                                <InputGeneral width="100%" height="50px" name="planta"
                                    onChange={handleChangePallet}  
                                    value={formPallet.planta} 
                                     readOnly/>
                            </ContainerInputs>
                            <ContainerInputs width="100%">
                                <label htmlFor="estado">Estado</label>
                                <InputGeneral width="100%" height="50px" name="estado"
                                onChange={handleChangePallet}  
                                value={formPallet.estado} 
                                 readOnly
                                />
                            </ContainerInputs>
                            <ContainerInputs width="50%">
                                <ButtonGeneral width="100%" height="50px" color=""
                                    onClick={handleEditarPallet}
                                >
                                    {formPallet._id ? 'Editar': 'Crear'}                                     
                                </ButtonGeneral>
                            </ContainerInputs>
                            <ContainerInputs width="50%">
                                <BtnSpanGeneral width="100%" height="50px" color="#7B7D7D"
                                onClick={handleCancelarPallet}
                                >
                                    Cancelar
                                </BtnSpanGeneral>
                            </ContainerInputs>
                        </FormMainContainerSet>
                        <MainContainerSet display="" width="60%" alignItems="center" flexWrap="" justifyContent="center">
                            <ContainerInputs width="50%">
                                {/* <label htmlFor="nombres">Codigo</label> */}
                                <InputGeneral width="80%" height="50px" name="nombres" placeholder="Busqueda"
                                    onChange={handleTextBusquedaPallet}  
                                    value={textBusquedaPallet}                                                            
                                />
                            </ContainerInputs>
                            <ContainerInputs width="auto">
                                <ButtonGeneral width="auto" height="50px" color=""
                                    onClick={buscarPallet}
                                >                                    
                                    <i className="fa-solid fa-magnifying-glass"></i>
                                </ButtonGeneral>
                            </ContainerInputs>
                            <div className="table-responsive m-4 table-responsive-sm">
                                <table className="tableFixedNone mx-auto">
                                <thead>
                                        <tr className=''>
                                            {/* <th >ID</th>              */}
                                            <th >Pallet</th>
                                            <th >Estado</th>
                                            <th>Planta</th>
                                            <th></th>                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {pallets.map((pallet)=>{
                                            return <tr>
                                                
                                                <td>{pallet.numPallet}</td>
                                                <td>{pallet.estado}</td>
                                                <td>{pallet.planta}</td>
                                                
                                                <td>
                                                <ContainerInputs width="80px" 
                                                onClick={()=> handleEditPallet(pallet._id)}
                                                >
                                                    <ButtonGeneral width="100%" height="50px" color="#424949"><i className="fas fa-pen"></i></ButtonGeneral>
                                                </ContainerInputs>                           
                                                </td>
                                            </tr>
                                        })} 
                                    </tbody>
                                </table>
                            </div>
                        </MainContainerSet>
                    </MainContainerSet>
                </DivBordered>
                 
            </MainContainerSet>
        </>
    )
}

export default Administrar
