import React, { MouseEventHandler, useState, useEffect, ChangeEvent } from 'react'
import { Modal } from 'react-bootstrap';
import { proveedor } from '../../Proveedores/proveedor'
import { toast } from 'react-toastify';
import moment from 'moment';
import DetSalida from './DetSalida';
import { almacenes, tipoMovimiento, salida, detSalida, lotesFromAgricultor } from '../TypesAlmacenes'
import * as almacenesService from '../../Planta/AlmacenMP/AlmacenMPServices'
import * as ALMACENESservice from '../AlmacenesService'
import * as proveedoresService from '../../Proveedores/ProveedorService'
import * as loteService from '../../Lotes/LoteService'
import {
    Input, Label, Select, Option, Container, FormContainer, Form
    , TableContainer, Table, THead, TH, TR, TBody, TD, ContainerInput
    , SPAN, ButtonForm, SpanOptForm, MainContainer, I
} from './ModalSalidaElements'
interface Props {
    show: boolean,
    onHide: MouseEventHandler<HTMLElement>,
    idAlmacen: almacenes | undefined,
    salida: salida
    // onHideFun: () => void
}

function FormSalida(props: Props|any) {
    const initialSalida = {
        _id: '',
        estado: 'PENDIENTE',
        numero: 0,
        almacenOrigen: {
            _id: '',
            nombre: ''
        },
        tipoMovimiento: {
            _id: '',
            descripcion: ''
        },
        almacenDestino: {
            _id: '',
            nombre: ''
        },
        proveedorDestino: {
            _id: '',
            nombre: ''
        },
        ordenSiembra: '',
        loteAgricultor: {
            _id: '',
            numeroContrato: ''
        },
        fechaSalida: '',
        guiaRemision: '',
        subTotal: 0,
        igvTotal: 0,
        total: 0
    }
    const [stateFormIngreso, setStateFormIngreso] = useState(false)
    const [salida, setSalida] = useState<salida>(initialSalida);
    const [stateDisabled, setStateDisabled] = useState({ cliente: true, almacenDestino: true })
    const [optTipoMov, setOptTipoMov] = useState<tipoMovimiento[]>([])
    const [optAlmacenDestino, setOptAlmacenDestino] = useState<almacenes[]>([])
    const [optClientes, setOptClientes] = useState<proveedor[]>([])
    const [listDetSalida, setListDetSalida] = useState<detSalida[]>([])
    const [detalleSalida, setDetalleSalida] = useState<detSalida | undefined>()
    const [stateModalDetSalida, setStateModalDetSalida] = useState(false)
    const [stateOS, setStateOS] = useState(false)
    const [stateLote, setStateLote] = useState(false)
    const [optLotes, setOptLotes] = useState<lotesFromAgricultor[]>([])
    const handleSubmitSalida = async (e: any) => {
        e.preventDefault();
        try {
            if (!salida._id) { // Si no existe el ID de salida es por que se va crear
                console.log(salida)
                const resSaveSalida = await ALMACENESservice.createSalida(salida)
                if (resSaveSalida.status === 200) {
                    toast.success("SALIDA CREADA");
                    setSalida({ ...salida, _id: resSaveSalida.data._id, numero: resSaveSalida.data.numero })
                    // setStateFormIngreso(true)
                } else {
                    toast.error(resSaveSalida.status)
                }
            }
        } catch (error:any) {
            toast.error("Verificar OS Duplicado o verifique Conexion")
        }
    }
    const anularSalida = () => {

    }
    const eliminarSalida = () => {

    }
    const handleChangeSalida = (e: any) => {
        console.log(salida, "ESTADO SALIDA")
        if (e.target.name === 'tipoMovimiento') {
            const selTipoMov = optTipoMov.find(tipoMov => tipoMov._id === e.target.value);
            console.log(selTipoMov?.destinoLote);

            if (selTipoMov?.transformacion) {
                setOptAlmacenDestino([]);
                setOptLotes([])
                setStateOS(true);
                setStateLote(false)
                setStateDisabled({ cliente: false, almacenDestino: true })
                setSalida({ ...salida, [e.target.name]: e.target.value, almacenDestino: { _id: '', nombre: '' }, proveedorDestino: { _id: '', nombre: '' }, loteAgricultor: { _id: '', numeroContrato: '' }, ordenSiembra: '' })

            }
            else if (selTipoMov?.salInterna) {
                console.log("entro tipo mov sal iunterna1")
                setOptLotes([]);
                setOptClientes([])
                setStateOS(false)
                setStateLote(false)
                loadAlmancenesDestino();
                setStateDisabled({ cliente: true, almacenDestino: false })
                setSalida({ ...salida, [e.target.name]: e.target.value, almacenDestino: { _id: '', nombre: '' }, proveedorDestino: { _id: '', nombre: '' }, loteAgricultor: { _id: '', numeroContrato: '' }, ordenSiembra: '' })

            } else if (selTipoMov?.destinoLote) {
                setOptClientes([])
                setOptLotes([]);
                setOptAlmacenDestino([]);
                setStateOS(false)
                setStateLote(true)
                setStateDisabled({ cliente: false, almacenDestino: true })
                setSalida({ ...salida, [e.target.name]: e.target.value, almacenDestino: { _id: '', nombre: '' }, proveedorDestino: { _id: '', nombre: '' }, loteAgricultor: { _id: '', numeroContrato: '' }, ordenSiembra: '' })

            } else {
                setOptClientes([])
                setOptLotes([]);
                setStateOS(false)
                setStateLote(false)
                setStateDisabled({ cliente: false, almacenDestino: true })
                setSalida({ ...salida, [e.target.name]: e.target.value, almacenDestino: { _id: '', nombre: '' }, proveedorDestino: { _id: '', nombre: '' }, loteAgricultor: { _id: '', numeroContrato: '' }, ordenSiembra: '' })

            }

        } else if (e.target.name === 'proveedorDestino') {
            console.log("entro tipo mov sal iunterna2")
            setSalida({ ...salida, [e.target.name]: e.target.value, loteAgricultor: { _id: '', numeroContrato: '' } })
            if (e.target.value) {
                getLotesAgricultor(e.target.value)
            }
        } else {
            console.log("entro tipo mov sal iunterna4")
            setSalida({ ...salida, [e.target.name]: e.target.value })
        }
    }

    const loadTipoMovimientos = async () => {
        const resTipoMovimientos = await ALMACENESservice.getTipoMovimientoByDocumento('salidas')
        console.log(resTipoMovimientos.data, "TIPO MOVIMIENTOS");
        setOptTipoMov(resTipoMovimientos.data);

    }
    const loadAlmancenesDestino = async () => {
        const resAlmacenes = await almacenesService.getAllAlmacenes();
        setOptAlmacenDestino(resAlmacenes.data)
    }
    const busquedaCliente = async (e: any) => {
        // console.log("busqueda Provedor")

        if (e.key === 'Enter') {
            // console.log('enter press here! ', e.target.value)
            e.preventDefault();
            const resProveedores = await proveedoresService.busquedaProveedor(e.target.value)
            setOptClientes(resProveedores.data)
            // console.log(optProveedores)
        }
    }
    const resetFormSalida = () => {
        console.log("entrando el RESET DETALLE")
        setSalida(initialSalida)
        setStateFormIngreso(false);
        setListDetSalida([])
        setStateDisabled({ cliente: true, almacenDestino: true })
    }
    const addDetalleSalida = () => {
        if (!salida._id || salida.estado != 'PENDIENTE') {
            return toast.info("Estado de la salida no disponible")
        }
        setStateModalDetSalida(true)
    }
    const editDetSalida = (id: string | undefined) => {

    }
    const deleteDetSalida = (id: string | undefined) => {

    }
    const closeModalDetSalida = () => {
        console.log("MODAL DET SALIDA CERRANDO", salida._id)
        setStateModalDetSalida(false)
        loadDetSalida(salida._id);
    }
    const loadDetSalida = async (id: string | undefined) => {

        if (id) {
            const resDetSalida = await ALMACENESservice.getDetSalidasByIdSalida(id)
            console.log(resDetSalida)
            setListDetSalida(resDetSalida.data)
        }
    }
    const getLotesAgricultor = async (id: string) => {
        const resLotes = await loteService.getLotesVsAgricultor(id);
        console.log(resLotes.data, "LOTES DEL AGRICULTOR")
        setOptLotes(resLotes.data)
    }
    useEffect(() => {

        if (props.show) {
            console.log(props.salida, "ENTRANDO AL  PROPS SHOW TRUE")
            loadTipoMovimientos();
            if (props.salida._id) {
                setSalida(props.salida)
                loadDetSalida(props.salida._id);
            } else {
                setSalida({ ...salida, almacenOrigen: { _id: props.idAlmacen?._id, nombre: props.idAlmacen?.nombre } })
                setListDetSalida([])
            }
        } else {
            resetFormSalida();
        }
    }, [props.show])

    return (
        <>
            <Modal
                show={props.show}
                onHide={props.onHide}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <div className="col-12  d-flex flex-wrap justify-content-between  ">
                            <div className="">
                                {props.salida._id ? ('Edicion') : ('Creacion')} Salida #{salida.numero} - {props.idAlmacen?.nombre} 
                                &nbsp;&nbsp;&nbsp;
                            </div>
                            
                            <div className=" text-primary">
                                {salida.estado}
                            </div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Form onSubmit={handleSubmitSalida}>
                            <fieldset disabled={stateFormIngreso}>
                                <FormContainer align={'flex-start'}>
                                    {/* <ContainerInput size={'200px'}>
                                        <Label htmlFor='estado'> Estado</Label>
                                        <Input type='text' id='estado' name='estado' bg={'#21332636'}
                                            value={salida.estado}
                                            readOnly
                                            required
                                        />
                                    </ContainerInput>
                                    <ContainerInput size={'100px'}>
                                        <Label htmlFor='numero'>Numero Salida</Label>
                                        <Input type='number' id='numero' name='numero' bg={'#21332636'}
                                            value={salida.numero}
                                            readOnly
                                            required />

                                    </ContainerInput> */}
                                    <ContainerInput size={'300px'}>
                                        <Label htmlFor='tipoMovimiento'>Tipo Movimiento</Label>
                                        <Select id='tipoMovimiento' name='tipoMovimiento' bg={'#fff'}
                                            onChange={handleChangeSalida}
                                            required
                                        >
                                            {salida.tipoMovimiento._id ? (<Option value={salida.tipoMovimiento._id}>{salida.tipoMovimiento.descripcion}</Option>) : (<Option value="">SELECCIONE</Option>)}
                                            {optTipoMov.map((movimientos) => {
                                                return <Option key={movimientos._id} value={movimientos._id}>{movimientos.descripcion}</Option>
                                            })
                                            }
                                        </Select>
                                    </ContainerInput>
                                    <ContainerInput size={'250px'}  >
                                        <Label htmlFor='almacenOrigen'>Almacen Origen</Label>
                                        <Select id='almacenOrigen' name='almacenOrigen' bg={'#fff'}
                                            required
                                            onChange={handleChangeSalida}
                                        >

                                            {salida.almacenOrigen._id ? (<Option value={salida.almacenOrigen._id}>{salida.almacenOrigen.nombre}</Option>) : (<Option value="">SELECCIONE</Option>)}

                                        </Select>
                                    </ContainerInput>
                                    <ContainerInput size={'150px'}>
                                        <Label htmlFor='ordenSiembra'>Orden Siembra</Label>
                                        <Input type='text' id='ordenSiembra' name='ordenSiembra' bg={stateOS ? ('#fff') : ('#21332636')}
                                            value={salida.ordenSiembra}
                                            onChange={handleChangeSalida}
                                            required={stateOS ? (true) : (false)}
                                            readOnly={stateOS ? (false) : (true)}
                                        />
                                    </ContainerInput>
                                    <ContainerInput size={'300px'} >
                                        <Label htmlFor='almacenDestino'>Almacen Destino</Label>
                                        <Select id='almacenDestino' name='almacenDestino' bg={stateDisabled.almacenDestino ? ('#21332636') : ('#FFF')}
                                            disabled={stateDisabled.almacenDestino}
                                            onChange={handleChangeSalida}
                                            required
                                        >
                                            {salida.almacenDestino?._id ? (<Option value={salida.almacenDestino._id}>{salida.almacenDestino.nombre}</Option>) : (<Option value="">SELECCIONE</Option>)}
                                            {optAlmacenDestino.map((almacenes) => {
                                                return <Option key={almacenes._id} value={almacenes._id}>{almacenes.nombre}</Option>
                                            })
                                            }
                                        </Select>
                                    </ContainerInput>
                                    <ContainerInput size={'100px'}>
                                        <Label htmlFor='busquedaProv'>Busq. Cliente</Label>
                                        <Input type='text' id='busquedaProv' name='busquedaProv' bg={'#fff'}
                                            disabled={stateDisabled.cliente}
                                            onKeyPress={busquedaCliente}
                                        />
                                    </ContainerInput>
                                    <ContainerInput size={'250px'}>
                                        <Label htmlFor='proveedorDestino'>Cliente</Label>
                                        <Select id='proveedorDestino' name='proveedorDestino' bg={stateDisabled.cliente ? ('#21332636') : ('#FFF')}
                                            disabled={stateDisabled.cliente}
                                            onChange={handleChangeSalida}
                                            required>
                                            {salida.proveedorDestino?._id ? (<Option value={salida.proveedorDestino._id}>{salida.proveedorDestino.nombre}</Option>) : (<Option value="">SELECCIONE</Option>)}
                                            {optClientes.map((clientes) => {
                                                return <Option key={clientes._id} value={clientes._id}>{clientes.nombre}, RUC:{clientes.numeroDocumento}</Option>
                                            })
                                            }
                                        </Select>
                                    </ContainerInput>
                                    <ContainerInput size={'250px'}>
                                        <Label htmlFor='loteAgricultor'>Lote</Label>
                                        <Select id='loteAgricultor' name='loteAgricultor' bg={stateLote ? ('#fff') : ('#21332636')}
                                            disabled={!stateLote}
                                            onChange={handleChangeSalida}
                                            required={stateLote}>
                                            {salida.loteAgricultor?._id ? (<Option value={salida.loteAgricultor._id}>{salida.loteAgricultor.numeroContrato}</Option>) : (<Option value="">SELECCIONE</Option>)}
                                            {optLotes.map((lotes) => {
                                                return <Option key={lotes._id} value={lotes._id}>{lotes.numeroContrato}, Area:{lotes.areaLote}</Option>
                                            })
                                            }
                                        </Select>
                                    </ContainerInput>
                                    <ContainerInput size={'200px'}>
                                        <Label htmlFor='fechaSalida'>Fecha Salida</Label>
                                        <Input type='date' id='fechaSalida' name='fechaSalida' bg={'#fff'}
                                            value={moment(salida.fechaSalida).format('YYYY-MM-DD')}
                                            onChange={handleChangeSalida}
                                            required />
                                    </ContainerInput>
                                    <ContainerInput size={'150px'}>
                                        <Label htmlFor='guiaRemision'>Guia Remision</Label>
                                        <Input type='text' id='guiaRemision' name='guiaRemision' bg={'#fff'}
                                            value={salida.guiaRemision}
                                            onChange={handleChangeSalida}
                                        />
                                    </ContainerInput>
                                    <ContainerInput size={'200px'}>
                                        <Label htmlFor='subTotal'>Sub Total</Label>
                                        <Input type='number' step='any' id='subTotal' name='subTotal' bg={'#21332636'}
                                            value={salida.subTotal}
                                            required
                                            readOnly
                                        />

                                    </ContainerInput>
                                    <ContainerInput size={'200px'}>
                                        <Label htmlFor='igvTotal'>Igv Total</Label>
                                        <Input type='number' id='igvTotal' name='igvTotal' bg={'#21332636'}
                                            value={salida.igvTotal}
                                            required
                                            readOnly
                                        />
                                    </ContainerInput>
                                    <ContainerInput size={'200px'}>
                                        <Label htmlFor='total'>Total</Label>
                                        <Input type='number' id='total' name='total' bg={'#21332636'}
                                            value={salida.total}
                                            required
                                            readOnly
                                        />
                                    </ContainerInput>
                                </FormContainer>
                                <FormContainer align={'center'}>
                                    <ContainerInput size={'200px'}>
                                        <ButtonForm>{salida._id ? ('Editar') : ('Crear')}</ButtonForm>
                                    </ContainerInput>
                                    {salida._id ? (
                                        <>
                                            <ContainerInput size={'200px'}>
                                                <SpanOptForm color={'#6d6d6d'} onClick={anularSalida}>Anular</SpanOptForm>
                                            </ContainerInput>
                                            <ContainerInput size={'200px'}>
                                                <SpanOptForm color={'#970000'} onClick={eliminarSalida}>Eliminar</SpanOptForm>
                                            </ContainerInput>
                                        </>
                                    ) : ('')}

                                </FormContainer>



                            </fieldset>
                        </Form>
                        <div className="row container">
                            <h4 className="mt-3">Detalle</h4>
                            <SPAN onClick={addDetalleSalida}>+</SPAN>
                        </div>
                        <TableContainer height={'400px'} className="table-responsive table-striped col-12 mx-auto shadow mb-5 bg-body rounded trheight tableFixHead">
                            <Table >
                                <THead>
                                    <TR>
                                        <TH>#</TH>
                                        <TH>ESTADO</TH>
                                        <TH >PRODUCTO</TH>
                                        <TH>CANTIDAD</TH>

                                        <TH >SUBTOTAL</TH>
                                        <TH >IGV</TH>
                                        <TH >TOTAL</TH>
                                        <TH >ORDEN COMPRA</TH>
                                        <TH >ORDEN SIEMBRA</TH>
                                        <TH >DETALLE</TH>
                                        <TH >NUMERO INGRESO</TH>
                                        <TH >OPCIONES___</TH>
                                    </TR>
                                </THead>
                                <TBody>

                                    {listDetSalida.map((detSalida, index) => {
                                        return <TR id={detSalida._id} key={detSalida._id}>
                                            <TD>{index}</TD>
                                            <TD>{detSalida.estado}</TD>
                                            <TD>{detSalida.producto.nombre}</TD>
                                            <TD>{detSalida.cantidad}</TD>
                                            <TD>{detSalida.lote}</TD>
                                            <TD>{detSalida.precio}</TD>
                                            <TD>{detSalida.igv}</TD>
                                            <TD>{detSalida.total}</TD>
                                            <TD>{detSalida.ordenCompra}</TD>
                                            <TD>{detSalida.ordenSiembra}</TD>
                                            <TD>{detSalida.detalle}</TD>
                                            <TD>{detSalida.numIngresoRef}</TD>
                                            <TD>
                                                <MainContainer align={'center'}>
                                                    <I onClick={() => editDetSalida(detSalida._id)} color={'#2b577a'} className="fas fa-edit"></I>
                                                    <I onClick={() => deleteDetSalida(detSalida._id)} color={'#8a2c2c'} className="far fa-trash-alt"></I>
                                                </MainContainer>
                                            </TD>
                                        </TR>
                                    })}

                                </TBody>
                            </Table>
                        </TableContainer>
                    </Container>
                </Modal.Body>
            </Modal>
            <DetSalida show={stateModalDetSalida} onHide={closeModalDetSalida} idSalida={salida._id} numeroSalida={salida.numero} detalleSalida={detalleSalida} idAlmacen={props.idAlmacen} functionCloseModal={closeModalDetSalida} />
        </>
    )
}

export default FormSalida
