import React, { ChangeEvent, FormEvent, FormEventHandler, useState, useEffect } from 'react'
import { localidad } from './localidad'
import {departamento} from '../Departamentos/departamento'
import * as departamentoService from '../Departamentos/departamentoService'
import * as localidadService from './LocalidadService'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'

type InputChange = ChangeEvent<HTMLInputElement | HTMLSelectElement>;
interface Params {
  id: string
}

function LocalidadForm() {
  const history = useNavigate();
  const params = useParams<any>();

  const getDepartamentos = async() =>{
    const resDepartamentos = await departamentoService.getDepartamentos();
    setDepartamento(resDepartamentos.data);
  }

  const initialState = {
    nombre: '',
    coordenadas: '',
    departamento: '',
    zoom: '12'
  }
  const [localidad, setLocalidad] = useState<localidad>(initialState);
  const [departamentos, setDepartamento] = useState<departamento[]>([]);

  const handleInputChange = (e: InputChange) => {
    setLocalidad({ ...localidad, [e.target.name]: e.target.value });
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!params.id) {
      await localidadService.createLocalidades(localidad);
      toast.success('Localidad Add');
      setLocalidad(initialState);
    } else {
      await localidadService.updateLocalidades(params.id, localidad);
    }
    history('/localidad');
  }
  const getLocalidad = async (id: string) => {

    const res = await localidadService.getLocalidad(id);
    console.log(id, res.data)
    const { nombre, coordenadas, departamento, zoom } = res.data;
    setLocalidad({ nombre, coordenadas, departamento, zoom: '12' });
  }

  useEffect(() => {
    if (params.id) getLocalidad(params.id);
    getDepartamentos();
    console.log(departamentos)
  }, [])

  return (
    <div className="row">
      <div className="col-md-4 offset-md-4">
        <div className="card">
          <div className="card-body">
            {params.id ? (
              <h3>Editar Localidad</h3>
            ) : (<h3>Nueva Localidad</h3>)
            }

            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  required
                  type="text"
                  name="nombre"
                  placeholder="Nombre de Localidad"
                  className="form-control"
                  value={localidad.nombre}
                  onChange={handleInputChange}
                  autoFocus />
              </div>
              <div className="form-group">
                <input
                  required
                  type="text"
                  name="coordenadas"
                  placeholder="Coordenadas"
                  className="form-control"
                  value={localidad.coordenadas}
                  onChange={handleInputChange} />
              </div>

              <div className="form-group">
                <select onChange={handleInputChange} name="departamento" className="form-control form-select form-select-sm" aria-label=".form-select-sm example" required>
                  {params.id ? (
                    <option value={localidad.departamento} >{localidad.departamento}</option>
                  ) :
                    (<option value={''} >DEPARTAMENTO</option>)}
                  {departamentos.map((departamento)=>{
                    return <option key={departamento._id} value={departamento._id}>{departamento.nombre}</option>
                  })}
                  {/* <option value='AREQUIPA'>AREQUIPA</option>
                  <option value='CUSCO'>CUSCO</option> */}

                </select>
              </div>
              {params.id ? (
                <button className="btn btn-info">Editar Localidad</button>
              ) : (
                <button className="btn btn-primary">Crear Localidad</button>
              )
              }
            </form>
          </div>
        </div>
      </div>

    </div>
  )
}

export default LocalidadForm
