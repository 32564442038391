import axios from 'axios'
import { transportista } from './transportista'
import {busqueda, showModalFlete} from '../PagoTransporte/pagoTransportista'
import {resTransportista} from '../PagoTransporte/pagoTransportista'
import {vehiculo} from './Vehiculos/vehiculos'
import apiURL from '../../context/apiURL'
const API = apiURL.API;

export const OneTransportista = async(id: string)=>{
    return await axios.get<transportista>(`${API}/oneTransportistaServer/${id}`);
}
export const setTransporteVsVehiculo = async(id: string)=>{
    return await axios.get<vehiculo[]>(`${API}/setTransporteVsVehiculoServer/${id}`);
}
export const textBusquedaTransportistaServer = async(id: string) => {
    return await axios.get<resTransportista[]>(`${API}/textBusquedaTransportistaServer/${id}`)
}
export const getTransporteVsFechaServer = async(data: busqueda) => {
    return await axios.post<any>(`${API}/getTransporteVsFechaServer`, data)
}
//Configuracion de flete a transportes
export const setFleteTransporteServer = async(data: showModalFlete) =>{
    return await axios.post(`${API}/setFleteTransporteServer`, data)
}
//  Asignacion de Factura a transportes
export const setFacturaTransporteServer = async(data: showModalFlete) =>{
    return await axios.post(`${API}/setFacturaTransporteServer`, data)
}

export const pdfListTransportesServer = async(data: any[]) =>{
    return await axios.post(`${API}/pdfListTransportesServer`, data);
}
export const SendPdfTransportesServer = async() =>{
    return await axios.get(`${API}/SendPdfTransportesServer`,{responseType: 'blob'} )
}