import React, { useState, useEffect } from 'react'

import * as almacenMPServices from '../AlmacenMP/AlmacenMPServices'

import { almacenes, 
    reporteUbicacionPallet, 
    formatos, 
    reporteFormVsProd, 
    reporteDetallePallets, 
    zonas,
    nivelPallet} from '../AlmacenMP/almacen'
import { toast } from 'react-toastify';
import IcoExcel from '../../../Media/svg/excel.svg'
import momentz from 'moment-timezone';
import saveAs from 'file-saver'
function DetalleNivel() {
    let xFila = 0;
    let xCol = 0;
    const [listAlmacenes, setListAlmacenes] = useState<almacenes[]>([]);
    const [zonasAlmacen, setZonasAlmacen] = useState<zonas[]>([])
    const [reporte, setReporte] = useState<reporteUbicacionPallet[]>([])
    const [reporteFormVsProd, setReporteFormVsProd] = useState<reporteFormVsProd[]>([])
    const [reporteDetPallet, setReporteDetPallet] = useState<reporteDetallePallets[]>([]);
    const [reporteNivelPallet, setReporteNivelPallet] = useState<nivelPallet[]>([])
    const [filasBase, setFilasBase] = useState<any[]>([])
    const [listOptFormato, setListOptFormato] = useState<formatos[]>([])
    const [textBusFormato, setTextBusFormato] = useState('')
    const [dataGetReporte, setDataGetReporte] = useState({ idAlmacen: '61534152431a5c10acf19d9e', idZona:'', idFormato: '', likePallet:'', ordenar:'F'})
    const [loadDiv, setLoadDiv] = useState(false)
    const [stateBtnSubmit, setStateBtnSubmit] = useState(false)
    const [inicio, setInicio] = useState(0)
    const handleSetDataToSearch = async (e: any) => {
        setDataGetReporte({ ...dataGetReporte, [e.target.name]: e.target.value })
        if (e.target.name === 'idAlmacen') {
            const resZonas = await almacenMPServices.getZonasByAlmacen(e.target.value)
            setZonasAlmacen(resZonas.data);
        }
    }

    const handleChangetextBusFormato = (e: any) => {
        setTextBusFormato(e.target.value)
    }

    const loadAlmacenes = async () => {
        const resAlmacenes = await almacenMPServices.getAllAlmacenesPlanta();
        const resZonas = await almacenMPServices.getZonasByAlmacen('61534152431a5c10acf19d9e')
        setZonasAlmacen(resZonas.data);
        setListAlmacenes(resAlmacenes.data);
    }
    
    const loadDetallePallets = async () =>{
        setStateBtnSubmit(true)
        setReporteDetPallet([]);
        const resReporte = await almacenMPServices.getReporteDetallePallets(dataGetReporte)
        if (resReporte.data.length !<0){
            setStateBtnSubmit(false)
            return toast.error("Consulta sin registros")
        } 
        setReporteDetPallet(resReporte.data)
        setStateBtnSubmit(false)
    }
    const getExcelDetPallet = async () =>{
        try {
            if (reporteDetPallet.length===0) return toast.error("Reporte sin datos")
            await almacenMPServices.createExcelDetallePallets(reporteDetPallet)
            const resExcel = await almacenMPServices.getReportePallet('ReporteDetallePallets.xlsx');
            pdfBlob(resExcel, 'ReporteDetallePallets.xlsx')  
        } catch (error:any) {
               toast.error(error.message)
        }
    }

    const getReportePallets = async () => {
        try {   
            setStateBtnSubmit(true)
            setReporte([])
            const resReporte = await almacenMPServices.reporteUbicacionPallets(dataGetReporte);
            // console.log(resReporte.data)
            if (resReporte.data.length !<0) {
                setStateBtnSubmit(false)
                return toast.info("Resultado sin Datos")
            }            
            setReporte(resReporte.data)
            setStateBtnSubmit(false)
        } catch (error) {
            setStateBtnSubmit(false)
            toast.error("Inicie Sesion Nuevamente")
        }           
    }
    const busquedaFormato = async () => {
        const resFormatos = await almacenMPServices.busquedaFormato(textBusFormato);
        if (!resFormatos.data[0].codigo) return toast.error("Error de Busqueda")
        setListOptFormato(resFormatos.data)
    }
    const loadFormatoVsProd = async () => {
        try {
            setStateBtnSubmit(true)
            setReporteFormVsProd([])
            const resReporteFormVsProd = await almacenMPServices.reporteFormatoVsProd(dataGetReporte)
            if (resReporteFormVsProd.data.length !<0) {
                setStateBtnSubmit(false)
                return toast.info("Resultado sin Datos")
            } 
            setReporteFormVsProd(resReporteFormVsProd.data)
            setStateBtnSubmit(false)
        } catch (error) {
            setStateBtnSubmit(false)
            toast.error("Inicie Sesion Nuevamente")
        }        
    }
    const pdfBlob = async(res:any, nameFile:string)=>{
        const pdfBlob= await new Blob([res.data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"})
        saveAs(pdfBlob, nameFile)
       }
    // const downloadExcelUbicacionPallet = async () => {        
    //     if (reporte.length===0) return toast.error("Reporte sin datos")
    //     const resDownload = await almacenMPServices.getExcelEstadoPallets(reporte)
    //     const url = await (new Blob([resDownload.data]));
    //     saveAs(url, 'ReporteEstadoPallets.xlsx')
    // }
    const getReporteEstadoPallets = async () => {        
       try {
        if (reporte.length===0) return toast.error("Reporte sin datos")
        await almacenMPServices.createExcelEstadoPallets(reporte)
        const resExcel = await almacenMPServices.getReportePallet('ReporteEstadoPallets.xlsx');
        pdfBlob(resExcel, 'ReporteEstadoPallets.xlsx')  
       } catch (error:any) {
           toast.error(error.message)
       }
    }
    const downloadExcelFormProPallet = async () =>{
        try {
            if (reporteFormVsProd.length===0) return toast.error("Reporte sin datos")
            await almacenMPServices.createExcelFormatoProduccionPallets(reporteFormVsProd)
            const resExcel = await almacenMPServices.getReportePallet('ReporteFormProdPallets.xlsx');
            pdfBlob(resExcel, 'ReporteFormProdPallets.xlsx')  
           } catch (error:any) {
               toast.error(error.message)
           }
    }
    const submitDetNivelPallet = async() =>{
        try {
            // console.log(dataGetReporte)
            if(!dataGetReporte.idZona )
            {
                toast.info("Debe seleccionar la Zona a consultar")
                return;
            }
            setStateBtnSubmit(true)

            const resNivelesPallet = await almacenMPServices.getRepNivelPallet2(dataGetReporte);

            let resNivelPallet = resNivelesPallet.data.resNivelPallet;
            const actualizarArray = (elemento:any) => {
                const [cfila, ccolumna] = elemento.base.matris.match(/\d+/g);
                return {
                  ...elemento,
                  cfila: parseInt(cfila, 10),
                  ccolumna: parseInt(ccolumna, 10),
                };
            };
            const arrayActualizado = resNivelPallet.map(actualizarArray);
            if(dataGetReporte.ordenar=="C")
            {   
                  // Recorrer el array y actualizar cada objeto con los campos adicionales
                
    
                const arrayOrdenado = arrayActualizado.sort((a:any, b:any) => {
                    if (a.ccolumna === b.ccolumna) {
                      return a.cfila - b.cfila;
                    }
                    return a.ccolumna - b.ccolumna;
                  });
                resNivelPallet = arrayActualizado;
            }else{
                const arrayOrdenado = arrayActualizado.sort((a:any, b:any) => {
                    if (a.cfila === b.cfila) {
                      return a.ccolumna - b.ccolumna;
                    }
                    return a.cfila - b.cfila;
                  });
                resNivelPallet = arrayActualizado;                
            }
            
            
            const formatos = resNivelesPallet.data.resFormatos;
            //console.log(resNivelPallet);
            const tablas = [];
            const filas = [];
            let niveles = [];
            let anterioreMatrisRecontruida =''
            let primero = true;
            for (let i = 0; i < resNivelPallet.length; i++) {
                //Hacemos un barrido de todos los pallets de la Zona
                //console.log("Imprimiendo:" + i + ":::::***** " );

                const oneBase = resNivelPallet[i];
                if(primero){
                    primero = false;
                    if(dataGetReporte.ordenar=="C")
                        setInicio(oneBase.ccolumna)
                    else    
                        setInicio(oneBase.cfila)
                }
                //Buscando Pallets por Niveles por Base
                    let findNivel1= oneBase.pallets.filter((pallet:any) => pallet.nivel === 1) 
                    let arrayNivel1 = [];

                        for (let f = 0; f < findNivel1.length; f++) {
                            let oneNivel1 = findNivel1[f];
                            const findFormato = formatos.find((formato:any)=> formato._id === oneNivel1.formato)  
                            // console.log(findFormato, "FORMATO ENCONTRADOR")  
                            oneNivel1.form =  findFormato.descripcion;
                            arrayNivel1.push(oneNivel1)
                        }
                                                            
                    let findNivel2= oneBase.pallets.filter((pallet:any) => pallet.nivel === 2)
                    let arrayNivel2 = [];
                        for (let f = 0; f < findNivel2.length; f++) {
                            let oneNivel2 = findNivel2[f];
                            const findFormato = formatos.find((formato:any)=> formato._id === oneNivel2.formato)  
                            // console.log(findFormato, "FORMATO ENCONTRADOR")  
                            oneNivel2.form =  findFormato.descripcion;
                            arrayNivel2.push(oneNivel2)
                        }
                    let findNivel3= oneBase.pallets.filter((pallet:any) => pallet.nivel >= 3)
                    let arrayNivel3 = [];
                        for (let f = 0; f < findNivel3.length; f++) {
                            let oneNivel3 = findNivel3[f];
                            const findFormato = formatos.find((formato:any)=> formato._id === oneNivel3.formato)  
                            // console.log(findFormato, "FORMATO ENCONTRADOR")  
                            oneNivel3.form =  findFormato.descripcion;
                            arrayNivel3.push(oneNivel3)
                        }

                const nivelToPush = {
                    nivel1: arrayNivel1,
                    nivel2: arrayNivel2,
                    nivel3: arrayNivel3
                }
                    
                const splitMatris = oneBase.base.matris.split('');

                //const indexF = splitMatris.findIndex((matris: any) => matris === 'F')
                //const indexC = splitMatris.findIndex((matris: any) => matris === 'C')                
                var reconstruirMatris='';
                
                if(dataGetReporte.ordenar=="C")
                {
                    reconstruirMatris = "C" + oneBase.ccolumna
                    
                }else{
                    reconstruirMatris = "F" + oneBase.cfila
                }
                if (anterioreMatrisRecontruida !== reconstruirMatris && i>1) {
                    tablas.push(niveles)
                    niveles=[]
                }
                //Aqui se agrega los arreglos por nivel al array Niveles

                niveles.push(nivelToPush)
                anterioreMatrisRecontruida = reconstruirMatris
                // console.log(reconstruirMatris, "MATRIS RECONSTRUIDA")
                              
            }   
            // console.log(tablas) 
            tablas.push(niveles)
            setFilasBase(tablas)
            setReporteNivelPallet(resNivelPallet)
            setStateBtnSubmit(false)
            console.log("****************************************")
            console.log(filasBase)
        } catch (error:any) {
            toast.error(error)
            setStateBtnSubmit(false)
        }
    }

    useEffect(() => {
        loadAlmacenes();
    }, [])
    return (
        <>
            <div className='text-center'>
                    <h1>Reporte - Detalle Nivel Pallets</h1>
            </div>
            <div className={`${loadDiv ? 'loadDiv' : ''} `}>
                 <div className='container-fluid' style={{ width: '95%', left:'50px',padding:'0', margin:'0px' }}>       
                    <div className='row'>
                        <div className='col-3 '>
                            <label htmlFor="idAlmacen">Almacen</label>
                            <select className='form-control form-control-lg' id='idAlmacen' name='idAlmacen' onChange={handleSetDataToSearch}>
                            
                                {listAlmacenes.map((almacenes) => {
                                    return (
                                        <option value={almacenes._id}>{almacenes.nombre}</option>
                                    )
                                })}
                            </select>
                        </div>        
                        <div className='col-2'>
                            <label htmlFor="idZona">Zona</label>
                                <select className='form-control form-control-lg' id='idZona' name='idZona' onChange={handleSetDataToSearch}>
                                    <option value="">Seleccione</option>
                                    {zonasAlmacen.map((zonaAlm) => {
                                        return (
                                            <option value={zonaAlm._id}>{zonaAlm.nombre}</option>
                                        )
                                    })}
                                </select>
                        </div>
                        <div className='col-3'>
                            <label htmlFor="ordenar">Mostrar Por:</label>
                                <select className='form-control form-control-lg' id='ordenar' name='ordenar' onChange={handleSetDataToSearch}>
                                    <option value="F" selected>Fila</option>
                                    <option value="C">Columna</option>
                                </select>
                        </div>
                        <div className='col-3'>                   
                            <label htmlFor="ordenar">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>              
                            <button className='btn btn-primary btn-lg btn-block' disabled={stateBtnSubmit} onClick={submitDetNivelPallet}>
                            {stateBtnSubmit 
                            ?   <div className="spinner-border text-ligth" role="status">
                                    <span className="sr-only">Cargando...</span>
                                </div>
                            : 'Cargar' }
                            </button>
                        </div> 
                    </div>
                </div>
                {/*  */}
                <div className='row text-center align-items-end'>
                    <div className="row">
                        {
                        
                        filasBase.map((filas) => {
                            
                            xFila++;
                            if(xFila==1){
                                xFila = inicio
                            }
                            return( <>
                                {/* <div className="p-2 border bg-dark text-light"> {nivelPallet.almacen} </div>   */}
                                <div className="col-6 pt-2 pb-5 ">
                                    <table className="tableFixedNone mx-auto">
                                        <thead>
                                            <tr><td colSpan={3}> {dataGetReporte.ordenar=="C" ? (<b>Columna</b>  ) : (<b>Fila</b>)} : {xFila}</td></tr>
                                            <tr>
                                                <td >NIVEL 1</td>
                                                <td >NIVEL 2</td>
                                                <td >NIVEL 3 +</td>
                                            </tr>
                                        </thead>
                                        <tbody >
                                            {filas.map((fila:any, index:number)=>{
                                                

                                                return <tr >
                                                    <td style={{maxWidth: '400px', width: '350px', lineHeight:'11px'}}>
                                                        {fila.nivel1.map((n1:any)=>{                                                        
                                                            return <>
                                                                <span  style={{fontSize: '9px','fontFamily':'Arial Narrow'}}><b>{n1.numPallet}</b> :{n1.form}</span> <br />
                                                            </>
                                                            })}
                                                    </td>
                                                    <td style={{maxWidth: '400px', width: '350px', lineHeight:'11px'}}>
                                                        {fila.nivel2.map((n2:any)=>{                                                        
                                                            return <>
                                                                <span style={{fontSize: '9px','fontFamily':'Arial Narrow'}}><b>{n2.numPallet}</b> : {n2.form}</span> <br />
                                                            </>
                                                        })}
                                                    </td>
                                                    <td style={{maxWidth: '400px', width: '350px', lineHeight:'11px'}}>
                                                        {fila.nivel3.map((n3:any)=>{                                                        
                                                            return <>
                                                                <span  style={{fontSize: '9px','fontFamily':'Arial Narrow'}}><b>{n3.numPallet}</b> :{n3.form}</span> <br />
                                                            </>
                                                        })}
                                                    </td>
                                                </tr>
                                            })}                               
                                        </tbody>
                                    </table>
                                </div>                          
                            </>
                            )
                        })}
                    </div>
                    {/* <div className='col-12 col-sm-12 col-md-2 m-2 mx-auto text-center' >
                        <img src={IcoExcel} alt="" onClick={downloadExcelFormProPallet} className="iconExcel" />
                        
                    </div> */}
                </div>
            </div>

        </>
    )
}

export default DetalleNivel
