import React from 'react'
import { Container, FormContainer, ContainerInput, Input, Label, Select, ButtonForm, SpanOptForm,
    Option, TableContainer, Table, THead, TH, TR, TBody, TD } from '../AlmacenesElements'
function TrazabilidadAlmacen() {
    return (
        <>
            <Container align={'center'}>
                <FormContainer align={'center'}>
                    <h3>Trazabilidad de Almacenes</h3>
                </FormContainer>
            </Container>
            <Container align={'flex-start'}>
                <FormContainer align={'flex-start'}>
                <ContainerInput size={'200px'}>
                        <Label htmlFor='almacenes'>Almacen</Label>
                        <Select id='almacenes' name='almacenes' bg={'#e6e6e6'}>
                        <Option value=''>todos</Option>
                        
                        </Select>
                    </ContainerInput>
                    <ContainerInput size={'200px'}>
                        <Label htmlFor='campo'>Campo</Label>
                        <Select id='campo' name='campo' bg={'#ffffff'}>
                        <Option value=''>Seleccione</Option>
                        <Option value='ordenSiembra'>Orden Siembra</Option>
                        <Option value='ordenCompra'>Orden Compra</Option>
                        <Option value='guiaRemision'>Guia Remision</Option>\
                        <Option value='guiaRecepcion'>Guia Recepcion</Option>
                        </Select>
                    </ContainerInput>
                    <ContainerInput size={'200px'}>
                        <Label htmlFor='valorCampo'>Valor</Label>
                        <Input type='text' id='valorCampo' name='valorCampo' bg={'#fff'}/>
                    </ContainerInput>
                    <ContainerInput size={'200px'}>
                        <Label htmlFor='proveedorCliente'>Valor Prov-Cli</Label>
                        <Input type='text' id='proveedorCliente' name='proveedorCliente' bg={'#e6e6e6'}/>
                    </ContainerInput>
                    <ContainerInput size={'400px'}>
                        <Label htmlFor='provClientes'>Proveedor-Cliente</Label>
                        <Select id='provClientes' name='provClientes' bg={'#e6e6e6'}>
                        <Option value=''>Seleccione</Option>
                        
                        </Select>
                    </ContainerInput>
                    <ContainerInput size={'200px'}>
                        <Label htmlFor='lote'>Lote</Label>
                        <Select id='lote' name='lote' bg={'#d7d7ff8b'}>
                        <Option value=''>Seleccione</Option>
                        
                        </Select>
                    </ContainerInput>
                    <ContainerInput size={'200px'}>
                    <ButtonForm>Buscar</ButtonForm>
                    </ContainerInput>
                    
                </FormContainer>
            </Container>
            <TableContainer height={'600px'} className="table-responsive table-striped col-12 mx-auto shadow mb-5 bg-body rounded trheight tableFixHead">
                            <Table >
                                <THead>
                                    <TR>
                                        <TH>#</TH>
                                        <TH>ESTADO</TH>
                                        <TH >PRODUCTO</TH>
                                        <TH>CANTIDAD</TH>                                        
                                        <TH >SUBTOTAL</TH>
                                        <TH >IGV</TH>
                                        <TH >TOTAL</TH>
                                        <TH >ORDEN COMPRA</TH>
                                        <TH >ORDEN SIEMBRA</TH>
                                        <TH >DETALLE</TH>
                                        <TH >NUMERO INGRESO</TH>
                                        <TH >OPCIONES___</TH>
                                    </TR>
                                </THead>
                                <TBody>

                                    

                                </TBody>
                            </Table>
                        </TableContainer>
        </>
    )
}

export default TrazabilidadAlmacen
