import React, { useEffect, useState, ChangeEvent, useRef, useContext } from 'react'
import { lote, Blote, totales } from './lote'
import * as loteService from './LoteService'

import { useNavigate } from 'react-router-dom'
import moment from 'moment';
import {toast} from 'react-toastify'
// PINT PDF
import {saveAs} from 'file-saver'
import LotePdf from './LotePdf/LotePdf'
import ReactDOM from "react-dom";
// import { Document, Page, Text, View, StyleSheet, PDFViewer } from "@react-pdf/renderer";
import ModalPdf from './LotePdf/ModalPdf';
import ModalDash from '../Dashboard/ModalDash/ModalDash'
//OBTENIENDO INFO DE USUARIO
import authContext from '../../context/AuthContext';
import Swal from 'sweetalert2'
import { read, utils } from 'xlsx'
const noTruncarDecimales = {maximumFractionDigits: 20};
type InputChange = ChangeEvent<HTMLInputElement | HTMLSelectElement>;

function LoteList() {
    const { loggedIn } = useContext(authContext);
    const history = useNavigate();
    // const [refPrint, setstate] = useState()
    const [importLotes, setImportLotes] = useState<any[]>([])
    const [loadImport, setLoadImport] = useState(false)
    const [lotes, setLotes] = useState<lote[]>([]);
    const [totales, setTotales] = useState<totales>()
    const [Blotes, setBlotes] = useState<Blote>({
        fechaIni: '',
        fechaFin: '',
        columna: '',
        textoColumna: '',
        estadoLote: ''
    });
    const loadLotes = async () => {
        const res = await loteService.getLotes();
      //  console.log("ejecutando loadLotes")
        setLotes(res.data);
        
    }

    const handleSetBusqueda = (e: any) => {
        setBlotes({ ...Blotes, [e.target.name]: e.target.value });
        // console.log(Blotes);
    }
    const handleSubmitBusqueda = async () =>{
        
       try {
        setLoadDiv(true);
        // if(!Blotes.fechaIni && !Blotes.fechaFin && !Blotes.textoColumna  ){
        //     setLoadDiv(false);
        //     return toast.warning('Seleccione almenos una opcion de busqueda');           
        // }        
            const resBusqueda = await loteService.BusquedaLotes(Blotes);            
            console.log(resBusqueda);
            if (resBusqueda.data.resLotes.length>0) {
                toast.success('Busqueda Exitosa');
                setLotes(resBusqueda.data.resLotes);
                const formatArea = ((parseFloat(resBusqueda.data.totales.areaLote.toFixed(2))).toLocaleString(undefined, noTruncarDecimales)) 
                setTotales({ ... totales, areaLote:  formatArea})
                setLoadDiv(false);
            }else{
                toast.info('No hay registros para la busqueda');
                setLoadDiv(false);
            }
            
        
       } catch (error:any) {
        setLotes([]);
        setLoadDiv(false);
        if (error.message === 'Request failed with status code 304') {
            toast.error('No hay Registros para la busqueda');            
        } else{
            toast.error(error.message)
        }
       }
    }
    const handleCreateLote = ()=>{
        if(loggedIn.type==='ADMINISTRADOR' || loggedIn.type === 'JEFECAMPO'){
            history('/new-lote');            
        }else{
            return toast.error('Usuario no Autorizado');
        } 
        
    }
    const handleDelete = async (id: string) => {
        try {
            // await loteService.deleteLote(id);
        loadLotes();
        toast.warning('Lote Eliminado');
        } catch (error:any) {
            if (error.message === 'Request failed with status code 303') {
                toast.error('No se puede eliminar, registros pendientes');
            }
        }
    }

    
    
    // useEffect(() => {
    //     loadLotes();
        
    // },[])  
   //modale funcions
   const [pdfLote, setPdfLote] = useState({
       name:'qwe',
       receiptId: 0,
       price1:0,
       price2:0
   })
   const [show, setShow] = useState({ estado: false, idLote: '' });
   const [loadDiv, setLoadDiv] = useState(false)
   const handleClose = () => setShow({ idLote: '', estado: false });
   const handleCloseModalCosechas = () => setShow({ idLote: '', estado: false });
   const handleShowModalCosechas = (e: any) => {
       console.log(e)
       setShow({ idLote: e.target.parentNode.id, estado: true });
   }
   
    const pdfBlob = async(res:any)=>{
    const pdfBlob= await new Blob([res.data], {type: 'appication/pdf'})
    saveAs(pdfBlob, 'newPdf.pdf')
    }

    const handlePrintPdf = async (id: string) =>{
        try {
            setLoadDiv(true);
            const resPdfLote = await loteService.CretePdfLote(id);
            const getPdfLote = await loteService.SendPdfLote();
            pdfBlob(getPdfLote);
            setLoadDiv(false);
        } catch (error:any) {
            console.log(error)
            setLoadDiv(false);
            toast.error('504 Consulte con Sistemas');
        }
        
    }
    const readUploadFile = (e: any) => {
        e.preventDefault();
        if (e.target.files) {
            const reader = new FileReader();
            reader.onload = (e: any) => {
                if (e) {
                    const data = e.target.result;
                    const workbook = read(data, { type: "array" });
                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];
                    const json = utils.sheet_to_json(worksheet);
                    console.log(json);
                    setImportLotes(json)
                }

            };
            reader.readAsArrayBuffer(e.target.files[0]);
        }
    }
    const submitImportLotes = async () => {
        setLoadImport(true)
        if (importLotes) {
            const resImportLotes = await loteService.importLotes(importLotes)
            console.log(resImportLotes.data)
            var contratosError = '';
            for (let e = 0; e < resImportLotes.data.length; e++) {
                const oneError = resImportLotes.data[e];
                contratosError = contratosError + ', ' + oneError.contrato;
            }
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: "Importacion completa",
                showConfirmButton: false,
                timer: 1000
            }).then(() => {
                if (resImportLotes.data.length > 0) {
                    Swal.fire({
                        position: 'center',
                        icon: 'warning',
                        title: "Contrato con errores, revisar duplicados, ruc/dni proveedor",
                        showConfirmButton: true,
                        text: contratosError
                        // timer: 1000
                    })  
                }
               
            })

        }
        setLoadImport(false)
    }
    // const App = () => (
    //     <PDFViewer>
    //       <LotePdf />
    //     </PDFViewer>
    //   );
    return (
        <div className={`${loadDiv?'loadDiv':''} `}>
            <div className="row text-center d-flex justify-content-center align-items-center  ">
                <div>
                    <h3>LOTES  <svg onClick={handleCreateLote} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                    </svg></h3>
                </div>
                <div className="row mx-auto col-12 justify-content-center">
                <div className="m-2">
                    <input type="file"
                        accept=".xlsx"
                        onChange={readUploadFile} />
                </div>
                <div className="m-1">
                    <button className="btn btn-outline-dark" disabled={loadImport} style={{ fontSize: "20px" }} onClick={submitImportLotes}>
                        
                        {loadImport
                        ? <>
                            <div className="spinner-border text-dark" role="status">
                            <span className="sr-only">Loading...</span>
                            </div>
                        </>
                        :
                        <>
                            <i className="fa-solid fa-file-arrow-up"></i>
                        </>}
                    </button>

                </div>
            </div>
            </div>
            <div className="row inputBusqueda align-items-end">
                <div className="form-group col-12 col-md-2">
                    <label htmlFor="fechaInicio">Siembra Fecha Inicio</label>
                    <input 
                    type="date" 
                    id="fechaIni" 
                    name="fechaIni" 
                    className="form-control"
                    max={Blotes.fechaFin}
                    value={Blotes.fechaIni}
                    onChange={handleSetBusqueda}
                    />
                </div>
                <div className="form-group col-12 col-md-2">
                    <label htmlFor="fechaFin">Siembra Fecha Fin</label>
                    <input 
                    type="date" 
                    id="fechaFin" 
                    name="fechaFin" 
                    className="form-control"
                    min={Blotes.fechaIni}
                    value={Blotes.fechaFin}
                    onChange={handleSetBusqueda}
                    />
                </div>
                <div className="form-group col-8 col-md-2 align-self-end">
                    <label htmlFor="estadoLote">Estado de Lote</label>
                    <select name="estadoLote"  onChange={handleSetBusqueda} id="estadoLote" className="form-control">
                        
                        <option value="">TODOS</option>   
                        <option value="HABILITADO">HABILITADO</option>  
                        <option value="COSECHA">COSECHA</option>                   
                        <option value="CERRADO">CERRADO</option>
                        <option value="ANULADO">ANULADO</option>
                    </select>
                </div>
                <div className="form-group col-8 col-md-2 align-self-end">
                    <label htmlFor="columna">Seleccione</label>
                    <select name="columna"  onChange={handleSetBusqueda} id="valorbusqueda" className="form-control">
                    <option value="">TODOS</option> 
                        <option value="numeroContrato">CONTRATO</option>
                        <option value="proveedor">AGRICULTOR</option>                    
                        <option value="user">TECNICO</option>
                    </select>
                </div>
                <div className="form-group col-8 col-md-3 align-self-end">
                    {/* <label htmlFor="textoColumna"></label> */}
                    <input 
                    
                    type="text" 
                    className="form-control" 
                    name="textoColumna" 
                    placeholder=""
                    value={Blotes.textoColumna}
                    onChange={handleSetBusqueda}
                    
                    />
                </div>
                <div className="form-group col-2 col-md-1 align-self-end text-center">
                    <button onClick={handleSubmitBusqueda} className="btn btn-outline-dark"><i className="fas fa-search schFormulario"></i></button>
                </div>
            </div>
            
            <div className="table-responsive  col-12 mx-auto  mb-5 bg-body rounded trheight tableFixHead">

                <table className="table shadow">
                    <thead>
                        <tr >
                            <th scope="col">NOMBRE LOTE</th>
                            <th scope="col">NUMERO CONTRATO</th>
                            <th scope="col">PROVEEDOR</th>
                            <th scope="col">COORDENADAS</th>
                            <th scope="col">LOCALIDAD</th>
                            <th scope="col">AREA_LOTE <strong className="totalesFormList">{totales?.areaLote} Hec</strong>  </th>
                            <th scope="col">ESTADO</th>
                            <th scope="col">TECNICO ENCARGADO</th>
                            <th scope="col">CAMPAÑA</th>
                            
                            <th scope="col">VARIEDAD</th>
                            <th scope="col">VIVERO</th>
                            <th scope="col">FECHA SIEMBRA_VIVERO</th>
                            <th scope="col">FECHA_DE_SIEMBRA</th>
                            <th scope="col">INICIO_COSECHA_TENT.</th>
                            <th scope="col">FIN_COSECHA_TEN.</th>
                            <th scope="col">CANTIDAD COSECHA</th>
                            <th scope="col">OPCIONES____</th>
                        </tr>
                    </thead>
                    <tbody>
                        {lotes.map((lote) => {   
                                                                                                                                
                            return <tr id={lote._id} key={lote._id} className="tabledb textDashTable" onClick={handleShowModalCosechas}>
                                <td>{lote.nombreLote}</td>
                                <td>{lote.numeroContrato}</td>
                                <td>{lote.proveedor.nombre}</td>
                                <td>{lote.coordenadas}</td>
                                <td>{lote.localidad.nombre}</td>
                                <td>{lote.areaLote}</td>
                                <td>{lote.estado}</td>
                                <td>{lote.user?.nombre}</td>
                                <td>{lote.campaña}</td>
                                
                                <td>{lote.variedad}</td>
                                <td>{lote.vivero}</td>
                                <td>{moment(lote.siembraVivero).format('YYYY-MM-DD')}</td>
                                <td>{moment(lote.fechaSiembra).format('YYYY-MM-DD')}</td>
                                <td>{moment(lote.inicioCosechaTen).format('YYYY-MM-DD')}</td>
                                <td>{moment(lote.finCosechaTen).format('YYYY-MM-DD')}</td>
                                <td>{lote.cantidadCosecha}</td>
                                    <td className="row">
                                    <div className="col-4">
                                    <i className="far fa-file-alt" onClick={() => lote._id && handlePrintPdf(lote._id)}></i>                                    
                                    </div>
                                    <div className="col-4">
                                    <i onClick={() => history(`/update-lote/${lote._id}`)} className="fas fa-pen"></i>
                                    </div>                                    
                                    <div className="col-4">
                                    <i id={lote._id} onClick={() => lote._id && handleDelete(lote._id)} className="fas fa-trash"></i>
                                    </div>                                                           
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
            
            <ModalDash show={show} onHide={handleCloseModalCosechas} />            
            
        </div>
    )
}


export default LoteList
