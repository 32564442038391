import styled from 'styled-components'

export const PruebaContariner = styled.div`
   
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  grid-auto-rows: 100px;

`

export const AlmacenContainer = styled.div`
    color: #fff;
    /* 
    background: #010606; */
    border: 1px solid #fff;
    display: grid;
    margin: auto;
    padding: 0px;
    width: 100%;
    height: 100vw;
    grid-gap: 10px;
    grid-template-areas: 
    "ZonaA"
    
    ;
    grid-template-columns: 100% ;
    & > * {
        background-color:#e6e6e6;
        color: #010625;
        display: flex;
        justify-content: center;
        align-items: center;
        border: solid 1px #010625;
    } 
   
   @media screen and (min-width: 500px){
       padding: 0px;
       padding: 0 40px 40px;
       grid-template-areas:
       "ZonaA Pasadizo1 ZonaCajas Pasadizo2 ZonaTapas ";
       grid-template-columns: 1fr 0.2fr 0.9fr 0.2fr 0.9fr;
       grid-template-rows: 2.8fr;
   }
`
export const AlmacenZonaRow = styled.div`
    display: grid;
    grid-auto-columns: minmax( 1fr);
    align-items: center;
 
    
`
// NOMBAR AREAS



export const  ZonaA = styled.div`
   
    grid-area: ZonaA;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-gap: 1px;
    grid-auto-rows: auto;      
   color: #e6e6e6;
`

export const TitleH1 = styled.h1`
    padding-top: 20px;
    margin: auto;
    color: #010101;
    text-align: center;
    text-shadow: 5px 10px 50px rgba(0,0,0, 0.4);
`
export const BtnBusqueda = styled.button`
    border-radius: 8px;
    border: 2px solid #125c03;
    background: #ffffff;
    padding: 0.6em 0.8em;
    margin: 10px;
    font-family: monospace;
    font-weight: bold;
    color: #757575;

   @media screen and (max-width: 750px){
       width: 100%;       
       margin: 10px;
   }

    &:focus{
        transform: scale(1.09);
        transition: ease-in-out;
    }
`

export const  Pasadizo1 = styled.div`
    grid-area: Pasadizo1;
    background-color:#ffffff;
    @media screen and (max-width: 768px){
       font-size: 10px
   }
`
export const  Pasadizo2 = styled.div`
    grid-area: Pasadizo2;
    background-color:#ffffff;
    @media screen and (max-width: 768px){
       font-size: 10px
   }
`
export const  ZonaCajas = styled.div`
    grid-area: ZonaCajas;
   
    @media screen and (max-width: 768px){
       font-size: 10px
   }
`
export const  ZonaTapas = styled.div`
    grid-area: ZonaTapas;
    
    @media screen and (max-width: 768px){
       font-size: 10px
   }
`