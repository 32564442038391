import React, { useState, useEffect } from 'react'

import * as almacenMPServices from '../AlmacenMP/AlmacenMPServices'

import { almacenes, 
    reporteUbicacionPallet, 
    formatos, 
    reporteFormVsProd, 
    reporteDetallePallets, 
    zonas,
    nivelPallet} from '../AlmacenMP/almacen'
import { toast } from 'react-toastify';
import IcoExcel from '../../../Media/svg/excel.svg'
import momentz from 'moment-timezone';
import saveAs from 'file-saver'
function UbicacionPallets() {
    let xFila = 0;
    let xCol = 0;
    const [listAlmacenes, setListAlmacenes] = useState<almacenes[]>([]);
    const [zonasAlmacen, setZonasAlmacen] = useState<zonas[]>([])
    const [reporte, setReporte] = useState<reporteUbicacionPallet[]>([])
    const [reporteFormVsProd, setReporteFormVsProd] = useState<reporteFormVsProd[]>([])
    const [reporteDetPallet, setReporteDetPallet] = useState<reporteDetallePallets[]>([]);
    const [reporteNivelPallet, setReporteNivelPallet] = useState<nivelPallet[]>([])
    const [filasBase, setFilasBase] = useState<any[]>([])
    const [listOptFormato, setListOptFormato] = useState<formatos[]>([])
    const [textBusFormato, setTextBusFormato] = useState('')
    const [dataGetReporte, setDataGetReporte] = useState({ idAlmacen: '61534152431a5c10acf19d9e', idZona:'', idFormato: '', likePallet:''})
    const [loadDiv, setLoadDiv] = useState(false)
    const [stateBtnSubmit, setStateBtnSubmit] = useState(false)
    const handleSetDataToSearch = async (e: any) => {
        setDataGetReporte({ ...dataGetReporte, [e.target.name]: e.target.value })
        if (e.target.name === 'idAlmacen') {
            const resZonas = await almacenMPServices.getZonasByAlmacen(e.target.value)
            setZonasAlmacen(resZonas.data);
        }
    }

    const handleChangetextBusFormato = (e: any) => {
        setTextBusFormato(e.target.value)
    }

    const loadAlmacenes = async () => {
        const resAlmacenes = await almacenMPServices.getAllAlmacenesPlanta();
        const resZonas = await almacenMPServices.getZonasByAlmacen('61534152431a5c10acf19d9e')
        setZonasAlmacen(resZonas.data);
        setListAlmacenes(resAlmacenes.data);
    }
    
    const loadDetallePallets = async () =>{
        setStateBtnSubmit(true)
        setReporteDetPallet([]);
        const resReporte = await almacenMPServices.getReporteDetallePallets(dataGetReporte)
        if (resReporte.data.length !<0){
            setStateBtnSubmit(false)
            return toast.error("Consulta sin registros")
        } 
        setReporteDetPallet(resReporte.data)
        setStateBtnSubmit(false)
    }
    const getExcelDetPallet = async () =>{
        try {
            if (reporteDetPallet.length===0) return toast.error("Reporte sin datos")
            await almacenMPServices.createExcelDetallePallets(reporteDetPallet)
            const resExcel = await almacenMPServices.getReportePallet('ReporteDetallePallets.xlsx');
            pdfBlob(resExcel, 'ReporteDetallePallets.xlsx')  
        } catch (error:any) {
               toast.error(error.message)
        }
    }

    const getReportePallets = async () => {
        try {   
            setStateBtnSubmit(true)
            setReporte([])
            const resReporte = await almacenMPServices.reporteUbicacionPallets(dataGetReporte);
            // console.log(resReporte.data)
            if (resReporte.data.length !<0) {
                setStateBtnSubmit(false)
                return toast.info("Resultado sin Datos")
            }            
            setReporte(resReporte.data)
            setStateBtnSubmit(false)
        } catch (error) {
            setStateBtnSubmit(false)
            toast.error("Inicie Sesion Nuevamente")
        }           
    }
    const busquedaFormato = async () => {
        const resFormatos = await almacenMPServices.busquedaFormato(textBusFormato);
        if (!resFormatos.data[0].codigo) return toast.error("Error de Busqueda")
        setListOptFormato(resFormatos.data)
    }
    const loadFormatoVsProd = async () => {
        try {
            setStateBtnSubmit(true)
            setReporteFormVsProd([])
            const resReporteFormVsProd = await almacenMPServices.reporteFormatoVsProd(dataGetReporte)
            if (resReporteFormVsProd.data.length !<0) {
                setStateBtnSubmit(false)
                return toast.info("Resultado sin Datos")
            } 
            setReporteFormVsProd(resReporteFormVsProd.data)
            setStateBtnSubmit(false)
        } catch (error) {
            setStateBtnSubmit(false)
            toast.error("Inicie Sesion Nuevamente")
        }        
    }
    const pdfBlob = async(res:any, nameFile:string)=>{
        const pdfBlob= await new Blob([res.data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"})
        saveAs(pdfBlob, nameFile)
       }
    // const downloadExcelUbicacionPallet = async () => {        
    //     if (reporte.length===0) return toast.error("Reporte sin datos")
    //     const resDownload = await almacenMPServices.getExcelEstadoPallets(reporte)
    //     const url = await (new Blob([resDownload.data]));
    //     saveAs(url, 'ReporteEstadoPallets.xlsx')
    // }
    const getReporteEstadoPallets = async () => {        
       try {
        if (reporte.length===0) return toast.error("Reporte sin datos")
        await almacenMPServices.createExcelEstadoPallets(reporte)
        const resExcel = await almacenMPServices.getReportePallet('ReporteEstadoPallets.xlsx');
        pdfBlob(resExcel, 'ReporteEstadoPallets.xlsx')  
       } catch (error:any) {
           toast.error(error.message)
       }
    }
    const downloadExcelFormProPallet = async () =>{
        try {
            if (reporteFormVsProd.length===0) return toast.error("Reporte sin datos")
            await almacenMPServices.createExcelFormatoProduccionPallets(reporteFormVsProd)
            const resExcel = await almacenMPServices.getReportePallet('ReporteFormProdPallets.xlsx');
            pdfBlob(resExcel, 'ReporteFormProdPallets.xlsx')  
           } catch (error:any) {
               toast.error(error.message)
           }
    }
    const submitDetNivelPallet = async() =>{
        try {
            // console.log(dataGetReporte)
            const resNivelesPallet = await almacenMPServices.getRepNivelPallet(dataGetReporte);
            const resNivelPallet = resNivelesPallet.data.resNivelPallet;
            const formatos = resNivelesPallet.data.resFormatos;
            //console.log(resNivelPallet);
            const tablas = [];
            const filas = [];
            let niveles = [];
            let anterioreMatrisRecontruida =''
            for (let i = 0; i < resNivelPallet.length; i++) {
                //console.log("Imprimiendo:" + i + ":::::***** " );
                const oneBase = resNivelPallet[i];
                // console.log(oneBase)              
                    let findNivel1= oneBase.pallets.filter((pallet:any) => pallet.nivel === 1) 
                    let arrayNivel1 = [];

                    for (let f = 0; f < findNivel1.length; f++) {
                        let oneNivel1 = findNivel1[f];
                        const findFormato = formatos.find((formato:any)=> formato._id === oneNivel1.formato)  
                        // console.log(findFormato, "FORMATO ENCONTRADOR")  
                        oneNivel1.form =  findFormato.descripcion;
                        arrayNivel1.push(oneNivel1)
                    }
                                                        
                    let findNivel2= oneBase.pallets.filter((pallet:any) => pallet.nivel === 2)
                    let arrayNivel2 = [];
                    for (let f = 0; f < findNivel2.length; f++) {
                        let oneNivel2 = findNivel2[f];
                        const findFormato = formatos.find((formato:any)=> formato._id === oneNivel2.formato)  
                        // console.log(findFormato, "FORMATO ENCONTRADOR")  
                        oneNivel2.form =  findFormato.descripcion;
                        arrayNivel2.push(oneNivel2)
                    }
                    let findNivel3= oneBase.pallets.filter((pallet:any) => pallet.nivel >= 3)
                    let arrayNivel3 = [];
                    for (let f = 0; f < findNivel3.length; f++) {
                        let oneNivel3 = findNivel3[f];
                        const findFormato = formatos.find((formato:any)=> formato._id === oneNivel3.formato)  
                        // console.log(findFormato, "FORMATO ENCONTRADOR")  
                        oneNivel3.form =  findFormato.descripcion;
                        arrayNivel3.push(oneNivel3)
                    }

                    const nivelToPush = {
                        nivel1: arrayNivel1,
                        nivel2: arrayNivel2,
                        nivel3: arrayNivel3
                    }
                    
                const splitMatris = oneBase.base.matris.split('');
                const indexF = splitMatris.findIndex((matris: any) => matris === 'F')
                const indexC = splitMatris.findIndex((matris: any) => matris === 'C')
                var reconstruirMatris='';
                for (let m = 0; m < indexC; m++) {
                    const oneLetraMatris = splitMatris[m];
                    reconstruirMatris = reconstruirMatris + oneLetraMatris
                }
                if (anterioreMatrisRecontruida !== reconstruirMatris && i>1) {
                    tablas.push(niveles)
                    niveles=[]
                }
                niveles.push(nivelToPush)
                anterioreMatrisRecontruida = reconstruirMatris
                // console.log(reconstruirMatris, "MATRIS RECONSTRUIDA")
                              
            }   
            // console.log(tablas) 
            tablas.push(niveles)
            setFilasBase(tablas)                 
            setReporteNivelPallet(resNivelPallet)
        } catch (error:any) {
            toast.error(error)
        }
    }

    useEffect(() => {
        loadAlmacenes();
    }, [])
    return (
        <>
            <div className={`${loadDiv ? 'loadDiv' : ''} `}>
                <div className='row align-items-end mx-auto justify-content-center'>
                    <div className='col-12 col-sm-12 col-md-2 m-2'>
                        <label htmlFor="idAlmacen">Almacen</label>
                        <select className='form-control form-control-lg' id='idAlmacen' name='idAlmacen' onChange={handleSetDataToSearch}>
                            <option value="61534152431a5c10acf19d9e">ALSURPERU PLANTA</option>
                            {listAlmacenes.map((almacenes) => {
                                return (
                                    <option value={almacenes._id}>{almacenes.nombre}</option>
                                )
                            })}
                        </select>
                    </div>
                    
                    <div className='row col-12 col-sm-12 col-md-auto m-2'>                        
                        <div>                        
                        <input className="form-control-lg" type="text"
                            onChange={handleChangetextBusFormato}
                            placeholder='Ejm: Blanca' />
                        </div>
                        <div>
                        <span className="btn btn-outline-dark btn-lg" onClick={busquedaFormato}>Buscar Formato</span>
                        </div>                            
                    </div>
                    {/* <div className='col-12 col-sm-12 col-md-auto m-2'>
                        
                    </div> */}
                    <div className='col-12 col-sm-12 col-md-2 m-2'>
                        <label htmlFor="idFormato">Formato</label>
                        <select className='form-control form-control-lg' name='idFormato'
                            onChange={handleSetDataToSearch}>
                            <option value="">Todos</option>
                            {listOptFormato.map((formatos) => {
                                return (
                                    <option value={formatos._id}>{formatos.descripcion} - {formatos.codigo}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className='col-12 col-sm-12 col-md-2 m-2'>
                        <label htmlFor="likePallet">Pallet Contiene</label>
                        <input className="form-control-lg" type="text" name='likePallet'
                            value={dataGetReporte.likePallet}
                            onChange={handleSetDataToSearch} />
                    </div>
                    
                </div>
                {/*  */}
                <div className='text-center'>
                    <h1>DETALLE PALLETS</h1>
                </div>
                <div className='text-center'>
                    <div className='col-12 col-sm-12 col-md-2 m-2 mx-auto'>
                        <button className='btn btn-dark btn-lg btn-block' disabled={stateBtnSubmit} onClick={loadDetallePallets}>
                        {stateBtnSubmit 
                        ?   <div className="spinner-border text-ligth" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        : 'Obtener' }                        
                        </button>
                    </div>
                    <div className='col-12 col-sm-12 col-md-2 m-2 mx-auto text-center' >
                        <img src={IcoExcel} alt="" onClick={getExcelDetPallet} className="iconExcel" />
                    </div>
                </div>
                <div>
                    <div className="table-responsive table-striped col-12 col-md-12 mx-auto shadow mb-5 bg-body rounded trheight tableFixHead">
                        <table className="table">
                            <thead>
                                <tr className=''>
                                    <th scope="col">PALLET</th>
                                    <th scope="col">F.PROD.</th>
                                    <th scope="col">JULIANO</th>
                                    <th scope="col">BATCH</th>
                                    <th scope="col">SUBPROCESO</th>
                                    <th scope="col">NIVELES</th>
                                    <th scope="col">SALDO</th>
                                    <th scope="col">PROD. DIA</th>
                                    <th scope="col">FORMATO</th>
                                    <th scope="col">EST</th>
                                    <th scope="col">PLANTA</th>
                                    <th scope="col">RX</th>
                                    <th scope="col">CONTADOR</th>
                                    <th scope="col">OBSERVACIONES</th>
                                    <th scope="col">ACCIONES CORRECTIVAS</th>
                                </tr>
                            </thead>
                            <tbody>
                                {reporteDetPallet.map((detallePallet, index) => {
                                    return <tr key={index}>
                                        <td>{detallePallet.numPallet}</td>
                                        <td>{momentz.tz(detallePallet.detpallet.fechaProduccion, "America/Lima").format("DD-MM-YYYY")}</td>
                                        <td>{detallePallet.detpallet.codigoJuliano}</td>
                                        <td>{detallePallet.detpallet.batch}</td>
                                        <td>{detallePallet.detpallet.subproceso}</td>
                                        <td>{detallePallet.detpallet.niveles}</td>
                                        <td>{detallePallet.detpallet.saldo}</td>
                                        <td>{detallePallet.detpallet.prodDia}</td>
                                        
                                        <td>{detallePallet.formato.descripcion}</td>
                                        <td>{detallePallet.estadopallet}</td>
                                        <td>{detallePallet.planta}</td>
                                        <td>{detallePallet.rx}</td>
                                        <td>{detallePallet.contador}</td>
                                        <td>{detallePallet.observaciones}</td>
                                        <td>{detallePallet.accionesCorrectivas}</td>
                                    </tr>
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                {/*  */}
                <div className='text-center'>
                    <h1>UBICACION PALLETS</h1>
                </div>             
                    <div className='col-12 col-sm-12 col-md-2 m-2 mx-auto'>
                        <button className='btn btn-dark btn-lg btn-block' disabled={stateBtnSubmit} onClick={getReportePallets}>
                        {stateBtnSubmit 
                        ?   <div className="spinner-border text-ligth" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        : 'Obtener' }
                        </button>
                    </div>
                    <div className='col-12 col-sm-12 col-md-2 m-2 mx-auto text-center' >
                        <img src={IcoExcel} alt="" onClick={getReporteEstadoPallets} className="iconExcel" />                        
                    </div>            
                <div>
                    <div className="table-responsive table-striped col-12 mx-auto shadow mb-5 bg-body rounded trheight tableFixHead">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">ESTADO</th>
                                    <th scope="col">ALMACEN</th>
                                    <th scope="col">ZONA</th>
                                    <th scope="col">MATRIZ</th>
                                    <th scope="col">CODIGO PALLET</th>
                                    <th scope="col">PRODUCCION</th>
                                    <th scope="col">FORMATO</th>
                                    <th scope="col">ESTADO PALLET</th>
                                    <th scope="col">PLANTA</th>
                                    <th scope="col">RX</th>
                                    <th scope="col">OBSERVACIONES</th>
                                </tr>
                            </thead>
                            <tbody>
                                {reporte.map((reporte) => {
                                    return <tr key={reporte._id}>
                                        <td>{reporte?.estado}</td>
                                        <td>{reporte.almacen?.nombre}</td>
                                        <td>{reporte.zona?.nombre}</td>
                                        <td>{reporte.base?.matris}</td>
                                        <td>{reporte.numPallet}</td>
                                        <td className="h5">{reporte.totalprod}</td>
                                        <td>{reporte.formato.descripcion}</td>
                                        <td>{reporte.estadopallet}</td>
                                        <td>{reporte.planta}</td>
                                        <td>{reporte.rx}</td>
                                        <td>{reporte.observaciones}</td>
                                    </tr>
                                })
                                }
                            </tbody>
                        </table>

                    </div>
                </div>
                
                <div className='text-center'>
                    <h1>FORMATO VS PRODUCCION</h1>
                </div>
                <div className='text-center'>
                    <div className='col-12 col-sm-12 col-md-2 m-2 mx-auto'>
                        <button className='btn btn-dark btn-lg btn-block' disabled={stateBtnSubmit} onClick={loadFormatoVsProd}>
                        {stateBtnSubmit 
                        ?   <div className="spinner-border text-ligth" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        : 'Obtener' }
                        </button>
                    </div>
                    <div className='col-12 col-sm-12 col-md-2 m-2 mx-auto text-center' >
                        <img src={IcoExcel} alt="" onClick={downloadExcelFormProPallet} className="iconExcel" />
                        
                    </div>
                </div>
                <div>
                    <div className="table-responsive table-striped col-12 col-md-8 mx-auto shadow mb-5 bg-body rounded trheight tableFixHead">
                        <table className="table">
                            <thead>
                                <tr className=''>
                                    <th scope="col">CODIGO FORMATO</th>
                                    <th scope="col">DESCRIPCION</th>
                                    <th scope="col">PRODUCCION TOTAL</th>

                                </tr>
                            </thead>
                            <tbody>
                                {reporteFormVsProd.map((reporteFP) => {
                                    return <tr key={reporteFP.form._id}>
                                        <td>{reporteFP.form.codigo}</td>
                                        <td>{reporteFP.form.descripcion}</td>
                                        <td className="h5">{reporteFP.totalProd}</td>
                                    </tr>
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </>
    )
}

export default UbicacionPallets
