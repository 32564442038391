import React, { useState, useContext } from 'react'
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';

// import useStyles from "./styles";
import { Link } from 'react-router-dom'
import authContext from '../../context/AuthContext';
import LogOutBtn from '../Login/LogOutBtn';
import * as usuarioService from '../Usuarios/UsuarioService'
import ModalBox from '../Planta/ModalBox/ModalEscanQR'
import * as almacenesServices from '../Planta/AlmacenMP/AlmacenMPServices'
import { useNavigate } from 'react-router-dom'
import './Navbar.css';
import logo from './logo.png'; // Ruta de tu logo


const NavBar = () => {
  
  const { loggedIn } = useContext(authContext);
  // LOGOUT
  const history = useNavigate();
  const { getLoggedIn } = useContext(authContext)

  const [modalBox, setmodalBox] = useState({ estadoModal: false, base: [] })
  const [flagState, setFlagState] = useState(false)
  const handleShowModal = (base: any) => {  
    console.log("Intentando abrir modal");
    setmodalBox({ ...modalBox, estadoModal: true, base: base });
  }
  const changeFlagState = () =>{
      setFlagState(true)
  }
  const handleCloseModal = () => {
      if (flagState) {
          selectBases(); 
      }             
      setmodalBox({ ...modalBox, estadoModal: false, base: [] })
  };

  const selectBases = async () => {
    const idAlmacen = '61534152431a5c10acf19d9e';
    const resBases = await almacenesServices.getBases(idAlmacen);
    //console.log(resBases.data)
    //setBases(resBases.data)        
}

  async function logOut() {
    await usuarioService.logoutUsuario();
    await getLoggedIn();
    history("/login");
  }
  async function clicUrl(e: any) {  
    e.preventDefault();
    
    if (!loggedIn.statusLogin) {
      console.log("ENTRO AL  PUCH FALSE")
      await getLoggedIn();
      history("/login");
    } else {
      console.log("ENTRO AL  PUsh ELSE")
      history("/dashboard");
    }
  }

  return (
    <div >
      <Navbar collapseOnSelect expand="lg" variant="dark" fixed="top" className="custom-navbar" >
        
        <Container>
          <Navbar.Brand ><img
          src={logo}
          alt="Alsur Peru"
          width="70"
          height="42"
          className="d-inline-block align-top"
        /></Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            {loggedIn.statusLogin === true && (
              <>
                <Nav className="ml-auto">


                  <Link to="/dashboard"> <Nav.Link href="#/dashboard" >| INICIO | </Nav.Link></Link>
                  {(loggedIn.type === 'ADMINISTRADOR' || loggedIn.type === 'EVALUADOR' || loggedIn.type === 'PROYECTISTA'|| loggedIn.type === 'JEFECAMPO') &&
                    <>
                      <NavDropdown title="CAMPO" id="collasible-nav-dropdown">
                        <div className="h5 pt-2 pl-2">Cosechas</div>
                        <Link to="/lotes"><NavDropdown.Item href="#/lotes">Lotes </NavDropdown.Item></Link>
                        <Link to="/cogidas"><NavDropdown.Item href="#/cogidas">Cosechas </NavDropdown.Item></Link>
                        <Link to="/proveedor"><NavDropdown.Item href="#/proveedor">Proveedores </NavDropdown.Item></Link>
                        <div className="h5 pt-2 pl-2">Proyecciones</div>
                        <Link to="/proyecciones"><NavDropdown.Item href="#/proyecciones">Proyeccion por Agricultor </NavDropdown.Item></Link>
                        {/* <Link to="/proyeccionesResumenLotes"><NavDropdown.Item href="#/proyeccionesResumenLotes">Proyeccion Resumen por Lotes </NavDropdown.Item></Link> */}
                        <Link to="/reporteProyeccion"><NavDropdown.Item href="#/reporteProyeccion">Reporte Proyeccion </NavDropdown.Item></Link>
                        <div className="h5 pt-2 pl-2">Pagos</div>
                        <Link to="/pagosTransportista"><NavDropdown.Item href="#/pagosTransportista">Pagos Transportistas</NavDropdown.Item></Link>
                        <Link to="/pagosAgricultores"><NavDropdown.Item href="#/pagosAgricultores">Pagos Agricultores</NavDropdown.Item></Link>
                      </NavDropdown>

                    </>
                  }



                  {(loggedIn.type === 'ADMINISTRADOR' || loggedIn.type === 'PLANTA') &&
                    <>
                      <NavDropdown title="| APT PLANTA | " id="collasible-nav-dropdown">
                        <Link to="/almacenMPIndex"><NavDropdown.Item href="#/almacenMPIndex">Almacen MP </NavDropdown.Item></Link>
                        <Link to="/salidaPallet"><NavDropdown.Item href="#/salidaPallet">Salida Pallets </NavDropdown.Item></Link>
                        <Link to="/AdministrarAPT"><NavDropdown.Item href="#/AdministrarAPT">Administrar</NavDropdown.Item></Link>
                        <NavDropdown.Divider />
                        <Link to="/parteDiario"><NavDropdown.Item href="#/parteDiario">Parte Diario de Producción </NavDropdown.Item></Link>
                        <Link to="/detalleNivel"><NavDropdown.Item href="#/detalleNivel">Detalle Nivel Pallets </NavDropdown.Item></Link>
                        <Link to="/ubicacionPallets"><NavDropdown.Item href="#/ubicacionPallets">Reportes </NavDropdown.Item></Link>
                        <NavDropdown.Divider />
                        <Link to="/pruebas"><NavDropdown.Item href="#/pruebas">Pruebas </NavDropdown.Item></Link>
                      </NavDropdown>
                    </>
                  }
                  {(loggedIn.type === 'ADMINISTRADOR' || loggedIn.type ==='CALIDADPRODUCCION') &&
                    <>
                      <NavDropdown title="| CALIDAD-PROD | " id="collasible-nav-dropdown">
                        {/* <Link to="/inputsCalidadProd"><NavDropdown.Item href="#/inputsCalidadProd">Permiso de Usuarios</NavDropdown.Item></Link> */}
                        <Link to="/ResumenInputs"><NavDropdown.Item href="#/ResumenInputs">Reporte General</NavDropdown.Item></Link>
                        <Link to="/inputsCalidadProd"><NavDropdown.Item href="#/inputsCalidadProd">Registro Input</NavDropdown.Item></Link>
                        <Link to="/InputRegMas"><NavDropdown.Item href="#/InputRegMas">Registro Input Masivo</NavDropdown.Item></Link>
                        <Link to="/RegistroObjetivos"><NavDropdown.Item href="#/RegistroObjetivos">Registro Objetivos</NavDropdown.Item></Link>
                        <Link to="/ParametrosInputs"><NavDropdown.Item href="#/ParametrosInput">Parametros</NavDropdown.Item></Link>
                        <Link to="/Permisos"><NavDropdown.Item href="#/Permisos">Permisos</NavDropdown.Item></Link>                                                                        
                      </NavDropdown>
                    </>
                  }
                  {(loggedIn.type === 'ADMINISTRADOR' || loggedIn.type === 'PLANTAQQQQQQQQ') &&
                    <>
                      <NavDropdown title="| ALMACENES | " id="collasible-nav-dropdown">
                        <Link to="/almacenesIndex"><NavDropdown.Item href="#/almacenesIndex">Almacenes </NavDropdown.Item></Link>
                        <Link to="/IndexReportesAlm"><NavDropdown.Item href="#/IndexReportesAlm">Reportes </NavDropdown.Item></Link>
                        <Link to="/KardexAlmacen"><NavDropdown.Item href="#/KardexAlmacen">Kardex </NavDropdown.Item></Link>
                        <Link to="/TrazabilidadAlmacen"><NavDropdown.Item href="#/TrazabilidadAlmacen">Trazabilidad </NavDropdown.Item></Link>
                         
                      </NavDropdown>

                    </>
                  }

                  {loggedIn.type === 'ADMINISTRADOR' && (
                    <>
                      <NavDropdown title="| ADMINISTRACION | " id="collasible-nav-dropdown">
                        <Link to="/usuarios"><NavDropdown.Item href="#/usuarios">Usuarios</NavDropdown.Item></Link>
                        <Link to="/departamentos"><NavDropdown.Item href="#/departamentos">Departamentos</NavDropdown.Item></Link>
                        <Link to="/localidad"><NavDropdown.Item href="#/localidad">Localidades</NavDropdown.Item></Link>
                        
                        <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                      </NavDropdown>
                    </>
                  )}
                  

                </Nav>
                <Nav>
                  <Link to="/login"><Nav.Link href="#"  onClick={() => { handleShowModal('') }}>| ESCANEAR QR | </Nav.Link></Link>
                </Nav>
                <Nav>
                  <Link to="/login"><Nav.Link href="#/login" onClick={logOut}>| CERRAR SESIÓN | </Nav.Link></Link>
                </Nav>
              </>
            )}
            {loggedIn.statusLogin === false && (
              <>
                <Nav className="ml-auto">
                  <Link to="/login"><Nav.Link href="#/login" onClick={logOut}>| INICIAR SESIÓN | </Nav.Link></Link>
                </Nav>
              </>
            )}

          </Navbar.Collapse>
        </Container>
      </Navbar>
      <ModalBox base={modalBox.base} show={modalBox.estadoModal} onHide={handleCloseModal} checkFlagState={changeFlagState} />

    </div>
  )
}

export default NavBar;

