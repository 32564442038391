import React, { useState, useEffect } from 'react'
import {
    MainContainerSet,
    MainContainerSet2,
    DivQRTitulo,
    DivQRPallet,
    DivQRDetalle,
    DivQRFechas,
    DivQRFechasTitulo,
    DivQRCodigoFormato,
    DivQRTotal,
    DivQRPalletSmall

} from '../../../stylesGeneral/mainElements'
import QRCode from "react-qr-code"
import moment from 'moment';
import * as almacenMPServices from '../AlmacenMP/AlmacenMPServices'
import './dibujar.css';
import { Paletizado } from '../AlmacenMP/AlmacenMPElements';
interface DetallesPallet {
    _id: string;
    fechaProduccion: string;
    codigoJuliano: string;
    prodDia: number;
    batch: string;
    niveles: string;
    prodAcumulada: number;
    // ... otros campos que puedas recibir de la API
}

interface CabPallet {
    _id: string;
    planta: string;
    numPallet: string;
}




function DibujarQr() {
    const [codigoPallet, setPallet] = useState(window.location.href)
    const [codigoFormato, setFormato] = useState(window.location.href)   
    const [size, setSize] = useState('')   
    const [plataforma, setPlataforma] = useState('')   
    const divStyles = {
        width:'100%',
        display: 'flex',
        'flex-wrap': 'wrap',
        'justify-content':'space-between'
      }; 

    const divStylesItem = {
        flex: '0 0 48%',
        'margin-bottom': '0px',
        'padding': '0px',
        'box-sizing': 'border-box'
      };

    

    const [miQr, setMiQr] = useState(window.location.href)  

    const getImprimir = () => {
        window.print();
        return ''
    }

    const getValorQr = () => {
        let inicio = miQr.indexOf('url:');
        let datos = miQr.substr((inicio+4),200);
        let partes = datos.split('|||');
        let p1 = partes[0];        //actualizarFormato(partes[1])        
        return datos
    }       

    const getPallet = () => {        
        let inicio = miQr.indexOf('url:');
        let datos = miQr.substr((inicio+4),200);
        let partes = datos.split('|||');
        let pallet = partes[0]
        let valor = pallet.split('|')
        let tmp = valor[0];
        return tmp.substring(0,8);
    }

    const getFormato = () => {        
        let inicio = miQr.indexOf('url:');
        let datos = miQr.substr((inicio+4),200);
        let partes = datos.split('|||');
        let pallet = partes[0]
        let valor = pallet.split('|')
        return valor[1]
    }
 
    const [datos, setDatos] = useState(null); // Estado para almacenar los datos de MongoDB
    const [detallesPallet, setDetallesPallet] = useState<DetallesPallet[]>([]);
    const [cabPallet, setCabPallet] = useState<CabPallet[]>([]);
    const [nombre, setNombre] = useState(null); 
    const [total, setTotal] = useState(0); 
    const [formatop, setFormatoP] = useState(null); 
    const [conteo, setConteo] = useState(""); 
    const [tipop, setTipoP] = useState(null); 
    const [calidad, setCalidad] = useState(null); 
    const [cultivo, setCultivo] = useState(null); 


    useEffect(() => {
       
         // Limpia los estilos cuando el componente se desmonta
    
        // Función para cargar los datos desde MongoDB
        const cargarDatos = async () => {
          try {
            let inicio = miQr.indexOf('url:');
            let datos = miQr.substr((inicio+4),200);
            let partes = datos.split('|||');
            let pallet = partes[0];
            let valor = pallet.split('|');
            const codigo = valor[1]; // Puedes obtener el código de alguna manera (por ejemplo, desde una prop)
            const response = await almacenMPServices.busquedaFormatoPorCodigo(codigo);
            setDatos(response.data); // Actualiza el estado con los datos obtenidos
            
            let codigoPallet = valor[0];
            setTotal(parseInt(valor[2]));
            const cabecera = await(almacenMPServices).getPalletsByNumPallet(codigoPallet);
            const detalles = await almacenMPServices.getDetPalletsByNumPallet(codigoPallet);
            /*Definir estilo a usar dependiendo de items */
            const isAndroid = /Android/.test(navigator.userAgent);

            if (isAndroid) {
                console.log('El usuario está utilizando Android.');
                setPlataforma('-A')
              // Realiza acciones específicas para Android
            } else {
                setPlataforma('')
                console.log('El usuario no está utilizando Android.');
              // Realiza acciones específicas para otros sistemas operativos
            }

            const cabPallet = response.data[0];
            let nombrePlanta = cabPallet.planta;
            let prefijo = "";
            if(nombrePlanta!="AREQUIPA"){
                prefijo = "-C";
            }
            if(detalles.data.length<=1){
                setSize( '-1');
            }
            else if(detalles.data.length<=2){
                setSize('-2');
            }
            else if(detalles.data.length<=4){
                setSize( '-4');
            }
            else if(detalles.data.length<=6){
                setSize( '-6');
            }
            else if(detalles.data.length<=8){
                setSize( '-8');
            }
            else if(detalles.data.length<=10)
            {
                setSize( '-10');
            }
            else if(detalles.data.length>=11)
            {
                setSize( '-11');
            }
            else{
                setSize( '');
            }
            /*Fin definicion de estilos */
            setCabPallet(cabecera.data);
            setDetallesPallet(detalles.data);
            if (response.data && response.data.length > 0) {
                const primerItem = response.data[0];
                setNombre(primerItem.descripcion.substr(0,55)); // Actualiza el estado con el valor del campo 'nombre'
                setFormatoP(primerItem.formatop);
                setCultivo(primerItem.cultivo);
                setCalidad(primerItem.calidad);
                setTipoP(primerItem.tipop);
                setConteo(primerItem.conteo);
              }
          } catch (error) {
            console.error('Error al cargar los datos desde MongoDB:', error);
          }
        };
    
        // Llama a la función para cargar los datos cuando se monta el componente
        cargarDatos();
       
      
      }, []); 
    

    return (         
        <div className={'rotated-view'+plataforma+size}>
            <div className='compact-view'>
            <MainContainerSet2 display="" width="" alignItems="center" flexWrap="" justifyContent="center"  >           
                <MainContainerSet2 display="" width="100%" alignItems="flex-start" flexWrap="" justifyContent="center">
                    <MainContainerSet display="" width="75%" alignItems="center" flexWrap="" justifyContent="center">
                    <DivQRTitulo>
                        TARJETA DE PALETIZADO-PROCESO
                    </DivQRTitulo>
                    </MainContainerSet>
                    <MainContainerSet display="" width="25%" alignItems="center" flexWrap="" justifyContent="center">
                    <DivQRTitulo>RE-APT-003</DivQRTitulo>
                    </MainContainerSet>
                     
                        {getPallet().length > 6 ? <p className="p">
                            <DivQRPalletSmall>{getPallet()} </DivQRPalletSmall>  
                        </p> : <p className="p">
                            <DivQRPallet>{getPallet()}</DivQRPallet>  
                        </p>
                        }
                </MainContainerSet2>
                <MainContainerSet display="" width="25%" alignItems="center" flexWrap="" justifyContent="center">
                    <QRCode value={"" + getPallet() + "|" + getFormato()} size={128} style={{ height: "auto", maxWidth: "250px", width: "250px"}}/>
                </MainContainerSet>  
                <MainContainerSet display="" width="75%" alignItems="center" flexWrap="" justifyContent="center">
                    <DivQRCodigoFormato>
                        CODIGO: {getFormato()}  
                    </DivQRCodigoFormato>
                    <DivQRDetalle>
                        {nombre}
                    </DivQRDetalle>
                    <MainContainerSet display="" width="100%" alignItems="flex-start" flexWrap="" justifyContent="center">
                        <MainContainerSet display="" width="16%" alignItems="center" flexWrap="" justifyContent="center">
                            <DivQRDetalle>
                                CONTEO:  
                            </DivQRDetalle>
                        </MainContainerSet>    
                        <MainContainerSet display="" width="38%" alignItems="center" flexWrap="" justifyContent="center">
                            <DivQRDetalle>
                            {conteo}
                            </DivQRDetalle>
                        </MainContainerSet>    
                        <MainContainerSet display="" width="19%" alignItems="center" flexWrap="" justifyContent="center">
                            <DivQRDetalle>
                                FORMATO:     
                            </DivQRDetalle>
                        </MainContainerSet>    
                        <MainContainerSet display="" width="27%" alignItems="center" flexWrap="" justifyContent="center">
                            <DivQRDetalle>
                            {formatop}
                            </DivQRDetalle>
                        </MainContainerSet>    
                    </MainContainerSet>

                    <MainContainerSet display="" width="100%" alignItems="flex-start" flexWrap="" justifyContent="center">
                        <MainContainerSet display="" width="65%" alignItems="center" flexWrap="" justifyContent="center">
                            <DivQRDetalle>CALIDAD: </DivQRDetalle>
                        </MainContainerSet>
                        <MainContainerSet display="" width="35%" alignItems="center" flexWrap="" justifyContent="center">
                            <DivQRDetalle>{calidad} </DivQRDetalle>
                        </MainContainerSet>
                    </MainContainerSet>
                    <MainContainerSet display="" width="100%" alignItems="flex-start" flexWrap="" justifyContent="center">
                        <MainContainerSet display="" width="65%" alignItems="center" flexWrap="" justifyContent="center">
                            <DivQRDetalle>T.PRODUCTO: {tipop}</DivQRDetalle>
                        </MainContainerSet>
                        <MainContainerSet display="" width="35%" alignItems="center" flexWrap="" justifyContent="center">
                            <DivQRDetalle>{cultivo} </DivQRDetalle>
                        </MainContainerSet>
                    </MainContainerSet>

                </MainContainerSet>  

                <MainContainerSet2 display="" width="100%" alignItems="flex-start" flexWrap="" justifyContent="center">
                    <div style={divStyles}>
                    <div style={divStylesItem}>
                    <MainContainerSet display="" width="100%" alignItems="flex-start" flexWrap="" justifyContent="center">
                        <MainContainerSet display="" width="25%" alignItems="center" flexWrap="" justifyContent="center">
                            <DivQRFechasTitulo>F.PROD. </DivQRFechasTitulo>
                        </MainContainerSet>
                        <MainContainerSet display="" width="18%" alignItems="center" flexWrap="" justifyContent="center">
                            <DivQRFechasTitulo>JULIANO </DivQRFechasTitulo>
                        </MainContainerSet>                    
                        <MainContainerSet display="" width="16%" alignItems="center" flexWrap="" justifyContent="center">
                            <DivQRFechasTitulo>BATCH </DivQRFechasTitulo>
                        </MainContainerSet>  
                        <MainContainerSet display="" width="10%" alignItems="center" flexWrap="" justifyContent="center">
                            <DivQRFechasTitulo>NV.</DivQRFechasTitulo>
                        </MainContainerSet>                    
                        <MainContainerSet display="" width="15%" alignItems="center" flexWrap="" justifyContent="center">
                            <DivQRFechasTitulo>CANT.</DivQRFechasTitulo>
                        </MainContainerSet>
                        <MainContainerSet display="" width="16%" alignItems="center" flexWrap="" justifyContent="center">
                            <DivQRFechasTitulo>P.ACU. </DivQRFechasTitulo>
                        </MainContainerSet>
                    </MainContainerSet> 
                    </div>
                    <div style={divStylesItem}>
                    <MainContainerSet display="" width="100%" alignItems="flex-start" flexWrap="" justifyContent="center">
                        <MainContainerSet display="" width="25%" alignItems="center" flexWrap="" justifyContent="center">
                            <DivQRFechasTitulo>&nbsp;&nbsp;FEC.PROD.&nbsp;&nbsp;</DivQRFechasTitulo>
                        </MainContainerSet>
                        <MainContainerSet display="" width="18%" alignItems="center" flexWrap="" justifyContent="center">
                            <DivQRFechasTitulo>&nbsp;&nbsp;&nbsp;JULIANO&nbsp;&nbsp;&nbsp;</DivQRFechasTitulo>
                        </MainContainerSet>                    
                        <MainContainerSet display="" width="16%" alignItems="center" flexWrap="" justifyContent="center">
                            <DivQRFechasTitulo>&nbsp;&nbsp;&nbsp;BATCH&nbsp;&nbsp;&nbsp;</DivQRFechasTitulo>
                        </MainContainerSet>       
                        <MainContainerSet display="" width="10%" alignItems="center" flexWrap="" justifyContent="center">
                            <DivQRFechasTitulo>&nbsp;&nbsp;&nbsp;NV.&nbsp;&nbsp;&nbsp;</DivQRFechasTitulo>
                        </MainContainerSet>             
                        <MainContainerSet display="" width="15%" alignItems="center" flexWrap="" justifyContent="center">
                            <DivQRFechasTitulo>&nbsp;&nbsp;CANT.&nbsp;&nbsp;</DivQRFechasTitulo>
                        </MainContainerSet>
                        <MainContainerSet display="" width="16%" alignItems="center" flexWrap="" justifyContent="center">
                            <DivQRFechasTitulo>&nbsp;&nbsp;P.ACU.&nbsp;&nbsp;</DivQRFechasTitulo>
                        </MainContainerSet>
                    </MainContainerSet> 
                    </div>
                    </div>
                    {detallesPallet.length > 0 ? (
                        // Muestra los detalles del pallet si están disponibles
                        <div style={divStyles}>
                        {detallesPallet.map(item => (
                            
                            <div style={divStylesItem}>
                                
                            {
                                
                                <MainContainerSet display="" width="100%" alignItems="flex-start" flexWrap="" justifyContent="center">
                                    <MainContainerSet display="" width="25%" alignItems="center" flexWrap="" justifyContent="center">
                                        <DivQRFechas>{moment(item.fechaProduccion).format('YY/MM/DD')}</DivQRFechas>
                                    </MainContainerSet>
                                    <MainContainerSet display="" width="18%" alignItems="center" flexWrap="" justifyContent="center">
                                        <DivQRFechas>&nbsp;{item.codigoJuliano}</DivQRFechas>
                                    </MainContainerSet>                    
                                    <MainContainerSet display="" width="16%" alignItems="center" flexWrap="" justifyContent="center">
                                        <DivQRFechas>{item.batch}</DivQRFechas>
                                    </MainContainerSet>  
                                    <MainContainerSet display="" width="10%" alignItems="center" flexWrap="" justifyContent="center">
                                        <DivQRFechas>{item.niveles}</DivQRFechas>
                                    </MainContainerSet>                    
                                    <MainContainerSet display="" width="15%" alignItems="center" flexWrap="" justifyContent="center">
                                        <DivQRFechas>{item.prodDia}</DivQRFechas>
                                    </MainContainerSet>
                                    <MainContainerSet display="" width="16%" alignItems="center" flexWrap="" justifyContent="center">
                                        <DivQRFechas>{item.prodAcumulada}</DivQRFechas>
                                    </MainContainerSet>
                                </MainContainerSet> 

                            }
                            
                            </div>
                        ))}
                        </div>
                    ) : (
                        // Muestra un mensaje si no hay detalles del pallet disponibles
                        <p>No se encontraron detalles para el número de pallet especificado.</p>
                    )}
                    
                </MainContainerSet2> 
                <MainContainerSet display="" width="100%" alignItems="flex-start" flexWrap="" justifyContent="center">
                    <MainContainerSet display="" width="50%" alignItems="center" flexWrap="" justifyContent="center">
                                        
                    </MainContainerSet>
                    <MainContainerSet display="" width="50%" alignItems="center" flexWrap="" justifyContent="center">
                        <DivQRTotal>TOTAL: {total}</DivQRTotal>
                        
                    </MainContainerSet>                    
                                    
                </MainContainerSet>
            </MainContainerSet2>  
            </div> 


            
            </div>               

            
    )
}

export default DibujarQr
