import React, { MouseEventHandler, useState, useEffect,useContext } from 'react'
import { Button, Modal } from 'react-bootstrap';
import {pallets} from './paletizado'
import {envases, formatos, tapas, detallePallet} from '../ModalBox/boxModal'
import {ModalConfirm, ModalConfirmDeleteDet} from '../ModalBox/ModalConfirm/ModalConfirm'
import ModalMover from '../ModalBox/ModalMover/ModalMover'
import moment from 'moment';
import * as almacenMPService from '../AlmacenMP/AlmacenTransito/AlmacenTransitoServices'
import { toast } from 'react-toastify';
import authContext from '../../../context/AuthContext';

interface Props {
    show: boolean,
    onHide: MouseEventHandler<HTMLElement>|any,
    // base: any
    // onHideFun: () => void
}
function ModalPaletizado(props: Props) {
    const { loggedIn } = useContext(authContext);
    const [pallets, setPallets] = useState<pallets[]>([]);
    const initialStatePallet = {
        accionesCorrectivas: '',
        base:'65282675ec5ec49a72518c18',
        codigo: '',
        estadopallet: '',
        planta: '', rx: '', userid : loggedIn._id,
        created: '',
        envase: '',
        formato:  {
            _id: '',
            codigo: '',
            descripcion: '',
            calidad:''
          },
        nivel:1,
        numPallet: '',
        observaciones: '',
        tapa:  {
            _id: '',
            codigo: '',
            descripcion: ''
          },
        contador: '',
        turno: '',
        _id: ''
    }
    const [topPallet, setTopPallet] = useState<pallets>(initialStatePallet)
    const [textBusFormato, setTextBusFormato] = useState('');
    const [listFormatos, setListFormatos] = useState<formatos[]>([]);
    const [listEnvases, setListEnvases] = useState<envases[]>([]);
    const [listTapas, setListTapas] = useState<tapas[]>([]);
    const [detallePallet, setDetallePallet] = useState<detallePallet[]>([])
    // const [stateBtnSaveTop, setStateBtnSaveTop] = useState({ top: true, detalle: true })
    const [formDetPallet, setFormDetPallet] = useState<detallePallet>({_id:'', fechaProduccion: '', codigoJuliano: 0, batch: '', niveles: 0, saldo: 0, prodDia: 0, prodAcumulada: 0, pallet: '', subproceso:''})
    const [visibleDiv, seTvisibleDiv] = useState({listPallets: false, cabeceraPallet: false, detPallet: false});
    const [valorBusqueda, setValorBusqueda] = useState('');
    const [modalConfirm, setModalConfirm] = useState(false)
    const [modalConfirmDet, setModalConfirmDet] = useState(false)
    const [estadoModalMover, setEstadoModalMover] = useState(false)
    const [idPalletToDelete, setidPalletToDelete] = useState('')
    const [esSalida, setEsSalida] = useState(0)
 
    const getMiPallet = () => {
        return miPallet
    }
    const [miQr, setMiQr] = useState("QR Inicial")
    const [filaDetalle, setFilaDetalle] = useState(-1);
    const [diaJuliano, setDiaJuliano] = useState('');
    const [tipoProceso, setTipoProceso] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [miPallet, setMiPallet] = useState('')
    const calcularDiaJuliano = (fechaA:string) => {

        let fecha =new Date(fechaA);
        // Obtener el día del año
          var comienzoDeAño = new Date(fecha.getFullYear(), 0, 1);
          var milisegundosEnUnDia = 24 * 60 * 60 * 1000;
          var diasTranscurridos = Math.floor((fecha.getTime() - comienzoDeAño.getTime()) / milisegundosEnUnDia) + 1;
          diasTranscurridos++;
  
        setDiaJuliano(diasTranscurridos.toString());
        
      };

    const asignarJuliano = () => {
        
        setFormDetPallet({...formDetPallet, codigoJuliano: parseInt(diaJuliano)})
        
    }

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked); // Cambia el estado del checkbox cuando se hace clic
        let campo = 'rx';
        //console.log ("modificando el check" + (!isChecked ? "Si" : "No"));
        setTopPallet({...topPallet, [campo]: (!isChecked ? "Si" : "No")});
        
    }

    const actualizarQr = async(valor:string)=>{
        
        setMiQr(valor)
        
    }

    const actualizarMiPallet = async(valor:string)=>{
        setMiPallet(valor)
        setIsChecked(topPallet.rx=="Si"?true:false)
        
    }

    const cargarPallet = async(id: string) => {
        
        
        //setStateBtnSaveTop({ top: true, detalle: true });
        // const busquedaNivel:any  = pallets.find(pallet => pallet._id === id);              
        // setTopPallet(busquedaNivel);
        
        const resOnePallet = await almacenMPService.selectOnePallet(id);
        //valorQr = "probando"
        setTopPallet(resOnePallet.data)      
        let temporal = 'url:'+resOnePallet.data.numPallet + '|'+ resOnePallet.data.formato.codigo+ '|' //+ resOnePallet.data.formato.descripcion
        
        setIsChecked(resOnePallet.data?.rx=="Si"?true:false)
       // console.log(resOnePallet.data)
        await actualizarDetalles(id);        
        setFormDetPallet({_id:'', fechaProduccion: '', codigoJuliano: 0, batch: '', niveles: 0, saldo: 0, prodDia: 0, prodAcumulada: 0, pallet:id, subproceso:''})
        setListFormatos([])
        setTextBusFormato('')
        let total = 0
        let detalles = ''
        detallePallet.forEach((item, index) => {
             //console.log(item)
             total = total + item.prodDia
             detalles = detalles + '||' + moment(item.fechaProduccion).format('YYYY-MM-DD') + '|' + item.batch + '|' + item.prodDia
        });
        temporal = temporal + total + detalles
        actualizarQr(temporal)
         //console.log(temporal)
        //console.log("AQUI--->" + temporal)
        window.open('#/obtenerQr/' + temporal,'_blank');
    }


    const resetDetPallet = () =>{
        setDetallePallet([]);
        setFormDetPallet({_id:'', fechaProduccion: '', codigoJuliano: 0, batch: '', niveles: 0, saldo: 0, prodDia: 0, prodAcumulada: 0, pallet: '', subproceso:''});
    }
    const handleChangeTop = (e:any) =>{
        if(e.target.name === 'bformato'){
            setTextBusFormato(e.target.value);
        }else if(e.target.name=="formato"){
            setTopPallet({...topPallet, [e.target.name]: e.target.value})
            let calidad = e.target.options[e.target.selectedIndex].getAttribute('data-calidad')
            setTipoProceso((calidad=="CONGELADO"?"CONGELADO":(calidad===null?"":(calidad==""?"":"CONSERVA"))))
        }else{
            setTopPallet({...topPallet, [e.target.name]: e.target.value})
        }
    }
    const buscarFormato = async() =>{
        const resBusquedaFormato = await almacenMPService.busquedaFormato(textBusFormato)
         console.log(resBusquedaFormato)
        setListFormatos(resBusquedaFormato.data);
       
    }
    
   
    
    const editarDetalle = (id: string, indice: number)=>{
        const busquedaDetalle:any  = detallePallet.find(detPallet => detPallet._id === id);
         setFormDetPallet(busquedaDetalle);
        setFilaDetalle(indice);
        setFormDetPallet({...busquedaDetalle, fechaProduccion: moment(busquedaDetalle.fechaProduccion).format('YYYY-MM-DD')})
        calcularDiaJuliano(moment(busquedaDetalle.fechaProduccion).format('YYYY-MM-DD'));
      
     }
     
    const eliminarDetalle = (id: string)=>{
        setidPalletToDelete(id);
        setModalConfirmDet(true)
    }
    const handleChangeDetPallet= (e:any)=>{
        setFormDetPallet({...formDetPallet, [e.target.name]: e.target.value})
        if(e.target.name=="fechaProduccion"){
              calcularDiaJuliano(e.target.value);
        }
        
    }

    const buscarInsertado = async(pallet: string) =>{
        seTvisibleDiv({listPallets: true, cabeceraPallet: false, detPallet: false})
        
        const resBusPallets = await almacenMPService.busquedaPalletsPaletizado(pallet,'65282675ec5ec49a72518c18');
        console.log(resBusPallets.data)
        setPallets(resBusPallets.data);
    }

    const busquedaPallets = async() =>{
        seTvisibleDiv({listPallets: true, cabeceraPallet: false, detPallet: false})
        
        const resBusPallets = await almacenMPService.busquedaPalletsPaletizado(valorBusqueda,'65282675ec5ec49a72518c18');
        console.log(resBusPallets.data)
        setPallets(resBusPallets.data);
    }
    const anadirPallet = () =>{
        setListFormatos([])
        setTopPallet(initialStatePallet)
        resetDetPallet();
        seTvisibleDiv({listPallets: false, cabeceraPallet: true, detPallet: false})
    }
    const changeValueBusqueda = (e:any) =>{
        setValorBusqueda(e.target.value)
    }
    const changeValueBusquedaFormato = (e:any) =>{
        setTextBusFormato(e.target.value)
    }
    const submitCabeceraPallet = async(e:any) =>{
        e.preventDefault();
        try {
            if (topPallet._id) { //Edicion
                await almacenMPService.editOnePallet(topPallet);                
                // actualizarPallets();
                // setTopPallet(initialStatePallet);                
                toast.success("Pallet Editado")
            }else{ //Creacion
                const resBusPallets = await almacenMPService.createPalletPaletizado(topPallet)
                if (resBusPallets.status===200) {
                    setTopPallet({...topPallet, _id: resBusPallets.data._id});
                    buscarInsertado(resBusPallets.data.numPallet)
                    
                    //setFormDetPallet({...formDetPallet, pallet: resBusPallets.data._id})
                    //seTvisibleDiv({listPallets: false, cabeceraPallet: true, detPallet: true})
                    toast.success('Pallet Registrado: ' +  resBusPallets.data.numPallet )
                }
            }
        } catch (error:any) {
            if (error.message==='Request failed with status code 410') {
                toast.error('Codigo de Pallet ya existe') 
            }            
        }
    }
    const handleSubmitDetallePallet = async(e:any)=>{
        e.preventDefault();
        setFilaDetalle(-1);
        try {            
            asignarJuliano()
            if (formDetPallet._id) { //Edicion
                await almacenMPService.updateDetPallet(formDetPallet);
                setFormDetPallet({...formDetPallet, _id:'', fechaProduccion: '', codigoJuliano: 0, batch: '', niveles: 0, saldo: 0, prodDia: 0, prodAcumulada: 0, subproceso:''})
                actualizarDetalles(topPallet._id)  
                toast.success('Detalle Editado')  
            }else{ // Creacion
                await almacenMPService.createDetPallet(formDetPallet);
                setFormDetPallet({...formDetPallet, _id:'', fechaProduccion: '', codigoJuliano: 0, batch: '', niveles: 0, saldo: 0, prodDia: 0, prodAcumulada: 0, subproceso:''})
                actualizarDetalles(topPallet._id);
                toast.success('Detalle Creado') 
            }      
            setDiaJuliano('0')
            setFilaDetalle(-1)      
        } catch (error:any) {
            toast.error(error.message);
        }
    }
    const getTapasEnvases = async()=>{
        const resTapas = await almacenMPService.getTapas();
        const resEnvases = await almacenMPService.getEnvases();
        setListTapas(resTapas.data);
        setListEnvases(resEnvases.data);
    }
    const selectPalletBusqueda = (idPallet: string)=>{
        
        const selectedPallet = pallets.find(pallet=> pallet._id === idPallet)
        console.log(selectedPallet, "Pallet encontrado");
        if (selectedPallet) {
            seTvisibleDiv({listPallets: false, cabeceraPallet: true, detPallet: true})
            setTopPallet(selectedPallet)
            setFormDetPallet({_id:'', fechaProduccion: '', codigoJuliano: 0, batch: '', niveles: 0, saldo: 0, prodDia: 0, prodAcumulada: 0, pallet:idPallet, subproceso:''})
            let calidad = selectedPallet.formato.calidad;
            setTipoProceso((calidad=="CONGELADO"?"CONGELADO":( (calidad === undefined)?"":(calidad==""?"":"CONSERVA"))));
            actualizarDetalles(idPallet);
        }else{
            toast.info("Pallet no encontrado")
        }
        
    }
    const actualizarDetalles = async(id:string)=>{
        const resDetPallet= await almacenMPService.getDetPalletsById(id)             
        setDetallePallet(resDetPallet.data)
        
    }
    const handleCloseModalConfirm = () => setModalConfirm(false);
    const handleCloseModalConfirmDet = () => setModalConfirmDet(false);
    const handleCloseModalMover = () => setEstadoModalMover(false);
    const handleShowModalMover = (e:any) => {
        e.preventDefault();
        setEstadoModalMover(true)
    };
    const handleShowModalConfirm = (e:any) => {
        e.preventDefault();
        setEsSalida(0)
        setModalConfirm(true)
    };
    const afterModalConfirm = ()=>{
        setModalConfirm(false)
        seTvisibleDiv({listPallets: false, cabeceraPallet: false, detPallet: false})
        
        // actualizarPallets();
    }
    const afterOkModalMover = () =>{
        setEstadoModalMover(false);
        seTvisibleDiv({listPallets: false, cabeceraPallet: false, detPallet: false})
        toast.success('Movimiento realizado')
        // actualizarPallets();
    }
    const afterModalConfirmDet = () =>{
        setModalConfirmDet(false);
        actualizarDetalles(topPallet._id);
    }
    const handleShowModalSalida = (e:any) =>{
        e.preventDefault();
        setEsSalida(1)
        setModalConfirm(true)
    }
    useEffect(() => {
        if (props.show) {
            //getTapasEnvases();
        }else{
            seTvisibleDiv({listPallets: false, cabeceraPallet: false, detPallet: false})
        }
        // getTapasEnvases();
        // setStateBtnSaveTop({ top: false, detalle: false })
        // console.log(stateBtnSaveTop)
        // reinicioFormDetalle();
        // putPallets();
    }, [props.show])
    useEffect(() => {
        
        var sumaProduccion = 0;
        if(!formDetPallet._id){
          for (let i = 0; i < detallePallet.length; i++) {
              const oneProdAcumulada = detallePallet[i].prodDia;
              sumaProduccion += oneProdAcumulada
          }
        }
        const prodTotal = sumaProduccion + formDetPallet.prodDia*1;
        setFormDetPallet({...formDetPallet, prodAcumulada: prodTotal})
      }, [formDetPallet.prodDia])
    return (
        <>
           <Modal
                show={props.show}
                onHide={props.onHide}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Paletizado - Administracion de Palets
                 </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row justify-content-center align-items-center">
                        <input className="form-control form-control-lg col-2 " value={valorBusqueda} onChange={changeValueBusqueda} type="text" name='valorBusqueda'/>
                        <button className="btn btn-lg btn-dark m-1" onClick={busquedaPallets}>Buscar</button>
                        <button className="btn btn-lg btn-success m-1"onClick={anadirPallet} >Añadir Pallet</button>
                    </div>
                    {/* <div className="row justify-content-center">
                        {pallets.map((pallet) => {
                            return (<button className="btn btn-outline-success m-1" onClick={() => { handleBtnNivel(pallet._id) }}>Nivel {pallet.nivel} - {pallet.numPallet}</button>)
                        })
                        }

                        <button className="btn btn-success m-1" onClick={btnAddPallet}>Add Nivel</button>
                    </div> */}
                    <div id='listPallets'>
                      {visibleDiv.listPallets?(
                          <>
                            {pallets.map((pallet) => {
                            return (<button className="btn btn-lg btn-outline-success m-1" 
                            onClick={() => {actualizarMiPallet(pallet._id); selectPalletBusqueda(pallet._id) }}
                            >Nivel {pallet.nivel} - {pallet.numPallet}</button>)
                        })
                        }
                          </>
                      ):(<></>)}
                    </div>
                    <div id="topPallet">
                        {visibleDiv.cabeceraPallet?(<>
                                <form onSubmit={submitCabeceraPallet}>
                                    <div className="row m-2">
                                        <div className="col-12 col-md-1">
                                            <label htmlFor="nivel">Nivel</label>
                                            <input type="hidden" name="base" value="65282675ec5ec49a72518c18" />
                                            <input type="text" className="form-control " name="nivel"
                                                    value={topPallet?.nivel}                                                     
                                                    readOnly />
                                        </div>
                                        <div className="col-12 col-md-2">
                                            <label htmlFor="numPallet">Codigo Pallet</label>
                                            <input type="text" className="form-control " name="numPallet" 
                                            onChange={handleChangeTop}
                                            value={topPallet?.numPallet} readOnly />
                                        </div>
                                        <div className="col-12 col-md-9">
                                            <div className="row align-items-end">
                                                <div className="col-8 col-md-3">
                                                <label htmlFor="bformato">Filtrar Formato</label>
                                                    <input type="text" className="form-control " name="bformato" 
                                                    onChange={changeValueBusquedaFormato}
                                                    value={textBusFormato}/>
                                                </div>
                                                
                                                <div className="col-4 col-md-1">
                                                <span className=" btn btn-block btn-primary" onClick={buscarFormato}><i className="fas fa-search"></i></span>
                                                </div>
                                                <div className="col-12 col-md-8">
                                                    <label htmlFor="formato">Codigo - Formato</label>
                                                    <select className="form-control " name="formato" onChange={handleChangeTop}  required >
                                                        {topPallet?._id ? (<>
                                                            <option value={topPallet?.formato._id} selected>{topPallet?.formato.codigo} - {topPallet?.formato.descripcion}</option>                                                    
                                                            </>
                                                        ) : (<>
                                                            <option value="" selected>Seleccione</option>
                                                            </>
                                                        )}
                                                        {listFormatos.map((formato)=>{
                                                            return(
                                                                <option value={formato._id} >{formato.codigo} - {formato.descripcion}</option>
                                                            )
                                                        })}                                                                                                                        
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        

                                        <div className="col-12 col-md-4">
                                            <label htmlFor="contador">Contador</label>
                                            <input type="text" className="form-control " name="contador" 
                                            onChange={handleChangeTop}
                                            value={topPallet?.contador}  required/>
                                        </div>
                                        <div className="col-12 col-md-2">
                                            <label htmlFor="turno">Turno</label>
                                            <select className="form-control " name="turno" onChange={handleChangeTop}  required >
                                                {topPallet?._id ? (<>
                                                    <option value={topPallet?.turno} selected>{topPallet?.turno}</option>                                                    
                                                    </>
                                                ) : (<>
                                                    <option value="" selected>Seleccione</option>
                                                    </>
                                                )}
                                                    <option value="DIA" >DIA</option>
                                                    <option value="TARDE">TARDE</option>
                                                    <option value="NOCHE">NOCHE</option>
                                            </select>
                                        </div>
                                        <div className="col-12 col-md-3">
                                        <label htmlFor="estadopallet">Estado del Pallet</label>
                                            <select className="form-control " name="estadopallet" onChange={handleChangeTop}  required >
                                                {topPallet?._id ? (<>
                                                    <option value={topPallet?.estadopallet} selected>{topPallet?.estadopallet}</option>                                                    
                                                    </>
                                                ) : (<>
                                                    <option value="" selected>Seleccione</option>
                                                    </>
                                                )}
                                                 <option value="OBSERVADO" >OBSERVADO</option>
                                                    <option value="REPROCESO">REPROCESO</option>
                                                    <option value="3RA CALIDAD">3RA CALIDAD</option>
                                                    <option value="EXPORTABLE">EXPORTABLE</option> 
                                                    <option value="INSUMOS">INSUMOS</option>   
                                            </select>
                                        </div>
                                        <div className="col-12 col-md-2">
                                            <label htmlFor="planta">Planta</label>
                                            <select className="form-control " name="planta" onChange={handleChangeTop}  required >
                                                {topPallet?._id ? (<>
                                                    <option value={topPallet?.planta  } selected>{topPallet?.planta}</option>                                                    
                                                    </>
                                                ) : (<>
                                                    <option value="" selected>Seleccione</option>
                                                    <option value="AREQUIPA" >AREQUIPA</option>
                                                    <option value="CUSCO" >CUSCO</option>
                                                    </>
                                                )}
                                                   
                                            </select>
                                        </div>
                                        <div className="col-12 col-md-1">
                                            <label htmlFor="rx">Rx</label>
                                            <input type='checkbox' name='rx' className="form-control"  checked={(topPallet?.rx==="Si"?true:false)} onChange={handleCheckboxChange} ></input>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <label htmlFor="observaciones">Observaciones</label>
                                            <textarea name="observaciones" className="form-control" cols={30} rows={2} 
                                            onChange={handleChangeTop}
                                            value={topPallet?.observaciones}></textarea>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <label htmlFor="accionesCorrectivas">Acciones Correctivas</label>
                                            <textarea name="accionesCorrectivas" className="form-control" cols={30} rows={2} 
                                            onChange={handleChangeTop}
                                            value={topPallet?.accionesCorrectivas}></textarea>
                                        </div>
                                    </div>
                                    <div className="row">
                                    <div className={`col-12 col-md-2 m-1 mx-auto`}>
                                        <button className="btn btn-block btn-dark btn-lg" ><i className="fa fa-save"></i>  {topPallet._id ? ('Editar') : ('Crear')}</button>
                                    </div>
                                    {topPallet._id?(
                                        <>
                                            
                                            <div className={`col-12 col-md-2 m-1 mx-auto`}>
                                                <button className="btn btn-block btn-info btn-lg" onClick={handleShowModalMover}><i className="fas fa-people-carry"></i> Mover</button>
                                            </div>
                                            <div className={`col-12 col-md-2 m-1 mx-auto`}>
                                                <button className="btn btn-block btn-warning btn-lg" onClick={handleShowModalSalida}><i className="fas fa-clipboard-check"></i> Salida</button>
                                            </div>
                                            <div className={`col-12 col-md-2 m-1 mx-auto`}>
                                                <button className="btn btn-block btn-danger btn-lg" onClick={handleShowModalConfirm}><i className="fas fa-trash"></i> Eliminar</button>
                                            </div>
                                            <div className={`col-12 col-md-2 m-1 mx-auto`}> 
                                                <button className="btn btn-block btn-success btn-lg"  onClick={() => { cargarPallet(getMiPallet());  }}><i className="fas fa-qrcode"></i> QR</button>
                                            </div>
                                        </>
                                    ):('')}
                                    </div>
                                </form>
                            </>):(<></>)}                            
                    </div>

                    {/* DETALLE PALET */}
                    <div id="detalle">
                        {visibleDiv.detPallet ? (<> <div className="table-responsive  col-12 mx-auto  mb-5 bg-body rounded trheight tableFixHeadPallet">
                            <table className="table shadow">
                                <thead>
                                    <tr>
                                        <th scope="col">Fecha ProducciÓn</th>
                                        <th scope="col">Juliano</th>
                                        <th scope="col">Batch</th>
                                        <th scope="col">Niveles</th>
                                        <th scope="col">Saldo</th>
                                        <th scope="col">Prod. DÍa</th>
                                        <th scope="col">Prod. Acumulada</th>
                                        <th scope="col">Opciones</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {detallePallet.map((det,indice)=>{
                                        return <tr>
                                        <td align="center">{moment(det.fechaProduccion).format('DD-MM-YYYY')}</td>
                                        <td align="center">{det.codigoJuliano}</td>
                                        <td align="center">{det.batch}</td>
                                        <td align="center">{det.niveles}</td>
                                        <td align="center">{det.saldo}</td>
                                        <td align="center">{det.prodDia}</td>
                                        <td align="center">{det.prodAcumulada}</td>
                                        <td>
                                        <span className="btn btn-outline-primary m-2" onClick={()=>{editarDetalle(det._id,indice)}}><i className="fas fa-pen"></i></span>
                                            <span className="btn btn-outline-danger m-2" onClick={()=>{eliminarDetalle(det._id)}}><i className="fas fa-trash"></i></span>
                                        </td>
                                    </tr>
                                    })}
                                    
                                </tbody>
                            </table>
                        </div>
                            <form onSubmit={handleSubmitDetallePallet}>
                                <div className="row m-2">
                                <div className="col-12 col-md-3">
                                        <label htmlFor="fechaProduccion">Fecha</label>
                                        <input type="date" className="form-control " name="fechaProduccion" required
                                        value={formDetPallet?.fechaProduccion} onChange={handleChangeDetPallet} onBlur={asignarJuliano}/>
                                    </div>
                                    <div className="col-12 col-md-2">
                                        <label htmlFor="codigoJuliano">Juliano</label>
                                        <input type="text" className="form-control " readOnly name="codigoJuliano" value={diaJuliano} onChange={handleChangeDetPallet}/>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <label htmlFor="batch">SubProceso</label>
                                        <select className="form-control " name="subproceso"  onChange={handleChangeDetPallet} required >
                                                {formDetPallet?.subproceso ? (<>
                                                    <option value={formDetPallet?.subproceso  } selected>{formDetPallet?.subproceso}</option>                                                    
                                                        {tipoProceso=="CONSERVA" ? (<>    
                                                            <option value="" >Seleccione</option>
                                                            <option value="COCIDO">COCIDO</option>
                                                            <option value="CRUDO">CRUDO</option>
                                                            <option value="DESCONGELADO">DESCONGELADO</option>
                                                            <option value="REPROCESO">REPROCESO</option>
                                                            </>) : (<>
                                                            {tipoProceso=="-" ? (<>    
                                                                <option value="" >Seleccione</option>
                                                                <option value="-" >No aplica</option>
                                                            </>) : (<>
                                                                <option value="" >Seleccione</option>
                                                                <option value="CONGELADO">CONGELADO</option>
                                                            </>)}
                                                        </>)}
                                                    </>
                                                ) : (<>
                                                    {tipoProceso=="CONSERVA" ? (<>    
                                                        <option value="" >Seleccione</option>
                                                        <option value="COCIDO">COCIDO</option>
                                                        <option value="CRUDO">CRUDO</option>
                                                        <option value="DESCONGELADO">DESCONGELADO</option>
                                                        <option value="REPROCESO">REPROCESO</option>
                                                        </>) : (<>
                                                        {tipoProceso=="" ? (<>    
                                                            <option value="" >Seleccione</option>
                                                            <option value="-">No aplica</option>
                                                        </>) : (<>
                                                            <option value="" >Seleccione</option>
                                                            <option value="CONGELADO">CONGELADO</option>
                                                        </>)}
                                                    </>)}
                                                </>)}
                                                   
                                            </select>
                                    </div>
                                    <div className="col-12 col-md-2">
                                        <label htmlFor="batch">Batch</label>
                                        <input type="text" className="form-control " name="batch" required
                                        value={formDetPallet?.batch} onChange={handleChangeDetPallet}/>
                                    </div>
                                    <div className="col-12 col-md-2">
                                        <label htmlFor="niveles">Niveles</label>
                                        <input type="number" className="form-control " name="niveles" required
                                        value={formDetPallet?.niveles} onChange={handleChangeDetPallet}/>
                                    </div>
                                    <div className="col-12 col-md-2">
                                        <label htmlFor="saldo">Saldo</label>
                                        <input type="number" className="form-control " name="saldo" required
                                        value={formDetPallet?.saldo} onChange={handleChangeDetPallet}/>
                                    </div>
                                    <div className="col-12 col-md-2">
                                        <label htmlFor="prodDia">Prod. Dia</label>
                                        <input type="number" className="form-control " name="prodDia" required
                                        value={formDetPallet?.prodDia} onChange={handleChangeDetPallet}/>
                                    </div>
                                    <div className="col-12 col-md-2">
                                        <label htmlFor="prodAcumulada">Prod. Acumulada</label>
                                        <input type="number" className="form-control " name="prodAcumulada" readOnly required
                                        value={formDetPallet?.prodAcumulada} />
                                    </div>
                                    <div className="col-12 col-md-2">
                                        <label htmlFor="prodAcumulada">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                                        <button className="btn btn-info">{formDetPallet._id ? ('EDITAR') : ('AGREGAR')}</button>
                                    </div>
                                </div>
                            </form>
                        </>) : ('')}
                    </div>


                </Modal.Body>
                <Modal.Footer>                    
                    <Button variant="success btn-block" 
                    onClick={props.onHide} 
                    >Salir</Button>

                </Modal.Footer>
            </Modal> 
            <ModalConfirm show={modalConfirm} onHide={handleCloseModalConfirm} pallet={topPallet} idUsuario={loggedIn._id} afterModalConfirm={afterModalConfirm}esSalida={esSalida} />
            <ModalConfirmDeleteDet show={modalConfirmDet} onHide={handleCloseModalConfirmDet} id={idPalletToDelete} afterModalConfirmDet={afterModalConfirmDet} />
            <ModalMover show={estadoModalMover} pallet={topPallet} onHide={handleCloseModalMover} afterModalConfirm={afterOkModalMover}/>
        </>
    )
}

export default ModalPaletizado
