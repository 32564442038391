import axios from 'axios'
import {proxCosechaFromLote, dataToUpdateProy,paramsBusquedaPro} from './proyecciones'

import apiURL from '../../context/apiURL'
const API = apiURL.API;

export const importProyeccionesDia = async(data: any) => {
    return await axios.post(`${API}/importProyeccionesDia`, data);
}
export const importProyecciones = async(data: any) => {
    return await axios.post(`${API}/importProyecciones`, data);
}
export const importCosechado = async(data: any) => {
    return await axios.post(`${API}/importCosechado`, data);
}


export const editProxFechaFromLote = async(data: proxCosechaFromLote) => {
    return await axios.post<proxCosechaFromLote>(`${API}/editProxFechaFromLote`, data);
}
export const busquedaAgricultor = async(text: string)=>{
    return await axios.post(`${API}/busquedaAgricultor`, text)
}

export const getProyeccionesList = async(id: string) =>{
    return await axios.get(`${API}/getProyeccionesList/${id}`)
}

export const editOneProyeccion = async(data: dataToUpdateProy) => {
    return await axios.post(`${API}/editOneProyeccion`, data)
}
export const editOneProyeccionFromTable = async(data: any) => {
    return await axios.post(`${API}/editOneProyeccionFromTable`, data)
}
export const editProyCosechado = async(data: any) => {
    return await axios.post(`${API}/editProyCosechado`, data)
}
export const editProyCosechadoFromTable = async(data: any) => {
    return await axios.post(`${API}/editProyCosechadoFromTable`, data)
}
export const resumenProyeccion = async(data: paramsBusquedaPro) =>{
    return await axios.post(`${API}/resumenProyeccion`, data)
}

export const deleteOneProyeccion = async(idProyeccion: string) =>{
    return await axios.get(`${API}/deleteOneProyeccion/${idProyeccion}`)
}
export const createNewProyeccion = async(data: any)=>{  //add proyeccion
    return await axios.post(`${API}/createNewProyeccion`, data)
}
export const getTipoProyeccionByFechaSiembra = async(fechaSiembra: string)=>{
    return await axios.get(`${API}/getTipoProyeccionByFechaSiembra/${fechaSiembra}`)
}

export const creacionProyeccionV2 = async(idProyeccion: string, lote: any)=>{  //add proyeccion
    return await axios.post(`${API}/creacionProyeccionV2`, {idProyeccion, lote})
}
export const setFechaRealSiembra = async(data: any)=>{
    return await axios.post(`${API}/setFechaRealSiembra`, data )
}
export const getUsuariosByTipo = async(id: string)=>{
    return await axios.get(`${API}/getUsuariosByTipo/${id}`)
}
export const getProyeccionToEditOnTable = async(id: string)=>{
    return await axios.get(`${API}/getProyeccionToEditOnTable/${id}`)
}
export const updateProyectistaToLote = async(id: string)=>{
    return await axios.get(`${API}/updateProyectistaToLote/${id}`)
}
export const setNewProyectistaToLote = async(data: any)=>{
    return await axios.post(`${API}/setNewProyectistaToLote`, data)
}

//REPORTES
export const generateExcelReporteProyeccion = async(data: any)=>{
    return await axios.post(`${API}/generateExcelReporteProyeccion`, data)
}
export const downloadReporteProyecciones = async(nameFile: string) =>{
    return await axios.get(`${API}/downloadReporteProyecciones/${nameFile}`,  {headers:{
        'Content-Disposition': "attachment; filename=template.xlsx",
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    }, responseType: 'blob'} )
}
