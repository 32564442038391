import React, {ChangeEvent,FormEvent, useState, useEffect, useContext} from 'react'
import {loginup} from './loginup'
import * as usuarioService from '../Usuarios/UsuarioService'
import { useNavigate } from 'react-router-dom'
import authContext from '../../context/AuthContext';
import {toast} from 'react-toastify'
import alcachofa from '../../images/alcachofa.svg'
//import logoalsur from '../../images/alsurLogoNuevo.png'
import logoalsur from './logo.png'
import './Login.css';

type InputChange = ChangeEvent<HTMLInputElement | HTMLSelectElement>;
function Login() {
    const history = useNavigate();
    const [login, setLogin] = useState({
        usuario: '',
        password: ''
    })
    const [loggedIn, setLoggedIn] = useState(undefined);

    const {getLoggedIn} = useContext(authContext);

    const handleInputChange = (e: InputChange) => {
        setLogin({...login, [e.target.name]: e.target.value});
      }

      const handleSubmit = async (e: FormEvent<HTMLFormElement>)=>{
        e.preventDefault();

        try {
            const resLogin: any = await usuarioService.loginUsuario(login);
        if (resLogin.status===200){
            getLoggedIn();
            history("/dashboard")
        } else{
            toast.error('Usuario no Registrado');
        }  
        } catch (error) {
            toast.error('Usuario no Registrado');
        }   

      }

    return (
        <div className="wrapper">
            <form onSubmit={handleSubmit} className="form ">
                    <img src={logoalsur} className="w-50" alt="" />
                      <div className="inp">                        
                        <input type="text" className="input" name="usuario" id="usuario" value={login.usuario} aria-describedby="emailHelp"
                        onChange={handleInputChange}  placeholder='Usuario'/><i className="fas fa-user"></i>
                    </div>
                    <div className="inp">
                        <input type="password" className="input" id="password" name="password" placeholder='Password' value={login.password} onChange={handleInputChange}
                        /><i className="fas fa-lock"></i>
                    </div>
                    <button type="submit" className="submit">INGRESAR</button>
                   
            </form>
            <div></div>
            <div className='banner'>
                <h1 className='wel_text'> Sistema de Pallets</h1>
                <p className='version'>V2.1 - 2023</p>
            </div>
        </div>
    )
}

export default Login
