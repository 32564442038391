import axios from 'axios'
import {localidad} from './localidad'
import apiURL from '../../context/apiURL'
const API = apiURL.API;

export const getLocalidades = async () => {
   return await axios.get<localidad[]>(`${API}/localidades`)    
}

export const createLocalidades = async (localidad: localidad) => {
   return await axios.post(`${API}/localidades`, localidad)    
}

export const getLocalidad = async (id: string) => {
  
   return await axios.get<localidad>(`${API}/localidad/${id}`)    
}

export const updateLocalidades = async (id: string, localidad: localidad) => {
   return await axios.put<localidad>(`${API}/localidades/${id}`, localidad)    
}

export const deleteLocalidad = async (id: string) => {
   return await axios.delete<localidad>(`${API}/localidades/${id}`)    
}
// FUNCIONES ADICIONALES
export const getLocalidadesVSdep = async (id: string) => {
   
   return await axios.get<localidad[]>(`${API}/localidades/${id}`)    
}