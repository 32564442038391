import styled, {css} from 'styled-components'

interface Props {
    position: string
  }
interface color{
    colorBG: number,
    buscado: boolean,
    idPallet: string
}
export const  Box = styled.div`   
    height:1vh;
    background: #010609;
`

export const BoxWrapper = styled.div`
    position: relative;
    height:100%;
    width: 100%;
  display: inline-flex;
`;

export const TooltipTarget = styled.button<color>`
    border: none;
    height:100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    color: inherit;
    ${({colorBG, buscado})=>{
        if(buscado){
            return css`
                    background: #fafd41;
                    color: #000;
                    
                `;
        }
        if(colorBG===0){
            return css`
                    background: #1d72a3;
                    
                `;
        }
        if(colorBG===1){
            return css`
                    background: #297a34;;
                    
                `;
        }
        if(colorBG===2){
            return css`
                    background: #c77430;
                    
                `;
        }
        if(colorBG===3){
            return css`
                    background: #801406;
                    
                `;
        }
        if(colorBG>=4){
            return css`
                    background: #49110a;
                    
                `;
        }
     
    }};
    padding: 0.5px;
    /* margin: -1px; */
    
    cursor: inherit;
    display: flex;
`
export const CenterContainer = styled.div<Props>`
    position: absolute;
    width: 200px;
    margin-left: -100px;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 50%;
    bottom: calc(100% + 5px);
    pointer-events:none;

    ${({position}) =>{
        switch(position){
            case 'bottom':
                return css`
                    bottom: unset;
                    top: calc(100% + 5px);
                `;
            case 'left':
                return css`
                  margin-left: 0;
                  width: 100%;
                  left: unset;
                  top: 50%;
                  right: calc(100% + 5px);
                  width: max-content;  
                `;
            case 'right':
                return css`
                 margin-left: 0;
                  width: 100%;                  
                  top: 50%;
                  left: calc(100% + 5px);
                  width: max-content;  
                `;

            default:
                return css`
                    bottom: calc(100% + 5px);
                `;
        }
    }}
`
export const TooltipBox = styled.span<Props>`
    position: relative;
    background-color: #000;
    color: #fff;
    text-align: center;
    width: 110px;
    border-radius: 5px;
    padding: 10px 8px;
    font-size: 12px;
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.15), 0 4px 8px rgba(0, 0, 0, 0.2);

    &:after{
        content:"";
        position:absolute;
        width: 1px;
        height: 1px;
        border-width: 5px;
        border-style: solid;
        border-color: #000000 transparent transparent transparent;
        left: calc(50% - 4.5px);
        top: 100%;
    }

    ${({position})=>{
        switch(position){
            case "bottom":
                return css`
                    &:after{
                        border-color: transparent transparent #000000 transparent;
                        top: unset;
                        width: 1px;
                        bottom: 100%;
                        left: calc(50% - 5px);
                    }
                `;
            case "left":
                return css`
                    &:after{
                        border-color: transparent transparent  transparent #000000;                                               
                        left: 100%;
                        top: calc(50% - 5px);
                    }
                `;
            case "right":
                return css`
                    &:after{
                        border-color: transparent #000000 transparent transparent;
                        right: 100%;
                        left: unset;                        
                        top: calc(50% - 5px);
                    }
                `;
            default:
                return css``;
        }
    }}
`;