import React, { useState, useEffect, MouseEventHandler } from 'react'
import { Button, Modal } from 'react-bootstrap';
import * as almacenServices from '../../AlmacenMP/AlmacenMPServices'
import { zonas, almacenes, bases } from '../boxModal'
import Swal from 'sweetalert2'
interface Props {
    show: boolean,
    onHide: MouseEventHandler<HTMLElement> | any,
    pallet: any,
    afterModalConfirm: () => void
}
function ModalMover(props: Props) {
    const [listZonas, setListZonas] = useState<zonas[]>([])
    const [listAlmacenes, setlistAlmacenes] = useState<almacenes[]>([])
    const [listBases, setListBases] = useState<bases[]>([])
    const [idBaseMove, setIdBaseMove] = useState('');
    const [baseMove, setBaseMove] = useState({id:'', base:'', fila:'', columna:''})
    const [validaBaseFound, setValidaBaseFound] = useState(false)
    const [numNivelMove, setnumNivelMove] = useState(0)
    const loadInit = async () => {
        const resAlmacenes = await almacenServices.getAllAlmacenesPlanta()
        setlistAlmacenes(resAlmacenes.data)
    }

    const changeAlmacen = async (e: any) => {
        const idAlmacen = e.target.value;
        const resZonas = await almacenServices.getZonasByAlmacen(idAlmacen);
        setListZonas(resZonas.data);
    }
    const changeZona = async (e: any) => {
        const idZona = e.target.value;
        const resBase = await almacenServices.getBasesByZona(idZona);        
        setListBases(resBase.data);
    }
    const changeBase = async (e: any) => {
        const idBaseSelected = e.target.value;
        setIdBaseMove(idBaseSelected);
        const busquedaBase = listBases.find(base => base._id === idBaseSelected);

        setnumNivelMove(busquedaBase?.pallets.length + 1);
    }
    const submitMovePallet = async () => {
        const idPallet = props.pallet._id;
        if (!validaBaseFound) {
            return Swal.fire({
                position: 'center',
                icon: 'error',
                title: "Error en Base",
                text: 'Asigne una Base existente',
                showConfirmButton: true,
                // timer: 2000,
            })
        }
        if (!idBaseMove || !idBaseMove || !numNivelMove) {
            return Swal.fire({
                position: 'center',
                icon: 'error',
                title: "Campos Incompletos",
                text: 'Llene correctamente Zona y Base',
                showConfirmButton: true,
                // timer: 2000,
            })
        } else {         
            const data = {
                idPallet: idPallet,
                idBaseMove: idBaseMove,
                numNivelMove: numNivelMove,
                numNivelActual: props.pallet.nivel,
                idBase: props.pallet.base
            }
            await almacenServices.moveOnePallet(data);
            setIdBaseMove('')
            setnumNivelMove(0)
            setBaseMove({id:'', base:'', fila:'', columna:''})
            setListBases([])
            props.afterModalConfirm();
        }
    }
    const handleChangeFilaColumna = (e: any) =>{
        setBaseMove({...baseMove, [e.target.name]: e.target.value});
    }
    useEffect(() => {
      const busquedaBase = listBases.find((base)=> base.matris === "F"+baseMove.fila+"C"+baseMove.columna)
      
      if(busquedaBase) {
        setValidaBaseFound(true)
        const nivel= parseInt(busquedaBase.pallets.length)+1
        setnumNivelMove(nivel)
        setIdBaseMove(busquedaBase._id.toString())
        setBaseMove({...baseMove, id:busquedaBase._id, base:"F"+baseMove.fila+"C"+baseMove.columna+" Nivel : "+ nivel })
        }
      else{
        setValidaBaseFound(false)
        setnumNivelMove(1)
        setIdBaseMove('')
        setBaseMove({...baseMove, id:"", base:"F"+baseMove.fila+"C"+baseMove.columna })
      }
      
    }, [baseMove.fila, baseMove.columna])
    
    useEffect(() => {
        loadInit();
    }, [])
    return (
        <>
            <Modal
                show={props.show}
                onHide={props.onHide}
                size='lg'
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Mover Pallet {props.pallet.numPallet}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <label htmlFor="almacen">Almacen</label>
                            <select className="form-control " onChange={changeAlmacen} name="almacen" required >
                                <option value="">Seleccione</option>
                                {listAlmacenes.map((almacen) => {
                                    return (<option value={almacen._id}>{almacen.nombre}</option>)
                                })}
                            </select>
                        </div>
                        <div className="col-12 col-md-4">
                            <label htmlFor="zona">Zona</label>
                            <select className="form-control " onChange={changeZona} name="zona" required >
                                <option value="">Seleccione</option>
                                {listZonas.map((zona) => {
                                    return (<option value={zona._id}>{zona.nombre} </option>)
                                })}
                            </select>
                        </div>
                        <div className="col-12 col-md-2">
                            <label htmlFor="fila">Fila <i className="fas fa-arrow-right"></i> </label>                                                        
                            <input type="text" className="form-control" 
                                onChange={handleChangeFilaColumna}
                                value={baseMove.fila}
                                name="fila"
                            />                           
                        </div>
                        <div className="col-12 col-md-2">
                            <label htmlFor="fila">Columna <i className="fas fa-arrow-down"></i> </label>                                                      
                            <input type="text" className="form-control" 
                                onChange={handleChangeFilaColumna}
                                value={baseMove.columna}
                                name="columna"
                            />
                            

                        </div>
                        {/* <div className="col-12 col-md-4">
                            <label htmlFor="base">Base</label>
                            <select className="form-control " onChange={changeBase} name="base" required >
                                <option value="">Seleccione</option>
                                {listBases.map((base) => {
                                    return (<option value={base._id}>{base.matris} , Nivel {base.pallets.length + 1}</option>)
                                })}
                            </select>
                        </div> */}
                        <div className="col-12 col-md-4">
                            <label htmlFor="base">Base</label>
                            <input type="text" className={validaBaseFound? 'form-control bg-success text-light' : 'form-control bg-danger text-light'} readOnly 
                                value={baseMove.base}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="row col-12 text-center ">
                        <Button variant="info" className="col-12 col-md-5 m-1" onClick={submitMovePallet}>Mover</Button>
                        <Button variant="outline-info" className="col-12 col-md-5 m-1" onClick={props.onHide} >Cancelar</Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ModalMover
