import React, { useEffect, useState } from 'react'
import { useNavigate , useParams } from 'react-router-dom'
interface Params {
    id: string | undefined
}
function FormInputs() {
    const history = useNavigate ();
    const params = useParams();
    const [dataParams, setDataParams] = useState<any[]>([])
    if(params.id){
        const dataParams:any = params.id.split(';')
        setDataParams(dataParams)
    }    

    useEffect(() => {
        console.log(params)
    }, [])
    return (
        <div>
            <div className="text-center col-12 col-sm-4 mx-auto p-3">
                <h3><strong>Creacion de Registro</strong></h3>
                <h2 className="text-info font-weight-bold">{dataParams[1]}</h2>
            </div>
            <form action="">
                <div className="d-flex flex-wrap justify-content-center">
                    <div className="p-2">
                        <label htmlFor="">Fecha</label>
                        <input className="form-control form-control-lg border-info" type="text"/>
                    </div>
                    <div className="p-2">
                        <label htmlFor="">Fecha</label>
                        <input className="form-control form-control-lg border-info" type="text"/>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default FormInputs
