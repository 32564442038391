import React, {useState, useEffect, ChangeEvent} from 'react'
import * as ProyeccionCtrl from '../ProyeccionesService'
import {resResumenPro, arrayFechas, CBFechas, usuariosByTipo,
    loteToModal, proyeccionToModal } from '../proyecciones'
import proyeccionImg from '../../../Media/png/proyeccion.png'
import proyCampoImg from '../../../Media/png/proyCampo.png'
import cosechadoImg from '../../../Media/png/cosechado.png'
import IcoExcel from '../../../Media/svg/excel.svg';
import moment from 'moment';
import { WrapTotal, WrapFechasCosecha, TDProySis, TDProyCam,TDProyDiaCam, TDCosechado, WrapTDProyeccion, TDNull,
    InputProyeccionCampo, WrapBusqueda } from '../ProyeccionesElements'
import ModalSetProyeccion from './ModalSetProyeccion'
import ModalAdmLote from './ModalAdmLote'
import 'moment/locale/es';
import { toast } from 'react-toastify';
import saveAs from 'file-saver'
import { read, utils } from 'xlsx'
import Swal from 'sweetalert2'
moment.locale('es');

type InputChange = ChangeEvent<HTMLInputElement | HTMLSelectElement>;
const noTruncarDecimales = {maximumFractionDigits: 20};
function ReporteProyeccion() {
    
    const initialLoteToModal = {
        idLote: '',
        nombreLote: '',
        agricultor: '',
        areaLote: 0,    
        numeroContrato: '',
        proyectista: ''
      
    }
    const [loadImportProyeccion, setLoadImportProyeccion] = useState(false)
    const [importProyeccion, setImportProyeccion] = useState<any[]>([])
    const [loadImportProyeccionDia, setLoadImportProyeccionDia] = useState(false)
    const [importProyeccionDia, setImportProyeccionDia] = useState<any[]>([])
    const [loadImportCosechado, setLoadImportCosechado] = useState(false)
    const [importCosechado, setImportCosechado] = useState<any[]>([])

    const [loadDiv, setLoadDiv] = useState(false)
    const [dataBusqueda, setdataBusqueda] = useState({campaña: '2021-2022', idProyectista: '', fechaInicio:'', fechaFin:'', estado:'', columna:'', columnaText:''})
    const [dataProyeccion, setdataProyeccion] = useState<resResumenPro[]>([]);
    const [busquedaDataProyeccion, setBusquedaDataProyeccion] = useState<resResumenPro[]>([])
    const [loteToModal, setLoteToModal] = useState<loteToModal>(initialLoteToModal);
    const [idProyeccionToModal, setIdProyeccionToModal] = useState('')
    const [numeroProyeccionToModal, setNumeroProyeccionToModal] = useState(0)
    const [proyeccionToModal, setProyeccionToModal] = useState<proyeccionToModal>()

    const [totalPeso, setTotalPeso] = useState(0)
    const [totalJabasProyCampo, setTotalJabasProyCampo] = useState(0)
    const [totalJabasCosechado, setTotalJabasCosechado] = useState(0)
    const [idEvaluador, setIdEvaluador] = useState('')
    const [arrayFechas, setArrayFechas] = useState<arrayFechas[]>([])
    // const [stateCBFechas, setStateCBFechas] = useState<CBFechas>(initialStateCBFechas)
    const [stateModalSetProyeccion, setStateModalSetProyeccion] = useState(false)
    const [modalAdmLote, setModalAdmLote] = useState(false)
    const [textBusqueda, setTextBusqueda] = useState('');
    const [stateBtnBusqueda, setStateBtnBusqueda] = useState(false)
    const handleHideModalSetProyeccion = ()=>{
        setStateModalSetProyeccion(false)
    }
    const afterHideModalSetProyeccion = () =>{
        BusquedaProyeccion();
        setStateModalSetProyeccion(false)
    }
    const handleChange = (e:InputChange)=>{   
        setTextBusqueda('')     
        setdataBusqueda({...dataBusqueda, [e.target.name]: e.target.value})
    }
    const handleChangeEvaluador = (e: InputChange)=>{
        setIdEvaluador(e.target.value)
    }
    const [evaluadores, setEvaluadores] = useState<usuariosByTipo[]>([])
    // const [stateCBEvaluadores, setStateCBEvaluadores] = useState(false)

    const BusquedaProyeccion = async()=>{
        
        if(!dataBusqueda.fechaInicio || !dataBusqueda.fechaFin){
            return toast.info("Configure un rango de fechas.")
        }
        setStateBtnBusqueda(true)
            const resBusqueda = await ProyeccionCtrl.resumenProyeccion(dataBusqueda)
            console.log(resBusqueda.data)
            setArrayFechas(resBusqueda.data.arrayFechas)

            var resultadoBusqueda = resBusqueda.data.newArrayLotes.filter((input: resResumenPro) => {
                if (input.agricultor.toString().toLowerCase().includes(textBusqueda.toLowerCase()) || 
                input.numeroContrato.toString().toLowerCase().includes(textBusqueda.toLowerCase())) {
                    return input
                }
            })
            setdataProyeccion(resultadoBusqueda)
            // console.log(resBusqueda.data.newArrayLotes)
            setBusquedaDataProyeccion(resBusqueda.data.newArrayLotes)
            setTotalPeso(resBusqueda.data.totalJabas)
            setTotalJabasProyCampo(resBusqueda.data.totalJabasCampo)
            setTotalJabasCosechado(resBusqueda.data.totalJabasCosechado)
            setStateBtnBusqueda(false)   
        
    }
  
    const setModalProyeccionCosechado = async(matris: string)=>{
        // console.log(matris)
        try {
            const splitMatris = matris.split('::');
            const idLote= splitMatris[0];
            const idProyeccion = splitMatris[1];
            const posicionRegistro = parseInt(splitMatris[2]);
            
            if (idProyeccion =='undefined') return toast.info("Seleccione fecha con Proyeccion de Sistema ")
            setIdProyeccionToModal(idProyeccion);        
            const loteFind = dataProyeccion.find((lotes)=> lotes.idLote === idLote);        
            if(!loteFind) return toast.error("Lote no encontrado, recargue pagina o consulte con sistemas")
            const validaExisteNumProyCosechado = loteFind.cosechado.find((cosechado) => cosechado.numeroProyeccion === loteFind.proyecciones[posicionRegistro].numeroProyeccion)
            // if(validaExisteNumProyCosechado)return toast.info("Registro Cosechado Consulte con Sistemas para editarlo")
            const numeroProyeccion = loteFind.proyecciones[posicionRegistro];
           
            const validaServerProyeccion = await ProyeccionCtrl.getProyeccionToEditOnTable(idProyeccion)
            if(validaServerProyeccion.data.ErrorMessage) return toast.error(validaServerProyeccion.data.ErrorMessage)
            // console.log(validaServerProyeccion.data)
            if(!validaServerProyeccion.data) return console.log("Proy No existe")
            setProyeccionToModal(validaServerProyeccion.data)
            setNumeroProyeccionToModal(numeroProyeccion.numeroProyeccion);
            setLoteToModal(loteFind);
            // console.log(loteFind)
            setStateModalSetProyeccion(true);
        } catch (error:any) {
            toast.error("Recargue")
        }
    }
    const changeBusquedaInput = (e: InputChange) => {
        setTextBusqueda(e.target.value);
        filterInput(e.target.value)
    }
    const filterInput = (terminoBusqueda: string) => {
        var resultadoBusqueda = busquedaDataProyeccion.filter((input) => {
            if (input.agricultor.toString().toLowerCase().includes(terminoBusqueda.toLowerCase()) || 
            input.numeroContrato.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())) {
                return input
            }
        })
        setdataProyeccion(resultadoBusqueda)
    }
    const getProyectistas = async()=>{
        const resEvaluadores = await ProyeccionCtrl.getUsuariosByTipo("PROYECTISTA")
        setEvaluadores(resEvaluadores.data);
    }
    const pdfBlob = async(res:any, nameFile:string)=>{
        const pdfBlob= await new Blob([res.data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"})
        saveAs(pdfBlob, nameFile)
       }
    const getReporteExcelProyeccion = async()=>{
        try {
            if(arrayFechas.length === 0) return toast.info("Reporte sin datos")
            await ProyeccionCtrl.generateExcelReporteProyeccion({arrayFechas, dataProyeccion})
            const donwloadExcel = await ProyeccionCtrl.downloadReporteProyecciones('ReporteProyeccion.xlsx')
            pdfBlob(donwloadExcel, 'ReporteProyeccion.xlsx')
        } catch (error:any) {
            toast.error(error)
        }        
    }
    const showModalGestionLote = (idLote: string)=>{
        setModalAdmLote(true);
        const loteFound = dataProyeccion.find((lotes)=> lotes.idLote === idLote);
        // console.log(loteFound)
        if(!loteFound) return toast.error("Lote no encontrado, recargue pagina o consulte con sistemas")
        setLoteToModal(loteFound)
    }
    const hideModalAdmLote = () =>{
        setModalAdmLote(false)
    }
    const readUploadFile = (e:any)=>{
        e.preventDefault();
        if (e.target.files) {
            const reader = new FileReader();
            reader.onload = (e: any) => {
                if (e) {
                    const data = e.target.result;
                    const workbook = read(data, { type: "array" , cellDates: true,});
                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];
                    const json = utils.sheet_to_json(worksheet);
                    console.log(json);
                    setImportProyeccion(json)
                }

            };
            reader.readAsArrayBuffer(e.target.files[0]);
        }
    }
    const submitImportProyecciones = async()=>{
        setLoadImportProyeccion(true)
        const resImportProy = await ProyeccionCtrl.importProyecciones(importProyeccion)
        var contratosError = '';
        for (let e = 0; e < resImportProy.data.length; e++) {
            console.log(resImportProy.data)
            const oneError = resImportProy.data[e];
            contratosError = contratosError + ', ' + oneError;
        }
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: "Importacion completa",
            showConfirmButton: false,
            timer: 1000
        }).then(() => {
           if (resImportProy.data.length > 0) {
            Swal.fire({
                position: 'center',
                icon: 'warning',
                title: "Contrato con errores, revisar existencias y fechas",
                showConfirmButton: true,
                text: contratosError
                // timer: 1000
            })
           }
        })
        setLoadImportProyeccion(false)
    }
    const submitImportCosechado = async()=>{    
        setLoadImportCosechado(true)
        const resImportCosechas = await ProyeccionCtrl.importCosechado(importProyeccion)
        var contratosError = '';
        for (let e = 0; e < resImportCosechas.data.length; e++) {
            console.log(resImportCosechas.data)
            const oneError = resImportCosechas.data[e];
            contratosError = contratosError + ', ' + oneError;
        }
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: "Importacion completa",
            showConfirmButton: false,
            timer: 1000
        }).then(() => {
           if (resImportCosechas.data.length > 0) {
            Swal.fire({
                position: 'center',
                icon: 'warning',
                title: "Contrato con errores, revisar existencias y fechas",
                showConfirmButton: true,
                text: contratosError
                // timer: 1000
            })
           }
        })
        setLoadImportCosechado(false)
    }
    const submitImportProyeccionDia = async()=>{    
        setLoadImportProyeccionDia(true)
        const resImportCosechas = await ProyeccionCtrl.importProyeccionesDia(importProyeccion)
        var contratosError = '';
        for (let e = 0; e < resImportCosechas.data.length; e++) {
            console.log(resImportCosechas.data)
            const oneError = resImportCosechas.data[e];
            contratosError = contratosError + ', ' + oneError;
        }
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: "Importacion completa",
            showConfirmButton: false,
            timer: 1000
        }).then(() => {
           if (resImportCosechas.data.length > 0) {
            Swal.fire({
                position: 'center',
                icon: 'warning',
                title: "Contrato con errores, revisar existencias y fechas",
                showConfirmButton: true,
                text: contratosError
                // timer: 1000
            })
           }
        })
        setLoadImportProyeccionDia(false)
    }
    useEffect(() => {
        getProyectistas();
    }, [])
    return (        
        <div className={`${loadDiv?'loadDiv':''} `}>
            <div className="text-center ">
                <h3>RESUMEN PROYECCION  </h3>
            </div>
            
            <div className="row inputBusqueda align-items-end">
                <div className="form-group col-12 col-md-2 align-self-end">
                    <label htmlFor="campaña">Campaña</label>
                    <select name="campaña" onChange={handleChange} className="form-control form-control-lg">
                        <option selected value="2021-2022">2021-2022</option>   
                        <option value="2020-2021">2020-2021</option>  
                        <option value="2021-2022">2021-2022</option>                                   
                    </select>
                </div>                
                <div className="form-group col-12    col-md-2 align-self-end">
                
                    <label htmlFor="idProyectista" >Proyectista</label>
                    <select name="idProyectista" onChange={handleChange} className="form-control form-control-lg">                        
                        <option selected value="">Todos</option>   
                        {evaluadores.map((evaluador) => {
                            return <option key={evaluador._id} value={evaluador._id}>{evaluador.nombre}</option>
                        })}                             
                    </select>
                </div>
                <div className="form-group col-12 col-md-2">
                    <label htmlFor="fechaInicio">Fecha Inicio</label>
                    <input 
                    type="date"                  
                    name="fechaInicio" 
                    max={dataBusqueda.fechaFin}
                    className="form-control form-control-lg"
                    value={dataBusqueda.fechaInicio}
                    onChange={handleChange}
                    />
                </div>
                <div className="form-group col-12 col-md-2">
                    <label htmlFor="fechaFin">Fecha Fin</label>
                    <input 
                    type="date"                     
                    name="fechaFin" 
                    className="form-control form-control-lg"  
                    min={dataBusqueda.fechaInicio} 
                    value={dataBusqueda.fechaFin}
                    onChange={handleChange}                
                    />
                </div>               
                <div className="form-group col-12 col-md-1 align-self-end text-center">
                    
                    <button onClick={BusquedaProyeccion} disabled={stateBtnBusqueda} className="btn btn-lg btn-block btn-outline-dark">
                        {stateBtnBusqueda?
                        <div className="spinner-border text-ligth" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                        :<i className="fas fa-search schFormulario"></i>}                        
                    </button>
                </div>                
            </div>
            <WrapTotal>
                <div>
                    <img src={proyeccionImg} alt=""/>
                    <h3>{totalPeso} Jabas - {totalPeso*12.5} Kg</h3>                        
                </div>  
                <div>
                    <img src={proyCampoImg} alt=""/>
                    <h3>{totalJabasProyCampo} Jabas - {totalJabasProyCampo*12.5} Kg</h3>                        
                </div>   
                <div>
                    <img src={cosechadoImg} alt=""/>
                    <h3>{totalJabasCosechado} Jabas - {totalJabasCosechado*12.5} Kg</h3>                        
                </div>                            
            </WrapTotal>
            <div>

            </div>
            <WrapBusqueda>
                <input type="text" placeholder="Busqueda Agricultor o Contrato"
                    name="busquedaReporte"
                    value={textBusqueda}
                    onChange={changeBusquedaInput}
                />
              <img  onClick={getReporteExcelProyeccion} src={IcoExcel}  className="iconExcel" alt=""/>
                {/* <button  >Excel</button> */}
            </WrapBusqueda>
            <div className="table-responsive mb-5" style={{border:"1px gray solid", borderRadius: "5px"}}>
                <table className="tableFixedColumC1">
                    <thead>
                        <tr >
                            {/* <th scope="col">TECNICO</th> */}
                            <th scope="col">CONTRATO</th>
                            {/* <th scope="col">AGRICULTOR</th> */}
                            <th scope="col">HEC</th>   
                            {arrayFechas.map((fechas)=>{
                                return (<th style={{minWidth: "120px"}}>{fechas.fecha} <br/>
                                            <span style={{fontWeight: "normal", background:"#0B5262", color:"white", padding:"2px", borderRadius:"4px"}}>{fechas.jabasPS}</span> <span style={{fontWeight: "normal", background:"#46AF96", color:"white", padding:"2px", borderRadius:"4px"}} >{fechas.jabasPC}</span> <span style={{fontWeight: "normal", background:"#839192", color:"white", padding:"2px", borderRadius:"4px"}} >{fechas.jabasDiaPC}</span> <span style={{fontWeight: "normal", background:"#49824A", color:"white", padding:"2px", borderRadius:"4px"}}> {fechas.jabasCO}</span> 
                                        </th>)                                
                            })} 
                        </tr>
                    </thead>
                        <tbody>
                            { dataProyeccion.map((Proyecciones)=>{                                                             
                                return (
                                   <tr key={Proyecciones.idLote}>
                                        <td  onClick={()=>showModalGestionLote(Proyecciones.idLote)} style={{cursor:"pointer"}} >{Proyecciones.numeroContrato} <br/>
                                            <span style={{fontSize:"10px"}}>{Proyecciones.agricultor}</span> <br/>
                                            <span style={{fontSize:"10px", background:"gray", color: "white", padding:"4px"}}>{Proyecciones.nombreLote}</span>
                                        </td>                                    
                                    <td>{Proyecciones.areaLote}</td>                                    
                                        {Proyecciones.proyecciones.map((proyeccion, index)=>{
                                            return(                                                
                                                    <td key={Proyecciones.idLote + '::' + index} >
                                                        <WrapTDProyeccion onClick={() => { setModalProyeccionCosechado(Proyecciones.idLote+'::'+proyeccion.idProyeccion + '::' + index) }}>
                                                                    {proyeccion.jabas ? 
                                                                        <TDProySis> 
                                                                            <span>{proyeccion.jabas}</span>{" ps #"+proyeccion.numeroProyeccion}
                                                                        </TDProySis>: 
                                                                           <TDNull></TDNull>} 
                                                                    {Proyecciones.proyeccionesCampo[index]?.jabas ? 
                                                                        <TDProyCam>
                                                                            <span>{Proyecciones.proyeccionesCampo[index]?.jabas}</span>{' pc #'+Proyecciones.proyeccionesCampo[index]?.numeroProyeccion}
                                                                        </TDProyCam> :                                                                        
                                                                        <TDNull></TDNull>} 
                                                                    {Proyecciones.proyeccionesDiaCampo[index]?.jabas ? 
                                                                        <TDProyDiaCam>
                                                                            <span>{Proyecciones.proyeccionesDiaCampo[index]?.jabas}</span>{' pc #'+Proyecciones.proyeccionesDiaCampo[index]?.numeroProyeccion}
                                                                        </TDProyDiaCam> :                                                                        
                                                                        <TDNull></TDNull>} 
                                                                    {Proyecciones.cosechado[index]?.jabas ? 
                                                                        <TDCosechado>
                                                                            <span>{Proyecciones.cosechado[index]?.jabas}</span>{' c #'+Proyecciones.cosechado[index]?.numeroProyeccion}
                                                                        </TDCosechado>:
                                                                        <TDNull></TDNull>}
                                                        </WrapTDProyeccion>
                                                    </td>
                                                )
                                        })}
                                   </tr>                                   
                                )
                            })}                                 
                        </tbody>
                </table>
            </div>
            <div className="border-bottom">
                Modulo de Importacion
            </div>
            <div className="d-flex flex-wrap justify-content-center align-items-start">              
                <div className="d-flex">
                <div className="m-2">                    
                    <input type="file"
                    className="form-control"
                        accept=".xlsx"
                        placeholder="Excel"
                        onChange={readUploadFile} /> 
                                          
                </div>
                <div className="m-1">
                    <button className="btn btn-outline-dark" disabled={loadImportProyeccion} style={{ fontSize: "20px" }} onClick={submitImportProyecciones}>                        
                        {loadImportProyeccion
                        ? <>
                            <div className="spinner-border text-dark" role="status">
                            <span className="sr-only">Loading...</span>
                            </div>
                        </>
                        :
                        <>
                           Proyeccion Semanal <i className="fa-solid fa-file-arrow-up"></i>
                        </>}
                    </button>
                </div>
                </div>
                <div className="d-flex">
                <div className="m-2">                
                    <input type="file"
                        className="form-control"
                        accept=".xlsx"
                        placeholder="Excel"
                        onChange={readUploadFile} />                        
                </div>
                <div className="m-1">
                    <button className="btn btn-outline-dark" disabled={loadImportProyeccionDia} style={{ fontSize: "20px" }} onClick={submitImportProyeccionDia}>                        
                        {loadImportProyeccionDia
                        ? <>
                            <div className="spinner-border text-dark" role="status">
                            <span className="sr-only">Loading...</span>
                            </div>
                        </>
                        :
                        <>
                           Proyeccion Diaria <i className="fa-solid fa-file-arrow-up"></i>
                        </>}
                    </button>
                </div>
                </div>
                <div className="d-flex">
                <div className="m-2">                
                    <input type="file"
                        className="form-control"
                        accept=".xlsx"
                        placeholder="Excel"
                        onChange={readUploadFile} />                        
                </div>
                <div className="m-1">
                    <button className="btn btn-outline-dark" disabled={loadImportCosechado} style={{ fontSize: "20px" }} onClick={submitImportCosechado}>
                        
                        {loadImportCosechado
                        ? <>
                            <div className="spinner-border text-dark" role="status">
                            <span className="sr-only">Loading...</span>
                            </div>
                        </>
                        :
                        <>
                           Cosechas <i className="fa-solid fa-file-arrow-up"></i>
                        </>}
                    </button>
                </div>
                </div>                
            </div>
            <ModalSetProyeccion 
                show={stateModalSetProyeccion} 
                lote={loteToModal}
                idProyeccion={idProyeccionToModal}
                allProyeccion={proyeccionToModal}
                numeroProyeccion={numeroProyeccionToModal}
                onHide={handleHideModalSetProyeccion} 
                onHideFun={afterHideModalSetProyeccion} 
            />
            <ModalAdmLote 
                show={modalAdmLote}
                lote={loteToModal}
                proyectistas= {evaluadores}
                onHide={hideModalAdmLote}
            />
            
        </div>        
            
        
    )
}

export default ReporteProyeccion
