import React, { useState, useEffect } from 'react'
import { cogida, cogidaList } from './cogida'
import * as cogidaService from './CogidaService'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import moment from 'moment';
// import ModalDash from './ModalDash/ModalDash'
import ModalCogida from './ModalCogida/ModalCogida'
// PDF-REPORT
// import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

function CogidaList() {
    const history = useNavigate();
    const [cogida, setCogidas] = useState<cogidaList[]>([]);
    const [loadDiv, setLoadDiv] = useState(false)
    const loadCogidas = async () => {
        const resCogidas = await cogidaService.getCogidasLocalidadDep();
        
        setCogidas(resCogidas.data);
    }
    const pdfBlob = async (res: any) => {
        const pdfBlob = await new Blob([res.data], { type: 'appication/pdf' })
        // saveAs(pdfBlob, 'newPdf.pdf')
    }
    const handlePrintPdf = async (id: string) => {
        try {
            setLoadDiv(true);
            await cogidaService.CretePdfCosecha(id);
            const getPdfLote = await cogidaService.SendPdfCosecha();
            pdfBlob(getPdfLote);
            setLoadDiv(false);
        } catch (error:any) {
            console.log(error);
            setLoadDiv(false);
            toast.error('504 Consulte con Sistemas');
        }
    }

    useEffect(() => {
        loadCogidas();

    }, [])

    const handleDelete = async (id: string) => {
        try {
            await cogidaService.deleteCogida(id);
            loadCogidas();
        } catch (error:any) {
            if (error.message === 'Request failed with status code 303') {
                toast.error('No se puede eliminar, registros pendientes');
            }
        }
    }
    const [show, setShow] = useState({ estado: false, idLote: '' });

    const handleCloseModal = () => setShow({ idLote: '', estado: false });
    const handleShowModal = (e: any) => {

        setShow({ idLote: e.target.id, estado: true });
    }
    const [busqueda, setBusqueda] = useState({
        contrato: ''
    })

    const handleChangeBusqueda = (e: any) => {
        setBusqueda({ contrato: e.target.value });
    }
    const handleSubmitBusquedaCosecha = async (e: any) => {
        e.preventDefault();
        try {
            
            const resBusqueda = await cogidaService.busquedaCosechaList(busqueda)

            if(resBusqueda.data.length>0) {
                setCogidas(resBusqueda.data);
                toast.success('Busqueda Realizada')
            }else{
                toast.info("El Contrato no tiene Cosechas ingresadas")
                setCogidas([]);
            }
            
            
        } catch (error:any) {
            if (error.message === 'Request failed with status code 304') {
                toast.error("El lote no existe")
            } else {
                toast.error("Intente volver a iniciar Sesion")
            }
        }

    }
    return (
        <div className={`${loadDiv ? 'loadDiv' : ''}`}>
            <div className="text-center ">
                <h3>COSECHAS
                    {/* <svg onClick={() => history.push('/new-cogida')} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                </svg> */}
                    <button className="btn btn-outline-dark " onClick={() => history('/new-cogida')}><i className="fas fa-plus btnadd"></i>
                    </button>
                </h3>
            </div>
            <div className="row mt-5">
                {/* <div className="form-group col-12 col-md-3">
                    <label htmlFor="fechaInicio">Fecha Inicio</label>
                    <input type="date" id="fechaInicio" name="fechaInicio" className="form-control"/>
                </div>
                <div className="form-group col-12 col-md-3">
                    <label htmlFor="fechaFin">Fecha Fin</label>
                    <input type="date" id="fechaFin" name="fechaFin" className="form-control"/>
                </div> */}

                {/* <div className="form-group col-12 col-md-2 align-self-end">
                
                    <select name="valorbusqueda" id="valorbusqueda" className="form-control">
                        <option selected>COLUMNA</option>
                        <option value="">LOTE</option>
                        <option value="">PROVEEDOR</option>
                        <option value="">PLACA</option>
                        <option value="">EVALUADOR</option>
                    </select>
                </div> */}
                <div className="form-group col-12 col-md-3 align-self-end">
                    <label htmlFor="">Ingrese numero exacto de contrato</label>
                    <input type="text" className="form-control" onChange={handleChangeBusqueda} value={busqueda.contrato} placeholder="" />
                </div>
                <div className="form-group col-12 col-md-1 align-self-end text-center">
                    <button className="btn btn-outline-dark" onClick={handleSubmitBusquedaCosecha}><i className="fas fa-search schFormulario"></i></button>
                </div>
            </div>
            <div className="table-responsive table-striped col-12 mx-auto shadow mb-5 bg-body rounded trheight tableFixHead">

                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">DEPARTAMENTO</th>
                            <th scope="col">LOCALIDAD</th>
                            <th scope="col">LOTE</th>
                            <th scope="col">NUMERO COSECHA</th>

                            <th scope="col">PESO</th>
                            <th scope="col">JABAS</th>
                            <th scope="col">CALIBRE</th>
                            <th scope="col">GUIA DE REMISION</th>
                            {/* <th scope="col">PROVEEDOR</th> */}
                            <th scope="col">PLACA</th>
                            <th scope="col">EVALUADOR</th>
                            <th scope="col">FECHA</th>

                            <th scope="col">OPCIONES___</th>
                        </tr>
                    </thead>
                    <tbody>

                        
                                
                                    {cogida.map((cogida) => {
                                                                                
                                        return <tr key={cogida._id}>
                                            <td>{cogida.lote.localidad.departamento.nombre}</td>
                                            <td>{cogida.lote.localidad.nombre}</td>
                                            <td>{cogida.lote.numeroContrato}</td>
                                            <td>{cogida.numCosecha}</td>

                                            <td>{cogida.peso}</td>
                                            <td>{cogida.jabas}</td>
                                            <td>{cogida.calibre}</td>
                                            <td>{cogida.guiaRemision}</td>
                                            {/* <td>{cogida.proveedor.nombre}</td> */}
                                            <td>{cogida.placa}</td>
                                            <td>{cogida.evaluador.nombre}</td>
                                            <td>{moment(cogida.fecha).format('YYYY-MM-DD')}</td>
                                            <td className="row">
                                                <div className="col-4">
                                                    <i className="far fa-file-alt" onClick={() => cogida._id && handlePrintPdf(cogida._id)}></i>
                                                </div>
                                                <div className="col-4">
                                                    <i onClick={() => history(`/update-cogida/${cogida._id}`)} className="fas fa-pen"></i>
                                                </div>
                                                <div className="col-4">
                                                    <i id={cogida._id} onClick={handleShowModal} className="fas fa-trash"></i>
                                                </div>


                                            </td>
                                        </tr>
                                    })
                                    }
                        

                    </tbody>
                </table>

            </div>
            <ModalCogida show={show} onHide={handleCloseModal} />
        </div>
    )
}

export default CogidaList
