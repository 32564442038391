import React, { useState, useEffect, MouseEventHandler } from 'react'
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import moment from 'moment';
import * as ALMACENESservice from '../AlmacenesService'
import FormSalida from './FormSalida'
import { ingreso,salida, almacenes, tipoMovimiento, detIngreso } from '../TypesAlmacenes'
import {
    Input, Label, Select, Option, Container, FormContainer, Form
    , TableContainer, Table, THead, TH, TR,TRbody, TBody, TD, ContainerInput
    , SPAN, ButtonForm, BtnOpt
} from './ModalSalidaElements'

interface Props {
    show: boolean,
    onHide: MouseEventHandler<HTMLElement>,
    idAlmacen: almacenes | undefined
}
function ListSalidas(props: Props|any) {
    const initialSalida = {
        _id:'',
        estado: 'PENDIENTE',
        numero: 0,
        almacenOrigen: {
            _id: '',
            nombre: ''
        },
        tipoMovimiento: {
            _id: '',
            descripcion: ''
        },
        almacenDestino:{
            _id: '',
            nombre: ''
        },
        proveedorDestino:{
            _id: '',
            nombre: ''
        },
        ordenSiembra:'',
        loteAgricultor:{
            _id:'',
            numeroContrato:''
        },
        fechaSalida: '',
        guiaRemision:'',
        subTotal:0,
        igvTotal:0,
        total:0
    }
    const [modalFormSalida, setModalFormSalida] = useState(false);
    const [salida, setSalida] = useState<salida>(initialSalida)
    const [listSalidas, setListSalidas] = useState<salida[]>([])
    
    const showModalCreateSalida = () =>{
        setModalFormSalida(true)
    }
    const handleCloseModalSalida = () =>{
        setModalFormSalida(false)
        setSalida(initialSalida)
        loadListSalidas(); 
    }
    const loadListSalidas = async() =>{
        if (props.idAlmacen?._id) {
            const resSalidas = await ALMACENESservice.getSalidasByAlmacen(props.idAlmacen?._id);
            console.log(resSalidas.data)
            setListSalidas(resSalidas.data);
        }else{
            toast.info("Almacen no definido");
        }
    }
    const viewSalida = (id: string|undefined) =>{
        const selectSalida = listSalidas.find(salida=>salida._id===id)
        if (selectSalida?._id) {            
            setSalida(selectSalida)
            setModalFormSalida(true);
        }else{
            toast.error("Registro no Mapeado")
        }        
    }
    useEffect(() => {
        if (props.show) {
            loadListSalidas(); 
        }else{
            setListSalidas([]);
        }
    }, [props.show])
    return (
        <>
            <Modal
                show={props.show}
                onHide={props.onHide}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                // fullscreen={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <Container>
                            <FormContainer align={'flex-center'}>
                                <ContainerInput size={'400px'}>
                                    SALIDAS - 
                                    {props.idAlmacen?.nombre}
                                </ContainerInput>
                                <ContainerInput size={'200px'}>
                                    <ButtonForm 
                                    onClick={showModalCreateSalida}
                                    >
                                        Crear
                                    </ButtonForm>
                                </ContainerInput>
                            </FormContainer>
                        </Container>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Container>
                            <FormContainer align={'flex-start'}>
                                <ContainerInput size={'200px'}>
                                    <Label htmlFor='fechaInicial'>Fecha Inicio</Label>
                                    <Input type="date" name="fechaInicial" id="fechaInicial"
                                    bg={'#fff'}/>                                  
                                </ContainerInput>
                                <ContainerInput size={'200px'}>
                                    <Label htmlFor='fechaFin'>Fecha Fin</Label>
                                    <Input type="date" name="fechaFin" id="fechaFin"
                                    bg={'#fff'}/>                                  
                                </ContainerInput>
                                <ContainerInput size={'200px'}>
                                    <Label htmlFor='fechaFin'>Fecha Fin</Label>
                                    <Select  id="fechaFin"
                                    bg={'#fff'}>
                                        <Option value=''>Seleccione</Option>
                                        <Option value='numero'>Numero Ingreso</Option>
                                        <Option value='estado'>estado</Option>
                                        <Option value='tipoMovimiento'>Tipo Movimiento</Option>
                                        <Option value='proveedor'>Proveedor</Option>
                                        </Select> 
                                </ContainerInput>
                                <ContainerInput size={'200px'}>
                                    <Label htmlFor='textBusqueda'>Valor</Label>
                                    <Input type="text" name="textBusqueda" id="textBusqueda"
                                    bg={'#fff'}/>                                  
                                </ContainerInput>
                            </FormContainer>
                        </Container>
                    <TableContainer height={'10%'} className="table-responsive  col-12 mx-auto shadow mb-5 bg-body rounded trheight tableFixHead">
                        <Table >
                            <THead>
                                <TR>
                                    <TH>ESTADO</TH>
                                    <TH>NUMERO</TH>
                                    <TH>TIPO MOVIMIENTO</TH>
                                    <TH>ALM ORIGEN</TH>
                                    <TH>CLIENTE DESTINO</TH>                                    
                                    <TH>ALM DESTINO</TH>                                    
                                    <TH >FECHA SALIDA</TH>                                    
                                    <TH >SUBTOTAL</TH>
                                    <TH >IGV TOTAL</TH>
                                    <TH >TOTAL</TH>                                    
                                </TR>
                            </THead>
                            <TBody>

                                {listSalidas.map((salidas) => {
                                    return <TRbody id={salidas._id} key={salidas._id} 
                                            onClick={()=>viewSalida(salidas._id)}>
                                        <TD>{salidas.estado}</TD>
                                        <TD>{salidas.numero}</TD>
                                        <TD>{salidas.tipoMovimiento.descripcion}</TD>
                                        <TD>{salidas.almacenOrigen.nombre}</TD>
                                        <TD>{salidas.proveedorDestino?.nombre}</TD>
                                        
                                        <TD>{salidas.almacenDestino?.nombre}</TD>                                        
                                        <TD>{moment(salidas.fechaSalida).format('YYYY-MM-DD')}</TD>                                        
                                        <TD>{salidas.subTotal}</TD>
                                        <TD>{salidas.igvTotal}</TD>
                                        <TD>{salidas.total}</TD>
                                      
                                    </TRbody>
                                })}

                            </TBody>
                        </Table>
                    </TableContainer>

                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>
            <FormSalida idAlmacen={props.idAlmacen} salida={salida} show={modalFormSalida} onHide={handleCloseModalSalida} />
        </>
    )
}

export default ListSalidas
