import React,{ useRef, useState, useEffect } from 'react'
import {MainContainer, ContainerInputs, SelectGeneral, InputGeneral, ButtonGeneral,
  InputGeneralLG, TextAreaSet, SelectGeneralSet } from '../../stylesGeneral/mainElements'
import {WrapRelog} from './MarcadorCapElements'
import {areas, temas, capacitadores} from './registroPersonal'
import momentz from 'moment-timezone';
import Swal from 'sweetalert2'
import { toast } from 'react-toastify'
import * as registroPersonalServices from './RegistroPersonalServices'

function MarcadorCap() {

  const [dateNow, setDateNow] = useState('')
  const [dni, setDni] = useState('')
  
  const [capacitadores, setCapacitadores] = useState<capacitadores[]>([])
  const [areas, setAreas] = useState<areas[]>([])
  const [temas, setTemas] = useState<temas[]>([])
  const [nombreTemaActual, setNombreTemaActual] = useState('')
  const [dataMarcacion, setDataMarcacion] = useState({dni: '', idCapacitador: '', idArea: '', idTema:''})
  
  const setHora = async()=>{
    const current = momentz();
    setDateNow(momentz.tz(current, "America/Lima").format("HH:mm:ss"))
  }
  const handleChangeDataMarcacion = (e:any)=>{
    setDataMarcacion({...dataMarcacion, [e.target.name]: e.target.value})
    if(e.target.name === 'idArea') selectArea(e.target.value)
    if (e.target.name === 'idTema') {
      const findNombreTema = temas.find((tema)=> tema._id === e.target.value );      
      if ( findNombreTema ) setNombreTemaActual(findNombreTema.nombre)
    }
  }
  const submitMarcacion = async(e:any) =>{
    if(e.key === 'Enter'){
      if(!dataMarcacion.dni ||!dataMarcacion.idCapacitador || !dataMarcacion.idArea || !dataMarcacion.idTema){
        setDataMarcacion({...dataMarcacion, dni: ''})
        return Swal.fire({
          position: 'center',
          icon: 'error',
          title:  "COMPLETE DATOS PARA REALIZAR MARCACION",
          showConfirmButton: false,
          timer: 2000
        })
      }
      const resMarcacion = await registroPersonalServices.registrarMarcacion(dataMarcacion)
      if(resMarcacion.data.MessageError) {
        setDataMarcacion({...dataMarcacion, dni: ''})
        return Swal.fire({
          position: 'center',
          icon: 'error',
          title: resMarcacion.data.MessageError,
          showConfirmButton: false,
          timer: 2000
        })
      }
      const { NOMBRES, A_PATERNO, A_MATERNO, ACCION} = resMarcacion.data;
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: NOMBRES +" "+A_PATERNO+" "+A_MATERNO,
        text: 'Marcacion Correcta',
        showConfirmButton: false,
        timer: 2000,
        html:'<b class = "text-info">' + ACCION +'</b>'
      })

      setDataMarcacion({...dataMarcacion, dni: ''})
    }
         
  }
  const selectArea = async(e:string)=>{
    const resTemas = await registroPersonalServices.getTemasbyAreasActivos(e)
    if(resTemas.data) return setTemas(resTemas.data)    
  }
  const getAreas = async() =>{
    const resAreas = await registroPersonalServices.getAreasActivos();
    setAreas(resAreas.data)
  }
  const getCapacitadores = async()=>{
    const resCapacitadores= await registroPersonalServices.getAllCapacitadoresActivos();
    setCapacitadores(resCapacitadores.data)
  }
  useEffect(() => {
    setInterval(setHora, 1000);
    getAreas();
    getCapacitadores();
  }, [])
  // useEffect(() => {
  //   if (dataMarcacion.dni.length>=8) {
  //     submitMarcacion();
  //     // setDataMarcacion({...dataMarcacion, dni: ''})
  //   }
  // }, [dataMarcacion.dni])
  
  return (
    <>
    <MainContainer>
      <h3>REGISTRO DE CAPACITACIONES</h3>
    </MainContainer>
    <MainContainer>
      <WrapRelog>
        <div>
        {dateNow}
        </div>
    
      </WrapRelog>      
    </MainContainer>
    <MainContainer>
    <ContainerInputs width='600px'>  
          <label htmlFor="">Capacitador</label>        
          <SelectGeneral height="30px" bg="#495563"              
              name="idCapacitador"
              onChange={handleChangeDataMarcacion}
          >
            <option value=""> Seleccione </option>
          {capacitadores.map((capacitador)=>{
            return(
              <option value={capacitador._id}> {capacitador.nombres} {capacitador.apellidos} </option>
            )
          })}                      
          </SelectGeneral>
      </ContainerInputs> 
    </MainContainer>
    <MainContainer>     
    <ContainerInputs width='250px'>  
          <label htmlFor="">Area</label>        
          <SelectGeneral height="40px" bg="#495563"              
              name="idArea"
              onChange={handleChangeDataMarcacion}
          >
            <option value=""> Seleccione </option>
          {areas.map((area)=>{
            return(
              <option value={area._id}> {area.nombre} </option>
            )
          })}
          </SelectGeneral>
      </ContainerInputs> 
     
      <ContainerInputs width='350px'>
          <label htmlFor="">Tema Capacitacion</label>
            <SelectGeneralSet height="40px" bg="" width="" textAlign=""              
              name="idTema"
              onChange={handleChangeDataMarcacion}
          >
            <option value=""> Seleccione </option>
            {temas.map((tema)=>{
            return(
              <option value={tema._id}> {tema.nombre} </option>
            )
          })}
          </SelectGeneralSet>
      </ContainerInputs>
    </MainContainer>
    <MainContainer>
    <ContainerInputs width='600px'>  
          <TextAreaSet                              
              name="nombreTema"
              value={nombreTemaActual}            
          />        
      </ContainerInputs> 
    </MainContainer>
     <MainContainer>
    <ContainerInputs width='600px'> 
          <label htmlFor="dni">DNI</label>
          <InputGeneralLG width="100%" height="55px"
              autoFocus               
              name="dni"
              value={dataMarcacion.dni}
              onKeyDown={submitMarcacion}
              onChange={handleChangeDataMarcacion}
          />        
      </ContainerInputs> 
    </MainContainer>
        <div style={{height:"300px"}}>

        </div>
    </>    
  )
}

export default MarcadorCap