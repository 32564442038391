import React, { useState, useEffect } from 'react'
import * as almacenMPServices from '../AlmacenMP/AlmacenMPServices'
import {  reporteParteDiario} from '../AlmacenMP/almacen'
import { toast } from 'react-toastify';
import IcoExcel from '../../../Media/svg/excel.svg'
import momentz from 'moment-timezone';
import saveAs from 'file-saver'
function ParteDiario() {

  
   
    const [reporteDetPallet, setReporteDetPallet] = useState<reporteParteDiario[]>([]);
   
    const [dataGetReporte, setDataGetReporte] = useState({ planta: '', fecha:'', proceso:''})
  
    const [stateBtnSubmit, setStateBtnSubmit] = useState(false)
    const handleSetDataToSearch = async (e: any) => {
        setDataGetReporte({ ...dataGetReporte, [e.target.name]: e.target.value })
    }

    /*
        Cargar Reporte
    */
    const loadDetallePallets = async () =>{

       
        if(dataGetReporte.planta=='')
        {
            toast.info ("Debe seleccionar una Planta")
            return;
        }

        if(dataGetReporte.fecha=='')
        {
            toast.info("Seleccione la fecha de producción que desea consultar")
            return;
        }



        setStateBtnSubmit(true)
        setReporteDetPallet([]);
        const resReporte = await almacenMPServices.getParteDiario (dataGetReporte)
        if (resReporte.data.length !<0){
            setStateBtnSubmit(false)
            return toast.error("Consulta sin registros")
        } 
        setReporteDetPallet(resReporte.data)
        setStateBtnSubmit(false)
    }

    /*
    Exportar a Excel
    */
    const getExcelDetPallet = async () =>{
        try {
            if (reporteDetPallet.length===0) return toast.error("Reporte sin datos")
            const fullData = { reporteDetPallet, dataGetReporte};
            await almacenMPServices.createExcelParteDiario(fullData)
            const resExcel = await almacenMPServices.getReportePallet('ReporteParteDiario.xlsx');
            pdfBlob(resExcel, 'ReporteDetallePallets.xlsx')  
        } catch (error:any) {
               toast.error(error.message)
        }
    }



    const pdfBlob = async(res:any, nameFile:string)=>{
        const pdfBlob= await new Blob([res.data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"})
        saveAs(pdfBlob, nameFile)
       }




    useEffect(() => {
        //loadAlmacenes();
    }, [])
    return (
        <>
            <div className='container-fluid' style={{ width: '95%', left:'50px',padding:'0', margin:'0px', position:'fixed' }}>                   
                <div className='row text-center'>
                    <h1>PARTE DIARIO DE PRODUCCIÓN</h1>
                </div> 
                <div className='row'>
                    <div className='col-3 '>
                        <label htmlFor="idAlmacen">PLANTA</label>
                        <select className='form-control ' id='planta' name='planta' onChange={handleSetDataToSearch}>
                            <option value="">Seleccione</option>
                            <option value="AREQUIPA">AREQUIPA</option>
                            <option value="CUSCO">CUSCO</option>
                            
                        </select>
                    </div>
                    

                    <div className='col-3'>
                        <label htmlFor="fecha">FECHA DE PRODUCCION</label>
                        <input className="form-control" type="date" name="fecha"
                            onChange={handleSetDataToSearch}
                            placeholder='AAAA-MM-DD' />
                    </div>
                    <div className='col-2'>                        
                                <label htmlFor="fecha">&nbsp;</label>
                                <button className='btn btn-primary btn-block' disabled={stateBtnSubmit} onClick={loadDetallePallets}>
                                {stateBtnSubmit 
                                ?   <div className="spinner-border text-ligth" role="status">
                                        <span className="sr-only">Cargando...</span>
                                    </div>
                                : 'Cargar' }                        
                                </button>
                    </div>

                    <div className='col-2' >
                        <label htmlFor="fecha">&nbsp;</label>
                        <button className='btn btn-success btn-block' onClick={getExcelDetPallet} >Exportar</button>
                    </div>

                </div>
                <div className="row">
                      <div className='12'>&nbsp;</div>                  
                </div>

                <div>
                    <div className="table-responsive table-striped col-12 col-md-12 mx-auto shadow mb-5 bg-body rounded trheight tableFixHead">
                        <table className="table">
                            <thead>
                                <tr className=''>
                                    <th scope="col">CODIGO</th>
                                    <th scope="col">DESCRIPCIÓN</th>
                                    <th scope="col">SUBPROCESO</th>
                                    <th scope="col">TOTAL</th>
                                    <th scope="col">CALIDAD</th>
                                    <th scope="col">P.TOTAL</th>
                                </tr>
                            </thead>
                            <tbody>
                                {reporteDetPallet.map((detallePallet, index) => {
                                    return <tr key={index}>
                                        <td>{detallePallet._id.codigo}</td>
                                        <td>{detallePallet._id.descripcion}</td>
                                        <td>{detallePallet._id.subproceso}</td>
                                        <td align="center">{detallePallet.proddia}</td>
                                        <td align="center">0</td>
                                        <td align="center">{detallePallet.proddia}</td>
                                    </tr>
                                })
                                }
                                <tr>
                                    <td >-</td>
                                    <td >-</td>
                                    <td >-</td>
                                    <td >-</td>
                                    <td >-</td>
                                    <td >-</td>
                                </tr>
                                <tr>
                                    <td >-</td>
                                    <td >-</td>
                                    <td >-</td>
                                    <td >-</td>
                                    <td >-</td>
                                    <td >-</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>  

            </div>

        </>
    )
}
export default ParteDiario
