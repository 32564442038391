import axios from 'axios'
import {UsuarioInterface, UsuarioState} from './usuario'
import {loginup} from '../Login/loginup';
// import path from 'path';

import apiURL from '../../context/apiURL'
const API = apiURL.API;

export const getUsuarios = async () => {
   return await axios.get<UsuarioInterface[]>(`${API}/usuarios`)    
}

export const isValidateLogin = async () => {
   return await axios.get<UsuarioState>(`${API}/verifylogin`)    
}

export const createUsuario = async (usuario: UsuarioInterface) => {
   return await axios.post(`${API}/usuarios`, usuario)    
}

export const getUsuario = async (id: string) => {
   return await axios.get<UsuarioInterface>(`${API}/usuarios/${id}`)    
}

export const updateUsuarios = async (id: string, usuario: UsuarioInterface) => {
   return await axios.put<UsuarioInterface>(`${API}/usuarios/${id}`, usuario)    
}

export const deleteUsuario = async (id: string) => {
   return await axios.delete<UsuarioInterface>(`${API}/usuarios/${id}`)    
}

export const loginUsuario = async (usuario: loginup) => {
   console.log(API)
   return await axios.post<UsuarioInterface>(`${API}/login/`, usuario)    
}
export const logoutUsuario = async () => {
   return await axios.get<UsuarioInterface>(`${API}/logout/`)    
}