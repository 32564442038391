import React, { MouseEventHandler, FormEvent, useEffect, useState, useContext } from 'react'
import { Modal } from 'react-bootstrap';
import * as calidadProdServices from '../CalidadProduccionServices'
import { listInputs, currentInput } from '../typesCalidadProduccion'
import { toast } from 'react-toastify';
import moment from 'moment';
import authContext from '../../../context/AuthContext'
interface Props {
    show: boolean,
    onHide: MouseEventHandler<HTMLElement>,
    input: currentInput,
    afterHidenModal: () => void,
    changeControlSubmit: () => void
}
function ModalInputRegMas(props: Props|any) {
    const { loggedIn } = useContext(authContext);
    const initialRegInput = { _id: '', idInput: '', cantidad: 0, fechaRegistro: '', idUsuario: loggedIn._id }
    const [registroInput, setRegistroInput] = useState(initialRegInput)
    const submitRegistroInput = async () => {

    }
    const changeFormRegInput = async(e: any) => {                
            setRegistroInput({ ...registroInput, [e.target.name]: e.target.value })      
    }
    const updateFechaReg = async()=>{
        const resOneRegByfecha = await calidadProdServices.getOneRegInputByFecha({idInput: registroInput.idInput , fechaRegistro: registroInput.fechaRegistro, idUsuario: registroInput.idUsuario})                      
        if (resOneRegByfecha.data) {
            if (resOneRegByfecha.data._id) {
                setRegistroInput({...registroInput, _id: resOneRegByfecha.data._id, cantidad: resOneRegByfecha.data.cantidad})
                toast.success("Fecha con Registro")
            }else{
                toast.error("Error en Peticion")
            }            
        }else{
            setRegistroInput({...registroInput, _id: '', cantidad: 0})
            toast.warning("Fecha sin Registro")
        }       
    }

    const submitRegInput = async(e:any)=>{
        e.preventDefault();
        try {
            console.log(registroInput);
            if (!registroInput.fechaRegistro) return toast.error("Asigne Fecha")    
            const resCreateUpdateReg = await calidadProdServices.createUpdateRegInput(registroInput);
            toast.success("Registrado")
            props.changeControlSubmit();
            // props.afterHidenModal();
        } catch (error:any) {
            toast.error(error.message)
        }
    }
    const resetForm = () =>{
        setRegistroInput(initialRegInput)
    }  
    const getRegistro = () =>{
     console.log("OBTENIENDO REGISTRO")   
    }
    // useEffect(() => {
    //     if (registroInput.fechaRegistro) {
    //         updateFechaReg();
    //     }
    // }, [registroInput.fechaRegistro])
    useEffect(() => {
        if (!props.show) {
            resetForm();
        }else{
            setRegistroInput({ ...registroInput, idInput: props.input._id})
        }
    }, [props.show])
    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.onHide}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center col-12 col-sm-12 col-md-6 mx-auto pb-3">                        
                        <h3 className="text-dark font-weight-bold">{props.input.nameInput}</h3>
                        <p>{props.input.categoria.nombre} - {props.input.idSubCatInput.nombre}</p>
                    </div>
                    <form onSubmit={submitRegistroInput}>
                        <div className="d-flex flex-wrap justify-content-center">
                            <div className="p-3">
                                <label htmlFor="" className="text-dark">Fecha</label>
                                <input className="form-control form-control-lg "
                                    name="fechaRegistro"
                                    type="date"                                    
                                    onChange={changeFormRegInput}
                                    onBlur={updateFechaReg}
                                    value={registroInput.fechaRegistro}
                                    required />                                
                            </div>
                            <div className="p-3">
                                <label htmlFor="" className="">Cantidad</label>
                                <div className="row align-items-center">
                                    <input className="form-control form-control-lg col-12 col-sm-12 col-md-8" type="number" step="any" min="0"
                                        name="cantidad"
                                        onChange={changeFormRegInput}                                        
                                        value={registroInput.cantidad}
                                        required />
                                    <h5 className='col-12 col-sm-12 col-md-4 text-center  font-weight-bold'>{props.input.uMedida.nombre}</h5>
                                </div>
                            </div>
                        </div>
                        <div className="text-center p-2">
                            <button className={`btn ${registroInput._id ? 'btn-info': 'btn-dark'} btn-lg`} onClick={submitRegInput}>{registroInput._id ? 'Editar':'Crear'} Registro</button>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ModalInputRegMas
